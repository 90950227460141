import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import React, { useRef, useState } from 'react';
import { EditPageHeader } from '../../components/EditPageHeader';
import { toast } from 'react-toastify';
import * as API from "../../framework/API/api_digitalSign";
import { useTranslation } from '../../locale/useTranslation';

const ActivateScreenPoup = ({onClose}) => {

    const lang = useTranslation();
    const [otp, setOtp] = useState(['', '', '', '']);
    const [name, setName] = useState('');
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move to next input if current field is filled
        if (element.value.length === 1) {
            if (index < 3) {
                inputRefs[index + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            if (otp[index]) {
                const newOtp = [...otp];
                newOtp[index] = '';
                setOtp(newOtp);
            } else if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        }
    };

    const handleFocus = (index) => {
        inputRefs[index].current.select();
    };

    const onSubmit = async () => {
        if (name.length == 0) {
            toast.info(lang.please_enter_screen_name_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return
        }
        if (otp[0] == '' || otp[1] == '' || otp[2] == '' || otp[3] == '') {
            toast.info(lang.please_enter_otp_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return
        }
        const enteredOtp = otp.join('')
        let res = await API.activateScreen(enteredOtp, name);
        if (!res.success) {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            if (res.data.isValid) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                if (res.data.isActive) {
                    await API.refreshPlaylist(res.data.socketID);
                }
                onClose(true)
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }

    return (
        <Dialog
            title={lang.activate_screen_dialog_header}
            onClose={onClose}
            width={"280px"}
            height={"300px"}
        >
            <EditPageHeader saveBtnColor={'success'} saveButtonTitle={lang.activate_button_text} title={lang.activate_screen_button_tooltip} showTitle={false} onSubmit={onSubmit} onCancel={onClose} />
            <div className='mt-2'>
                {/* screen name input*/}
                <div className='mb-4'>
                    <label>Name</label>
                    <Input name='screenName' type="text" placeholder='Enter Screen Name' value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                {/* 4 DIGIT OTP BOXES */}
                <label className='pb-1'>PIN</label>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {otp.map((data, index) => {
                        return (
                            <input
                                className="otp-input"
                                key={index}
                                type="text"
                                maxLength="1"
                                ref={inputRefs[index]}
                                value={data}
                                onChange={e => handleChange(e.target, index)}
                                onKeyDown={e => handleKeyDown(e, index)}
                                onFocus={() => handleFocus(index)}
                            />
                        )
                    })}
                </div>
            </div>
        </Dialog>
    );
}

export default ActivateScreenPoup;
