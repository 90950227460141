import { toast } from "react-toastify";
import { DIGITAL_SIGN_SOCKET_BASEURL } from "../constant/baseurl";
import { COLUMNSTYPE, CONFIGURL, DIGITALSIGNURL, ENTITYNAME, GetHeader, LOCALSTORAGE_KEY, LOGINURL } from "../constant/constant";
import { utility } from "../utility/utilityProvider";

let defaultArgs = {
  query: [],
  sort: { SID: 1 },
  page: null,
  perPage: null,
  archive: false
}

export const sendEvent = async (socketID, data) => {
  try {
    let finalData = {
      socketID: socketID,
      data: data
    }
    let FINALURL = DIGITAL_SIGN_SOCKET_BASEURL + '/api/sendEvent';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const refreshPlaylist = async (socketID) => {
  try {
    let finalData = {
      socketID: socketID,
    }
    let FINALURL = DIGITAL_SIGN_SOCKET_BASEURL + '/api/refreshPlaylist';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const saveData = async (entityname, saveData) => {

  saveData = utility.prepareSaveData(entityname, saveData)

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    let FINALURL = DIGITALSIGNURL.BASEURL + "saveData/" + entityname;

    //prepare data
    const finalData = {
      data: saveData,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    utility.checkTokenExpire(responseData);
    return responseData;

  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getEntitiesWithSearch = async (entityname, args = defaultArgs, columns, searchText, filters) => {

  utility.checkSessionExpire();

  try {
    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      filters: filters
    };

    if (entityname == ENTITYNAME.Users) {
      const user = utility.getValue(LOCALSTORAGE_KEY.userData);
      if (user && user.RoleType) {
        finalData.query = [...finalData.query, ["RoleType.ID", ">=", user.RoleType.ID]]
      }
    }

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getEntitiesWithSearch/' + entityname;
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getDigitalSignDashboardData = async () => {
  
  utility.checkSessionExpire();

  try {

    let companyData = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

    let FINALURL = DIGITALSIGNURL.BASEURL + 'getDigitalSignDashboardData';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({
      companyId: companyData?._id
    }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    utility.checkTokenExpire(responseData);
    return responseData;

  } catch (error) {
     toast.error(error.message);
  }
}

export const getDigitalSignScreen = async (args = defaultArgs, searchText) => {

  utility.checkSessionExpire();

  try {

    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      searchText: searchText || ''
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getDigitalSignScreen';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
     toast.error(error.message);
  }
}

export const getDevicesStatus = async (nodeId) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getDevicesStatus';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ nodeId: nodeId }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
     toast.error(error.message);
  }
}

export const activateScreen = async (otp, Name) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'activateScreen';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ otp: otp, Name: Name }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
     toast.error(error.message);
  }
}

export const getPlaylists = async (args = defaultArgs, searchText) => {

  utility.checkSessionExpire();

  let finalData = {
    query: args.query || defaultArgs.query,
    sort: args.sort || defaultArgs.sort,
    page: args.page || defaultArgs.page,
    perPage: args.perPage || defaultArgs.perPage,
    archive: args.archive || defaultArgs.archive,
    searchText: searchText || ''
  };

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getPlaylists';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
     toast.error(error.message);
  }
}

export const getPlaylist = async (playlistId) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getPlaylist';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ playlistId: playlistId }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
     toast.error(error.message);
  }
}

export const getPublishPlaylist = async (playlistId) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getPublishPlaylist';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ playlistId: playlistId }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
     toast.error(error.message);
  }
}

export const getOverlaysByOverlayIds = async (overlayIds) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getOverlaysByOverlayIds';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ overlayIds: overlayIds }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
     toast.error(error.message);
  }
}

export const getData = async (entityname, args = defaultArgs) => {

  utility.checkSessionExpire();

  try {
    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
    };
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getEntities/' + entityname;
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
     toast.error(error.message);
  }
}

export const getDataLookup = async (entityname, args = defaultArgs, localStorageKey = '') => {

  utility.checkSessionExpire();

  try {
    let dataLookup = utility.getValue(entityname);
    if (dataLookup != null) return dataLookup;

    let finalData = {
      query: args.query,
      sort: args.sort,
      page: args.page,
      perPage: args.perPage
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getEntities/' + entityname;

    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.success) {
      let key = localStorageKey == '' ? entityname : localStorageKey
      utility.setValue(key, data);
      return data;
    }
    utility.checkTokenExpire(data)
    return data;

  }
  catch (error) {
    toast.error(error.message);
  }

}

export const getFormData = async (entityname, userId) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'formData/' + entityname;
    const finalData = {
      data: userId ? userId : null
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();
    if (resData.success) {
      return resData.data.sort((a, b) => a.sequence - b.sequence).filter((x) => x.type != COLUMNSTYPE.hidden);
    }
    utility.checkTokenExpire(resData);
    return resData;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const onDigitalSignup = async (data) => {

  try {
    let FINALURL = LOGINURL.BASEURL + 'onDigitalSignup';
    const finalData = {
      data: data
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();
    return resData;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getSignupMasters = async () => {

  try {
    let FINALURL = CONFIGURL.BASEURL + 'getSignupMasters';
    let Finalinit = GetHeader();
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();
    return resData;
  }
  catch (error) {
    toast.error(error.message);
  }
}