
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTranslation } from "../locale/useTranslation";



const ImagePreviewPage = (props) => {
  const lang = useTranslation ();

     return <Dialog
        title={lang.preview_image_title_label}
        onClose={props.onClose}
        width={"25vw"}
      >
        <div style={{textAlign:"center"}}>
        <img src={props.image} width={"200px"} object-fit= "fit" />  
        </div>
          
    </Dialog>
}

export default ImagePreviewPage;
