import React, { useState } from 'react'
import { ENTITYNAME } from '../../framework/constant/constant';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import * as API from "../../framework/API/api"
import { utility } from '../../framework/utility/utilityProvider';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, FormElement } from '@progress/kendo-react-form';
import { EditPageHeader } from '../../components/EditPageHeader';

const SponsorTypeEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        SID: props.item.SID ?? 0,
        Description: props.item.copy ? 'copy of ' + props.item.Description : props.item.Description ?? '',
        Rating: props.item.Rating ?? 0,
        Archive: props.item.Archive ?? false,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);

    function validationCheck() {
        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Rating <= 0 || dataItem.Rating >10 || dataItem.Rating == "" || dataItem.Rating == undefined) {
            toast.error(`${lang.please_enter_rating_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {

        if (validationCheck()) {
            const saveData = {
                SID: dataItem.SID ?? 0,
                Description: dataItem.Description,
                Rating: parseInt(dataItem.Rating),
                Archive: dataItem.Archive ?? false,
            }

            console.log(saveData);

            let res = await API.saveData(ENTITYNAME.SponsorType, saveData);
            if (res.success) {
                props.cancelEdit();
                props.refresh();
                utility.deleteLocalStorageItem(ENTITYNAME.SponsorType);
                return;
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const onChangeForm = (e) => {

        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }


    return (
        <>
            <Dialog
                title={props.item.SID > 0 ? props.item.Description : "Sponsor Type"}
                onClose={props.cancelEdit}
                width={"300px"}
                height={"max-content"}
            >
              <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row mt-2">
                            <div className="col">
                                <label>{lang.description_label} *</label>
                                <input
                                    name={"Description"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChangeForm}
                                    value={dataItem.Description}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label>{lang.rating_label} *</label>
                                <input
                                    name="Rating"
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChangeForm}
                                    value={dataItem.Rating}
                                    min={0}
                                />
                                <p className="list-unstyled blockquote-footer">{lang.note_for_sponsor_type_select_null_label}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mt-3">
                                <input
                                    type={"checkbox"} name={"Archive"} style={{ marginTop: "10px" }}
                                    onChange={(e) => onChangeForm(e)}
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive} />
                                <label className="ml-1">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        </>
    )
}

export default SponsorTypeEditForm
