/* eslint-disable */
import React, { useState } from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { EditPageHeader } from '../../components/EditPageHeader';

const LiveEventTypeEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        IsLive: props.item.IsLive ?? false,
        Archive: props.item.Archive ?? false,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);

    const onChange = (e) => {
        if (e.target.name === 'Archive') {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else if (e.target.name === 'IsLive') {
            setDataItem({ ...dataItem, IsLive: !dataItem.IsLive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {

            const saveData = {
                _id: dataItem._id,
                SID: dataItem.SID,
                Description: dataItem.Description,
                IsLive : dataItem.IsLive ?? false,
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Description", "=", dataItem.Description]]

            }
            console.log(saveData);
            const res = await API.saveData(ENTITYNAME.LiveEventType, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.LiveEventType, data: res.data, message: res.message };
                API.SaveLogs(logData);
                props.cancelEdit();
                props.refresh();
                utility.deleteLocalStorageItem(ENTITYNAME.LiveEventType);
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>
            <Dialog
                title={props.item.Description ?? lang.create_live_event_type_dialog_header}
                onClose={props.cancelEdit}
                width={"400px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />

                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <label>{lang.description_label} *</label>
                                <input
                                    name={"Description"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Description}
                                />
                            </div>
                        </div>
                        <div className='row mt-2'>
                        <div className='col-4'>
                                <input
                                    style={{ border: "1px solid grey" }}
                                    name={"IsLive"}
                                    type='checkbox'
                                    onChange={onChange}
                                />
                                <label className="ml-2">{lang.islive_label}</label>
                            </div>
                            <div className='col-6'>
                                <input
                                    style={{ border: "1px solid grey" }}
                                    name={"Archive"}
                                    type='checkbox'
                                    onChange={onChange}
                                />
                                <label className="ml-2">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default LiveEventTypeEditForm;