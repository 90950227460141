/* eslint-disable */
import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useEffect, useState } from "react";
import { ENTITYNAME } from "../../framework/constant/constant";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as API from "../../framework/API/api";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";

const FormatScheduleForm = (props) => {

    const lang = useTranslation();
    const blankDataItem = {
        SID: 0,
        Description: "",
        Channel: {},
        Format: {},
        ValidFrom: "00:00:00:00",
        ValidTo: "00:00:00:00",
        FromDate: "",
        ToDate: ""
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [channels, setChannels] = useState([]);
    const [showFormat, setShowFormat] = useState(false);

    useEffect(() => {
        loadcombo();
        if(props.item.SID > 0){
            loadEditData();
        }
    }, []);

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel);
        setChannels(channelRes.data);
    };

    const loadEditData = async () => {

        let res = await API.getEntity(ENTITYNAME.FormatSchedule, parseInt(props.item.SID));
        console.log(res);
        if(res.success){
            setDataItem({
                ...res.data,
                SID: res.data.SID,
                Description: props.item.copy ? "Copy of " + res.data.Description : res.data.Description ?? "",
                Format: res.data?.Format,
                ValidFrom: utility.convertMilisecondsToStringWithFrames(res.data.ValidFrom),
                ValidTo: utility.convertMilisecondsToStringWithFrames(res.data.ValidTo),
                FromDate: utility.convertMilisecondsToDateString(res.data.FromDate),
                ToDate: utility.convertMilisecondsToDateString(res.data.ToDate),
                Archive: res.data.Archive ?? false
            })
        }else{
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
              });
        }
    }

    const onChange = (e) => {
        if(e.target.name == "Archive"){
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        }else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.Description == undefined || dataItem.Description == "") {
          toast.error(`${lang.please_enter_description_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        if (dataItem.Channel == undefined || Object.keys(dataItem.Channel).length == 0) {
          toast.error(`${lang.please_select_channel_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        if (dataItem.ValidFrom == undefined || dataItem.ValidFrom == "") {
          toast.error(`${lang.please_enter_valid_from_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        if (dataItem.ValidTo == undefined || dataItem.ValidTo == "") {
          toast.error(`${lang.please_enter_valid_to_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        if (dataItem.FromDate == undefined || dataItem.FromDate == "") {
          toast.error(`${lang.please_select_from_date_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        if (dataItem.ToDate == undefined || dataItem.ToDate == "") {
          toast.error(`${lang.please_select_to_date_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        if (dataItem.Format == undefined || Object.keys(dataItem.Format).length == 0) {
            toast.error(`${lang.please_select_format_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
        return true;
      }

    const handleSubmit = async () => {
        if(!isValid()) return;
        let saveData = {
            _id: dataItem._id,
            SID: dataItem.SID,
            Description: dataItem.Description,
            Channel: dataItem.Channel ? {
                _id: dataItem.Channel?._id,
                SID: dataItem.Channel?.SID,
                FullChannelName: dataItem.Channel?.FullChannelName
            } : {},
            Format_id: dataItem?.Format?._id,
            Format: dataItem.Format ? {
                _id: dataItem.Format._id,
                Name: dataItem.Format.Name
            } : {},
            ValidFrom: utility.convertStringWithFramesToMilliseconds(dataItem.ValidFrom),
            ValidTo: utility.convertStringWithFramesToMilliseconds(dataItem.ValidTo),
            FromDate: utility.convertStringDatetoMilliseconds(dataItem.FromDate),
            ToDate: utility.convertStringDatetoMilliseconds(dataItem.ToDate),
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["Description", "=", dataItem.Description]]
        }
        let res = await API.saveData(ENTITYNAME.FormatSchedule, saveData);
        if(res.success){
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.FormatSchedule);
        }else{
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
              });
        }
    }

    const hanldeSelectedFormat = (selectedFormat) => {
        setDataItem({...dataItem, Format: selectedFormat[0]});
    }

    return (
        <Dialog
            title={props.item.Description ?? lang.create_schedule_format_dialog_header}
            onClose={props.cancelEdit}
            width={"60%"}
        >
            <div className="row">
                <div className="col-12">
                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                    <div className="row mt-2">
                        <div className="col-6">
                            <label>{lang.description_label} *</label>
                            <input
                                type="text"
                                name="Description"
                                className="form-control form-control-sm"
                                value={dataItem.Description}
                                onChange={onChange}
                            />
                        </div>
                        <div className="col-6">
                            <label>{lang.channel_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={channels}
                                value={dataItem.Channel}
                                textField="FullChannelName"
                                dataItemKey="_id"
                                name="Channel"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <label>{lang.from_date_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="FromDate"
                                value={dataItem.FromDate}
                                onChange={onChange}
                            />
                        </div>
                        <div className="col-6">
                            <label>{lang.to_date_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="ToDate"
                                value={dataItem.ToDate}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <label>{lang.valid_from_label} *</label>
                            <TimePickerWithFormat
                                className="form-control form-control-sm"
                                name="ValidFrom"
                                value={dataItem.ValidFrom}
                                onChange={onChange}
                            />
                        </div>
                        <div className="col-6">
                            <label>{lang.valid_to_label} *</label>
                            <TimePickerWithFormat
                                className="form-control form-control-sm"
                                name="ValidTo"
                                value={dataItem.ValidTo}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <label>{lang.format_label} *</label>
                            <div style={{display: 'flex'}}>
                                <input type="text" name="Format" className="form-control form-control-sm" value={dataItem.Format?.Name} onChange={onChange} disabled/>
                                <button style={{height: '31px', borderRadius: '5px'}} className="btn btn-sm btn-primary ml-1" onClick={() => setShowFormat(true)}><i className="fa fa-ellipsis-h" /></button>
                            </div>
                        </div>
                        <div className="col mt-4">
                            <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
                            <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
                        </div>
                    </div>
                </div>
            </div>
            {showFormat && <CollectionSelection title={lang.select_format_dialog_header} addButtonTitle={lang.select_button_tooltip} entityname={ENTITYNAME.Format} setDataList={hanldeSelectedFormat} closeForm={()=> setShowFormat(false)} mode="single" width="60%"/>}
        </Dialog>
    )

}

export default FormatScheduleForm;