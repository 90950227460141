import React, { memo, useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from '../../../framework/API/api'
import { DateOnlyCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { CheckboxCell } from "../../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { utility } from "../../../framework/utility/utilityProvider";
import { useTranslation } from "../../../locale/useTranslation";
import ManageContentEditPanel from "./manageContentEditPanel";
import { ContextMenu } from "primereact/contextmenu";
import MultipleContentEditPanel from "./multipleContentEditPanel";
import MyStatusCell from "../../../components/MyStatusCell";
import { Gantt, GanttDayView, GanttMonthView, GanttWeekView } from "@progress/kendo-react-gantt";
import moment from "moment";
import CustomPaginationDropDown from "../../../framework/forms/helpercomponents/CustomGridCells/CustomPaginationDropDown";


const ManageContentCollection = (props) => {

    const filterBy = props.filterBy;
    const gridData = props.data;
    const lang = useTranslation();

    const SELECTED_FIELD = "selected";

    const [tileViewGridData, setTileViewGridData] = useState([]);
    const [timelineViewData, setTimelineViewData] = useState([]);

    // 0 for tile view, 1 for grid view and 2 for timeline view
    const [view, setView] = useState(1);

    const [selectedItems, _setSelectedItems] = useState([]);
    const selectedItemsRef = useRef([]);
    const setSelectedItems = (data) => {
        console.log(data);
        props.setSelectedItemCount(data.length);
        selectedItemsRef.current = data;
        _setSelectedItems(data);
    }
    const [showDetailView, setShowDetailView] = useState(false);

    const manageContentCollectionRef = useRef();
    const contextMenuRef = useRef();

    const menuModel = [
        {
            label: "Edit",
            icon: "pi pi-fw pi-pencil",
            command: () => {
                onEdit();
            },
        },
    ]

    const taskModelFields = {
        id: '_id',
        start: 'start',
        end: 'end',
        title: 'Title',
        jobStatus: 'latestTranscodeStatus'
    };

    const columns = [
        { field: 'ImageUrl', title: '.', width: 67, cell: (p) => <ImageCell {...p} /> },
        { field: 'Title', title: `${lang.title_label}`, width: 150 },
        { field: 'start', title: `${lang.start_date_label}`, width: 100, cell: (props) => <DateOnlyCell {...props} /> },
        { field: 'end', title: `${lang.end_Date_label}`, width: 100, cell: (props) => <DateOnlyCell {...props} /> },
        { field: 'latestTranscodeStatus', title: `${lang.job_status_label}`, width: "125px", cell: (props) => <StatusCell {...props} /> },
    ];

    useEffect(() => {
        setTimelineViewData(gridData.map(item => {
            return {
                ...item,
                start: new Date(moment(item.PublishStartDate).utc().format("YYYY-MM-DD")),
                end: new Date(moment(item.PublishEndDate).utc().format("YYYY-MM-DD")),
            }
        }))
        setTileViewGridData(gridData.slice(0, props?.page?.take));
    }, [props.data]);

    const onEdit = () => {

        selectedItemsRef.current.length == 0 && setShowDetailView(false);

        if (selectedItemsRef.current.length == 1) {
            manageContentCollectionRef.current.onEdit && manageContentCollectionRef.current.onEdit(selectedItemsRef.current[0]._id)
        }
    }

    const onContextMenu = (e, item = false, isTile = false) => {
        if (isTile) {
            contextMenuRef.current.show(e);
            if (selectedItems.length == 0 || !selectedItems.some(x => x._id == item._id)) {
                setSelectedItems([item]);
            }
        } else {
            contextMenuRef.current.show(e.syntheticEvent);
            if (selectedItems.length == 0 || !selectedItems.some(x => x._id == e.dataItem._id)) {
                setSelectedItems([e.dataItem]);
            }
        }
    };

    const onItemClick = (e, dataItem) => {
        console.log(e, dataItem);
        //multiselect using ctrl key
        if (e.ctrlKey || e.metaKey) {
            if (selectedItems?.some(x => x?._id == dataItem?._id)) {
                setSelectedItems(selectedItems.filter(x => x._id != dataItem._id));
            } else {
                setSelectedItems([...selectedItems, dataItem]);
            }

        }
        //multiselect using shift key
        else if (e.shiftKey) {
            let items = [];
            let startIndex = gridData.findIndex(x => x._id == selectedItems[0]._id);
            let endIndex = gridData.findIndex(x => x._id == dataItem._id);
            if (startIndex > endIndex) {
                items = gridData.slice(endIndex, startIndex + 1);
            } else {
                items = gridData.slice(startIndex, endIndex + 1);
            }
            setSelectedItems(items);
        }
        else {
            if (selectedItems?.some(x => x?._id == dataItem?._id)) {
                setSelectedItems([]);
            } else {
                setSelectedItems([dataItem]);
            }
        }
        onEdit();
    }

    const onFilterSearchChange = (e) => {
        //working on the basis of title for now client side
        props.setSearch(e.target.value);
    };

    const StatusCell = (props) => {
        return <MyStatusCell {...props}
            title={props.dataItem?.latestTranscodeStatus?.Description ?? 'N/A'}
            titleColor='#ffffff'
            tileBackColor={props.dataItem?.latestTranscodeStatus?.Description == 'To Do' ? "#f58d05" : props.dataItem?.latestTranscodeStatus?.Description == 'Done' ? "#1ca666" : '#5C469C'}
        />
    }

    return <div>
        {props.isLoading ?
            // loading
            <div className="ml-2 mt-2">
                <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
            </div> :
            //main
            <div style={{ overflowX: "hidden", padding: '5px 10px', height: "85vh", marginRight: showDetailView ? "500px" : 0, transition: 'all 0.3s linear' }}>
                <div className="row">
                    <div className="col-8">
                        <h1 className="page-title txt-color-bludeDark" style={{ margin: '10px 0' }}>
                            <i className="fa fa-tv" style={{ fontSize: "20px", color: "GrayText" }} ></i>
                            <span style={{ fontFamily: "sans-serif", paddingLeft: "5px", letterSpacing: "0.5px" }}>Total Assests - {gridData.length}</span>
                        </h1>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                        <div className="pointer p-1" onClick={() => setView(view < (filterBy._id == 0 ? 2 : 1) ? view + 1 : 0)} title={view == 0 ? `${lang.grid_view_label}` : view == 1 ? 'Timeline View' : `${lang.tile_view_label}`}><i className={`fas ${view == 0 ? 'fa-table-cells' : view == 1 ? 'fa-table-list' : 'fa-timeline'} fa-xl`} /></div>
                    </div>
                </div>
                {/* implementing search here */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ width: "300px" }}>
                        {/* left side of search area */}
                    </div>
                    <div className="flex-container">
                        <div className="flex-container-reverse flex-item-auto">
                            <div className="input-group input-group martb" style={{ width: '30%' }} >
                                <input type="search" className="form-control shadow-inset-2 pt-0 pb-0" id="search" aria-label="type 2 or more letters"
                                    placeholder={lang.search_button_text} onChange={onFilterSearchChange} />
                                <button style={{ border: "0px", backgroundColor: "white", height: "40px" }} >
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i style={{ margin: "5px 0px" }} className="fa fa-search" />
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>



                {view == 0
                    ?
                    // tile view
                    <div className="row" style={{ height: "70%", overflowY: "auto", overflowX: "hidden", display: "flex", alignItems: "start", justifyContent: "start", flexWrap: "wrap" }} onScroll={(e) => {
                        if (e.target.scrollTop + e.target.clientHeight + 50 > e.target.scrollHeight) {
                            setTileViewGridData([...tileViewGridData, ...gridData.slice(tileViewGridData.length, tileViewGridData.length + props?.page?.take)]);
                        } else if (e.target.scrollTop == 0) {
                            setTileViewGridData(gridData.slice(0, props?.page?.take));
                        }
                    }

                    }
                    >
                        {
                            tileViewGridData.map((item, index) => {
                                let genres = '';
                                if (item.Genres) {
                                    item.Genres.map(genre => {
                                        genres += genre.Description + ', ';
                                    })
                                    genres = genres.substring(0, genres.length - 2);
                                }
                                return <div className="col-lg-3 col-md-4 col-xs-6" style={{ flex: "25%" }} >
                                    <div style={{ cursor: "pointer", margin: "10px 40px", height: "220px", minWidth: "220px", maxWidth: "280px", backgroundColor: selectedItems.some(selectedItem => selectedItem?._id == item?._id) ? '#E8F6EF' : 'white', border: selectedItems.some(selectedItem => selectedItem?._id == item?._id) ? '1px solid black' : 'none' }} onContextMenu={(e) => onContextMenu(e, item, true)} onClick={(e) => onItemClick(e, item)} className="card" key={index}
                                        title={item?.Title + "\n" +
                                            utility.convertMilisecondsToStringWithFrames(item?.Duration) + "\n\n" +
                                            item?.Description + "\n\n" +
                                            item?.MediaCategory?.Description + "\n" +
                                            genres
                                        }>
                                        <img src={item?.ImageUrl} style={{ height: "150px", objectFit: "cover" }} className="card-img-top" alt={item?.Title} />
                                        <div style={{ display: "grid", alignContent: "space-around", height: "100%" }}>
                                            <p className="card-title" style={{ fontSize: "medium", fontWeight: "500" }}>{utility.subString(item?.Title, 15)}</p>
                                            <div className="card-subtitle mb-2 text-muted" style={{ fontSize: "small" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px" }}>
                                                    <div>{item?.MediaCategory?.Description}</div>
                                                    <div>{item?.AssetId}</div>

                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", padding: "0 13px" }}>
                                                    <div>{genres}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    :
                    view == 1
                        ?
                        // grid view
                        <Grid
                            style={{
                                height: "70%",
                            }}
                            rowHeight={50}
                            data={gridData.map(data => {
                                data[SELECTED_FIELD] = selectedItems.some(selectedItem => selectedItem?._id == data?._id);
                                return data;
                            })}
                            pageable={true}
                            skip={props?.page?.skip ?? 0}
                            take={props?.page?.take ?? 25}
                            total={props?.totalPages ?? 1}
                            onPageChange={props?.onPageChange}
                            onContextMenu={onContextMenu}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                cell: false,
                                mode: {
                                    value: "multiple",
                                    label: "Multiple selection mode",
                                },
                            }}
                            resizable={true}
                            onSelectionChange={(event) => {
                                let rowIndex = event.startRowIndex;
                                let dataItem = event.dataItems[rowIndex];
                                onItemClick(event, dataItem);
                            }}
                            pager={(props) => <CustomPaginationDropDown {...props} />}

                        >
                            <GridColumn
                                field={'ImageUrl'}
                                title={'Image'}
                                cell={ImageCell}
                                width={75}
                            />
                            <GridColumn
                                field={'Title'}
                                title={'Title'}
                                width={140}
                            />
                            <GridColumn
                                field={'MediaCategory.Description'}
                                title={'Media Category'}
                                width={130}
                            />
                            <GridColumn
                                field={'AssetId'}
                                title={'Asset Id'}
                                width={120}
                            />
                            <GridColumn
                                field={'mediaepisodemetadata.MamID'}
                                title={'Mam ID'}
                                width={120}
                            />
                            <GridColumn
                                field={'mediaepisodemetadata.PGRating.Description'}
                                title={'PG Rating'}
                                width={120}

                            />
                            <GridColumn
                                field={'Genres'}
                                title={'Genres'}
                                cell={ArrayCell}
                                format="Description"
                            />
                            <GridColumn
                                field={'IsOttAsset'}
                                title={'OTT Enabled'}
                                cell={CheckboxCell}
                                sortable={true}
                            />
                            <GridColumn
                                field={'latestTranscodeStatus'}
                                cell={StatusCell}
                                title={'Transcode Status'}
                                width={'125px'}
                            />
                        </Grid>
                        :
                        // timeline view
                        <Gantt
                            style={{ height: "70%" }}
                            taskData={timelineViewData}
                            taskModelFields={taskModelFields}
                            columns={columns}
                            onRowClick={(e) => { onItemClick(e, e.dataItem) }}
                        >
                            <GanttWeekView />
                            <GanttMonthView />
                        </Gantt>
                }

                {
                    selectedItems.length > 1 ?
                        <MultipleContentEditPanel selectedItems={selectedItems} showDetailView={showDetailView} setShowDetailView={setShowDetailView} />
                        :
                        <ManageContentEditPanel transcodeStatus={selectedItems[0]?.latestTranscodeStatus?.Description ?? 'N/A'} showDetailView={showDetailView} setShowDetailView={setShowDetailView} ref={manageContentCollectionRef} />
                }
                <ContextMenu model={menuModel} ref={contextMenuRef} />

            </div >
        }

    </div>
}

export default memo(ManageContentCollection);