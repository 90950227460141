/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import DashboardCard from './component/DashboardCard';
import { useTranslation } from '../../locale/useTranslation';
import * as API from '../../framework/API/api_digitalSign';
import { toast } from 'react-toastify';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Popup } from "@progress/kendo-react-popup";
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import MyStatusCell from '../../components/MyStatusCell';
import InfoRoundButton from '../../components/InfoRoundButton';

const DigitalSignDashboardForm = () => {

    const lang = useTranslation();
    const [cards, setCards] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [show, setShow] = useState(false);
    const anchor = useRef();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        let res = await API.getDigitalSignDashboardData();
        console.log(res);
        if (!res.success) {
            toast.error(res.message);
        } else {
            setCards(res.data.cards);
            setGridData(res.data.screens);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchor.current && !anchor.current.contains(event.target)) {
                setShow(false);
            }
        };
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [show]);

    const Mystatuscell = (props) => {
        const field = props.field
        let tileBackColor = props.dataItem[field] ? "#63aa43" : "#E72929";
        let status = props.dataItem[field] ? "Active" : "Inactive";
        return <MyStatusCell title={status} tileBackColor={tileBackColor} />

    }

    return (<>
        <div className='row m-2 mb-4'>
            <div className='col-12'>
                <div className='flex-container'>
                    <h1 className="page-title txt-color-bludeDark">
                        <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} ></i>
                        <span>{lang.digital_sign_dashboard_page_breadcrumb}</span>
                    </h1>
                    <div className="flex-container-reverse flex-item-auto" >
                        <button style={{margin: '26px 0px', borderRadius: '18px', fontSize: '12px'}} ref={anchor} className="btn btn-primary cursor-pointer mt-2" onClick={() => setShow(!show)} > <i className="fa fa-arrow-down ml-1"></i> {lang.create_button_text} </button>
                        <Popup show={show} anchor={anchor.current} popupClass={"popup-content"} style={{ width: '13vw' }}>
                            <div className='cursor-pointer ml-2 mt-3' onClick={() => window.open("/home/digitalSignScreenGroup", "_blank")}>
                                <i className={`fa fa-object-ungroup fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                {lang.new_screen_group_button_title}
                            </div><hr />
                            <div className='cursor-pointer ml-2' onClick={() => window.open("/home/Playlist", "_blank")}>
                                <i className={`fa fa-play fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                {lang.new_playlist_button_title}
                            </div><hr />
                            <div className='cursor-pointer ml-2' onClick={() => window.open("/home/digitalSignContent", "_blank")}>
                                <i className={`fa fa-clipboard fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                {lang.new_content_button_title}
                            </div><hr />
                            <div className='cursor-pointer ml-2 mb-2' onClick={() => window.open("/home/digitalsignScreen", "_blank")}>
                                <i className={`fa fa-desktop fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                {lang.new_screen_button_title}
                            </div>
                        </Popup>
                    </div>
                </div>
                <div className='row'>
                    {cards.map((card, index) => {
                        return (
                            <div className={index < 3 ? 'col-4' : 'col-4'} style={{ marginLeft: index == 3 && '15%' }}>
                                <DashboardCard backgroundColor={card.backgroundColor} name={card.name} count={card.count} />
                            </div>
                        )
                    })}
                </div>

                <div className="row shadow mt-4 mb-5" style={{ height: '400px' }}>
                    <div className='col-12 border'>
                        <div className='row mb-2'>
                            <div className="col m-1">
                                <div className="float-left mt-1">{lang.screen_players_label}
                                    <InfoRoundButton message="Total Screen-1 * Last 5 Screen" />
                                </div>
                                <div className='float-right mt-1'>
                                    <RoundButton title={lang.add_more_label} icon='external-link' onClick={() => window.open("/home/digitalsignScreen", "_blank")} />
                                </div>
                            </div>
                        </div>
                        <Grid data={gridData} resizable={true} style={{ height: '330px' }} >
                            <GridColumn field="SerialNumber" title="Serial Number"/>
                            <GridColumn field="Name" title="Name"/>
                            <GridColumn field="Orientation" title="Orientation" />
                            <GridColumn field="ActiveAgo" title="Active ago" />
                            <GridColumn field="active" title="Status" cell={Mystatuscell} />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    </>);

}

export default DigitalSignDashboardForm;