import { useEffect, useState } from "react";
import MediaEpisodeMataDataEditForm from "../../MediaEpisodeMataDataEditForm"
import { Dialog } from "@progress/kendo-react-dialogs";
import { utility } from "../../../../framework/utility/utilityProvider";
import underscore from "underscore";
import SaveButton from "../../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../../framework/forms/helpercomponents/buttons/CancelButton";
import { ENTITYNAME } from "../../../../framework/constant/constant";
import * as API from '../../../../framework/API/api'
import { toast } from "react-toastify";
import RoundButton from "../../../../framework/forms/helpercomponents/buttons/RoundButton";

const MetaDataTab = (props) => {

    const none = "N/A";

    const { mediaepisode_sid } = props;

    const [showEditMetaData, setShowEditMetaData] = useState(false);
    const [metaData, setMetaData] = useState({});
    const [loading, setLoading] = useState(true);


    const boxStyle = { margin: "10px 5px", padding: "10px", borderRadius: "10px", backgroundColor: "white" }
    const headingStyle = { fontFamily: "Roboto", fontSize: "16px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", padding: "10px 0" }
    const subHeadingStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", margin: "7px 0", padding: 0 }
    const subHeadingDetailStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: 0, marginBottom: "25px" }
    const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px 5px" }

    useEffect(() => {
        loadMediaEpisodeMetaData();
    }, [showEditMetaData]);

    const loadMediaEpisodeMetaData = async () => {
        setLoading(true);
        var responseMetaData = await API.getData(ENTITYNAME.MediaEpisodeMetaData, { query: ['MediaEpisodeSID', '=', mediaepisode_sid] });
        if (responseMetaData.success && responseMetaData.data.length > 0) {
            console.log(responseMetaData.data[0]);
            setMetaData(responseMetaData.data[0]);
        }
        setLoading(false);
    }

    const onCloseMetaData = () => {
        setShowEditMetaData(!showEditMetaData);
    }

    const castAndCrew = underscore.groupBy(metaData?.CastAndCrew, (CastAndCrew) => CastAndCrew?.CastType?.Description);

    //from edit form
    const getMetaData = async (data) => {
        setMetaData(data);
    }

    const onSaveMetadata = async () => {
        var responseMetaData = await API.saveData(ENTITYNAME.MediaEpisodeMetaData, metaData);

        if (!responseMetaData.success) {
            toast.error(responseMetaData.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        onCloseMetaData();
    }

    return (
        loading ? <div style={{ ...boxStyle, height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <i className="fa fa-spinner fa-spin fa-xl " />
        </div> :
            <>
                {/* Genral */}
                <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>General</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={onCloseMetaData} />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <div>
                            <h2 style={subHeadingStyle}>Pg Rating</h2>
                            <p style={subHeadingDetailStyle}>{metaData?.PGRating?.Description ?? none}</p>
                            <h2 style={subHeadingStyle}>Censor Rating</h2>
                            <p style={subHeadingDetailStyle}>{metaData?.CensorRating?.Description ?? none}</p>
                            <h2 style={subHeadingStyle}>Languages</h2>
                            <p style={subHeadingDetailStyle}>{utility.displayArrayInPipe(metaData?.Languages, 'Description') ?? none}</p>
                        </div>
                        <div>
                            <h2 style={subHeadingStyle}>Production Year</h2>
                            <p style={subHeadingDetailStyle}>{metaData?.ProductionYear ?? none}</p>
                            <h2 style={subHeadingStyle}>Keywords</h2>
                            <p style={subHeadingDetailStyle}>{metaData?.Keywords?.length == 0 ? none : metaData?.Keywords ?? none}</p>
                            <h2 style={subHeadingStyle}>Categories</h2>
                            <p style={subHeadingDetailStyle}>{utility.displayArrayInPipe(metaData?.Categories, 'Name') ?? none}</p>
                        </div>
                    </div>

                </div>

                {/* Synopsis */}
                <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>Synopsis</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={onCloseMetaData} />
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Language</th>
                                <th scope="col">Synopsis</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metaData?.Synopsis?.map((synopsis, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={tableDataStyle}>{synopsis?.Language?.Description}</td>
                                        <td style={tableDataStyle}>{utility.subString(synopsis?.Synopsis, 100)}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>

                {/* Cast & Crew */}
                <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>Cast & Crew</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={onCloseMetaData} />
                    </div>

                    {Object.keys(castAndCrew).map((key, index) => {
                        return (<>
                            <h2 style={subHeadingStyle}>{key}</h2>
                            <p style={subHeadingDetailStyle}>{utility.displayArrayInPipe(castAndCrew[key], 'Name') ?? none}</p>
                        </>
                        )
                    })}

                </div>

                {/* Audio Track */}
                <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>Audio Track</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={onCloseMetaData} />
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Language</th>
                                <th scope="col">Track Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metaData?.AudioTrack?.map((AudioTrack, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={tableDataStyle}>{AudioTrack?.Language?.Description}</td>
                                        <td style={tableDataStyle}>{AudioTrack?.TrackNumber}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>

                {/* Dynamic Field */}
                {metaData?.dynamicField && <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>Dynamic Fields</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={onCloseMetaData} />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>

                        {
                            Object.keys(metaData?.dynamicField).map((key, index) => {
                                if (typeof metaData?.dynamicField[key] == 'object') {
                                    return null;
                                } else {
                                    return (
                                        <div key={index}>
                                            <h2 style={subHeadingStyle}>{key.toUpperCase()}</h2>
                                            <p style={subHeadingDetailStyle}>{metaData?.dynamicField[key] ?? none}</p>
                                        </div>
                                    )
                                }
                            })

                        }

                    </div>

                </div>}

                {showEditMetaData && <Dialog
                    title={'Edit Meta Data'}
                    onClose={onCloseMetaData}
                    width={"75vw"}
                    height={"66vh"}
                >
                    <SaveButton onClick={onSaveMetadata}></SaveButton>
                    <CancelButton onClick={onCloseMetaData}></CancelButton>
                    <div className="mt-3"></div>
                    <MediaEpisodeMataDataEditForm getMetaData={getMetaData} metaData={metaData} />
                </Dialog>
                }
            </>

    )
}

export default MetaDataTab;