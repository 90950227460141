
import React, { useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader'
import { useTranslation } from '../../locale/useTranslation'
import { toast } from 'react-toastify'
import { ENTITYNAME } from '../../framework/constant/constant'
import { utility } from '../../framework/utility/utilityProvider'
import * as API from "../../framework/API/api";
import { Form, FormElement } from '@progress/kendo-react-form'
import { Dialog } from '@progress/kendo-react-dialogs'

const CurrencyEditForm = (props) => {
    const lang= useTranslation();

    const blankDataItem = {
        ...props.item,
        SID : props.item.SID ?? 0,
        Description: props.item.copy ? "Copy of " + props.item.Description: props.item.Description ?? "",
        ExcRate: parseFloat(props.item.ExcRate) ?? 0,
        Archive: props.item.Archive ?? false,
        IsDefault: props.item.IsDefault ?? false,
    }
    console.log(props.item)
     
    const [dataItem, setDataItem] = useState(blankDataItem)

    const onChange = (e) => {
        if (e.target.name == 'Archive') {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
          }
          else if (e.target.name == 'IsDefault') {
            setDataItem({ ...dataItem, IsDefault: !dataItem.IsDefault });
          } 
          else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
          }
    }

    const isValid = () =>{
        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return false;
          }
          if (dataItem.ExcRate < 0){
            toast.error(`${lang.excrate_should_not_be_negative_error_message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return false;
          }
          return true;
    }

    const handleSubmit = async() => {
        if(isValid()){
            let data = {
                SID: dataItem.SID ?? 0,
                Description: dataItem.Description,
                ExcRate: parseFloat(dataItem.ExcRate),
                Archive: dataItem.Archive ?? false,
                IsDefault: dataItem.IsDefault ?? false
            }
            console.log(data)
            let res = await API.saveData(ENTITYNAME.Currency, data);

            if (res.success) {
              props.cancelEdit();
              props.refresh();
              utility.deleteLocalStorageItem(ENTITYNAME.Currency)
              return;
            } else {
              toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
        }
    }

  return (
     <Dialog
        title={props.item.SID > 0 ? props.item.Description : "Create Currency"}
        onClose={props.cancelEdit}
        width={"770px"}
      >
        <EditPageHeader
          title={""}
          onSubmit={handleSubmit}
          onCancel={props.cancelEdit}
          showTitle={false}
        />
        <Form
          initialValues={dataItem}
          render={(formRenderProps) => (
            <FormElement>
        <div className='row'>
            <div className='col-12'>
                <div className='row mt-2'>
                    <div className='col-6'>
                        <label>{lang.description_label} *</label>
                        <input
                        name={"Description"}
                        type="text"
                        style={{
                          border: "solid lightgrey 1px",
                          height: "32px",
                        }}
                        onChange={onChange}
                        value={dataItem.Description}
                      />
                    </div>
                    <div className='col-6'>
                        <label>{lang.excrate_label}</label>
                        <input
                        name={"ExcRate"}
                        type="number"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.ExcRate}
                        min={0}
                      />
                      <p className=" text-muted" style={{ fontSize: "10px" }}>
                      {lang.note_enter_exchange_rate_equal_to_usd_label}
                    </p>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6 mt-1">
                      <input type={'checkbox'}
                        name={"Archive"}
                        label={lang.archive}
                        value={dataItem.Archive}
                        checked={dataItem.Archive}
                        onClick={onChange}
                        onChange={() => { }}
                      />
                      <label className='ml-2'>{lang.archive}</label>
                    </div>
                    <div className='col-6'>
                    <input type={"checkbox"} name={"IsDefault"} style={{ marginTop: "10px" }}
                        onChange={(e) => onChange(e)}
                        value={dataItem.IsDefault}
                        checked={dataItem.IsDefault} />
                      <label className="ml-1">{lang.is_default_label}</label>
                    </div>
                  </div>
            </div>
        </div>
        </FormElement>
          )}
        />
      </Dialog>
    
  )
}

export default CurrencyEditForm
