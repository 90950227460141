import moment from 'moment';
import * as API from '../API/api';
import { MEDIACATEGORIES } from '../constant/constant';
import { utility } from '../utility/utilityProvider';
export const DataHelper = {};

DataHelper.saveSeriesEntity = (dataItem, mediaCategory, isOttAsset, selectedImageUrl) => {

  let mediaCategoryData = mediaCategory.find((obj) => obj.SID == MEDIACATEGORIES.Series);

  let finalData = {
    _id: dataItem?._id,
    SID: dataItem?.SID,
    Title: dataItem?.Title,
    Content: dataItem?.Content && Object.keys(dataItem?.Content).length > 0 ? { _id: dataItem?.Content?._id, SID: dataItem?.Content?.SID, Description: dataItem?.Content?.Description } : {},
    Description: dataItem?.Description,
    MediaCategory: mediaCategoryData ? { _id: mediaCategoryData?._id, Description: mediaCategoryData?.Description, SID: mediaCategoryData?.SID, isLong: mediaCategoryData?.isLong } : {},
    ImageUrl: selectedImageUrl,
    Synopsis: dataItem?.Synopsis,
    AssetId: dataItem?.AssetId,
    PGRating: dataItem?.PGRating && Object.keys(dataItem?.PGRating).length > 0 ? {
      _id: dataItem?.PGRating?._id,
      Description: dataItem?.PGRating?.Description
    } : {},
    Languages: dataItem?.Languages?.length > 0 ? dataItem.Languages.map(x => {
      return {
        _id: x._id,
        Description: x.Description,
      }
    }) : [],
    IsOttAsset: isOttAsset ?? false,
    IsLive: dataItem?.IsLive ?? false,
    Keywords: dataItem?.Keywords,
    SeasonsCount: dataItem?.SeasonsCount ?? 0,
    ProductionYear: dataItem?.ProductionYear,
    Archive: dataItem?.Archive ?? false,
    Genres: dataItem?.Genres?.length > 0 ? dataItem.Genres.map(x => {
      return {
        _id: x._id,
        Description: x.Description,
      }
    }) : [],
    SubGenres: dataItem?.SubGenres?.length > 0 ? dataItem.SubGenres.map(x => {
      return {
        _id: x._id,
        Description: x.Description,
      }
    }) : [],
    checkDuplicate: true,
    query: [["Title", "=", dataItem?.Title]]
  }
  return finalData;
}

DataHelper.saveSeasonEntity = (dataItem, mediaCategory, isOttAsset, selectedSeriesId, selectedImageUrl) => {

  let mediaCategoryData = mediaCategory.find((obj) => obj.SID == MEDIACATEGORIES.Season);

  let finalData = {
    _id: dataItem?._id,
    SID: parseInt(dataItem?.SID),
    Title: dataItem?.Title,
    Content: dataItem?.Content && Object.keys(dataItem?.Content).length > 0 ? { _id: dataItem?.Content?._id, SID: dataItem?.Content?.SID, Description: dataItem?.Content?.Description } : {},
    Description: dataItem?.Description,
    MediaCategory: mediaCategoryData ? { _id: mediaCategoryData?._id, Description: mediaCategoryData?.Description, SID: mediaCategoryData?.SID, isLong: mediaCategoryData?.isLong } : {},
    ImageUrl: selectedImageUrl ?? "",
    Synopsis: dataItem?.Synopsis,
    AssetId: dataItem?.AssetId,
    Series_Id: selectedSeriesId,
    SeasonCode: dataItem?.SeasonCode,
    Genres: dataItem?.Genres?.length > 0 ? dataItem.Genres.map(x => {
      return {
        _id: x._id,
        Description: x.Description,
      }
    }) : [],
    SubGenres: dataItem?.SubGenres?.length > 0 ? dataItem.SubGenres.map(x => {
      return {
        _id: x._id,
        Description: x.Description,
      }
    }) : [],
    ProductionYear: dataItem?.ProductionYear,
    Keywords: dataItem?.Keywords,
    PGRating: dataItem?.PGRating && Object.keys(dataItem?.PGRating).length > 0 ? {
      _id: dataItem?.PGRating?._id,
      Description: dataItem?.PGRating?.Description
    } : {},
    Languages: dataItem?.Languages?.length > 0 ? dataItem.Languages.map(x => {
      return {
        _id: x._id,
        Description: x.Description,
      }
    }) : [],
    IsOttAsset: isOttAsset ?? false,
    Archive: dataItem?.Archive ?? false,
    checkDuplicate: true,
    query: [["Title", "=", dataItem?.Title], ['SeasonNumber', '=', dataItem?.SeasonNumber]]
  }
  return finalData;
}

const getLocalizationData = (dataItem, data) => {
  if (!data || data?.length == 0) return [];

  let finalData = data.map((x) => {

    let item = DataHelper.saveMetaDataEntity(x.MetaData, dataItem);
    delete item._id;
    delete item.SID;
    delete item.MediaEpisodeSID;
    x.Language = x?.Language ? { _id: x.Language._id, Description: x.Language.Description } : {};
    x.MetaData = item;
    return x;
  });
  return finalData;
}

DataHelper.saveMediaEpisodeEntity = (dataItem, mediaCategory, isOttAsset = false) => {

  let finalData = {
    _id: dataItem?._id,
    SID: parseInt(dataItem?.SID),
    Title: dataItem?.Title,
    EpisodeTitle: mediaCategory.SID == MEDIACATEGORIES.Episode ? dataItem?.EpisodeTitle : '',
    Description: dataItem?.Description,
    Content: dataItem?.Content ? { _id: dataItem?.Content?._id, SID: dataItem?.Content?.SID, Description: dataItem?.Content?.Description } : {},
    AssetId: dataItem?.AssetId,
    MediaCategory: dataItem?.MediaCategory ? { _id: dataItem?.MediaCategory?._id, Description: dataItem?.MediaCategory?.Description, SID: dataItem?.MediaCategory?.SID, isLong: dataItem?.MediaCategory?.isLong } : {},
    MediaCategoryType: dataItem?.MediaCategoryType ? { _id: dataItem?.MediaCategoryType?._id, Description: dataItem?.MediaCategoryType?.Description, SID: dataItem?.MediaCategoryType?.SID, MediaCategorySID: dataItem?.MediaCategoryType?.MediaCategorySID } : {},
    ImageUrl: dataItem?.ImageUrl ? dataItem?.ImageUrl : 'https://media.comicbook.com/files/img/default-movie.png',
    Duration: dataItem?.Duration,
    ReferenceCode: dataItem?.ReferenceCode,
    IMDbID: dataItem?.IMDbID ?? '',
    ReleaseDate: dataItem?.ReleaseDate ?? '',
    Genres: dataItem?.Genres?.length > 0 ? dataItem.Genres.map(x => {
      return {
        _id: x._id,
        Description: x.Description,
      }
    }) : [],
    SubGenres: dataItem?.SubGenres?.length > 0 ? dataItem.SubGenres.map(x => {
      return {
        _id: x._id,
        Description: x.Description,
      }
    }) : [],
    Localization: getLocalizationData(dataItem, dataItem?.Localization),
    Formats: dataItem?.Formats,
    IsOttAsset: isOttAsset ?? false,
    Archive: dataItem?.Archive ?? false,
    IsMusic: dataItem?.IsMusic ?? false,
    MusicCategory: dataItem?.MusicCategory ?? {},
    checkDuplicate: true,
    query: [["Title", "=", dataItem?.Title]]
  }

  return finalData;
}
DataHelper.saveMetaDataEntity = (dataItem, mediaEpisode) => {

  let finalData = {
    MediaEpisodeSID: mediaEpisode.SID,
    MediaEpisode_id: mediaEpisode._id,

    PGRating: dataItem?.PGRating ? {
      _id: dataItem?.PGRating?._id,
      Description: dataItem?.PGRating?.Description
    } : {},

    CensorRating: dataItem?.CensorRating ? {
      _id: dataItem?.CensorRating?._id,
      Description: dataItem?.CensorRating?.Description
    } : {},

    MediaTechnicalPrototype: dataItem?.MediaTechnicalPrototype ? {
      _id: dataItem?.MediaTechnicalPrototype?._id,
      Description: dataItem?.MediaTechnicalPrototype?.Description,
    } : {},

    Languages: dataItem?.Languages?.length > 0 ? dataItem.Languages.map((x) => {
      return {
        _id: x?._id,
        Description: x?.Description,
      }
    }) : [],

    Keywords: dataItem?.Keywords ?? '',

    ProductionYear: dataItem?.ProductionYear ?? '',

    Synopsis: dataItem?.Synopsis?.length > 0 ? dataItem.Synopsis.map((x) => {
      return {
        Synopsis: x?.Synopsis,
        Language: {
          _id: x.Language?._id,
          ISOCode: x.Language?.ISOCode,
          GoogleApiCode: x.Language?.GoogleApiCode,
          Description: x.Language?.Description
        }
      }
    }) : [],

    CastAndCrew: dataItem?.CastAndCrew?.length > 0 ? dataItem?.CastAndCrew?.map((x) => {
      return {
        Name: x.Name,
        CastType: { _id: x.CastType._id, Description: x.CastType?.Description }
      }
    }) : [],

    Categories: dataItem?.Categories?.length > 0 ? dataItem?.Categories?.map((x) => {
      return {
        _id: x._id,
        Name: x.Name,
        parent: x.parent
      }
    }) : [],

    AudioTrack: dataItem?.AudioTrack?.length > 0 ? dataItem?.AudioTrack.map((x) => {
      return {
        Language: {
          "_id": x.Language?._id,
          "Description": x.Language?.Description,
        },
        TrackNumber: x?.TrackNumber
      }
    }) : [],

    MamID: dataItem?.MamID ?? '',

    CensorshipDetail: dataItem?.CensorshipDetail ? dataItem?.CensorshipDetail : [],

    dynamicField: dataItem?.dynamicField ?? {},
  }

  if (dataItem?._id) {
    finalData._id = dataItem?._id;
  }

  if (dataItem?.SID) {
    finalData.SID = dataItem?.SID;
  }

  return finalData;
}
const getMediaEpisodeInShortFormPublishing = (data) => {

  if (data?.length == 0) return [];

  let finalData = data.map((x) => {
    return {
      SID: x?.SID,
      PublishStartDate: x?.PublishStartDate,
      PublishStartDateTime: x?.PublishStartDateTime,
      PublishEndDate: x?.PublishEndDate,
      PublishEndDateTime: x?.PublishEndDateTime,
      TimeBound: x?.TimeBound,
      PublishingSource: x?.PublishingSource,
      PlatformType: x?.PlatformType && Object.keys(x?.PlatformType).length > 0 ? {
        SID: x?.PlatformType?.SID,
        Description: x?.PlatformType?.Description,
      } : {},
      Channel: x?.Channel && x?.Channel?.length > 0 ? x?.Channel.map((y) => {
        return {
          _id: y._id,
          SID: y.SID,
          FullChannelName: y.FullChannelName
        }
      }) : [],
      Days: x.Days
    }
  });
  return finalData;
}

DataHelper.saveMediaEpisodeIsShortFormEntity = (dataItem) => {


  let finalData = {
    _id: dataItem?._id,
    SID: dataItem?.SID,
    AssetId: dataItem?.AssetId,
    Title: dataItem?.Title,
    Content: dataItem?.Content && Object.keys(dataItem?.Content).length > 0 ? {
      _id: dataItem?.Content?._id,
      SID: dataItem?.Content?.SID,
      Description: dataItem?.Content?.Description
    } : {},
    Description: dataItem?.Description,
    MediaCategory: dataItem?.MediaCategory && Object.keys(dataItem?.MediaCategory).length > 0 ? {
      _id: dataItem?.MediaCategory?._id,
      Description: dataItem?.MediaCategory?.Description,
      SID: dataItem?.MediaCategory?.SID,
      isLong: dataItem?.MediaCategory?.isLong
    } : {},
    MediaCategoryType: dataItem?.MediaCategoryType && Object.keys(dataItem?.MediaCategoryType).length > 0 ? {
      _id: dataItem?.MediaCategoryType?._id,
      Description: dataItem?.MediaCategoryType?.Description,
      SID: dataItem?.MediaCategoryType?.SID,
      MediaCategorySID: dataItem?.MediaCategoryType?.MediaCategorySID
    } : {},
    Version: dataItem?.Version && Object.keys(dataItem?.Version).length > 0 ? {
      _id: dataItem?.Version?._id,
      Name: dataItem?.Version?.Name
    } : {},
    Variance: dataItem?.Variance && Object.keys(dataItem?.Variance).length > 0 ? {
      _id: dataItem.Variance._id,
      Description: dataItem.Variance.Description
    } : {},
    Genres: dataItem?.Genres && dataItem?.Genres?.length > 0 ? dataItem.Genres.map((x) => {
      return { _id: x._id, Description: x.Description }
    }) : [],
    TcIn: utility.convertStringWithFramesToMilliseconds(dataItem?.TcIn ?? "00:00:00:00"),
    TcOut: utility.convertStringWithFramesToMilliseconds(dataItem?.TcOut ?? "00:00:00:00"),
    Duration: utility.convertStringWithFramesToMilliseconds(dataItem?.Duration ?? "00:00:00:00"),
    ReleaseDate: dataItem?.ReleaseDate ? new Date(moment(dataItem.ReleaseDate).format("YYYY-MM-DD HH:mm:ss")).getTime() : '',
    ImageUrl: dataItem?.ImageUrl,
    RestrictedGenres: dataItem?.RestrictedGenres && dataItem?.RestrictedGenres?.length > 0 ? dataItem?.RestrictedGenres?.map((x) => {
      return { _id: x._id, Description: x.Description }
    }) : [],
    Archive: dataItem.Archive ?? false,
    Publishings: getMediaEpisodeInShortFormPublishing(dataItem?.Publishings),
    IsBreak: dataItem?.IsBreak ?? false,
    TXReady: dataItem.TXReady ? true : false,
    Brand: dataItem?.Brand && Object.keys(dataItem?.Brand).length > 0 ? {
      _id: dataItem?.Brand?._id,
      Name: dataItem?.Brand?.Name
    } : {},
    Artist: dataItem?.Artist,
    BroadcasterId: dataItem?.BroadcasterId,
    Media: dataItem?.Media,
    Product: dataItem?.Product && Object.keys(dataItem?.Product).length > 0 ? {
      _id: dataItem?.Product?._id,
      Name: dataItem?.Product?.Name
    } : {},

  }
  return finalData;
}

DataHelper.saveScheduleFilterTemplate = (dataItem) => {
  if (!dataItem) return {};
  let finalData = {
    ...dataItem,
    MediaCategory: dataItem?.MediaCategory && dataItem?.MediaCategory?.length > 0 ? dataItem.MediaCategory.map(x => {
      return { _id: x._id, SID: x.SID, Description: x.Description, isLong: x.isLong }
    }) : [],
    MediaCategoryType: dataItem?.MediaCategoryType && dataItem?.MediaCategoryType?.length > 0 ? dataItem.MediaCategoryType.map(x => {
      return { _id: x._id, SID: x.SID, Description: x.Description, MediaCategorySID: x.MediaCategorySID }
    }) : [],
    Duration: { ...dataItem?.Duration, Condition: dataItem?.Condition && typeof dataItem?.Condition == 'string' ? utility.convertStringWithFramesToMilliseconds(dataItem?.Condition) : dataItem?.Condition },
    Brand: dataItem?.Brand && dataItem?.Brand?.length > 0 ? dataItem.Brand.map(x => {
      return { _id: x._id, Name: x.Name }
    }) : [],
    Product: dataItem?.Product && dataItem?.Product?.length > 0 ? dataItem.Product.map(x => {
      return { _id: x._id, Name: x.Name }
    }) : [],
  }
  return finalData;
}

DataHelper.savePlanningFilterTemplate = (dataItem) => {
  if (!dataItem) return {};
  let finalData = {
    Genres: dataItem?.Genres && dataItem?.Genres?.length > 0 ? dataItem.Genres.map((x) => { return { _id: x._id, Description: x.Description } }) : [],
    PGRating: dataItem?.PGRating && dataItem?.PGRating?.length > 0 ? dataItem.PGRating.map((x) => { return { _id: x._id, Description: x.Description } }) : [],
    MediaCategory: dataItem?.MediaCategory && dataItem?.MediaCategory?.length > 0 ? dataItem?.MediaCategory.map((x) => { return { _id: x._id, Description: x.Description, SID: x.SID, isLong: x.isLong } }) : [],
    Exhibitions: dataItem?.Exhibitions,
    ReleaseDate: dataItem?.ReleaseDate,
    Language: dataItem?.Language && dataItem?.Language?.length > 0 ? dataItem.Language.map((x) => { return { _id: x._id, Description: x.Description } }) : [],
    CastName : dataItem.CastName,
    CastType: dataItem?.CastType && Object.keys(dataItem?.CastType).length > 0 ? {
      _id: dataItem?.CastType?._id,
      Description: dataItem?.CastType?.Description
    } : {},
    isLive: dataItem?.isLive, 
    CreatedFrom: dataItem?.CreatedFrom,
    CreatedTo: dataItem?.CreatedTo
  }
  return finalData;
}