/* eslint-disable */
import { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../../framework/API/api";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { LookupCell } from "../../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { MenuCommandCell } from "../../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { collectionCampaignMenuModel } from "../../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { utility } from "../../../framework/utility/utilityProvider";
import { ArrayCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from 'react-toastify';
import BackRoundButton from "../../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { EnumCell } from "../../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { PlatformCell } from "../../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";
import { MyToggleButton } from "../../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { COLUMNSTYPE, FILEURL, LOCALSTORAGE_KEY, LOGEVENT, MODULE, } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import { UserPreference } from "../../userPreference/UserPreference";
import MyStatusCell from "../../../components/MyStatusCell";
import moment from "moment";
import { Loader } from "../../../framework/forms/helpercomponents/Loader";
import CampaignFilter from "./CampaignFilter";
import { useNavigate } from "react-router-dom";

export const CampaignCollection = (props) => {

  let page_take = props.page_take ?? 50;
  let showAdd = props.showAdd ?? true;
  let showBack = props.showBack ?? true;
  let showExport = props.showExport ?? false;
  let showCommandCell = props.showCommandCell ?? true;
  let showEdit = props.showEdit ?? true;
  let showTitle = props.showTitle ?? true;
  let gridRowRender = props.gridRowRender;
  let sortable = props.sortable ?? true;
  let sort = props.sort ?? { SID: 1 };
  let isPageable = props.isPageable ?? true;
  let wherestatement = props.wherestatement;
  let customColumns = props.customColumns ?? [];
  let openNewEditForm = props.openNewEditForm ?? false;
  let myTitle = props.title ?? "";
  let entityName = props.entityname;
  let showPreferenceButton = props.showPreferenceButton ?? true;
  let hideArchiveProps = props.hideArchive ?? false;
  let showFilterTemplate = props.showFilterTemplate ?? true;
  const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;

  const [gridData, setGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({ SID: 1 });
  const [isLoading, setIsLoading] = useState(true);
  const [showPreferences, setShowPreferences] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showArchived, setShowArchived] = useState(false);

  const [filterData, setFilterData] = useState({});
  const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
  const [gridSort, setGridSort] = useState([initialSort]);

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  const lang = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowArchived(false);
    setPage({ skip: 0, take: page_take });
    loadData(filterData, 0, page_take, '', false,initialSort);
  }, [props.entityname, props.gridData, props.title]);

  const enterEdit = (item) => {
    console.log("onEnterEdit");
    if (openNewEditForm === false) {
      setOpenForm(true);
      setEditItem(item);
    } // need to navigate to full page edit view
    else {
      navigate(`${item.SID}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false } });
    }
  };

  const handleNavigate = (item) => {
    if (item?.Module == MODULE.CAMPAIGN || item?.Module == MODULE.BOOKING || item?.Module == MODULE.SHOW_SPOTS) {
      window.open(item.path, "MyTargetWindowName");
    } else {
      navigate(`${item.path}`, { state: item });
    }

  }

  //onSubmit from EDIRFORM
  const handleSubmit = async (dataItem, dataToSave) => {
    console.log("handleSUbmit");
    //if datatosave is null then dataitem is datasave
    dataToSave = dataToSave == null ? dataItem : dataToSave;

    let isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

    if (!isDuplicate) {
      setOpenForm(true);
      toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let res = await API.saveData(props.entityname, dataToSave);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    utility.deleteLocalStorageItem(props.entityname)
    //to load grid with new data
    loadData(filterData, page.skip, page.take, searchText, showArchived,initialSort);
    setGridSort([initialSort]);
    setOpenForm(false);
  };

  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSearchClick();
    }
  }

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleArchive = async (dataItem) => {

    let resArchiveUpdated = await API.saveData(entityName, {
      ...dataItem,
      Archive: !dataItem.Archive
    })

    if (resArchiveUpdated.success) {
      console.log(`Archive is been updated to ${!dataItem.Archive}`);
      let logData = { event: resArchiveUpdated.data.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: MODULE.CAMPAIGN, data: resArchiveUpdated.data, message: resArchiveUpdated.data.Archive ? "Archive Campaign" : "UnArchive Campaign" };
      API.SaveLogs(logData);

    let sortEvent = gridSort[0] ?? initialSort;
    loadData(filterData, page.skip, page.take, searchText, showArchived,sortEvent);
      utility.deleteLocalStorageItem(entityName);
    } else {
      console.log(resArchiveUpdated.message);
    }
  }

  const onCloseUserPrefence = () => {
    let sortEvent = gridSort[0] ?? initialSort;
    loadData(filterData, page.skip, page.take, searchText, showArchived,sortEvent);
    setShowPreferences(!showPreferences);

  }

  //Edit Row
  const MyCommandCell = (props) => (

    <MenuCommandCell
      {...props}
      enterEdit={enterEdit}
      cancelEdit={handleCancelEdit}
      onSubmit={handleSubmit}
      item={editItem}
      dataColumns={dataColumns}
      onArchive={handleArchive}
      showEdit={showEdit}
      toNavigate={handleNavigate}
      myMenuModel={collectionCampaignMenuModel}
      openNewEditForm={openNewEditForm}
    />
  );

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={entityName}
      size={"small"}
    />
  );

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const handleServerSideSearch = (e) => {

    setSearchText(() => e.target.value);
    let sortEvent = gridSort[0] ?? initialSort;

    if (e.target.value == '') {
      loadData(filterData, page.skip, page.take, '', showArchived,sortEvent);
    }
  };

  const onSearchClick = () => {

    let sortEvent = gridSort[0] ?? initialSort;

    if (searchText != '') {
      loadData(filterData, page.skip, page.take, searchText, showArchived,sortEvent);
    } else {
      loadData(filterData, page.skip, page.take, '', showArchived,sortEvent);
    }

  }

  const pageChange = async (e) => {
    let sortEvent = gridSort[0] ?? initialSort;
    loadData(filterData, e.page.skip, e.page.take, searchText, showArchived,sortEvent);
    setPage(e.page);
  };

  const onArchiveClick = (e) => {
    setShowArchived(!showArchived);
    let sortEvent = gridSort[0] ?? initialSort;
    if (!showArchived) {
      loadData(filterData, 0, page_take, searchText, true,sortEvent);
    } else {
      loadData(filterData, page.skip, page_take, searchText, false,sortEvent);
    }
  }

  const refreshCollection = () => {
    setIsLoading(true);
    loadData(filterData, page.skip, page.take, searchText, showArchived,initialSort);
    setGridSort([initialSort]);
  }

  const onSortChange = (e) => {
    setGridSort(e.sort)
    let sortEvent = e.sort[0] ?? sort;
    loadData(filterData, page.skip, page.take, searchText, showArchived,sortEvent);
  }

  const downloadExcel = async () => {

    if (gridData.length == 0) {
      return;
    }

    let storeData = gridData.map(x => {
      return {
        CampaignNo: x.CampaignNo,
        Title: x.Title,
        Agency: x.Agency.Name,
        Client: x.Client.Name,
        Channels: x.Channels.map(obj => obj.FullChannelName).join(','),
        Commercials: x.Commercials.map(obj => obj.Title).join(','),
        CampaignType: x.CampaignType?.Description,
        Product: x.Product?.Name,
        OrderDate: x.OrderDate > 0 ? moment(new Date(x.OrderDate)).format('YYYY-MM-DD') : '',
        PeriodFrom: x.PeriodFrom > 0 ? moment(new Date(x.PeriodFrom)).format('YYYY-MM-DD') : '',
        PeriodTo: x.PeriodTo > 0 ? moment(new Date(x.PeriodTo)).format('YYYY-MM-DD') : '',
        DealType: x.DealType?.Description,
        TargetBudget: x.TargetBudget,
        TargetSpots: x.TargetSpots,
        TargetSeconds: x.TargetSeconds,
        Commission: x.Commission,
        Currency: x.Currency?.Description,
        Location: x.Location?.Description,
      }
    })
    console.log(storeData)
    let res = await API.exportCSV(storeData);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const loadData = async (filter, pageSkip, pageTake, searchText, archive,sorting) => {
    try {

      // setIsLoading(true);
      let current_page =  searchText != '' ? 1 :  pageSkip / pageTake + 1;

      let storedData = {
        PeriodFrom: filter?.PeriodFrom ? new Date(filter.PeriodFrom).getTime() : '',
        PeriodTo: filter?.PeriodTo ? new Date(filter.PeriodTo).getTime() : '',
        Channels: filter?.Channels ? filter.Channels : [],
        CampaignType: filter?.CampaignType ? filter.CampaignType : {},
        DealType: filter?.DealType ? filter.DealType : {},
        Agency: filter?.Agency ? filter.Agency : {},
        Client: filter?.Client ? filter.Client : {},
        Product: filter?.Product ? filter.Product : {}
      }
      

      let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
      let columns = await API.getFormData(preferenceColumnENntityName, currentUserId);

      let finalSort = sorting.dir ? { [sorting.field ?? 'SID']: sorting.dir == 'asc' ? 1 : -1 } : sort;

      let payload = {
        query: storedData,
        dataColumns: columns,
        archive: archive,
        searchText: searchText,
        page: current_page,
        perPage: pageTake,
        sort : finalSort
      }

      let resFilterData = await API.getCampaign(payload);
      console.log(resFilterData.data);
      setGridData(resFilterData.data);
      setTotal(resFilterData.pagination.total)
      customColumns.length > 0
        ? setDataColumns(customColumns)
        : setDataColumns(columns);
      setIsLoading(false);

    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  }

  const toGetFilteredData = (data) => {
    setFilterData(data);
    let sortEvent = gridSort[0] ?? initialSort;
    loadData(data,0,page.take, searchText, showArchived,sortEvent);
  }

  return (
    <div>
      {showTitle && (
        <>
          <h1 className="page-title txt-color-bludeDark">
            <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} ></i>
            <span>{myTitle === "" ? props.entityname : myTitle}</span>
          </h1>
        </>
      )}
      <div className="flex-container">
        <div className="martb mar">
          {showBack && (
            <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
          )}
          {showAdd && (
            <AddRoundButton onClick={() => enterEdit({ SID: 0 })}></AddRoundButton>
          )}
        </div>

        <div className="flex-container-reverse flex-item-auto">
          <div className="input-group input-group w-300 martb" >
            <input
              type="text"
              className="form-control shadow-inset-2 pt-0 pb-0"
              id="searchInput"
              aria-label="type 2 or more letters"
              placeholder={lang.search_button_text}
              onKeyDown={onSearchEnter}
              onChange={handleServerSideSearch}
              onFocus={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(true);
                }
              }}
              onBlur={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(false);
                }
              }}
            />
            {showFilterTemplate ? <CampaignFilter
              toGetFilterDataItem={toGetFilteredData}
              height={"38px"}
              onClear={() => { wherestatement = props.wherestatement; }}
            /> :
              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "40px" }} onClick={onSearchClick}>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                  </span>
                </div>
              </button>}
          </div>
          {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
            <PreferenceRoundButton
              icon={"gear"}
              title={lang.preference_button_tooltip}
              onClick={() => { setShowPreferences(!showPreferences) }}
            />
          </div>
          }
          <div className="mt-1" style={{ paddingTop: "12px" }}>
            <RefreshButton
              onClick={refreshCollection}
            />
          </div>
          {showExport && (
            <div className="m-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"download"}
                btnColor={"info"}
                style={{ margin: "0px 03px", color: 'white' }}
                title={'Export Campaign'}
                onClick={downloadExcel}
              /></div>
          )}
          {!hideArchiveProps && <div className="d-inline mt-3 mr-2">
            <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
          </div>}
        </div>
      </div>

      {isLoading && <Loader />}
      {!isLoading &&
        <Grid
          style={props.gridstyle ?? {
            height: "76vh",
          }}
          data={gridData}
          rowRender={gridRowRender}
          sort={gridSort}
          sortable={sortable}
          onSortChange={onSortChange}
          pageable={isPageable}
          skip={page.skip}
          take={page.take}
          onPageChange={pageChange}
          total={total}
          resizable={true}
        >
          {/* Edit Row */}

          {showCommandCell && (
            <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
          )}

          {/* Rows Loop */}
          {dataColumns.map((column, index) => {
            if (column.type === COLUMNSTYPE.date) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={DateOnlyCell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={true}
                />
              );
            } else if (column.type === COLUMNSTYPE.datetime) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={DateCell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={true}
                />
              );
            } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={TimeCell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={true}
                />
              );
            } else if (column.name === "SID") {
              return;
            } else if (column.type === COLUMNSTYPE.image) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={ImageCell}
                  title={column.label}
                  width={column.width ?? 100}
                  sortable={false}
                />
              );
            } else if (column.type === COLUMNSTYPE.select) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyDatalookupCell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.toggle) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyCustomToggleButton}
                  title={column.label}
                  width={85}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.checkbox) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={CheckboxCell}
                  title={column.label}
                  width={column.width ?? 80}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.array) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={ArrayCell}
                  title={column.label}
                  width={column.width ?? 300}
                  format={column.format ?? "Description"} //display item in object
                  sortable={true}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.href) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={HrefCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.enum) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={EnumCell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={true}
                />
              );
            } else if (column.type === COLUMNSTYPE.platform) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={PlatformCell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.status) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyStatusCell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={true}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.localdatetime) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={LocalDateTimeColumn}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={true}
                />
              );
            }
            else {
              return (
                column.type !== COLUMNSTYPE.hidden && (
                  <GridColumn
                    key={index}
                    field={column.name}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                )
              );
            }
          })}
        </Grid>}
      {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}

    </div>
  );
};
