/* eslint-disable */
import React from 'react'
import { useState, useEffect } from "react";
import { ASSETTYPE, CONTENT_TYPE, DOCUMENT_TYPE, FREQUENCY_TYPE, MENUTYPE, MODULE, PLAYLIST_STATUS, PRODUCTS, THEMES } from '../../../constant/constant';
import * as API from '../../../API/api'

//key name and there enum
var foriegnKey = {
  OttAssetTypeSID : ASSETTYPE,
  MenuType: MENUTYPE,
  OttThemeSID: THEMES,
  moduleID: MODULE,
  frequencyType: FREQUENCY_TYPE,
  Products: PRODUCTS,
  DocumentType: DOCUMENT_TYPE,
  Status: PLAYLIST_STATUS,
  content_type : CONTENT_TYPE
}

export const EnumCell = (props) => {

    const field = props.field || "" ;
    const value = props.dataItem != undefined ? props.dataItem[field] : 1;
    const [displayValue, setdisplayValue] = useState("");

    useEffect(() => {
      loaddata();
    }, [])


    async function loaddata(){
      //TO SHOW Description,Name etc.. if only _id is saving in db
      if(!foriegnKey[field]){
        let entityName = field.toLowerCase()
        let masterRes = await API.getDataLookup(entityName, { sort: { SID : 1 }, query: ['Archive', '=', false] });
        setdisplayValue(masterRes.data.find((x) => x._id == props.dataItem[field])[props?.format])
      }else{
        var arrayENUM = Object.keys(foriegnKey[field]).map((key) => { return {ID : foriegnKey[field][key] , Description : key}})
        var filter = arrayENUM.find((x) => x.ID == value);
        setdisplayValue(filter.Description);
      }
        
    }

     return <td>
          {displayValue} 
    </td>;
  };