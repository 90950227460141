import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as API from '../../../../framework/API/api'
import { ENTITYNAME, FILEURL, MODULETYPES, REPORTTYPE } from "../../../../framework/constant/constant";
import { useEffect, useState } from "react";
import ActionButton from "../../../../framework/forms/helpercomponents/buttons/ActionButton";
import { toast } from "react-toastify";
import EditRoundButton from "../../../../framework/forms/helpercomponents/buttons/EditRoundButton";
import AddRoundButton from "../../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import ExportTemplate from "../../../Export/ExportTemplate";

const ExportModal = (props) => {
    const { show, setShow, data, filterQuery } = props;

    const labelStyle = { fontWeight: "400", fontSize: "14px" }

    const inputStyle = { width: "220px", marginTop: "10px", fontSize: "14px", minHeight: "30px", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }

    const [dropdownData, setDropdownData] = useState({
        templates: [],
        formats: [REPORTTYPE[0], REPORTTYPE[2]]
    });
    const [selectedData, setSelectedData] = useState({
        template: { SID: 0, Name: 'Select Template', Columns: [] },
        format: null
    });

    const [showAddEditTemplate, setShowAddEditTemplate] = useState(false);
    const [refreshTemplate, setRefreshTemplate] = useState(false);

    useEffect(() => {
        loadData();
    }, [refreshTemplate]);

    const loadData = async () => {

        let dropdownDataState = dropdownData;

        let exportReportRes = await API.getData(ENTITYNAME.ExportTemplate, { sort: { Name: 1 }, query: ['ModuleType.SID', '=', MODULETYPES[8].SID] });
        if (exportReportRes.success) {
            dropdownDataState = { ...dropdownDataState, templates: exportReportRes.data };
        }

        setDropdownData(dropdownDataState);
    }

    const onFieldValueChange = (e) => {
        console.log(e.target.value);
        setSelectedData({ ...selectedData, [e.target.name]: e.target.value });
    }

    const onClose = () => {
        setShow(false);
    }

    const isValidforExport = () => {
        if (selectedData.template.SID === 0) {
            toast.error('Please select template', {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const onExport = async () => {
        console.log(show);
        if (isValidforExport()) {
            let args = {
                filterBy: show.filterData.filterBy.value,
                fromDate: show.filterData.fromDate.getTime(),
                toDate: show.filterData.toDate.getTime(),
                platformSID: show.filterData?.platform ? show.filterData.platform.SID : 0,
                regionSID: show.filterData?.region ? show.filterData.region.SID : 0,
                categories: show.filterData?.category ? show.filterData.category : [],
                genres: show.filterData?.genre ? show.filterData.genre : [],
                PGRating: show.filterData?.pgRating ? show.filterData.pgRating : null,
                channel: show.filterData?.channel ? show.filterData.channel.SID : 0,
                template: selectedData.template,
            }
            var res = await API.getManageContentExport(args);
            console.log(res);
            if (res.success) {
                window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
                toast.success('Successfully Exported!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const onAddEditTemplate = (isNew = false) => {
        if (isNew) {
            setSelectedData({ ...selectedData, template: { SID: 0, Name: 'Select Template', Columns: [] } });
        }
        setShowAddEditTemplate(!showAddEditTemplate);
    }

    const onRefreshTemplate = () => {
        setRefreshTemplate(!refreshTemplate);
    }

    return show.show && <Dialog
        title={'Export'}
        onClose={onClose}
        width={"320px"}
        height={"auto"}
    >
        <div>
            <ActionButton style={{ marginLeft: 0 }} title={'Export'} name={'Export'} onClick={onExport} />
            <ActionButton btnColor={'danger'} title={'Cancel'} name={'Cancel'} onClick={onClose} />
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "20px", padding: "10px 0" }}>
                <div>
                    <label style={labelStyle}>Template Name</label><br />
                    <DropDownList
                        data={dropdownData.templates}
                        name="template"
                        textField="Name"
                        dataItemKey="_id"
                        onChange={onFieldValueChange}
                        style={inputStyle}
                        value={selectedData.template}

                    />
                </div>

                <div style={{ paddingTop: "25px", paddingLeft: "5px", display: "flex" }}>
                    <EditRoundButton disabled={selectedData?.template?._id ? false : true} title={'Edit Template'} name={'Edit Template'} onClick={() => onAddEditTemplate(false)} />
                    <AddRoundButton title={'Add Template'} name={'Add Template'} onClick={() => onAddEditTemplate(true)} />
                </div>

                {/* <div>
                    <label style={labelStyle}>Format</label><br />
                    <DropDownList
                        data={dropdownData.formats}
                        name="format"
                        textField="Description"
                        dataItemKey="SID"
                        onChange={onFieldValueChange}
                        style={inputStyle}

                    />
                </div> */}
                {showAddEditTemplate && <ExportTemplate type={MODULETYPES[8]} item={selectedData.template} cancelEdit={onAddEditTemplate} refresh={onRefreshTemplate} />}
            </div>
        </div>
    </Dialog>
}

export default ExportModal;