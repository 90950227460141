/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";

export const JobsScheduleEditForm = (props) => {

  const [dataItem, setDataItem] = useState({
    
    Description : '',
    Command : '',
    Minutes : '*',
    Hours : '*',
    Days : '*',
    Months : '*',
    Weeks : '*',
    EnableErrorLog : false,
    Archive: false
  });
  const [showForm, setShowForm] = useState(false);
  const lang=useTranslation();

  useEffect(() => {
    setEditItem();
  }, [])


  const handleChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  function setEditItem() {

    let dataItemLocal = {};

    
      dataItemLocal = {
        ...props.item,
        SID: props.item.SID ? 0 : props.item.SID,
        Description : props.item.copy ? 'copy of ' + props.item.Description : props.item.Description,
        Command : props.item.Command ?? "",
        Minutes : props.item.Minutes ?? '*',
        Hours : props.item.Hours ?? '*',
        Days : props.item.Days ?? '*',
        Months : props.item.Months ?? '*',
        Weeks : props.item.Weeks ?? '*',
        EnableErrorLog : props.item.EnableErrorLog,        
        Archive: props.item.Archive

      }
     setDataItem(dataItemLocal);
    setShowForm(true);

  }

  const isValid = () => {
    const regex = /^[0-9*]*$/;

    if(dataItem.Description=="" || dataItem.Description==undefined){
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(dataItem.Command=="" || dataItem.Command==undefined){
        toast.error(`${lang.please_enter_command_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }      
     
    if (dataItem.Minutes === '' || !regex.test(dataItem.Minutes)) {
          toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message+ ": Minutes"}` , {
          position: toast.POSITION.TOP_RIGHT
        });
        return false; 
    } 
    if (dataItem.Minutes > 59 || dataItem.Minutes < 0) {
        toast.error(`${ "Minutes" + " " + lang.can_not_greater_than_error_message + ": 59"}` , {
        position: toast.POSITION.TOP_RIGHT
      });
      return false; 
      } 
    
    if (dataItem.Hours === '' || !regex.test(dataItem.Hours)) {
        toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Hours"}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false; 
    } 
    if (dataItem.Hours > 23 || dataItem.Hours < 1) {
      dataItem.Hours === '0'  ? toast.error(`${"Hours" + " " + lang.can_not_less_than_error_message + ": 1"}`) : toast.error(`${"Hours" + " " + lang.can_not_greater_than_error_message + ": 23"}` , {
      position: toast.POSITION.TOP_RIGHT
      });
      return false; 
      } 
    if (dataItem.Days === '' || !regex.test(dataItem.Days)) {
        toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Days"}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false; 
    } 
    if (dataItem.Days > 31 || dataItem.Days < 1) {
       dataItem.Days < 1 ? toast.error(`${"Days" + " " + lang.can_not_less_than_error_message + ": 1"}`) : toast.error(`${"Days" + " " + lang.can_not_greater_than_error_message + ": 31"}` , {
        position: toast.POSITION.TOP_RIGHT
      });
      return false; 
      } 
    if (dataItem.Months === '' || !regex.test(dataItem.Months)) {
        toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Months"}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false; 
    } 
    if (dataItem.Months > 12 || dataItem.Months < 1) {
       dataItem.Months < 1 ? toast.error(`${"Months" + " " + lang.can_not_less_than_error_message + ": 1"}`) :  toast.error(`${"Months" + " " + lang.can_not_greater_than_error_message + ": 12"}` , {
        position: toast.POSITION.TOP_RIGHT
      });
      return false; 
      } 
    if (dataItem.Weeks === '' || !regex.test(dataItem.Weeks)) {
        dataItem.Weeks < 1 ? toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Weeks"}`) :  toast.error(`${lang.invalid_input_only_number_and_star_are_allow_error_message + ": Weeks"}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false; 
    } 
    if (dataItem.Weeks > 7 || dataItem.Weeks < 1) {
        dataItem.Weeks < 1 ? toast.error(`${"Weeks" + " " + lang.can_not_less_than_error_message + ": 1"}`) : toast.error(`${"Weeks" + " " + lang.can_not_greater_than_error_message + ": 7"}` , {
        position: toast.POSITION.TOP_RIGHT
      });
      return false; 
      } 
    return true;
  }
  
  const setHourly = async () => {
    setDataItem({ ...dataItem,  Minutes:"0", Hours:"*", Days: "*", Months:"*", Weeks:"*"});
  }

const setDaily = async () => {
  setDataItem({ ...dataItem, Minutes:"0", Hours:"1", Days: "*", Months:"*", Weeks:"*"});
}

const setWeekly = async () => {
  setDataItem({ ...dataItem,  Minutes:"0", Hours:"1", Days: "*", Months:"*", Weeks:"*"});
}

const setMonthly = async () => {
  setDataItem({ ...dataItem,  Minutes:"0", Hours:"1", Days: "1", Months:"1", Weeks:"*"});
}
const setYearly = async () => {
  setDataItem({ ...dataItem,  Minutes:"0", Hours:"1", Days: "1", Months:"1", Weeks:"*"});

}


  const handleSubmit = async () => {

    if(isValid()){

      const saveData = {
        _id: dataItem._id,
        SID: dataItem.SID,
        Description : dataItem.Description,
        Command : dataItem.Command ?? "",
        Minutes : dataItem.Minutes ?? "*",
        Hours : dataItem.Hours ?? '*',
        Days : dataItem.Days ?? '*',
        Months : dataItem.Months ?? '*',
        Weeks : dataItem.Weeks ?? '*',
        EnableErrorLog : dataItem.EnableErrorLog ?? false,   
        Archive: dataItem.Archive ?? false,
        }
      console.log(saveData)
      const res = await API.saveData(ENTITYNAME.JobsSchedule,saveData);
      if(res.success){
        let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_JOB_SCHEDULE : LOGEVENT.UPDATE_JOB_SCHEDULE, module: MODULE.JOBS_SCHEDULE, data: res.data, message: res.message };
        API.SaveLogs(logData);
        props.refresh();
        props.cancelEdit();
      }else{
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      
    }
  }

  return (
    <>
      <div>
        <Dialog
          title={props.item.SID > 0 ? props.item.Description : `${lang.create_jobs_schedule_dialog_header}`}
          onClose={props.cancelEdit}
          width="650px"
          height="440px"
        >
          {showForm && <Form
            render={(formRenderProps) => (
              <FormElement>
                <EditPageHeader title={(props.item.SID > 0 ? dataItem.Description: `${lang.new_title_editpageheader}`)} onSubmit={handleSubmit} onCancel={props.cancelEdit}/>
                <div className="row">
                  <div className="col-12">

                    <div className="row mt-2">
                      <div className="col-12">
                        <label>{lang.description_label} *</label>
                       <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Description"} onChange={handleChange} value={dataItem.Description} />
                       </div>                     
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>{lang.command_label} *</label>
                            <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Command"} onChange={handleChange} value={dataItem.Command} />
                        </div>
                    </div>
                    <div className="mt-3">
                    <label htmlFor="">{lang.quick_schedule_lebel}</label>
                    </div>
                    <div className="row mt-2">
                        <div>
                            <ActionButton style={{borderRadius : "4px"}} title={lang.hourly_button_tooltip} name={lang.hourly_button_title} btnColor={'info'} onClick={(e) => { setHourly() }} />
                        </div>
                        <div>
                            <ActionButton style={{borderRadius : "4px"}} title={lang.daily_button_tooltip} name={lang.daily_button_title} btnColor={'info'} onClick={(e) => { setDaily(); }}/>
                        </div>
                        <div>
                            <ActionButton style={{borderRadius : "4px"}} title={lang.weekly_button_tooltip} name={lang.weekly_button_title} btnColor={'info'} onClick={(e) => { setWeekly(); }}/>
                        </div>
                        <div>
                            <ActionButton style={{borderRadius : "4px"}} title={lang.monthly_button_tooltip} name={lang.monthly_button_title} btnColor={'info'} onClick={(e) => { setMonthly(); }}/>
                        </div>
                        <div>
                            <ActionButton style={{borderRadius : "4px"}} title={lang.yearly_button_tooltip} name={lang.yearly_button_title} btnColor={'info'} onClick={(e) => { setYearly(); }}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-2" style={{paddingRight:'0px'}}>
                            <label htmlFor="">{lang.minute_label}</label>
                            <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px'}} name={"Minutes"} onChange={handleChange} value={dataItem.Minutes} />
                        </div>
                        <div className="col-2" style={{paddingRight:'0px'}}>
                            <label htmlFor="">{lang.hour_label}</label>
                            <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px'}} name={"Hours"} onChange={handleChange} value={dataItem.Hours} />
                        </div>
                        <div className="col-2" style={{paddingRight:'0px'}}>
                            <label htmlFor="">{lang.day_Of_month_label}</label>
                            <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px'}} name={"Days"} onChange={handleChange} value={dataItem.Days} />
                        </div>
                        <div className="col-2" style={{paddingRight:'0px'}}>
                            <label htmlFor="">{lang.month_label}</label>
                            <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px'}} name={"Months"} onChange={handleChange} value={dataItem.Months} />
                        </div>
                        <div className="col-2" style={{paddingRight:'0px'}}>
                            <label htmlFor="">{lang.week_label}</label>
                            <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px'}} name={"Weeks"} onChange={handleChange} value={dataItem.Weeks} />
                        </div>
                       
                    </div>
                    <div className="row mt-3">
                    <div className="col-3" >
                        <Field
                          name={"EnableErrorLog"}
                          component={Checkbox}
                          label={lang.enable_error_log_lebel}
                          checked={dataItem.EnableErrorLog}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-3" >
                        <Field
                          name={"Archive"}
                          component={Checkbox}
                          label={lang.archive}
                          checked={dataItem.Archive}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )}
          />}
        </Dialog>
      </div>
    </>)
};