/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { DAYS, ENTITYNAME, MONTH, PLAYLIST_SCHEDULE_TYPE, PLAYLIST_STATUS } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import { Menubar } from 'primereact/menubar';
import contextMenuItem from "../../../components/contextMenuItem";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PlaylistHelper } from "./PaylistHelper";
import { utility } from "../../../framework/utility/utilityProvider";
import * as API from "../../../framework/API/api";
export const PlaylistHeader = (props) => {

    const lang = useTranslation();
    const {
        date,
        selectedNode,
        handleFilterDialog,
        onDateChange,
        onHeaderExpandChange,
        onCut,
        onCopy,
        onPaste,
        onDelete,
        onPlaylistRefresh,
        handleNewPlaylistDialog,
        startDate,
        endDate,
        playlistData,
        onPlaylistNameChange,
        setShowScreenGroupPopup,
        onPlaylistAction,
        onSchedulePlaylistClick
    } = props
    const navigate = useNavigate();
    const createNewPlaylist = () => {
        navigate(`/home/PlaylistItem`, {
            state: {
                playlistId: '0',
            }
        });
    }

    const nameRef = useRef();
    const [name, setName] = useState(playlistData?.Name ?? '');
    const [showInput,setShowInput] = useState(false);
    const [status, setStatus] = useState(playlistData?.Status ?? PLAYLIST_STATUS.Standby);

    useEffect(() => {
        setName(playlistData?.Name ?? '');
        setStatus(playlistData?.Status ?? PLAYLIST_STATUS.Standby);
    }, [playlistData])

    const handleManageScreenGroup = async () => {
        setShowScreenGroupPopup(true);
    }

    const enterEdit = (item) => {
        console.log("onEnterEdit");
        navigate(`/home/PlaylistItem`, {
            state: {
                playlistId: item._id,
                copy: item.copy ?? false
            }
        });
    };

    const handlePlaylistRefresh = async(item) => {
        await PlaylistHelper.onRefreshPlaylist(item);
    }

    const fileMenu = PlaylistHelper.getPlaylistMenu({ 
        dataItem : playlistData,
        enterEdit: enterEdit,
        onDelete: () => {}, //handleDelete,
        onSchedulePlaylist: onSchedulePlaylistClick,
        onManageScreenGroup: handleManageScreenGroup,
        onPlaylistAction: onPlaylistAction,
        onRefreshPlaylist : handlePlaylistRefresh

    }).filter(Boolean);

    const items = [
        {
            label: 'File',
            items: [
                {
                    icon: 'pi pi-plus',
                    label: 'New Playlist',
                    command: () => {
                        createNewPlaylist();
                    }
                },
                ...fileMenu.slice(1,fileMenu.length)
            ]
        },
        {
            label: 'Edit',
            items: [
                {
                    label: 'Cut',
                    icon: 'fa fa-cut',
                    shortcut: 'Ctrl + X',
                    command: () => {
                        onCut();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Copy',
                    icon: 'pi pi-copy',
                    shortcut: 'Ctrl + C',
                    command: () => {
                        onCopy();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Paste',
                    icon: 'fa fa-paste',
                    shortcut: 'Ctrl + V',
                    command: () => {
                        onPaste();
                    },
                    template: contextMenuItem,
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    shortcut: 'del',
                    command: () => {
                        onDelete();
                    },
                    template: contextMenuItem,
                },
            ].filter(Boolean)
        },
        {
            label: 'View',
            items: [
                {
                    icon: 'pi pi-refresh',
                    label: 'Refresh Playlist',
                    shortcut: 'F5',
                    command: () => {
                        onPlaylistRefresh();
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Expand ",
                    icon: 'pi pi-arrows-v',
                    shortcut: 'Ctrl +',
                    command: () => {
                        onHeaderExpandChange({ target: { value: true } });
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Collapse ",
                    icon: 'pi pi-minus',
                    shortcut: 'Ctrl -',
                    command: () => {
                        onHeaderExpandChange({ target: { value: false } });
                    },
                    template: contextMenuItem,
                },
                {
                    label: "Screens",
                    icon: 'pi pi-stop',
                    command: () => {
                        handleManageScreenGroup();
                    },
                    template: contextMenuItem,
                },
            ]
        },
    ];


    return <>

        <div className="row mt-2 mb-1" style={{ fontFamily: "Roboto", width: "100%" }}>

            <div className="col-8" >
                <div className="flex-container" style={{ alignItems: "left" }}>
                    <div style={{ zIndex: 999 }}>
                        <h3 className="page-edit-title txt-color-bludeDark" style={{ margin: '0px 10px 0px 0px', fontSize: '18px' }}>
                            <i className="fa-regular fa-calendar-days" style={{ margin: '0px 5px 0px 0px' }}></i>
                            <input ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} onKeyUp={(e) => {
                                if (e.key == 'Enter') {
                                    nameRef.current.blur();
                                    setShowInput(false)
                                }
                            }} onFocus={() => setShowInput(true)} onBlur={() => { onPlaylistNameChange(name); setShowInput(false);}} style={ showInput ? { border: "1px solid grey", borderRadius: "5px" } : {}} />

                        </h3>
                        <Menubar style={{ height: "40px", zIndex: 999, background: "transparent", border: "none", marginLeft: "-8px", padding: '8px 8px 8px 0px' }} model={items} />
                    </div>
                </div>
            </div>

            <div className="col" style={{ textAlign: "right", padding: '0px', margin: '0px' }}>
                <div className="row-reverse">
                  <div className="col-3 badge badge-success mr-3 pt-2 pb-2">{utility.convertCamelCaseToWords(utility.getKeyByValue(PLAYLIST_STATUS, status))}</div>
                    <span style={{ fontSize: "large", cursor: "pointer" }} onClick={() => { setShowScreenGroupPopup(true) }}>
                        {
                            playlistData?.screenGroup?.length == 0 ?
                                'No Screen Groups'
                                : playlistData?.screenGroup.map(screen => {
                                    return screen.Name;
                                }).join(',').substring(0, 20)
                        }
                    </span>
                </div>
            </div>
        </div>
    </>
}