/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ENTITYNAME, LOGEVENT, MEDIACATEGORIES, MODULE } from "../../framework/constant/constant";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useLocation } from "react-router-dom";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";

export const MediaEpisodeBreakEditForm = (props) =>  {

    const lang = useTranslation();
    const loc = useLocation();
    const [mediaCategory, setMediaCategory] = useState([]);
    const [mediaCategoryType, setMediaCategoryType] = useState([]);
    const [filterMediaCategoryType, setFilterMediaCategoryType] = useState([]);

  
    let blankDataItem = {
      AssetId: props.item.AssetId ?? 'BREAK',
      Title: props.item.copy ? "Copy of " + props.item.Title : props.item.Title ?? "",
      Content: props.item.Content ?? {},
      Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
      MediaCategory: props.item.MediaCategory ?? {},
      MediaCategoryType: props.item.MediaCategoryType ?? {},
      Duration: props.item.Duration ? utility.convertMilisecondsToStringWithFrames(props.item.Duration) : "00:00:00:00",
      IsBreak: true
    };
  
    const [dataItem, setDataItem] = useState(blankDataItem);
  
    useEffect(() => {
      loadCombo();
      
    }, []);
  
    const loadCombo = async () => {
  
      var mediaCategoryData = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 }, query: ['Archive', '=', false] });
      var filterMedia = mediaCategoryData.data.filter((obj) => obj.isLong === false);
      setMediaCategory(filterMedia);
      
      
      var mediaCategoryTypeData = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 }, query: ['Archive', '=', false] });
      setMediaCategoryType(mediaCategoryTypeData.data);
      
      if(props.item.SID > 0){
        var mediaCategoryTypeTemp = mediaCategoryTypeData.data.filter((x) => x.MediaCategorySID == props.item.MediaCategory.SID);
        setFilterMediaCategoryType(mediaCategoryTypeTemp);
      }
    };

  
    const onChange = async (e) => {
        if(e.target.name == 'MediaCategory'){
            var mediaCategoryTypeTemp = mediaCategoryType.filter((x) => x.MediaCategorySID == e.target.value.SID);
            setFilterMediaCategoryType(mediaCategoryTypeTemp);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value, MediaCategoryType : {} });
        }else if(e.target.name == 'MediaCategoryType'){
            setDataItem({ ...dataItem, Content : {}, [e.target.name]: e.target.value });
        }else{
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };
  
    const isValidToSave = () => {

    if (props.item.SID == 0 && (dataItem.MediaCategory == undefined || Object.keys(dataItem.MediaCategory).length === 0)) {
        toast.error(`${lang.please_select_media_category_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }

    if (props.item.SID == 0 && (dataItem.MediaCategoryType == undefined || Object.keys(dataItem.MediaCategoryType).length === 0)) {
        toast.error(`${lang.please_select_media_category_type_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }
  
    if (dataItem.Duration == undefined || dataItem.Duration.includes("_") || dataItem.Duration.includes("00:00:00:00")) {
        toast.error(`${lang.invalid_duration_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }
  
    return true;
    };
  
    const handleSubmit = async () => {
  
      let res = isValidToSave();
      //console.log(res);
  
      if (res) {
        const saveData = {
          _id: props?.item?._id,
          SID: props.item.copy ? 0 : props.item.SID,
          Title : `${props.item.copy ? "Copy of" : ''} ${dataItem.MediaCategoryType.Description} Break ${dataItem.Duration}`,
          Description : `${props.item.copy ? "Copy of" : ''} ${dataItem.MediaCategoryType.Description} Break ${dataItem.Duration}`,
          Content: dataItem.Content ?? {},
          AssetId :  `${props.item.copy ? "Copy of-" : ''}${dataItem.MediaCategoryType.Description}-BREAK-${dataItem.Duration}`,
          MediaCategory: dataItem.MediaCategory,
          MediaCategoryType: dataItem.MediaCategoryType,
          Duration: utility.convertStringWithFramesToMilliseconds(dataItem.Duration) ?? "00:00:00:00",
          Archive: dataItem.Archive ?? false,
          Publishings : [],
          IsBreak: true,
        };
        props.item.copy && delete saveData?._id;

        let response = await API.saveData(ENTITYNAME.MediaEpisode, saveData);        
  
        if (!response.success) {
          console.log(response.message);
          toast.error(`${lang.already_exists_global_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        } else {

          let logData = {event: saveData.SID==0 ? LOGEVENT.CREATE_BREAK : LOGEVENT.UPDATE_BREAK, module: MODULE.MEDIA_EPISODE_BREAK, data: response.data, message: response.message };
          API.SaveLogs(logData);
          props.cancelEdit();
          props.refresh();
        }
  
      }
    };
  
  return (
    <>
        {<Dialog
            title={props.item.SID > 0 ? blankDataItem.Title : `Create Break`}
            onClose={props.cancelEdit}
            width={"60%"}
        >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false}/>
        <div className="row">
            <div className="col-md-12 mt-2">
            <Form
                render={(formRenderProps) => (
                <FormElement>
            
                        <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                            <label htmlFor="">{lang.media_category_label} *</label>
                            <DropDownList
                                data={mediaCategory}
                                name="MediaCategory"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.MediaCategory}
                                onChange={onChange}
                            />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-group">
                            <label htmlFor="TabView">{lang.media_category_type_label} *</label>
                            <DropDownList
                                data={filterMediaCategoryType}
                                name="MediaCategoryType"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.MediaCategoryType ?? mediaCategoryType[0]}
                                onChange={onChange}
                            />
                            </div>
                        </div>
                        </div>

                        <div className="row">
                        <div className="col">
                            <div className="form-group">
                            <label htmlFor="">{lang.duration_label} *</label>
                            <TimePickerWithFormat
                                name="Duration"
                                value={dataItem.Duration}
                                onChange={(e) => onChange(e)}
                                required
                            />
                            </div>
                        </div>
                        </div>

                        <div className="row">
                        <div
                            className="form-check"
                            style={{ marginBottom: "20px" }}
                        >
                            <Field
                            name={"Archive"}
                            component={Checkbox}
                            label={lang.archive}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.Archive ? true : false}
                            />
                        </div>
                        </div>

                </FormElement>
                )}
            />
            </div>
        </div> 
        </Dialog>}
    </>
  )
}