import { useTranslation } from "../../../../locale/useTranslation";

const CancelButton = (props) => {
    const lang=useTranslation();
    return <button title={lang.cancel_button_tooltip} style={{borderRadius : "4px"}} type="submit" onClick={props.onClick} className={`btn-square btn-danger`} >
    {lang.cancel_button_text}
</button> 
    
}

export default CancelButton;
