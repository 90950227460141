/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useLocation, useNavigate } from 'react-router-dom';
import * as API from '../../framework/API/api'
import { ENTITYNAME, LOGEVENT, MEDIACATEGORIES, MODULE, PUBLISHING_SOURCE } from '../../framework/constant/constant';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import { utility } from '../../framework/utility/utilityProvider';
import CollectionSelection from '../../framework/forms/CollectionSelection';
import { Expander } from '../platformLayout/tv/Dashboard/helperComponent/ExpandableTile';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import InfoRoundButton from '../../components/InfoRoundButton';
import { ContentEditForm } from '../masters/ContentEditForm';
import { GenreEditForm } from '../masters/GenreEditForm';
import LanguageTranslator from '../../components/LanguageTranslator';
import IMDBEditForm from './IMDBEditForm';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { ArrayCell } from '../../framework/forms/helpercomponents/CustomGridCells/ArrayCell';
import { Loader } from '../../framework/forms/helpercomponents/Loader';
import { DataHelper } from '../../framework/helper/DataHelper';

export const MediaEpisodeDetailForm = ({dataItem, setDataItem,...props}) => {

  const [content, setContent] = useState([]);
  const [mediaCategory, setMediaCategory] = useState([]);
  const [mediaCategoryType, setMediaCategoryType] = useState([]);
  const [genre, setGenre] = useState([]);
  const [moduleName, setModuleName] = useState('');
  const [openImageBank, setOpenImageBank] = useState(false);
  const [showTranslateForm, setShowTranslateForm] = useState(false);
  const defaultFormState = {
    Content: false,
    Genre: false
  }
  const [showEditForm, setShowEditForm] = useState(defaultFormState);
  const [selectedImageUrl, setSelectedImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');
  //on create new entity and edit quickEdit is false but on quickEdit click quickEdit is true
  
  const [openForm, setFormOpen] = useState(dataItem.SID == 0 || props.quickEdit);
  const selectedMediaCategorySID = props.mediaCategory;
  const [showIMDbForm, setShowIMDbForm] = useState(false);
  const [IMDbData, setIMDbData] = useState({});
  const [selectedMusicCategory, setSelectedMusicCategory] = useState({});
  const [openMusicCategory, setOpenMusicCategory] = useState(false);
  const [showAvailableMusic, setShowAvailableMusic] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [musicData, setMusicData] = useState([]);
  
  const [durationString, setDurationString] = useState('00:00:00:00');

  const navigate = useNavigate();
  const lang = useTranslation();
  const location = useLocation();





  useEffect(() => {
    loadCombo();
  }, []);

  useEffect(() => {
    setDurationString(utility.convertMilisecondsToStringWithFrames(dataItem.Duration ?? '00:00:00:00'));
  }, [dataItem]);

  const loadCombo = async () => {

    let content = await API.getDataLookup(ENTITYNAME.Content, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    let filteredContent = content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == props.mediaCategory)
    setContent(filteredContent);

    let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
    setMediaCategory(mediaCategory.data);
    setModuleName(mediaCategory.data.find((item) => item.SID == props.mediaCategory).Description);

    let mediaCategoryType = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 } });
    setMediaCategoryType(mediaCategoryType.data);

    let genres = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
    setGenre(genres.data);

    if (dataItem.SID == 0) {

      if (filteredContent.length > 0) {
        const updatedContent = await API.getAndSaveNextNumber(filteredContent[0].SID, { ...filteredContent[0], isSaveNextnumber: false });
        if (!updatedContent.success) return;

        setDataItem({
          ...dataItem,
          Genres: utility.getDefaultItems(genres.data),
          SubGenres: utility.getDefaultItems(genres.data),
          AssetId: updatedContent.data.HouseNumber,
          Content: filteredContent.length > 0 ? filteredContent[0] : {}
        })
      }

    }
    
  };



  const onChange = async (e) => {
    console.log(e.target.name, e.target.value)

    if (e.target.name == "DurationString") {
      setDurationString(e.target.value);
      return;
    }

    if (e.target.name == "Content") {

      let item = { ...dataItem, Content: e.target.value }
      const updatedContent = await API.getAndSaveNextNumber(e.target.value.SID, { ...e.target.value, isSaveNextnumber: false });
      if (!updatedContent.success) return;
      console.log(!updatedContent.success);

      setDataItem({ ...item, AssetId: updatedContent.data.HouseNumber });

    } else if (e.target.name == "Title") {
      setDataItem({ ...dataItem, Title: e.target.value, Description: e.target.value });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }


  };


  const setSelectedImage = (dataList) => {
    setSelectedImageUrl(() => dataList[0].ImageUrl);
    setDataItem({ ...dataItem, ["ImageUrl"]: selectedImageUrl });
  }

  const isValidToSave = () => {

    if (dataItem.Title == undefined || dataItem.Title === "") {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.SID == 0) {
      if (Object.keys(dataItem.Content).length === 0) {
        toast.error(`${lang.please_select_content_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }

    if (Object.keys(dataItem.Genres).length === 0) {
      toast.error(`${lang.please_select_genres_media_episode_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (durationString == undefined || durationString.includes('_') || utility.convertStringWithFramesToMilliseconds(durationString) == 0) {
      toast.error(`${lang.duration_should_be_greater_than_zero_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.IsMusic && Object.keys(selectedMusicCategory).length == 0) {
      toast.error(`${lang.please_select_music_category_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const onCancelEdit = () => {
    if (dataItem.SID == 0) {
      navigate(-1);
    } else {
      if (props.onClose) {
        setFormOpen(false)
        props.onClose();

      } else {
        props.quickEdit ? navigate(-1) : setFormOpen(false);
      }
    }
  }



  const onSaveData = async () => {

    let res = isValidToSave();
    console.log(res);

    if (res) {
      // New
      if (dataItem.SID == 0) {
        console.log("Add");
        const updatedContent = await API.getAndSaveNextNumber(dataItem.Content.SID, { ...dataItem.Content, isSaveNextnumber: true });
        if (!updatedContent.success) return;

        const saveData = {
          ...dataItem,
          SID: parseInt(dataItem.SID),
          Content: dataItem.Content,
          AssetId: updatedContent.data.HouseNumber,
          // ReleaseDate: moment(dataItem.ReleaseDate).format("YYYY-MM-DD HH:mm:ss"),
         // ReleaseDate: new Date(moment(dataItem.ReleaseDate).format("YYYY-MM-DD HH:mm:ss")).getTime(),
          Genres : dataItem.Genres ?? [],
          SubGenres : dataItem.SubGenres ?? [],
          MediaCategory: mediaCategory.find((obj) => obj.SID == selectedMediaCategorySID),
          MediaCategoryType: mediaCategoryType.find((obj) => obj.SID == props.mediaCategoryType),
          Duration: utility.convertStringWithFramesToMilliseconds(durationString),
          ImageUrl: selectedImageUrl,
          ReferenceCode: utility.getPaddedString(dataItem.ReferenceCode, 1, true, 4),
          Archive: dataItem.Archive ?? false,
          IMDbID: Object.keys(IMDbData).length > 0 ? IMDbData?.imdbID : "",
          IsMusic: dataItem.IsMusic ?? false,
          MusicCategory: dataItem.IsMusic ? {_id: selectedMusicCategory?._id, SID: selectedMusicCategory?.SID, Name: selectedMusicCategory?.Name} : {},
          checkDuplicate: true,
          query: [["Title", "=", dataItem.Title]],
        };

        let data = DataHelper.saveMediaEpisodeEntity(saveData, saveData.MediaCategory);
        console.log(data);
        const response = await API.saveData(ENTITYNAME.MediaEpisode, data)



        if (!response.success) {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        }

        // Save metaData through IMDb
        if (Object.keys(IMDbData).length > 0) {
          var imdbMetaDataRes = await API.saveIMDbMetaData(response.data.SID, IMDbData);
        }

        // Save logs
        let logData = { event: props.mediaCategory == MEDIACATEGORIES.Program ? LOGEVENT.CREATE_PROGRAM : props.mediaCategory == MEDIACATEGORIES.Movie ? LOGEVENT.CREATE_MOVIE : LOGEVENT.CREATE_TRAILER, module: props.mediaCategory == MEDIACATEGORIES.Program ? MODULE.MEDIA_EPISODE_PROGRAM : props.mediaCategory == MEDIACATEGORIES.Movie ? MODULE.MEDIA_EPISODE_MOVIE : MODULE.MEDIA_EPISODE_TRAILER, data: response.data, message: response.message };
        API.SaveLogs(logData);

        //creating default rights, not waiting 
        API.updateMediaDealRights(response.data, [], [], PUBLISHING_SOURCE.MediaEpisode, true);

        props.setMediaEpisodeData(response.data, Object.keys(IMDbData).length > 0 ? imdbMetaDataRes.data : {})
        props.setSaveState(false);

        setFormOpen(false);

        if (props.quickEdit) {
          console.log("Here")
          navigate(-1);
        }

      }
      else // modified
      {

        console.log("edit");

        const saveData = {
          ...dataItem,
          SID: dataItem.SID,
          //ReleaseDate: new Date(moment(dataItem.ReleaseDate).format("YYYY-MM-DD HH:mm:ss")).getTime(),
          Duration: utility.convertStringWithFramesToMilliseconds(durationString ?? '00:00:00:00'),
          MediaCategory: mediaCategory.find((obj) => obj.SID == selectedMediaCategorySID),
          MediaCategoryType: mediaCategoryType.find((obj) => obj.SID == props.mediaCategoryType),
          ImageUrl: selectedImageUrl,
          IsMusic: dataItem.IsMusic ?? false,
          MusicCategory: dataItem.IsMusic ? selectedMusicCategory : {},
          Archive: dataItem.Archive ?? false,
        };

        if (props.onSave) {
          props.onSave(saveData);
          setFormOpen(false);
          props.onClose();
          return;
        }

        console.log(saveData);

        const response = await API.saveData(ENTITYNAME.MediaEpisode, saveData);
        if (!response.success) {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        }

        if (response.data.MediaCategory.SID == MEDIACATEGORIES.Episode) {
          let payload = {
            data: response.data, //episode saved entity
            entityName: ENTITYNAME.MediaEpisode
          }

          let updateSeasonEpisodeCount = await API.updateSeasonEpisodeCount(payload);
          console.log(updateSeasonEpisodeCount);
        }

        console.log(props.mediaCategory);

        // Save logs
        let logData = { event: props.mediaCategory == MEDIACATEGORIES.Program ? LOGEVENT.UPDATE_PROGRAM : props.mediaCategory == MEDIACATEGORIES.Movie ? LOGEVENT.UPDATE_MOVIE : LOGEVENT.UPDATE_TRAILER, module: props.mediaCategory == MEDIACATEGORIES.Program ? MODULE.MEDIA_EPISODE_PROGRAM : props.mediaCategory == MEDIACATEGORIES.Movie ? LOGEVENT.UPDATE_MOVIE : LOGEVENT.UPDATE_TRAILER, data: response.data, message: response.message };
        API.SaveLogs(logData);

        setFormOpen(false);
        props.setMediaEpisodeData(response.data, IMDbData ?? {})
        props.setSaveState(false);
        if (props.quickEdit) {
          navigate(-1);
        }

      }
    }
    else {
      props.setSaveState(true);
    }

  };

  const onCreateMasterClick = (key) => {
    setShowEditForm({
      ...defaultFormState,
      [key]: true
    });
  }

  const handleLangugaeTranslate = (data) => {
    console.log(data);
    setDataItem({ ...dataItem, Description: data });
    setShowTranslateForm(false);
  }

  const handleIMDbDataList = async (data) => {

    setIMDbData(data ?? {});
    let duration = data?.Runtime == "N/A" ? 0 : data?.Runtime?.split(" ")[0] * 60000;
    let genreList = data?.Genre?.split(",") ?? [];
    let genreDataList = [];

    for (let i = 0; i < genreList.length; i++) {
      let item = genreList[i];
      item = item.trim();
      let genreData = await API.getData(ENTITYNAME.Genre, { query: ['Description', '=', item] });
      if (genreData.data.length == 0) {
        let addNewGenre = await API.saveData(ENTITYNAME.Genre, { "Description": item, "Archive": false, "ExternalDescription": item, "AlternateDescription": item });
        genreDataList.push(addNewGenre.data);
      } else {
        genreDataList.push(genreData.data[0]);
      }
    };

    let prepareData = {
      Title: data?.Title,
      Description: data?.Plot,
     // ReleaseDate: moment(new Date(data?.Released)).format("YYYY-MM-DD"),
      Duration: duration,
      ImageUrl: data?.Poster,
      Genres: genreDataList
    }
    setSelectedImageUrl(prepareData.ImageUrl);
    setDataItem({ ...dataItem, ...prepareData });

  }

  const handleMusicPreview = async () => {

    setShowAvailableMusic(true);

    //API CALL
    const musicRes = await API.getMusicByMusicCategory(selectedMusicCategory);
    console.log(musicRes);
    if (musicRes.success) {
      setIsLoading(false)
      setMusicData(musicRes.data ?? []);
    }

  }

  return (
    <div className="card mb-3 ml-2 mt-3" style={{ maxWidth: "100vw" }}>
      <i className="fa-solid fa-pen-to-square" onClick={() => setFormOpen(true)} style={{ position: "absolute", top: "20px", right: "35px", cursor: "pointer" }}></i>
      <Expander expand={dataItem.SID == 0 ? true : false} title={dataItem.Title?.length == 0 ? `${lang.enter_title}` : dataItem.Title} subtext={" #" + dataItem.AssetId} img={selectedImageUrl}>

        <div className='row' >
          <div className='col-2' style={{ margin: "10px 10px 10px 15px" }}>
            <img style={{ borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} width={"200px"} src={selectedImageUrl} alt='Dummy' />
          </div>

          <div className='col-9' style={{ textAlign: "left", margin: "20px 10px 10px 10px" }}>
            <h1 className='d-inline' style={{ fontFamily: "Roboto", fontSize: "55px", fontWeight: "400" }}>{utility.subString(dataItem.Title, 20)}</h1>
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{dataItem.AssetId} </p>
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{dataItem.Genres?.map((obj) => obj.Description).join(' | ')} </p>
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{durationString}</p>
            <h2 style={{ margin: "20px 0px 3px 0px", fontFamily: "Roboto", fontSize: "30px" }}>{lang.summary}</h2>
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "15px" }}>{utility.subString(dataItem.Description, 60)}</p>
            <br />
          </div>
        </div>

        {openForm &&
          <>
            <Dialog
              title={dataItem.SID > 0 ? dataItem.Title : `${lang.create_dialog_header} ${moduleName}`}
              onClose={onCancelEdit}
              width={"70vw"}
            // height={dataItem.IsMusic ? "550px" :"515px"}
            >
              <div className="row mt-3 mb-2">
                <div className="marlr ml-4">
                  <SaveButton onClick={() => onSaveData()} />
                  <CancelButton onClick={onCancelEdit} />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-9'>
                  <Form
                    render={(formRenderProps) => (
                      <FormElement>
                        <div className='row ml-1'>
                          <div className="col-12">

                            <div className="row">

                              <div className="col-5">
                                <div className="form-group">
                                  <label htmlFor="">{lang.title_label} *</label>
                                  <span className='float-right cursor-pointer' style={{
                                    backgroundColor: 'grey',
                                    color: 'white',
                                    padding: '0px 6px',
                                    border: '1px solid #ced4da',
                                    fontSize: '10px'

                                  }} onClick={() => setShowIMDbForm(true)}>
                                    {lang.imdb_label}
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="Title"
                                    value={dataItem.Title}
                                    onChange={(e) => onChange(e)}
                                    required
                                  />
                                </div>
                              </div>

                              {selectedMediaCategorySID == MEDIACATEGORIES.Episode && <div className="col-5">
                                <div className="form-group">
                                  <label htmlFor="">{lang.episode_title_label}</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="EpisodeTitle"
                                    value={dataItem.EpisodeTitle}
                                    onChange={(e) => onChange(e)}
                                    required
                                  />
                                </div>
                              </div>}

                              {selectedMediaCategorySID == MEDIACATEGORIES.Episode &&
                                <div className="col-2">
                                  <div className="form-group">
                                    <label htmlFor="">{lang.episode_number_labelshort}</label>
                                    <input
                                      style={{ height: "35px" }}
                                      type="text"
                                      disabled={true}
                                      className="form-control form-control-sm"
                                      name="EpisodeNumber"
                                      value={dataItem.EpisodeNumber}
                                      onChange={(e) => onChange(e)}
                                      required
                                    />
                                  </div>
                                </div>}

                             
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="">{lang.description_label}</label>
                                  <label className='float-right cursor-pointer' onClick={() => setShowTranslateForm(true)}><i className="fa-solid fa-language" aria-hidden="true"></i></label>
                                  <textarea
                                    rows={3}
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="Description"
                                    value={dataItem.Description}
                                    onChange={(e) => onChange(e)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label htmlFor="TabView">{lang.content_label} *</label>
                                  <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                                  <div style={{ display: 'flex' }}>
                                    <DropDownList
                                      data={content}
                                      name="Content"
                                      textField="Description"
                                      dataItemKey="_id"
                                      value={dataItem.Content}
                                      onChange={onChange}
                                      disabled={dataItem.SID > 0 ? true : false}
                                    />
                                    <button type="button" title='Create Tournament Type' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                                      style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterClick('Content') }}><i className="fa-solid fa-plus fa-xs"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className='col-4'>
                                <label htmlFor="">{lang.reference_code_label}</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="ReferenceCode"
                                  value={dataItem.ReferenceCode}
                                  onChange={(e) => onChange(e)}
                                />
                              </div>

                              <div className="col-4">
                                <div className="form-group">
                                  <label htmlFor="">{lang.asset_id_label}</label>
                                  {dataItem.SID > 0 ? <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="AssetId"
                                    value={dataItem.AssetId}
                                    onChange={(e) => onChange(e)}
                                  /> : <h4>{dataItem.AssetId}</h4>}
                                </div>
                              </div>


                              

                            </div>


                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="">{lang.duration_label} *</label>
                                  <TimePickerWithFormat
                                    className="form-control form-control-sm"
                                    name="DurationString"
                                    value={durationString}
                                    onChange={(e) => onChange(e)}
                                    required
                                  />
                                </div>
                              </div>

                  
                            </div>


                            <div className="row">
                            

                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="TabView">{lang.genre_label} *</label>
                                  <div style={{ display: 'flex' }}>
                                    <MultiSelect
                                      data={genre}
                                      name={"Genres"}
                                      textField="Description"
                                      dataItemKey="_id"
                                      value={dataItem.Genres}
                                      onChange={onChange}
                                    />
                                    <button type="button" title='Create Tournament Type' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                                      style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterClick('Genre') }}><i className="fa-solid fa-plus fa-xs"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="TabView">{lang.sub_genre_label} </label>
                                  <div style={{ display: 'flex' }}>
                                    <MultiSelect
                                      data={genre}
                                      name={"SubGenres"}
                                      textField="Description"
                                      dataItemKey="_id"
                                      value={dataItem.SubGenres}
                                      onChange={onChange}
                                    />
                                    <button type="button" title='Create Tournament Type' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                                      style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterClick('Genre') }}><i className="fa-solid fa-plus fa-xs"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>


                           

                            

                            </div>


                            <div className="row">
                              <div className='col-md-2'>
                                <div>
                                  <Field
                                    style={{ border: "1px solid grey" }}
                                    name={"Archive"}
                                    component={Checkbox}
                                    label={lang.archive}
                                    onChange={(e) => onChange(e)}
                                    checked={dataItem.Archive ? true : false}
                                  />
                                </div>
                              </div>

                              {selectedMediaCategorySID == MEDIACATEGORIES.Program &&

                                <div className='col-md-2'>
                                  <div>
                                    <Field
                                      style={{ border: "1px solid grey" }}
                                      name={"IsLive"}
                                      component={Checkbox}
                                      label={lang.islive_label}
                                      onChange={(e) => onChange(e)}
                                      checked={dataItem.IsLive ? true : false}
                                    />
                                  </div>
                                </div>
                              }
                              {selectedMediaCategorySID == MEDIACATEGORIES.Program && <div className='col-md-2'>
                                <div>
                                  <Field
                                    style={{ border: "1px solid grey" }}
                                    name={"IsMusic"}
                                    component={Checkbox}
                                    label={lang.is_music_label}
                                    onChange={(e) => onChange(e)}
                                    checked={dataItem.IsMusic}
                                    value={dataItem.IsMusic}
                                  />
                                </div>
                              </div>}
                              {dataItem.IsMusic &&
                                <div className="col">
                                  <label >{lang.music_category_label}: </label>
                                  <div className='d-flex align-items-center'>
                                    <input
                                      name={"MusicCategory"}
                                      className="form-control form-control-sm"
                                      type={'text'}
                                      value={selectedMusicCategory.Name}
                                      disabled={true}
                                    />
                                    <button type="button" title='Select Music category' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                                      style={{ height: "30px", marginLeft: '5px' }} onClick={() => setOpenMusicCategory(true)}><i className="fa-solid fa-ellipsis-h fa-xs"></i>
                                    </button>
                                    <button type="button" title='Available Music' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning "
                                      style={{ height: "30px", marginLeft: '5px' }} onClick={handleMusicPreview} ><i className="fa-solid fa-eye fa-xs"></i>
                                    </button>
                                  </div>
                                </div>}
                            </div>
                          </div>
                        </div>
                      </FormElement>
                    )}
                  />
                </div>

                <div className="col-md-3">
                  <div className='mt-4' style={{ textAlign: "center" }}>
                    <i className="fa-solid fa-pencil " style={{ position: "absolute", top: "0", right: "47px", cursor: "pointer" }} onClick={() => setOpenImageBank(true)}></i>
                    <img style={{ borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} width={"200px"} src={selectedImageUrl} alt='Dummy' />
                  </div>
                </div>
              </div>
              {dataItem.SID == 0 && <p className='text-muted' style={{ fontSize: '11px', position: "relative", top: "20px", left: "15px" }}>* {lang.deal_rights_give_automatically_for_all_channels_label}</p>}
            </Dialog>
          </>}

        {openImageBank && <CollectionSelection entityname={ENTITYNAME.OttPosters} closeForm={() => setOpenImageBank(!openImageBank)} setDataList={setSelectedImage} title={lang.select_image_collection_header} mode={"single"} width={"50vw"} />}

        {showEditForm.Content && <ContentEditForm item={{ SID: 0 }} cancelEdit={() => setShowEditForm(false)} refresh={() => loadCombo()} filterMediaCategory={props.mediaCategory} />}
        {showEditForm.Genre && <GenreEditForm item={{ SID: 0 }} cancelEdit={() => setShowEditForm(false)} refresh={() => loadCombo()} />}
        {showTranslateForm && <LanguageTranslator onSubmit={handleLangugaeTranslate} cancelEdit={() => setShowTranslateForm(false)} />}
        {showIMDbForm && <IMDBEditForm mediaCategory={props.mediaCategory} cancelEdit={() => setShowIMDbForm(false)} imdbDataList={handleIMDbDataList} />}
        {openMusicCategory && <CollectionSelection addButtonTitle={lang.select_button_text} entityname={ENTITYNAME.MusicCategory} closeForm={() => setOpenMusicCategory(false)} setDataList={(data) => setSelectedMusicCategory(data[0])} title={lang.select_music_category_dialog_header} mode={"single"} width={"50vw"} />}
        {showAvailableMusic &&
          <Dialog title={lang.available_music_dialog_header} onClose={() => { setShowAvailableMusic(false); setIsLoading(true); }} width={"50vw"}>
            {isLoading && <Loader height="40vh" />}
            {!isLoading && <Grid data={musicData} style={{ height: "400px" }} rowHeight={50} resizable={true}>
              <GridColumn field={'Title'} title={'Title'} />
              <GridColumn field={'MediaCategory.Description'} title={'Media Category'} />
              <GridColumn field={'AssetId'} title={'Asset Id'} />
              <GridColumn field={'Genres'} title={'Genres'} cell={ArrayCell} format="Description" />
              <GridColumn field={'Duration'} title={'Duration'} cell={TimeCell} sortable={true} />
            </Grid>}
          </Dialog>}
      </Expander>
    </div>
  )
}
