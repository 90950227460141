/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { CellRender, RowRender } from './renderers';
import { myTimeCell } from '../../framework/forms/helpercomponents/inline grid cell/MyTimeCell';
import { utility } from '../../framework/utility/utilityProvider';
import { CheckboxCell } from '../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell';
import { useTranslation } from '../../locale/useTranslation';



const EDIT_FIELD = 'inEdit';

const SegmentTypeCollectionTab = (props) => {

  var segmentDetails = props.segmentDetails ?? [];

  const [data, setData] = useState([]);
  const [actualDuration, setActualDuration] = useState('00:00:00:00');
  const [changes, setChanges] = useState(false);
  const lang=useTranslation();

  useEffect(() => { 

        setData(segmentDetails);

    },[props.segmentDetails])

  if (actualDuration !== null) {
    props.handleActualDuration(actualDuration);
  }

  const enterEdit = (dataItem, field) => {
    const newData = data.map(item => ({
      ...item,
      [EDIT_FIELD]: item.Sequence === dataItem.Sequence ? field : undefined
    }));
    console.log(dataItem);
    setData(newData);
  };

  const exitEdit = () => {
    const newData = data.map(item => ({
      ...item,
      [EDIT_FIELD]: undefined
    }));
    setData(newData);
  };

  const itemChange = event => {

    let field = event.field || '';
    event.dataItem[field] = event.value;

    let newData = data.map(item => 
      {
      if (item.Sequence === event.dataItem.Sequence) {
        item[field] = event.value;
      }

      //IF TcIn or TcOut Changes, We will update duration
      if (field === "TcOut" || field === "TcIn") 
      {
        if (item.Sequence === event.dataItem.Sequence) 
        {
          let duration = utility.convertStringWithFramesToMilliseconds(event.dataItem.TcOut) - utility.convertStringWithFramesToMilliseconds(event.dataItem.TcIn);
          item['Duration'] = utility.convertMilisecondsToStringWithFrames(duration);
        }
      }
      else if (field === "Duration") // IF Duration Changes, We will update TC out Only
      {
        if (item.Sequence === event.dataItem.Sequence) 
        {
          let tcOut = utility.convertStringWithFramesToMilliseconds(event.dataItem.TcIn) + utility.convertStringWithFramesToMilliseconds(event.dataItem.Duration);
          item['TcOut'] = utility.convertMilisecondsToStringWithFrames(tcOut);
        }

      }
      return item;
    });

    setData(newData);
    props.updatedSegmentDetails(newData);
    setChanges(true);
  };

  //realtime changes in endtime and duration and total duration 
  const realtimeChangeInDuration = (dataItem) => {
    let durationString = utility.convertStringWithFramesToMilliseconds(dataItem.TcOut) - utility.convertStringWithFramesToMilliseconds(dataItem.TcIn);
    let duration = utility.convertMilisecondsToStringWithFrames(durationString);
    return duration;
  }


  const customCellRender = (td, props) => <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;

  const customRowRender = (tr, props) => <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />;

  const calculatedDurationCell = (props) => {
    let sum = 0;

    data.forEach((item) => {
      sum = sum + utility.convertStringWithFramesToMilliseconds(item.Duration);
    })
    let totalDuration = utility.convertMilisecondsToStringWithFrames(sum);
    // dataItem.ActualDuration = totalDuration;
    setActualDuration(totalDuration);

    return (
      <td colSpan={props.colSpan} style={props.style}>
        {totalDuration}
      </td>
    );
  };

  const MyCheckBoxCell = (props) => <CheckboxCell {...props} disable={false}/>;

  

  return (
    <div>
          {/* to add data list here */}
          <Grid data={data} style={{ height: "30vh" }} dataItemKey={'Sequence'} onItemChange={itemChange} cellRender={customCellRender} rowRender={customRowRender} editField={EDIT_FIELD}>
            <GridColumn field="Sequence" title={lang.sequence_column} editable={false} width={'80px'} />
            <GridColumn field="MediaId" title={lang.media_id_column} />
            <GridColumn field="TcIn" title={lang.start_time_column} cell={myTimeCell} width={'120px'} />
            <GridColumn field="TcOut" title={lang.end_time_column} cell={myTimeCell} width={'120px'} />
            <GridColumn field="Duration" title={lang.duration_column} footerCell={calculatedDurationCell} cell={myTimeCell} width={'120px'} />
            <GridColumn field="IsPreviewed" title={lang.previewed_column} width={'90px'} editor="boolean" cell={MyCheckBoxCell}/>
          </Grid>
    </div>
  )
}

export default SegmentTypeCollectionTab