import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { utility } from "../../../utility/utilityProvider";
//timepicker component with format
export const TimePickerWithFormat = (fieldRenderProps) => {

  const onChange = (e) => {
    var event = {
      ...e,
      target: {
        ...e.target,
        value: utility.validateTimeString(e.target.value),
        name: e.target.name
      },
    }
    fieldRenderProps.onChange(event);
  }
  return (
    <div>
      <MaskedTextBox
        {...fieldRenderProps}
        mask="00:00:00:00"
        defaultValue="00:00:00:00"
        value={utility.validateTimeString(fieldRenderProps.value)}
        onChange={onChange}
        disabled={fieldRenderProps?.disabled ?? false}
      />
    </div>
  );
};