/* eslint-disable */
import React, { memo, useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { Checkbox } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { utility } from "../../framework/utility/utilityProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ENTITYNAME, LOCALSTORAGE_KEY, SUBTITLETYPE, TX_MODE } from "../../framework/constant/constant";
import SegmentTypeCollectionTab from "./SegemnetTypeCollectionTab";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import { toast } from 'react-toastify';
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import SaveRoundButton from "../../framework/forms/helpercomponents/buttons/SaveRoundButton";
import CancelRoundButton from "../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import { useRef } from "react";
import { SegmentQC } from "./SegmentQC";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import MediaFastSegmentationPopup from "./MediaFastSegmentationPopup";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { collectionSegmentMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import MediaEpisodeHistory from "../Planning/MediaEpisodeHistory";

function MediaEpisodeSegmentEditForm(props) {

  const lang = useTranslation();
  const [segmentTypes, setSegmentTypes] = useState([]);
  const [mediaEpisodeSegments, setMediaEpisodeSegments] = useState(props.segmentData);
  const [segmentDetails, setSegmentDetails] = useState([]);
  const [editToggle, setEditToggle] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editItem, setEditItem] = useState([]);
  const [dialogTitle, setDialogTitle] = useState(`${lang.create_version_dialog_header}`);
  const [language, setLanguage] = useState([]);
  const [materialContent, setManageContent] = useState([]);
  const fileRef = useRef(null);
  const [file, setFile] = useState('');
  const [selectedFile, setSelectedFile] = useState(lang.select_file_label);
  const [showQCStatus, setShowQCStatus] = useState(false);
  const [fastSegmentationPopupOpen,setFastSegmentationPopupOpen] = useState(false);
  const [showSegmentHistory, setShowSegmentHistory] = useState(false);
  const segmentQCRef = useRef();
  const setSegementQCRef = (data) => {
    segmentQCRef.current = data;
  }

  const segmentHistoryRef = useRef();
  const setSegementHistoryRef = (data) => {
    segmentHistoryRef.current = data;
  }

  // company Data
  const companyData = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

  let initialValue = {
    ActualDuration: utility.convertMilisecondsToStringWithFrames(0),
    SegmentType: {},
    SegmentNumber: companyData?.segByRc ? props?.mediaEpisodeEntity?.ReferenceCode : props.mediaEpisodeEntity.AssetId,
    fileupload: "",
    SegmentDetails: [],
    IsHardParted: false,
    IsDefault: false,
    Language: {},
    Subtitle: false,
    SubtitleType: {},
    TXReady: false,
    MaterialContent: {},
    Remarks : ''
  }



  const [dataItem, setData] = useState(initialValue);
  const dataItemRef = useRef(initialValue);
  const defaultSegmentType = useRef({});

  const setDataItem =(data) => {
    dataItemRef.current = data;
    setData(data);
  }
 

  const MyCheckBoxCell = (props) => <CheckboxCell {...props} />;


  let segmentDetail = {
    Sequence: 0,
    MediaId: "",
    TcIn: '00:00:00:00',
    TcOut: '',
    Duration: '',
    IsPreviewed: false
  };

  useEffect(() => {
    loadcombo();

    if (props.mediaEpisodeEntity != undefined && props.mediaEpisodeEntity.SID > 0 && props.segmentData.length == 0) {
      loadSavedSegments();
    }

  }, [])

  const onChange = async(e) => {

    if (e.target.name == "fileupload") {
      setFile(e.target.files[0]);
      setSelectedFile(lang.select_file_label);
      setDataItem({ ...dataItem, File: e.target.files[0].name });
    }else if (e.target.name == "MaterialContent") {
      //check 
      let value = e.target.value
      const updatedContent = await API.getAndSaveMaterialContentNextHouseNumber(value.SID, { ...value, isSaveNextnumber: false });
      if (!updatedContent.success) return;
      console.log(!updatedContent.success);
      setDataItem({ ...dataItem, MaterialContent : value, SegmentNumber: updatedContent.data.HouseNumber });

      let tempSegmentNumber = dataItem.SegmentNumber.length == 0 ? props.mediaEpisodeEntity.AssetId : dataItem.SegmentNumber;
      setSegmentDetails(segmentDetails.map((x) => {
        return {
          ...x,
          MediaId : x.MediaId.replace(
            tempSegmentNumber,
            updatedContent.data.HouseNumber
          )
        }
      }));

    }else if(e.target.name == 'GenerateMaterialID'){
      if(e.target.value) {
        setDataItem({ ...dataItem, SegmentNumber : '' ,[e.target.name]: e.target.value })
      }else{

        if(dataItem.MaterialContent == undefined || Object.keys(dataItem.MaterialContent).length == 0){
          setDataItem({ ...dataItem, SegmentNumber : props.mediaEpisodeEntity.AssetId, [e.target.name]: e.target.value })
          return;
        }

        setDataItem({ ...dataItem, 
          SegmentNumber : props.mediaEpisodeEntity.AssetId,
          [e.target.name]: e.target.value,
          MaterialContent : {} 
        });
        setSegmentDetails(segmentDetails.map((x) => {
          return {
            ...x,
            MediaId : x.MediaId.replace(dataItem.SegmentNumber,props.mediaEpisodeEntity.AssetId)
          }
        }));

      }
    }else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const loadcombo = async () => {

    // SEGMENT TYPE
    let segmentTypeRes = await API.getDataLookup(ENTITYNAME.SegmentType, { sort: { Description: 1 } });
    setSegmentTypes(segmentTypeRes.data);
    if(dataItemRef.current.SegmentType == undefined || dataItemRef.current.SegmentType._id == undefined){
      defaultSegmentType.current = utility.getDefaultItem(segmentTypeRes.data);
      setDataItem({...dataItemRef.current, SegmentType: defaultSegmentType.current })
    }

    // SUBTITLE
    if (dataItem.Subtitle) {
      let res = await API.readFile(file);
      console.log(res);
    }

    // LANGUAGES
    let languageRes = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
    setLanguage(languageRes.data);

    console.log(props?.mediaEpisodeEntity);
    // MATERIAL CONTENT
    let materialContentRes = await API.getData(ENTITYNAME.MaterialContent, { sort: { Description: 1 }, query: ["MediaCategory.SID", "=", props?.mediaEpisodeEntity?.MediaCategory?.SID] });
    console.log(materialContentRes.data);
    setManageContent(materialContentRes.data);
  }

  const loadSavedSegments = async () => {

    let res = await API.getData(ENTITYNAME.MediaEpisodeSegment, { query: ['MediaEpisodeSID', '=', parseInt(props.mediaEpisodeEntity.SID)] });
    if (res.success) {
      setMediaEpisodeSegments(() => res.data)
    }
  }

  //to get data from collection on edit
  const onEdit = (object) => {
    setEditToggle(true);
    setEditItem(object);
    setDialogTitle(`${lang.edit_version_dialog_header}`);

    setDataItem(() => ({ ActualDuration: utility.convertMilisecondsToStringWithFrames(object.ActualDuration), ...object }));

    let castedSegmentDetail = object.SegmentDetails.map(item => ({
      ...item, TcIn: utility.convertMilisecondsToStringWithFrames(item.TcIn),
      TcOut: utility.convertMilisecondsToStringWithFrames(item.TcOut), Duration: utility.convertMilisecondsToStringWithFrames(item.Duration)
    }));

    setSegmentDetails(() => castedSegmentDetail);

    setDataItem({ ...object, ["ActualDuration"]: utility.convertMilisecondsToStringWithFrames(object.ActualDuration) });

    setEditMode(() => true);

  }

  const handleGenerateSegmentClick = async (e) => {

    e.preventDefault();

    if(dataItem.SegmentNumber == undefined || dataItem.SegmentNumber == ""){
      toast.error(`${lang.please_enter_material_id_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if(dataItem.GenerateMaterialID && (!dataItem.MaterialContent || Object.keys(dataItem.MaterialContent).length == 0)){
      toast.error(`Select Material Content first`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    generateSegment();

  }

  const onAddSegment = async (isNewSegment) => {

    if (isNewSegment) {
      let filtereddata = mediaEpisodeSegments.some((item) => 
        item.SegmentType?.SID == dataItem.SegmentType?.SID &&
        item.Language?.SID == dataItem.Language?.SID && 
        item.SegmentType?.TXMode?.SID == dataItem.SegmentType?.TXMode?.SID);

      if (filtereddata) {
        toast.error(`${dataItem.SegmentType.Description} ${lang.version_label} ${lang.already_exists_global_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      } 
    }

    // isNewSegment true means new ,false means old edit
    if (!dataItem.SegmentType || segmentDetails.length == 0) {
      toast.error(`${lang.please_generate_segments_first_media_episode_version_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if(dataItem.SegmentType.SegmentCount != segmentDetails.length){
      toast.error(`${lang.generated_segments_selected_segmentType_mismatch_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if (dataItem.Subtitle) {
      if (!dataItem.SubtitleType || Object.keys(dataItem.SubtitleType).length==0) {
        toast.error(`${lang.please_select_subtitle_type_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      if (file == "" || file == undefined) {
        toast.error(`${lang.please_select_file_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
    }

    if(dataItem.GenerateMaterialID && (!dataItem.MaterialContent || Object.keys(dataItem.MaterialContent).length == 0)){
      toast.error(lang.select_material_content_first_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    // check dataItem.SegmentNumber != segmentDetails.MediaId
    let duplicateMediaID = segmentDetails.some((item) =>{
      let mediaId = item.MediaId.split('-');
      return mediaId[0] != dataItem.SegmentNumber
    });

    if (duplicateMediaID) {
      toast.error(lang.material_id_and_media_id_mismatch_please_re_generate_segments_first_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

     //CHECK SEGMENT TYPE and MEDIA ID
     let duplicateMaterialID = mediaEpisodeSegments.some((item) => 
      item._id != dataItem._id &&
      item.SegmentType?.SID == dataItem.SegmentType?.SID && 
      item.SegmentNumber == dataItem.SegmentNumber);

    if (duplicateMaterialID) {
      toast.error(lang.segment_already_exist_with_same_material_id_and_segment_type_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let hasInvalidDuration = segmentDetails.some(item => {
      let duration = item.Duration;
      return duration === null || duration === "00:00:00:00";
    });  
    console.log(segmentDetails)  
    if (hasInvalidDuration) {
      toast.error(`${lang.invalid_duration_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    
    segmentDetails.forEach((obj) => {
      delete obj.inEdit;
    })

    let materialID = dataItem.SegmentNumber;
    if(dataItem.GenerateMaterialID){
      let id = await API.getAndSaveMaterialContentNextHouseNumber(dataItem.MaterialContent.SID, { ...dataItem.MaterialContent, isSaveNextnumber: true });
      materialID = id.data.HouseNumber 
    }

    

    const saveSegmentData = {
      SID: dataItem.SID ?? 0,
      SegmentType: dataItem.SegmentType,
      ActualDuration: utility.convertStringWithFramesToMilliseconds(dataItem.ActualDuration),
      Language: dataItem.Language,
      SegmentNumber: materialID,
      IsHardParted: dataItem.IsHardParted ?? false,
      TXReady: dataItem.TXReady ?? false,
      IsDefault: dataItem.IsDefault ?? false,
      Archive: dataItem.Archive ?? false,
      Subtitle: dataItem.Subtitle ?? false,
      SubtitleType: dataItem.Subtitle ? dataItem.SubtitleType : {},
      File: dataItem.Subtitle ? file.name : "",
      Remarks : dataItem.Remarks ? dataItem.Remarks : "",
      SegmentDetails: segmentDetails.map(item => ({
        ...item, ["TcIn"]: utility.convertStringWithFramesToMilliseconds(item.TcIn),
        ["TcOut"]: utility.convertStringWithFramesToMilliseconds(item.TcOut), ["Duration"]: utility.convertStringWithFramesToMilliseconds(item.Duration)
      }))

    }

    const editIndex = mediaEpisodeSegments.indexOf(editItem)

    if (isNewSegment) {
      let temp = mediaEpisodeSegments;
      if (saveSegmentData.IsDefault) {
        temp = mediaEpisodeSegments.map((segment) => {
          return { ...segment, IsDefault: false }
        })
      }
      let saveData = [...temp, saveSegmentData]
      setMediaEpisodeSegments(() => saveData)
      props.getSegmentData(saveData);
    }
    else {

      setMediaEpisodeSegments(() => mediaEpisodeSegments.map((data, index) => {
        if (editIndex == index) {
          saveSegmentData._id = editItem._id;
          saveSegmentData.SID = editItem.SID;
          return saveSegmentData
        }
        else {
          data.IsDefault = saveSegmentData.IsDefault ? false : data.IsDefault;
          return data;
        }
      }))

      console.log(mediaEpisodeSegments);
      props.getSegmentData(mediaEpisodeSegments.map((data, index) => {
        if (editIndex == index) {
          return saveSegmentData
        }
        else {
          return data
        }
      }));
    }


    setDataItem(() => ({...initialValue, SegmentType: defaultSegmentType.current}));
    setSegmentDetails(() => []);
    setEditMode(false);
    setEditToggle(false);
  }

  const calculateSegmentDuration = function (episodeDuration, segmentCount) {

    const [hours, minutes, seconds, msec] = episodeDuration.split(':');
    // let frame = parseInt(msec)/40;
    let hoursToMilliseconds = parseInt(hours) * 3600000;
    let minutesToMilliseconds = parseInt(minutes) * 60000;
    let secondsToMilliseconds = parseInt(seconds) * 1000;

    let convertedMilliseconds = hoursToMilliseconds + minutesToMilliseconds + secondsToMilliseconds + (parseInt(msec) * 40);

    let result = segmentCount === 0 ? new Date('2000', '1', '1', '0', '0', '0') : utility.convertMilisecondsToStringWithFrames(convertedMilliseconds / segmentCount);
    return result;
  }

  const onAddSegmentQC = (dataItem) => {
    setShowQCStatus(true);
    setSegementQCRef(dataItem);  
  }

  const onSegmentHistory = (dataItem) => {
    setSegementHistoryRef(dataItem);
    setShowSegmentHistory(true);
  }

  const MyCommandCell = (props) => (

    <MenuCommandCell
      {...props}
      onEdit={onEdit} 
      removeSegment={removeSegment}
      myMenuModel={collectionSegmentMenuModel}
      onAddSegmentQC = {onAddSegmentQC}
      history= {onSegmentHistory}
    />
  );

  const MyTimeCell = (props) => <TimeCell {...props} />;


  const removeSegment = async (dataItem) => {
    const index = mediaEpisodeSegments.indexOf(dataItem);

    if (dataItem._id != undefined) {
      let resProgramSchedule = await API.getData(ENTITYNAME.ProgramSchedule, { query: ['Segment_id', '=', dataItem._id] });

      if (resProgramSchedule.data.length != 0) {
        toast.error(`${lang.this_segment_is_already_been_scheduled_segmnet_media_episode_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      } else {
        props.handleDeletedSegments(dataItem);
      }
    }

    // removing from here UI
    setMediaEpisodeSegments(() => mediaEpisodeSegments.filter((data, Index) => {
      if (index != Index) {
        return data
      }
    })
    )

    // removing from main form save list of segments
    props.getSegmentData(mediaEpisodeSegments.filter((data, Index) => {
      if (index != Index) {
        return data
      }
    }));

    // if _id is not generated, means it is just created and not saved in db yet
    // we do not need to use it in delete loop on main save
    if (dataItem._id) {
      // props.handleDeletedSegments(dataItem);
    }
  }

  const generateSegment = function (e) {
    let segmentCount = dataItem.SegmentType.SegmentCount;
    let segmentTypeSuffix = dataItem.SegmentType.Suffix ? dataItem.SegmentType.Suffix : '';

    if (segmentCount) {

      if (props.mediaEpisodeEntity === null || props.mediaEpisodeEntity.Duration === null) {
        toast.error(`${lang.media_episode_entity_duration_segment_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

      let segmentDetails = [];
      let episodeDuration = utility.convertMilisecondsToStringWithFrames(props.mediaEpisodeEntity.Duration);
      let actualSegmentDuration = calculateSegmentDuration(episodeDuration, segmentCount)  //to generate the function to calculate segment duration in utility folder

      let durationAsString = actualSegmentDuration;

      for (let cnt = 1; cnt <= segmentCount; cnt++) {

        if (dataItem.IsHardParted) {

          segmentDetail.Duration = actualSegmentDuration;
          segmentDetail.TcIn = '00:00:00:00';
          let millisecondsTcOut = utility.convertStringWithFramesToMilliseconds(segmentDetail.TcIn) + utility.convertStringWithFramesToMilliseconds(actualSegmentDuration);
          segmentDetail.TcOut = utility.convertMilisecondsToStringWithFrames(millisecondsTcOut);
          segmentDetail.IsPreviewed = false;

        } else {

          segmentDetail.Duration = actualSegmentDuration;
          segmentDetail.TcIn = segmentDetail.TcIn;
          let millisecondsTcOut = utility.convertStringWithFramesToMilliseconds(segmentDetail.TcIn) + utility.convertStringWithFramesToMilliseconds(actualSegmentDuration);
          segmentDetail.TcOut = utility.convertMilisecondsToStringWithFrames(millisecondsTcOut);
          segmentDetail.IsPreviewed = false;

        }

        segmentDetails.push({
          Sequence: cnt,
          MediaId: segmentTypeSuffix.length > 0 ? 
            `${dataItem.SegmentNumber}-${segmentTypeSuffix}-${cnt}-${segmentCount}` : `${dataItem.SegmentNumber}-${cnt}-${segmentCount}`,  //housenumber-segmentnumber-selectedsegmenttype.count === for mediaID
          TcIn: segmentDetail.TcIn,
          Duration: durationAsString,
          TcOut: segmentDetail.TcOut,
          IsPreviewed: false
        });
        segmentDetail.TcIn = segmentDetail.TcOut;


      }

      setSegmentDetails(segmentDetails);
      setDataItem({ ...dataItem, ["ActualDuration"]: episodeDuration });

    } else {
      toast.error(`${lang.please_select_segment_type_media_episode_version_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  const updatedSegmentDetails = (updatedData) => {
    console.log(updatedData);
    setSegmentDetails(updatedData);
  }

  const handleActualDuration = (durationAsString) => {
    dataItem.ActualDuration = durationAsString;
  }

  const clearData = () => {
    setSegmentDetails(() => []);
    setDataItem(() => ({...initialValue, SegmentType: defaultSegmentType.current}));
    setEditMode(false);
    setEditToggle(false);
  }

  const openForm = () => {
    if (props.mediaEpisodeSID == 0) {
      setDataItem(() => ({...initialValue, SegmentType: defaultSegmentType.current}));
    }
    setEditToggle(!editToggle)
  }

  const onUpdateSegement = (item) => {
    let index = mediaEpisodeSegments.findIndex(x => x._id === item._id);
    var data = [...mediaEpisodeSegments];
    data[index] = item;
    setMediaEpisodeSegments(data);
    props.getSegmentData(data);
  }

  const refreshSegmentDetails = () => {
    var newData = segmentDetails.map((x,i) => {
      var item = {
        ...x,
        Sequence: i+1,
        MediaId: `${dataItem.SegmentNumber}-${i+1}-${dataItem.SegmentType.SegmentCount}`
      }
      delete item.inEdit;

      return item;
    })
    setSegmentDetails(newData);
  }



  return (<>
    <div className="row form-box">

      <div className={'col-12'}>
        <AddRoundButton style={{marginBottom: '5px'}} onClick={() => setEditToggle(true)} />
        <RoundButton icon={"bolt"} title={"Fast Segmentation"} btnColor={'white'} onClick={() => setFastSegmentationPopupOpen(true)} />
        
        <Grid data={mediaEpisodeSegments} style={{ height: "62vh" }}>
          <GridColumn cell={MyCommandCell} width={"55px"} />
          <GridColumn field="SegmentType.Description" title={lang.segment_type_column} editable={false} width="150px" />
          <GridColumn field="SegmentType.TXMode.Name" title={lang.tx_mode_label} editable={false} width="150px" />
          <GridColumn field="Language.Description" title={lang.language_column} editable={false} width="150px" />
          <GridColumn field="SegmentNumber" title={lang.material_id_column} editable={false} width="150px" />
          <GridColumn field="ActualDuration" title={lang.actual_duration_column} cell={MyTimeCell} editable={false} width="150px" />
          <GridColumn field="IsDefault" title={lang.is_default_column} cell={MyCheckBoxCell} editable={false} width="150px" />
          <GridColumn field="Archive" title={lang.archive} cell={MyCheckBoxCell} editable={false} width="150px" />
          <GridColumn field="IsHardParted" title={lang.is_hard_parted_column} cell={MyCheckBoxCell} editable={false} width="150px" />
          <GridColumn field="TXReady" title={lang.transmission_column} cell={MyCheckBoxCell} editable={false} width="150px" />
        </Grid>
      </div>
      {editToggle && <Dialog
        title={dialogTitle}
        onClose={clearData}
        width={'950px'}
      >
        <div className='col-12'>
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <div className="row">
                  <div className="col marlr">

                    {!editMode && <AddRoundButton onClick={() => onAddSegment(true)} />}
                    {editMode && <SaveRoundButton onClick={() => onAddSegment(false)} />}
                    <CancelRoundButton onClick={clearData} />
                    <RefreshButton title={lang.refresh_generated_segments_tooltip} onClick = {refreshSegmentDetails}/> 

                  </div>
                  <div className='col' style={{ textAlign : 'right'}}>
                    <h6 style={{fontWeight : 400, margin : '10px 0px 0px 0px' }}>{`REFERENCE CODE : ${props.mediaEpisodeEntity?.ReferenceCode ?? 'NA'}`}</h6>
                  </div>
                </div>
                <div className="mt-1" style={{ border: "groove 1px" }}>
                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-12 mt-1">

                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.segment_type_label} *</label>

                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={segmentTypes}
                              name="SegmentType"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.SegmentType}
                              onChange={onChange}
                            />

                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.tx_mode_label}</label>

                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={Object.keys(TX_MODE)}
                              name="TxMode"
                              value={dataItem?.SegmentType?.TXMode?.Name ?? utility.getKeyByValue(TX_MODE,TX_MODE.TX)}
                              onChange={onChange}
                              disabled={true}
                            />

                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="">{lang.material_id_label} *</label>
                            <input style={{ height: "32px" }}
                              type="text"
                              className="form-control form-control-sm"
                              name="SegmentNumber"
                              value={dataItem.SegmentNumber}
                              onChange={(e) => onChange(e)}
                              disabled = {dataItem.GenerateMaterialID}
                              required
                            />
                          </div>

                        </div>

                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="">{lang.language_label}</label>
                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={language}
                              name="Language"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.Language}
                              onChange={onChange}
                            />
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="">{lang.remark_label}</label>
                            <input style={{ height: "32px" }}
                              type="text"
                              className="form-control form-control-sm"
                              name="Remarks"
                              value={dataItem.Remarks}
                              onChange={(e) => onChange(e)}
                            />
                          </div>

                        </div>
                      </div>

              
                      <fieldset className="border rounded-4" style={{padding : '20px 30px' ,margin : 0}}  >
                        <div className="row">
                        <div className = {dataItem.Subtitle ? "col-4" : 'col-12'} style={dataItem.Subtitle ? { marginTop: '19px' } :{}}>
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"Subtitle"}
                            component={Checkbox}
                            label={lang.subtitle_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.Subtitle}
                          />
                        </div>
                        {dataItem.Subtitle && <>
                          <div className="col-4">
                            <label htmlFor="">{lang.subtitle_type_lable} *</label>
                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={SUBTITLETYPE}
                              name="SubtitleType"
                              textField="Type"
                              dataItemKey="ID"
                              value={dataItem.SubtitleType}
                              onChange={onChange}
                            />
                          </div>
                          <div className="col-3" style={{ marginTop: '19px' }}>
                            <input
                              name="File"
                              type="text"
                              className="form-control form-control-sm"
                              value={dataItem.File}
                              onChange={onChange}
                              disabled={true}
                            />
                            
                          </div>
                          <div className="col-1">
                            <label
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-success"
                              style={{ height: "30px", marginTop: "19px" }}
                            >
                              <input
                                ref={fileRef}
                                type="file"
                                name="fileupload"
                                className="custom-file-updated-input"
                                hidden="hidden"
                                id="fileupload"
                                onChange={onChange}
                              />
                              {'...'}
                            </label>
                          </div>
                      </>}
                      </div>
                      </fieldset>

                      {!editMode && <fieldset className="border rounded-4" style={{padding : '20px 30px' ,margin : '8px 0px 0px 0px'}}  >
                        <div className="row">
                        <div className = {dataItem.GenerateMaterialID ? "col-6" : 'col-12'} style={dataItem.GenerateMaterialID ? { marginTop: '19px' } :{}}>
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"GenerateMaterialID"}
                            component={Checkbox}
                            label={'Auto Generate Material ID'}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.GenerateMaterialID}
                          />
                        </div>
                        {dataItem.GenerateMaterialID &&
                          <div className="col-6">
                            <label htmlFor="">{lang.material_content_sub_menu} *</label>
                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={materialContent}
                              name="MaterialContent"
                              textField="Description"
                              dataItemKey="SID"
                              value={dataItem.MaterialContent}
                              onChange={onChange}
                            />
                          </div>}
                      </div>
                      </fieldset>}
              
                      <div className="row mt-3 mb-2">
                        <div className="col">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"IsHardParted"}
                            component={Checkbox}
                            label={lang.is_hard_parted_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.IsHardParted}
                          />
                        </div>
                        <div className="col">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"TXReady"}
                            component={Checkbox}
                            label={lang.transmission_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.TXReady}
                          />
                        </div>
                        <div className="col-2">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"Archive"}
                            component={Checkbox}
                            label={lang.archive}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.Archive}
                          />
                        </div>
                        <div className="col-2">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"IsDefault"}
                            component={Checkbox}
                            label={lang.is_default_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.IsDefault}
                          />
                        </div>
                        <div className="col-3" style={{ textAlign: "right" }} >
                          <ActionButton title={lang.generate_segments_button_text} name={lang.generate_segments_button_text} onClick={handleGenerateSegmentClick} />
                        </div>
                      </div>

                      <div className="row mt-3" style={{ marginBottom: "05px" }}>
                        <div className="col-12">
                          {/* to add data list here */}
                          <SegmentTypeCollectionTab
                            segmentDetails={segmentDetails}
                            updatedSegmentDetails={updatedSegmentDetails}
                            handleActualDuration={handleActualDuration}
                            generateSegment={generateSegment}
                          />
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </FormElement>
            )} />
        </div>
        {editMode && <h6 className="ml-3 mt-3">{lang.changes_Will_Reflect_in_new_planned_items_only_segmenttype_error_message}</h6>}
      </Dialog>}
    </div >
    {showQCStatus && <SegmentQC segmentData={segmentQCRef.current} data={props.mediaEpisodeEntity} onClose={()=>setShowQCStatus(false)} updateSegement={onUpdateSegement}/>}

    {fastSegmentationPopupOpen && <MediaFastSegmentationPopup mediaEpisodeEntity = {props.mediaEpisodeEntity} programScheduleEntity={{}}  refreshSegments = {loadSavedSegments} onClose={() => setFastSegmentationPopupOpen(false)} source = {'media'}/>}

    {showSegmentHistory && <MediaEpisodeHistory selectedData={props?.mediaEpisodeEntity} segment={segmentHistoryRef.current} closeForm={()=>setShowSegmentHistory(false)} />}
  </>)
}

export default memo(MediaEpisodeSegmentEditForm);