/* eslint-disable */
import React, { useState } from 'react';
import logo from '../../../img/BOSS Logo_PNG.png';
import DigitalSignSignUpForm from './DigitalSignSignupForm';
import { useTranslation } from '../../../locale/useTranslation';
import { LOGIN_WARNING_MESSAGE } from '../../../framework/constant/constant';

const DigitalSignLoginForm = (props) => {
    const lang = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showSignUpForm, setShowSignUpForm] = useState(false);

    const labelStyle = {
        color: '#7b838a',
        textTransform: 'uppercase',
        letterSpacing: '.1rem'
    }

    const handleLogin = (e) => {
        let event = {
            ...e,
            target: {
                username: { value: email },
                password: { value: password },
            }
        }
        props.handleLoginClick(event);
    }

    return (
        <>
            <div className="container-fluid vh-100 d-flex">
                <div className="row vw-100">
                    <div className="col-6 d-flex flex-column justify-content-center align-items-start p-5 bg-white shadow">
                        <img src={logo} alt="Logo" className="mb-4" style={{ width: '70px' }} />
                        <h2 style={{ fontSize: '3rem', marginBottom: '20px', lineHeight: 1, fontWeight: 600, letterSpacing: '.1rem' }}>{lang.sign_in_label}</h2>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label" style={labelStyle}>{lang.email_label}/{lang.username_label}</label>
                            <input style={{ width: '200%', color: '#495057', height: '3rem' }} type="email" className="form-control" name="email" placeholder="Type email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label" style={labelStyle}>{lang.password_label}</label>
                            <input style={{ width: '200%', color: '#495057', height: '3rem' }} type="password" className="form-control" name="password" placeholder="Type password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <a href="#" className="text-primary cursor-pointer">{lang.forgot_password_label}</a>
                        </div>
                        {props?.showError && <div ng-show="error" className="alert alert-danger">
                            <a href="# " className="close" data-dismiss="alert"><span></span></a>{LOGIN_WARNING_MESSAGE}
                        </div>}
                        <button onClick={handleLogin} type="submit" style={{ backgroundColor: (email && password) ? '#275B89' : '#ACB5BD', color: 'white' }} disabled={(email && password) ? false : true} className={`btn mb-3`}>{lang.login_label}</button>
                        <p>{lang.do_not_have_an_account_label} <a onClick={() => setShowSignUpForm(true)} className="text-primary cursor-pointer">{lang.sign_up_label}</a></p>
                    </div>
                    <div className="col-6 d-flex justify-content-center align-items-center" style={{ backgroundColor: 'white' }}>
                        <img src={"https://www.fugo.ai/app/static/media/log-in-and-sign-up-illustration.c62188ba6ee84a1182c0d52db8c9c315.svg"} alt="logo" className="img-fluid" />
                    </div>
                </div>
            </div>
            {showSignUpForm && <DigitalSignSignUpForm onClose={() => setShowSignUpForm(false)} />}
        </>
    );
}

export default DigitalSignLoginForm;

