import { forwardRef, memo, useImperativeHandle, useState, useEffect } from "react";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as API from '../../../framework/API/api'
import { ENTITYNAME } from "../../../framework/constant/constant";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import MetaDataTab from "./component/metaDataTab";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import OttAssesTab from "./component/ottAssesTab";
import VersionTab from "./component/versionTab";
import PublishingTab from "./component/publishingTab";
import AttachMediaTab from "./component/attachMediaTab";
import { utility } from "../../../framework/utility/utilityProvider";
import { MediaEpisodeDetailForm } from "../MediaEpisodeDetailForm";

const ManageContentEditPanel = forwardRef((props, ref) => {
    console.log('edit panel');

    useEffect(() => {
        setShowDetailView(false);
    }, [])


    const { showDetailView, setShowDetailView, transcodeStatus } = props;
    const [tabValue, setTabValue] = useState(0);
    const [selectedItem, setSelectedItem] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showMediaEdit, setShowMediaEdit] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            onEdit: (item) => onEdit(item),
        }
    });

    const onEdit = async (mediaepisode_id) => {

        setIsLoading(true);
        setShowDetailView(true);
        let mediaepisodeRes = await API.getData(ENTITYNAME.MediaEpisode, { query: ['_id', '=', mediaepisode_id] });
        setIsLoading(false);

        if (mediaepisodeRes.success && mediaepisodeRes.data.length > 0) {
            setSelectedItem(mediaepisodeRes.data[0]);
            console.log(mediaepisodeRes.data[0]);
        } else {
            toast.error('failed to load data for this media');
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box className="mt-3 px-1">
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const onMediaEdit = () => {
        setShowMediaEdit(!showMediaEdit);
    }

    const handleSetMediaEpisodeSave = async (data) => {
        let saveData = { ...selectedItem, Title: data.Title, Description: data.Description, Genres: data.Genres, ImageUrl: data.ImageUrl, Duration: data.Duration, ReleaseDate: data.ReleaseDate, Archive: data.Archive };
        setSelectedItem(saveData);
        let mediaEpisodeSaveRes = await API.saveData(ENTITYNAME.MediaEpisode, saveData);
        if (!mediaEpisodeSaveRes.success) {
            toast.error('failed to save media episode');
        }
    }

    return (
        <div style={{ position: "absolute", top: 0, right: showDetailView ? 0 : "-500px", width: "500px", height: "95%", backgroundColor: "white", transition: 'all 0.3s linear', overflow: 'auto' }} >
            {isLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
                <i className="fa fa-spinner fa-spin fa-2xl" />
            </div>
                : <div>
                    <div style={{ padding: "10px", backgroundColor: "#f4f5fa" }}>
                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", padding: 0, margin: 0 }}>
                            <div>

                            </div>
                            <div>
                                <RoundButton icon={"close"} title={"Close"} onClick={() => setShowDetailView(false)} />

                            </div>

                        </div>
                        <div style={{ display: "flex", marginBottom: "20px", marginTop: "10px", height: "180px" }}>
                            <img src={selectedItem?.ImageUrl} style={{ height: "140px", minWidth: "180px", maxWidth: "180px", borderRadius: "5px", objectFit: "cover" }} className="card-img-top" alt={selectedItem?.Title} />
                            <div className="ml-3" style={{ height: "140px", width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h1>{selectedItem?.Title?.length > 18 ? selectedItem?.Title?.substring(0, 18) + '...' : selectedItem?.Title}</h1>
                                    <RoundButton icon={'pen'} title={'Edit'} onClick={onMediaEdit} />
                                </div>
                                <p className="pl-1 mb-1">{selectedItem?.AssetId}</p>
                                <p className="pl-1 mb-1">{selectedItem?.Description?.length > 90 ? selectedItem?.Description?.substring(0, 90) + '...' : selectedItem?.Description}</p>
                                <p className="pl-1 mb-1">{utility.displayArrayInPipe(selectedItem?.Genres, 'Description')}</p>
                                <div style={{ display: "flex" }}>
                                    <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="pl-1 mb-1 mr-3">
                                        Transcode Status :
                                    </p>
                                    <p style={{ width: "auto", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: transcodeStatus == 'Complete' ? 'green' : "grey", color: "white", borderRadius: "5px", padding: "2px 5px" }} className="pl-1 mb-1">{transcodeStatus}</p>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="pl-1 mb-1 mr-3">
                                        Is Ott Asset :
                                    </p>
                                    <p style={{ width: "auto", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: selectedItem?.IsOttAsset ? 'green' : "Red", color: "white", borderRadius: "5px", padding: "2px 5px" }} className="pl-1 mb-1">{selectedItem?.IsOttAsset ? "Yes" : "No"}</p>
                                </div>

                            </div>
                        </div>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons={true}
                            aria-label="visible arrows tabs example"
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                            }}
                        >
                            <Tab label="Meta Data"  {...a11yProps(0)} />
                            <Tab label="OTT Assets"  {...a11yProps(1)} />
                            <Tab label="Version"  {...a11yProps(2)} />
                            <Tab label="Publishing"  {...a11yProps(3)} />
                            <Tab label="Attach Media"  {...a11yProps(4)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <MetaDataTab mediaepisode_sid={selectedItem.SID} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <OttAssesTab mediaEpisode={selectedItem} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <VersionTab mediaEpisode={selectedItem} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <PublishingTab mediaEpisode={selectedItem} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <AttachMediaTab mediaEpisode={selectedItem} />
                        </TabPanel>

                    </div>

                    {showMediaEdit && <MediaEpisodeDetailForm SID={selectedItem.SID} Title={selectedItem?.Title ?? 'Edit'} mediaCategory={selectedItem.MediaCategory} mediaCategoryType={selectedItem.MediaCategoryType} quickEdit={true} onSave={handleSetMediaEpisodeSave} onClose={onMediaEdit} />}

                </div>
            }
        </div>
    )
});

export default memo(ManageContentEditPanel);