/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import {
    getSelectedState,
    Grid,
    GridColumn as Column,
    GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import {
    CONTENT_TYPE,
    ENTITYNAME,
    LOCALSTORAGE_KEY,
    LOGEVENT,
    MODULE,
} from "../../../framework/constant/constant";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { useTranslation } from "../../../locale/useTranslation";
import * as API from "../../../framework/API/api";
import { toast } from "react-toastify";
import MyStatusCell from "../../../components/MyStatusCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { utility } from "../../../framework/utility/utilityProvider";
import { getter } from "@progress/kendo-data-query";
import RefreshButton from "../../../framework/forms/helpercomponents/buttons/RefreshButton";
import { Loader } from "../../../framework/forms/helpercomponents/Loader";
import LiveEventCard from "../../LiveEvents/components/LiveEventCard";
import { ContextMenu } from "primereact/contextmenu";
import CollectionSelection from "../../../framework/forms/CollectionSelection";
import { MenuCommandCell } from "../../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { ConfirmAlert, ConfirmDeleteAlert } from "../../../ConfirmAlert";
import { EnumCell } from "../../../framework/forms/helpercomponents/CustomGridCells/EnumCell";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);
const CLOUD_NAME = "dl2n34gbw";
const UPLOAD_PRESET = "my_preset_3";

export const contentMenuModel = (props) => {

    const menu = [
        {
            label: 'Options',
            items: [
                { label: 'Publish', icon: 'pi pi-fw pi-calendar', command: () => props.onPublish({ ...props.dataItem }) },
                { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
            ]
        }
    ]

    return menu;
}


export default function DigiSignContentCollection(props) {

    const lang = useTranslation();
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

    const contextMenuRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);  

    const [selectedState, setSelectedState] = useState({});
    const [tileView, setTileView] = useState(false);
    const selectedFileRef = useRef([]);
    const [files, setFiles] = useState([]);
    const [displayFiles, setDisplayFiles] = useState([]);
    const [showFileProgressPopup, setShowFileProgressPopup] = useState(false);
    const [showPlaylistPopup, setShowPlaylistPopup] = useState(false);

    const selectedItemRef = useRef([]);
    const [selectedItem, _setSelectedItem] = useState({});
    const setSelectedItem = (data) => {
        selectedItemRef.current = data;
        _setSelectedItem(data);
    };

    const [filterDataItem, setFilterDataItem] = useState({
        FileType: { _id: 0, value: "All" }
    })

    useEffect(() => {
        loadcombo();
        loadData("");
    }, []);

    const loadData = async (contentType = 0) => {
        setIsLoading(true);

        let query = [];
        if (contentType && contentType != 0) {
            query = [...query, ["content_type", "=", contentType]];
        }

        let res = await API.getData(ENTITYNAME.DigitalSignContent, { query: query });
        if (res.success) {
            setIsLoading(false);
            console.log(res.data);
            let gridData = res.data.map((x) => {
                return {
                    ...x,
                    PosterUrl: x.content_type == CONTENT_TYPE.Video ? 'https://media.comicbook.com/files/img/default-movie.png' : x.url,
                    bytes: bytesToSize(x.bytes)
                }
            });
            setData(gridData);
        } else {
            toast.error(res.message);
        }
    }

    const onDeleteContent = async (item) => {

        let payload = {
            publicId: item.public_id,
            resourceType: item.resource_type,
        }

        //DELETE CONTENT FROM CLOUDINARY
        const deleteCloudinaryRes = await API.deleteContentFromCloudinary(payload);

        if (deleteCloudinaryRes.success) {

            //DELETE CONTENT FROM DATABASE
            const res = await API.deleteData(ENTITYNAME.DigitalSignContent, item.SID);

            //REMOVE CONTENT FROM PLAYLIST AS WELL
            await API.deleteDataByQuerry(ENTITYNAME.DigitalSignPlaylistItem, ["content_id", "=", item._id.toString()]);

            toast.success(deleteCloudinaryRes.message, { position: toast.POSITION.TOP_RIGHT });

            console.log(filterDataItem.FileType.value.toLowerCase());
            loadData(filterDataItem.FileType.value.toLowerCase());
        } else {
            toast.error(deleteCloudinaryRes.message, { position: toast.POSITION.TOP_RIGHT });
        }
    }


    const loadcombo = async () => { };

    const onChangeFilter = async (e) => {
        setFilterDataItem({ ...filterDataItem, [e.target.name]: e.target.value });
        await loadData(e.target.value._id);
    };

    const onRefresh = async () => {
        await loadData();
    };


    const contenMenu = [
        { label: "Publish", icon: "fa-solid fa-calendar-days fa-lg", command: () => { setShowPlaylistPopup(true) } },
        { label: "Delete", icon: "fa-solid fa-trash fa-lg", command: () => ConfirmAlert(() => onDeleteContent(selectedItemRef.current), () => { }, lang.confirm_delete_label, lang.do_you_really_want_to_delete_question) },
    ];

    const onContextMenu = (e, item) => {
        contextMenuRef.current.show(e);
        setSelectedItem(item);
        console.log(item)
    };

    //CONVERT BYTES TO MB
    const bytesToSize = (bytes) => {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "n/a";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + " " + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
    };

    const generateUniqueUploadId = () => {
        return `uqid-${Date.now()}`;
    };

    const onFileChange = (event) => {
        const files = Array.from(event.target.files);
        setFiles(files);
        let displayFilesLocal = files.map((x) => { return { name: x.name, percentage: 'Uploading..' } })
        setDisplayFiles(displayFilesLocal);
        selectedFileRef.current = files
        setShowFileProgressPopup(true);
        uploadFiles(files, displayFilesLocal);
    }

    const uploadFiles = async (uploadFiles, displayFilesLocal) => {
        if (uploadFiles.length == 0) {
            console.error('Please select a files.');
            toast.error('Please select a files.', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        for (let i = 0; i < uploadFiles.length; i++) {

            const file = uploadFiles[i];
            const uniqueUploadId = generateUniqueUploadId();
            const chunkSize = 5 * 1024 * 1024; // Allowed upto 5MB.
            const totalChunks = Math.ceil(file.size / chunkSize);
            let currentChunk = 0;


            const uploadChunk = async (start, end) => {
                const formData = new FormData();
                formData.append('file', file.slice(start, end));
                formData.append('cloud_name', CLOUD_NAME);
                formData.append('upload_preset', UPLOAD_PRESET);
                formData.append('tags', company?._id.toString());

                const contentRange = `bytes ${start}-${end - 1}/${file.size}`;

                console.log(`Uploading chunk for uniqueUploadId: ${uniqueUploadId}; start: ${start}, end: ${end - 1}`);

                try {
                    const response = await fetch(
                        `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/auto/upload`,
                        {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'X-Unique-Upload-Id': uniqueUploadId,
                                'Content-Range': contentRange,

                            },
                        }
                    );

                    if (!response.ok) {
                        throw new Error('Chunk upload failed.');
                    }

                    currentChunk++;

                    let percentage = Math.round((currentChunk / totalChunks) * 100);
                    var localFiles = displayFilesLocal;
                    console.log(localFiles[i]);
                    if (currentChunk < totalChunks) {
                        const nextStart = currentChunk * chunkSize;
                        const nextEnd = Math.min(nextStart + chunkSize, file.size);

                        //CALCULATE PERCENTAGE
                        console.log(percentage);


                        localFiles[i] = { ...localFiles[i], percentage: `${percentage}%` };
                        setDisplayFiles(localFiles);


                        uploadChunk(nextStart, nextEnd);
                    } else {

                        const fetchResponse = await response.json();
                        console.log(fetchResponse)

                        localFiles[i] = { ...localFiles[i], percentage: `100%` };
                        setDisplayFiles(localFiles);

                        //SAVE DATA IN DB
                        await handleSubmit({ fileName: file.name, ...fetchResponse })
                        console.info('File upload complete.');
                    }
                } catch (error) {
                    console.error('Error uploading chunk:', error);
                }
            };

            const start = 0;
            const end = Math.min(chunkSize, file.size);
            uploadChunk(start, end);

        }

    };

    const handleSubmit = async (dataItem) => {

        console.log(dataItem)

        const item = {
            fileName: dataItem.fileName,
            asset_id: dataItem.asset_id,
            public_id: dataItem.public_id,
            format: dataItem.format,
            resource_type: dataItem.resource_type,
            content_type: CONTENT_TYPE[utility.convertFirstLetterToUpperCase(dataItem.resource_type)],
            bytes: dataItem.bytes,
            url: dataItem.url,
            secure_url: dataItem.secure_url,
            playback_url: dataItem.playback_url ? dataItem.playback_url : '',
            duration: CONTENT_TYPE[utility.convertFirstLetterToUpperCase(dataItem.resource_type)] == CONTENT_TYPE.Image ? 30 * 1000 : dataItem.duration * 1000 //30 seconds hardcoded for image

        }

        console.log(item)
        const res = await API.saveData(ENTITYNAME.DigitalSignContent, item);
        if (res.success) {
            let logData = { event: LOGEVENT.CREATE_CONTENT, module: MODULE.DIGITAL_SIGN_CONTENT, data: res.data, message: res.message };
            API.SaveLogs(logData);
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }


    const onPublishContent = async (playlists) => {

        for (let i = 0; i < playlists.length; i++) {
            const playlist = playlists[i];
            let res = await API.getData(ENTITYNAME.DigitalSignPlaylistItem, { playlist_id: playlist._id });

            if (res.success) {

                //GET THE HIGHEST SEQUENCE
                let sequence = res.data.length > 0 ? res.data[res.data.length - 1].sequenceIndex + 1 : 0;
                let saveData = {
                    playlist_id: playlist._id,
                    content_id: selectedItem?._id?.toString(),
                    sequenceIndex: sequence,
                    overlay: [],
                }

                await API.saveData(ENTITYNAME.DigitalSignPlaylistItem, saveData);

            } else {
                console.log(res)
            }

        }

        toast.success("Content published in selected playlists successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        setShowPlaylistPopup(false);
    }

    const onCloseFileUploadPopup = () => {
        setShowFileProgressPopup(false);
        onRefresh();
    }

    const MyCommandCell = (props) => (
        <MenuCommandCell
            {...props}
            onPublish={() => { setShowPlaylistPopup(true); setSelectedItem(props.dataItem) }}
            onDelete={onDeleteContent}
            myMenuModel={contentMenuModel}
        />
    )

    //POPUP TABLE STYLE
    const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px 10px" }

    return (
        <div>
            <div className="row m-1">
                <div className="col-8">
                    <h1
                        className="page-title txt-color-bludeDark"
                        style={{ margin: "10px 0" }}
                    >
                        <i
                            className="fa fa-tv"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span
                            style={{
                                fontFamily: "sans-serif",
                                paddingLeft: "5px",
                                letterSpacing: "0.5px",
                            }}
                        >
                            Total Contents - {data?.length}
                        </span>
                    </h1>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-end">
                    <div className="pointer p-1" onClick={() => setTileView(!tileView)}
                        title={tileView ? `${lang.grid_view_label}` : `${lang.tile_view_label}`}
                    >
                        <i className={`fas ${tileView ? "fa-table-cells" : "fa-table-list"} fa-xl`} />
                    </div>
                </div>
            </div>

            <div
                className="row m-2 pt-2"
                style={{ backgroundColor: "#fafafa", borderRadius: "10px" }}
            >
                <div className="col-2 form-group">
                    <label htmlFor="TabView">{"Content Type"}</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={[
                            { _id: 0, value: "All" },
                            ...Object.keys(CONTENT_TYPE).map((key) => {
                                return { _id: CONTENT_TYPE[key], value: key }
                            })
                        ]}
                        name="FileType"
                        textField="value"
                        dataItemKey="_id"
                        value={filterDataItem.FileType}
                        onChange={onChangeFilter}
                        validator={(value) => (value ? "" : "Please select the value")}
                    />
                </div>
            </div>

            <div className={"row ml-3 mt-3"}>
                <button className="btn btn-default bg-primary btn-circle btn-my" style={{ borderRadius: '25px', padding: '0px', height: "30px", margin: '0px 0px 0px 2px' }}>
                    <label className="fa fa-plus" style={{ color: 'white', padding: '7px 9px', margin: '0px', cursor: 'pointer' }}>
                        <input
                            name={"file"}
                            className='custom-file-updated-input form-control form-control-sm'
                            type="file"
                            accept="image/*,video/*"
                            multiple hidden="hidden" onChange={(e) => { onFileChange(e) }} />
                    </label>
                </button>
                <RefreshButton onClick={onRefresh} />
            </div>

            <div className="row ml-1 mr-1" style={{ height: "77vh" }}>
                {isLoading ? (
                    <Loader height={"65vh"} />
                ) : tileView ? (
                    data &&
                    data.length > 0 &&
                    data.map((content, index) => (
                        <LiveEventCard
                            item={content}
                            showLive={false}
                            cardInline={2}
                            tooltip={`${content.fileName +
                                "\n" +
                                content.bytes +
                                "\n" +
                                content.format +
                                "\n"}`
                            }
                            title={content.fileName}
                            description={content.bytes}
                            subDescription={content.addOn ? `Added on : ${utility.convertMilisecondsToDateTimeString(content.addOn)}` : ''}
                            subDescription2={content.format}
                            index={index}
                            onContextMenu={onContextMenu}
                        />
                    ))
                ) : (
                    <Grid
                        style={{ margin: "10px", height: "75vh", width: "100%" }}
                        data={data.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        resizable={true}
                        selectable={{
                            enabled: true,
                            cell: false,
                            mode: "multiple",
                        }}
                    >
                        <Column cell={MyCommandCell} width={"60px"} locked={true} />
                        <Column
                            field="PosterUrl"
                            title={"Thumbnail"}
                            editable={false}
                            cell={ImageCell}
                            width={"100px"}
                        />
                        <Column
                            field="fileName"
                            title={"File Name"}
                            editable={false}
                            width={200}
                        />
                        <Column
                            field="bytes"
                            title={"Size"}
                            editable={false}
                            width={200}
                        />

                        <Column
                            field="content_type"
                            title={"Type"}
                            editable={false}
                            cell={EnumCell}
                            width={200}
                        />

                        <Column
                            field="format"
                            title={"Format"}
                            editable={false}
                            width={200}
                        />

                    </Grid>
                )}
            </div>

            {showFileProgressPopup && <Dialog
                title={"File Progress"}
                onClose={onCloseFileUploadPopup}
                width={"750px"}
            >
                <div>

                    <table className="table mt-2">
                        <thead>
                            <tr>
                                <th scope="col">{lang.name_column}</th>
                                <th scope="col">{lang.percentage_label}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayFiles?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={tableDataStyle}>{item?.name}</td>
                                        <td style={tableDataStyle}>{item?.percentage}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </Dialog>}

            <ContextMenu model={contenMenu} ref={contextMenuRef} />

            {showPlaylistPopup && <CollectionSelection entityname={ENTITYNAME.DigitalSignPlaylist} closeForm={() => { setShowPlaylistPopup(false) }} setDataList={onPublishContent} title={'Select Playlist'} width={"800px"} height={"500px"} />}
        </div>
    );
}
