/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  getSelectedState,
  Grid,
  GridColumn as Column,
  GridNoRecords,
  GridColumn,
} from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import {
  BOOKINGPOSITIONS_ENUM,
  BOOKINGSTATUS,
  BOOKINGUPDATETYPE,
  COLUMNSTYPE,
  ENTITYNAME,
  FILEURL,
  LOCALSTORAGE_KEY,
  LOGEVENT,
  MODULE,
  SOCKET_ACTION,
  SOCKET_EVENTS,
} from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useLocation } from "react-router-dom";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ContextMenu } from "primereact/contextmenu";
import { getter } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { EditPageHeader } from "../../components/EditPageHeader";
import { Form, FormElement } from "@progress/kendo-react-form";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { bookingSocket } from "../../framework/socket/bookingSocket";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import moment from "moment";
import socket from "../../framework/socket/socket";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { UserPreference } from "../userPreference/UserPreference";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { HrefCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import { PlatformCell } from "../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";
import MyStatusCell from "../../components/MyStatusCell";
import { IconCell } from "../../framework/forms/helpercomponents/CustomGridCells/IconCell";
import { SalesHelper } from "./SalesHelper";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

const sortArray = [
  { key: 'LineNumber', label: 'Line Number' },
  { key: 'BookingDate', label: 'Booking Date' },
  { key: 'SpotRate', label: 'Spot Rate' },
  { key: 'TimeRangeFrom', label: 'Time Range' },
]

const ShowSpots = () => {
  const location = useLocation();
  const cm = useRef(); //RIGHT CLICK : CONTEXT MENU
  const ref = useRef();
  const navigate = useNavigate();
  const lang = useTranslation();
  const [bookedSpots, setBookedSpots] = useState(0);
  const [bookedSeconds, setBookedSeconds] = useState(0);
  const [bookedAmount, setBookedAmount] = useState(0);
  const [dataColumns, setDataColumns] = useState([]);

  const [dataItem, setDataItem] = useState({
    EstimateCode: "",
    TimeRangeFrom: "00:00:00:00",
    TimeRangeTo: "00:00:00:00",
    BookingTransferTo: "",
    SpotRate: "",
    SelectedCommercial: {},
  });
  const [bookingDetails, setBookingDetails] = useState([]);
  const [purgedBookingDetails, setPurgedBookingDetails] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [showPreferences, setShowPreferences] = useState(false);

  const selectedItemsRef = useRef([]);
  const setSelectedItem = (items) => {
    selectedItemsRef.current = items;
  }
  const [years, setYears] = useState(["All"]);
  const defaultFilterValue = {
    MakeGood: false,
    Purge: false,
    Year: "All",
    Month: "All",
    FromDate: "",
    ToDate: "",
    Channel: { SID: 0, FullChannelName: 'All' },
    Brand: { SID: 0, Name: 'All' },
    Product: { SID: 0, Name: 'All' },
    Commercial: { SID: 0, Title: 'All' },
    Status: { SID: -1, Description: 'All' },
    Position: { SID: 0, Description: 'All' },
  }
  const [filterData, setFilterData] = useState(defaultFilterValue);
  const [months, setMonths] = useState(["All"]);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [showTimeRange, setShowTimeRange] = useState(false);
  const [showEstimateCodePopup, setShowEstimateCodePopup] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showSpotRate, setShowSpotRate] = useState(false);
  const [showRate10Sec, setShowRate10Sec] = useState(false);
  const [showCommercials, setShowCommercials] = useState(false);
  const [isMinified, setIsMinified] = useState(
    utility.getValue(LOCALSTORAGE_KEY.isMenuMified)
  );
  const [isLoading, setIsLoading] = useState(true);

  const [loadingStatus, setLoadingStatus] = useState(
    `${lang.data_loading_error_message}`
  );
  const [header, setHeader] = useState({
    CampaignNo: location.state.CampaignNo,
    TotalSpots: location.state.TargetSpots,
    TotalSeconds: location.state.TargetSeconds,
    TotalAmount: location.state.TargetBudget,
  });
  const [totalFCT, setTotalFCT] = useState(0);
  const [totalSpotRate, setTotalSpotRate] = useState(0);

  const [sorted, setSorted] = useState({ key: 'BookingDate', label: 'Booking Date' });
  const [purgeUnPurgePageOpen, setPurgeUnPurgePageOpen] = useState(false);
  const [makeExpectionPageOpen, setMakeExceptionPageOpen] = useState(false);

  //user
  let user = utility.getValue(LOCALSTORAGE_KEY.userData);

  useEffect(() => {
    loadBookingDetails(filterData);

  }, []);

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };


  const loadBookingDetailsWithFilter = async (filter) => {

    setIsLoading(true);
    let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
    console.log(currentUserId);
    let columns = await API.getFormData("showspots", currentUserId);
    let json = await API.getCampaignBookedSpots(location.state._id, filter);

    if (json.data.length == 0) {
      setIsLoading(false);
      setDataColumns(columns);
      setPurgedBookingDetails([]);
      setBookingDetails([]);
      setLoadingStatus(`${lang.no_record_found_error_message}`);
      return;
    }

    let shortedByDate = json.data.sort((a, b) => a[sorted.key] > b[sorted.key] ? 1 : -1);
    shortedByDate = shortedByDate.map(x => { return { ...x, campaign: location.state, ActualOnAir: utility.convertMilisecondsToStringWithFrames(x.ActualOnAir ?? 0), NominalOnAir: utility.convertMilisecondsToStringWithFrames(x.NominalOnAir ?? 0) } });
    console.log(shortedByDate);
    setBookingDetails(shortedByDate);
    setDataColumns(columns);
    setPurgedBookingDetails(shortedByDate);
    setLoadingStatus(`${lang.no_record_found_error_message}`);
    setIsLoading(false);
    getCampaignsExistingBookingDetails(location.state._id);

    return shortedByDate;

  }

  const getCampaignsExistingBookingDetails = async (campaign_id) => {
    try {

      let existingBookings = await API.getCampaignsExistingBookingDetails(campaign_id);
      console.log(existingBookings.data);

      if (existingBookings.success) {
        setBookedAmount(existingBookings.data.BookedAmount);
        setBookedSpots(existingBookings.data.BookedSpots);
        setBookedSeconds(existingBookings.data.BookedSeconds);
      }

    } catch (error) {
      console.log(error);

    }



  }


  const loadBookingDetails = async (filter) => {

    try {

      let json = await loadBookingDetailsWithFilter({
        ...filter,
        FromDate: filter.FromDate.length > 0 ? new Date(filter.FromDate).getTime() : 0,
        ToDate: filter.ToDate.length > 0 ? new Date(filter.ToDate).getTime() : 0
      });

      console.log(json)

      if (months.length == 1 && years.length == 1) {
        let minBookingDate = new Date(json[0].BookingDate);
        let maxBookingDate = new Date(
          json[json.length - 1].BookingDate
        );
        let yearTemp = [];
        let monthTemp = [];

        while (minBookingDate < maxBookingDate) {
          //for year insert
          if (!yearTemp.includes(minBookingDate.getFullYear())) {
            yearTemp.push(minBookingDate.getFullYear());
          }

          //for month insert
          if (!monthTemp.includes(month[minBookingDate.getMonth()])) {
            monthTemp.push(month[minBookingDate.getMonth()]);
          }

          minBookingDate = minBookingDate.addDays(28);

          if (minBookingDate > maxBookingDate) {
            if (!yearTemp.includes(maxBookingDate.getFullYear())) {
              yearTemp.push(maxBookingDate.getFullYear());
            }
            if (!monthTemp.includes(month[maxBookingDate.getMonth()])) {
              monthTemp.push(month[maxBookingDate.getMonth()]);
            }
          }
        }
        setMonths([...months, ...monthTemp]);
        setYears([...years, ...yearTemp]);
      }

    } catch (error) {
      console.log("error", error);
    }
  };

  const onSortChange = (e) => {

    let shortedByDate = bookingDetails.toSorted((a, b) => parseInt(a[e.target.value.key]) - parseInt(b[e.target.value.key]));
    setSorted(e.target.value);
    setBookingDetails(shortedByDate);
    setPurgedBookingDetails(shortedByDate);

  }

  const onChange = (e) => {
    setIsLoading(true);
    if (e.target.name == "Year") {
      var filteredData = [];
      if (e.target.value == "All" && filterData.Month != "All") {
        // year all month any
        filteredData = bookingDetails.filter((obj) => month[new Date(obj.BookingDate).getMonth()] == filterData.Month);
      } else if (e.target.value != "All" && filterData.Month != "All") {
        // year month
        filteredData = bookingDetails.filter((obj) => month[new Date(obj.BookingDate).getMonth()] == filterData.Month && new Date(obj.BookingDate).getFullYear() == e.target.value);
      } else if (e.target.value != "All" && filterData.Month == "All") {
        filteredData = bookingDetails.filter((obj) => new Date(obj.BookingDate).getFullYear() == e.target.value);
      } else {
        filteredData = bookingDetails // for all all
      }

      setPurgedBookingDetails(filteredData);
    }

    if (e.target.name == "Month") {
      var filteredData = [];
      if (e.target.value == "All" && filterData.Year != "All") {
        // month all year any
        filteredData = bookingDetails.filter(
          (obj) =>
            new Date(obj.BookingDate).getFullYear() == filterData.Year
        );
      } else if (e.target.value != "All" && filterData.Year != "All") {
        // month year
        filteredData = bookingDetails.filter(
          (obj) =>
            month[new Date(obj.BookingDate).getMonth()] == e.target.value &&
            new Date(obj.BookingDate).getFullYear() == filterData.Year
        );
      } else if (e.target.value != "All" && filterData.Year == "All") {
        filteredData = bookingDetails.filter(
          (obj) =>
            month[new Date(obj.BookingDate).getMonth()] == e.target.value
        );
      } else {
        filteredData = bookingDetails // for all all
      }
      setPurgedBookingDetails(filteredData);
    }

    setFilterData({ ...filterData, [e.target.name]: e.target.value });

    if (e.target.name != "Year" && e.target.name != "Month") {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
    setIsLoading(false);
  };


  const gridRowRender = (tr, props) => {
    const trProps = {
      onContextMenu: (e) => {
        ref.current = props.dataItem;
        if (Object.keys(selectedState).length == 0) {
          toast.error(`${lang.please_select_atleast_one_item}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        cm.current.show(e);
      },
    };
    return React.cloneElement(tr, { ...trProps }, tr.props.children);
  };


  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    selectedIds.forEach((value) => {
      if (newSelectedState[value]) {
        let filter = bookingDetails.find((obj) => obj._id == value);
        tempSelected.push(filter);
      }
    });

    setSelectedItem(tempSelected);

    //update footer values on update
    updateFooter(tempSelected);
  };


  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    if (checked) {
      selectedIds.forEach((value) => {
        if (newSelectedState[value]) {
          let filtered = bookingDetails.find((obj) => obj._id == value);
          tempSelected.push(filtered);
        }
      });
    }
    setSelectedItem(tempSelected);

    //update footer values on update
    updateFooter(tempSelected);
  };


  const onChangeFilterData = (e) => {
    if (e.target.name == 'Purge') {
      loadBookingDetailsWithFilter({ ...filterData, Purge: !filterData.Purge });
      setFilterData({ ...filterData, Purge: !filterData.Purge });
      setSelectedItem([]);
      setSelectedState({});
    } else if (e.target.name == 'MakeGood') {
      loadBookingDetailsWithFilter({ ...filterData, MakeGood: !filterData.MakeGood });
      setFilterData({ ...filterData, MakeGood: !filterData.MakeGood });
      setSelectedItem([]);
      setSelectedState({});
    } else {
      setFilterData({ ...filterData, [e.target.name]: e.target.value });
      setSelectedItem([]);
      setSelectedState({});
    }
  }

  //clear selected Item
  const contextMenuCancel = () => {
    setSelectedItem([]);
    setSelectedState({});
  };


  const updateFooter = (selectedItem) => {
    let spotRateSum = 0;
    let FCTsum = 0;

    if (selectedItemsRef.current.length > 0) {
      selectedItemsRef.current.forEach((object) => {
        if (object != undefined) {
          spotRateSum = spotRateSum + parseInt(object.SpotRate);
          FCTsum = FCTsum + object.MediaEpisode.Duration;
        }
      });
    }

    setTotalSpotRate(spotRateSum);
    setTotalFCT(FCTsum / 1000);
  };


  const TimeRange = () => {
    setShowTimeRange(true);
  };

  const canChangeSpotRate = async () => {
    let billedspots = selectedItemsRef.current.filter((val) => {
      return val.BookingStatusSID == BOOKINGSTATUS.BillGenerated;
    });

    return billedspots.length == 0;
  };


  const isValidToUpdate = async () => {
    let scheduledSpots = selectedItemsRef.current.filter((val) => {
      console.log(val.NominalOnAir);
      let nominalOnAir = utility.convertStringWithFramesToMilliseconds(val.NominalOnAir);
      return nominalOnAir != 0;
    });

    return scheduledSpots.length > 0 ? false : true;
  };


  const GetFreshMaxLineNo = async () => {

    let lineNo = 0;
    let resBooking = await API.getData(ENTITYNAME.Booking, { query: ['Campaign_Id', '=', selectedItemsRef.current[0].Campaign_Id] });

    if (resBooking.data.length > 0) {
      // sorting in descending order by line no to get highest line no
      resBooking.data.sort((a, b) => parseInt(b.LineNumber) - parseInt(a.LineNumber));
      lineNo = resBooking.data[0].LineNumber;

    }
    return lineNo + 1;
  }


  const isUniqueLineNoSelected = () => {
    const uniqueLineNo = Array.from(new Set(selectedItemsRef.current.map((item) => item.LineNumber)));
    return uniqueLineNo.length == 1 ? true : false;
  }


  const updateTimeRange = async () => {

    //CHECKING IF SPOTS OF SAME LINE NO HAVE BEEN SELECTED OR NOT
    if (!isUniqueLineNoSelected()) {

      toast.error(`${lang.booked_spots_unique_line_no_selection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setDataItem({
        ...dataItem,
        TimeRangeFrom: "00:00:00:00",
        TimeRangeTo: "00:00:00:00",
      });
      setSelectedItem([]);
      return;

    }

    //CHECKING VALID SPOTS ARE SELECTED TO UPDATE
    const _isValidToUpdate = await isValidToUpdate();
    if (!_isValidToUpdate) {
      toast.error(`${selectedItemsRef.current.length > 1 ? lang.booked_spots_scheduled_error_message : lang.booking_is_used_in_schedule_show_spot_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setDataItem({
        ...dataItem,
        TimeRangeFrom: "00:00:00:00",
        TimeRangeTo: "00:00:00:00",
      });
      setSelectedItem([]);
      return;
    }

    if (utility.convertStringWithFramesToMilliseconds(dataItem.TimeRangeFrom) > utility.convertStringWithFramesToMilliseconds(dataItem.TimeRangeTo)) {
      toast.error(`${lang.spot_time_from_time_to_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const updateData = {
      TimeRangeFrom: utility.convertStringWithFramesToMilliseconds(
        dataItem.TimeRangeFrom
      ),
      TimeRangeTo: utility.convertStringWithFramesToMilliseconds(
        dataItem.TimeRangeTo
      )
    };
    await updateBookedSpots(selectedItemsRef.current, true, BOOKINGUPDATETYPE.TIMERANGE, updateData)

    cancelContextMenuAndReload();
  };

  const cancelContextMenuAndReload = () => {

    contextMenuCancel();
    setDataItem({
      ...dataItem,
      TimeRangeFrom: "00:00:00:00",
      TimeRangeTo: "00:00:00:00",
      SpotRate: "",
      SelectedCommercial: ""
    });

    cancelEdit();
    loadBookingDetails(filterData);

  }

  const onBookingTransfer = async () => {
    console.log(new Date(dataItem.BookingTransferTo).getTime());
    selectedItemsRef.current.forEach(async (object) => {
      let res = await API.saveData(ENTITYNAME.Booking, {
        ...object,
        BookingDate: new Date(dataItem.BookingTransferTo).getTime(),
      });
      if (!res.success) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    });
    contextMenuCancel();
    setDataItem({ ...dataItem, BookingTransferTo: "" });
    setShowTransfer(false);
    // Refresh
  };


  const updateEstimateCode = async () => {

    //CHECKING IF SPOTS OF SAME LINE NO HAVE BEEN SELECTED OR NOT
    if (!isUniqueLineNoSelected()) {

      toast.error(`${lang.booked_spots_unique_line_no_selection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setDataItem({ ...dataItem, EstimateCode: "" });
      setSelectedItem([]);
      return;

    }

    const _isValidToUpdate = await isValidToUpdate();
    if (!_isValidToUpdate) {
      toast.error(`${selectedItemsRef.current.length > 1 ? lang.booked_spots_scheduled_error_message : lang.booking_is_used_in_schedule_show_spot_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setDataItem({ ...dataItem, EstimateCode: "" });
      setSelectedItem([]);
      return;
    }

    let maxLineNo = await GetFreshMaxLineNo();
    selectedItemsRef.current.forEach(async (object) => {
      let res = await API.saveData(ENTITYNAME.Booking, {
        ...object,
        EstimateCode: dataItem.EstimateCode,
        LineNumber: maxLineNo
      });
      if (!res.success) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }else{
        let logCapture = { ...object, EstimateCode: dataItem.EstimateCode, LineNumber: maxLineNo };
        let finalCaptureEstimateCode = SalesHelper.getSpotsData(logCapture, LOGEVENT.UPDATE_ESTIMATE_CODE, "Change Estimate Code");
        API.SaveLogs(finalCaptureEstimateCode);
      }

    });
      
    contextMenuCancel();
    setDataItem({ ...dataItem, EstimateCode: "" });
    setShowEstimateCodePopup(false);
    loadBookingDetails(filterData);
  };


  const SpotRate = () => {
    setShowSpotRate(true);
  };

  const Rate10Sec = () => {
    setShowRate10Sec(true);
  };


  const exceedingTargetBudget = async (fromRate10SecWindow) => {
    let cuurentBookedAmount = 0;
    let isExceedingTarget = false;
    let selectedCampaign = location.state;
    let previousTotalSpotAmout = utility.sumOfListColumn(selectedItemsRef.current, 'SpotRate');
    console.log("Previous Total : " + previousTotalSpotAmout);
    selectedItemsRef.current.forEach((bookingObj, index) => {
      if (fromRate10SecWindow) {
        let durationInSeconds = bookingObj.MediaEpisode.Duration / 1000;
        let spotRate = parseFloat(dataItem?.SpotRate) / 10 * durationInSeconds;
        cuurentBookedAmount = cuurentBookedAmount + spotRate;
      } else {
        cuurentBookedAmount = cuurentBookedAmount + parseFloat(dataItem?.SpotRate);

      }

    });

    isExceedingTarget = ((parseFloat(bookedAmount) - parseFloat(previousTotalSpotAmout)) + parseFloat(cuurentBookedAmount)) > parseFloat(selectedCampaign.TargetBudget)
    console.log(isExceedingTarget)
    return isExceedingTarget;
  }
  const changeSpotRate = async () => {

    let canChange = await canChangeSpotRate();

    let isExceeded = await exceedingTargetBudget(false);

    if (isExceeded) {
      toast.error(`${lang.exceding_target_budget_booking_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }


    if (!canChange) {
      toast.error(`${selectedItemsRef.current.length > 1 ? lang.booked_spots_scheduled_error_message : lang.booking_is_used_in_schedule_show_spot_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setDataItem({ ...dataItem, SpotRate: "" });
      setSelectedItem([]);
      return;
    }


    const uniqueLineNo = Array.from(new Set(selectedItemsRef.current.map((item) => item.LineNumber)));

    for (const lineNo of uniqueLineNo) {
      const bookingforthisLine = selectedItemsRef.current.filter((booking) => booking.LineNumber == lineNo);
      await updateBookedSpots(bookingforthisLine, true, BOOKINGUPDATETYPE.SPOTRATE, { SpotRate: dataItem.SpotRate });
    }

    cancelContextMenuAndReload();
  };



  const changeRate10Sec = async () => {

    let canChange = await canChangeSpotRate();

    let isExceeded = await exceedingTargetBudget(true);

    if (isExceeded) {
      toast.error(`${lang.exceding_target_budget_booking_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!canChange) {
      toast.error(`${selectedItemsRef.current.length > 1 ? lang.booked_spots_scheduled_error_message : lang.booking_is_used_in_schedule_show_spot_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setDataItem({ ...dataItem, SpotRate: "" });
      setSelectedItem([]);
      return;
    }

    const uniqueLineNo = Array.from(new Set(selectedItemsRef.current.map((item) => item.LineNumber)));

    for (const lineNo of uniqueLineNo) {
      const bookingforthisLine = selectedItemsRef.current.filter((booking) => booking.LineNumber == lineNo);
      await updateBookedSpots(bookingforthisLine, true, BOOKINGUPDATETYPE.RATE10SEC, { SpotRate: dataItem.SpotRate });
    }

    cancelContextMenuAndReload();
  };

  const updateBookedSpots = async (spots, assignNewLineNumber = true, bookingUpdateType, updatedVal) => {
    let maxLineNo = 0;
    if (assignNewLineNumber) maxLineNo = await GetFreshMaxLineNo();

    let updatedSpots = [];

    switch (bookingUpdateType) {
      case BOOKINGUPDATETYPE.SPOTRATE:
        spots.map(async (object) => {
          let rate10Sec = (updatedVal.SpotRate / (object.MediaEpisode.Duration / 1000)) * 10;
          const updatedObject = {
            _id: object._id,
            SpotRate: updatedVal.SpotRate,
            Rate10Sec: parseFloat(rate10Sec).toFixed(2),
            LineNumber: assignNewLineNumber ? maxLineNo : object.LineNumber
          };
          updatedSpots.push(updatedObject);
          let finalCaptureSpotRateData = SalesHelper.getSpotsData({ ...object, ...updatedObject}, LOGEVENT.CHANGE_SPOT_RATE, "Change Spot Rate");
          API.SaveLogs(finalCaptureSpotRateData);
        });

        break;

      case BOOKINGUPDATETYPE.RATE10SEC:
        spots.map(async (object) => {
          let spotRate = (updatedVal.SpotRate / 10) * (object.MediaEpisode.Duration / 1000);
          const updatedObject = {
            _id: object._id,
            SpotRate: parseFloat(spotRate).toFixed(2),
            Rate10Sec: updatedVal.SpotRate,
            LineNumber: assignNewLineNumber ? maxLineNo : object.LineNumber
          };
          updatedSpots.push(updatedObject);
          let finalCaptureRate10SecData = SalesHelper.getSpotsData({ ...object, ...updatedObject}, LOGEVENT.CHANGE_RATE10SEC, "Change Rate 10 Sec.");
          API.SaveLogs(finalCaptureRate10SecData);
        });

        break;

      case BOOKINGUPDATETYPE.TIMERANGE:
        spots.map(async (object) => {
          const updatedObject = {
            _id: object._id,
            TimeRangeFrom: updatedVal.TimeRangeFrom,
            TimeRangeTo: updatedVal.TimeRangeTo,
            LineNumber: assignNewLineNumber ? maxLineNo : object.LineNumber
          };
          updatedSpots.push(updatedObject);
          let finalCaptureTimeRangeData = SalesHelper.getSpotsData({ ...object, ...updatedObject}, LOGEVENT.CHANGE_TIME_RANGE, "Change Time Range");
          API.SaveLogs(finalCaptureTimeRangeData);

        });

        break;

      case BOOKINGUPDATETYPE.PURGE:
        spots.map(async (object) => {
          const updatedObject = {
            _id: object._id,
            Purge: true,
            Comment: updatedVal.Comment
          };
          updatedSpots.push(updatedObject);
          let logCapture = { ...object, Purge: true, Comment: updatedVal.Comment, TimeRange: {_id: object.TimeRange._id, SID: object.TimeRange.SID} };
          let finalCapturePurgeData = SalesHelper.getSpotsData(logCapture, LOGEVENT.PURGE, "Purge Item");
          API.SaveLogs(finalCapturePurgeData);
        });
        
        break;

      case BOOKINGUPDATETYPE.MAKE_EXCEPTION:
        spots.map(async (object) => {
          const updatedObject = {
            _id: object._id,
            NominalOnAir: 0,
            ParentHeaderMediaEpisode_id: ''
          };

          updatedSpots.push(updatedObject)
          let logCapture = { ...object, TimeRange: {_id: object.TimeRange._id, SID: object.TimeRange.SID},
            NominalOnAir: 0,
            ParentHeaderMediaEpisode_id: ''
          }
          let finalCaptureMakeExceptionData = SalesHelper.getSpotsData(logCapture, LOGEVENT.MAKE_EXCEPTION, "Make_Exception");
          API.SaveLogs(finalCaptureMakeExceptionData);
        });
        break;

      case BOOKINGUPDATETYPE.UNPURGE:
        spots.map(async (object) => {
          const updatedObject = {
            _id: object._id,
            Purge: false,
            Comment: updatedVal.Comment
          };
          updatedSpots.push(updatedObject);
          let logCapture = { ...object, Purge: false, Comment: updatedVal.Comment, TimeRange: {_id: object.TimeRange._id, SID: object.TimeRange.SID} };
          let finalCaptureUnPurgeData = SalesHelper.getSpotsData(logCapture, LOGEVENT.UNPURGE, "UnPurge Item");
          API.SaveLogs(finalCaptureUnPurgeData);

        });
        
        break;

      case BOOKINGUPDATETYPE.COMMERCIAL:

        spots.map(async (object) => {

          const isDurChanged = object.MediaEpisode.Duration != updatedVal.SelectedCommercial.Duration;

          let rate10Sec = object.IsRate10SecBooked ?? false ? object.Rate10Sec : (object.SpotRate / (updatedVal.SelectedCommercial.Duration / 1000)) * 10;
          let spotRate = object.IsRate10SecBooked ?? false ? (object.Rate10Sec / 10) * (updatedVal.SelectedCommercial.Duration / 1000) : object.SpotRate;

          const updatedObject = {
            _id: object._id,
            MediaEpisode: updatedVal.SelectedCommercial,
            MediaEpisode_Id: updatedVal.SelectedCommercial._id,
            LineNumber: assignNewLineNumber ? maxLineNo : object.LineNumber,
            Rate10Sec: isDurChanged ? parseFloat(rate10Sec).toFixed(2) : parseFloat(object.Rate10Sec).toFixed(2),
            SpotRate: isDurChanged ? parseFloat(spotRate).toFixed(2) : parseFloat(object.SpotRate).toFixed(2)
          };
          updatedSpots.push(updatedObject);

          let logCapture = { ...object, ...updatedObject, TimeRange: {_id: object.TimeRange._id, SID: object.TimeRange.SID}};
          let finalCaptureCommercialData = SalesHelper.getSpotsData(logCapture, LOGEVENT.CHANGE_COMMERCIAL, "Change Commercial");
          API.SaveLogs(finalCaptureCommercialData);

        });
        break;

    }

    let res = await API.saveData(ENTITYNAME.Booking, updatedSpots);

    // bookingSocket.onPurge(object); //refresh schedule
    getCampaignsExistingBookingDetails(location.state._id);
    toast.info(res.message, {
      position: toast.POSITION.TOP_RIGHT,
    });

  }


  const Commercials = () => {
    setShowCommercials(true);
  };


  const changeCommercials = async () => {

    if (!dataItem.SelectedCommercial) {

      toast.error("Please Select Commercial", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    //CHECKING IF SPOTS OF SAME LINE NO HAVE BEEN SELECTED OR NOT
    if (!isUniqueLineNoSelected()) {

      toast.error(`${lang.booked_spots_unique_line_no_selection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setDataItem({ ...dataItem, SelectedCommercial: "" });
      setSelectedItem([]);
      return;

    }

    var _isValidToUpdate = await isValidToUpdate();

    if (!_isValidToUpdate) {
      toast.error(`${selectedItemsRef.current.length > 1 ? lang.booked_spots_scheduled_error_message : lang.booking_is_used_in_schedule_show_spot_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setDataItem({ ...dataItem, SelectedCommercial: "" });
      setSelectedItem([]);
      return;
    }

    console.log(dataItem.SelectedCommercial);
    var errorMsg = await isCommercialValidToUpdate();
    if (errorMsg.length > 0) {
      toast.error(`${errorMsg}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;

    }

    await updateBookedSpots(selectedItemsRef.current, true, BOOKINGUPDATETYPE.COMMERCIAL, { SelectedCommercial: dataItem.SelectedCommercial })

    cancelContextMenuAndReload();

  };

  const isCommercialValidToUpdate = async () => {
    var errorMsg = "";
    var cuurentBookedAmount = 0;
    var cuurentBookedSeconds = 0;
    var isExceedingTarget = false;
    var selectedCampaign = location.state;

    var previousTotalSpotAmout = utility.sumOfListColumn(selectedItemsRef.current, 'SpotRate');

    selectedItemsRef.current.map((bookingObj, index) => {
      //console.log(bookingObj);
      var spotRate = bookingObj.IsRate10SecBooked ?? false ? (bookingObj.SpotRate / 10) * (dataItem.SelectedCommercial.Duration / 1000) : bookingObj.SpotRate;
      // console.log(spotRate);
      // console.log(bookingObj.IsRate10SecBooked);
      // console.log(bookingObj.SpotRate);
      cuurentBookedAmount = parseFloat(cuurentBookedAmount) + parseFloat(spotRate);
      cuurentBookedSeconds = parseInt(cuurentBookedSeconds) + ((parseInt(dataItem.SelectedCommercial.Duration ?? 0) / 1000) * 1);
    });


    // console.log((parseFloat(bookedAmount) - parseFloat(previousTotalSpotAmout)) + parseFloat(cuurentBookedAmount))


    isExceedingTarget = ((parseFloat(bookedAmount) - parseFloat(previousTotalSpotAmout)) + parseFloat(cuurentBookedAmount)) > parseFloat(selectedCampaign.TargetBudget)

    if (isExceedingTarget) {
      errorMsg = lang.exceding_target_budget_booking_error_message;
      console.log(errorMsg);
      return errorMsg;
    }


    //GETTING TOTAL SECONDS
    var previousTotalSeconds = 0;
    for (var i = 0; i < selectedItemsRef.current.length; i++) {
      let booking = selectedItemsRef.current[i];
      previousTotalSeconds += parseFloat(booking.MediaEpisode.Duration == null ? 0 : booking.MediaEpisode.Duration / 1000);

    }

    isExceedingTarget = ((parseInt(bookedSeconds) - parseInt(previousTotalSeconds)) + parseInt(cuurentBookedSeconds)) > parseInt(selectedCampaign.TargetSeconds);

    console.log("CHecking " + isExceedingTarget + " Booked Sec : " + bookedSeconds + " Current sec : " + cuurentBookedSeconds + " Prev Sec " + previousTotalSeconds);
    console.log((parseInt(bookedSeconds) - parseInt(previousTotalSeconds)) + parseInt(cuurentBookedSeconds))

    // if(parseInt(alreadyBookedDetails.BookedSeconds + cuurentBookedSeconds) > parseInt(selectedCampaign.TargetSeconds)); // this causing to enter in block
    if (isExceedingTarget) {
      errorMsg = lang.exceding_target_seconds_booking_error_message;
      console.log(errorMsg);
      return errorMsg;
    }



    return errorMsg;


  }

  const onClosedPurgeUnPurgePage = async () => {
    setPurgeUnPurgePageOpen(false);
    setMakeExceptionPageOpen(false);
    setDataItem({ ...dataItem, Comment: '' });
  }


  const onPurgeAndUnPurge = async () => {
    if (dataItem.Comment.trim() == '' || dataItem.Comment == undefined) {
      toast.error(`${lang.please_enter_comment_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if (!filterData.Purge) {
      onPurge();
    }
    else if (filterData.Purge) {
      onUnPurge();
    }
    setPurgeUnPurgePageOpen(false);
    setDataItem({ ...dataItem, Comment: '' });
  };

  const deleteScheduledSpotsFromScheduling = async () => {

    // const user = utility.getValue(LOCALSTORAGE_KEY.userData);

    let isValidForException = [];
    let inValidForException = [];
    let nonScheduledSpots = [];
    let scheduleSpotIds = [];

    for (let i = 0; i < selectedItemsRef.current.length; i++) {
      const bookedSpot = selectedItemsRef.current[i];
      if (bookedSpot.Status == 'Not Scheduled') {
        nonScheduledSpots.push(bookedSpot);
      } else {
        //CHECK SPOT BOOKING DATE IS GREATER THAN EQUAL TODAY'S DATE
        let currentDateInMillisec = new Date(moment(new Date()).format('YYYY-MM-DD')).getTime();

        if (bookedSpot.BookingDate >= currentDateInMillisec) {
          const lockPlaylist = await utility.getLockedPlaylist(bookedSpot.BookingDate, MODULE.SCHEDULING);
          if (lockPlaylist.success && lockPlaylist.data.filter(x => x.moduleID == MODULE.SCHEDULING).length == 0) {
            scheduleSpotIds.push(bookedSpot._id);
            isValidForException.push({ ...bookedSpot, NominalOnAir: '00:00:00:00', BookingStatusSID: BOOKINGSTATUS.None, Status: 'Not Scheduled' });
          } else {
            inValidForException.push(bookedSpot);
          }
        }
        else {
          inValidForException.push(bookedSpot);
        }
      }
    }

    let resDeletedSchedule = await API.deleteScheduledSpots(scheduleSpotIds);
    if (resDeletedSchedule.data.length > 0) {

      const processedScheduleDates = [];

      for (let index = 0; index < resDeletedSchedule.data.length; index++) {

        const element = resDeletedSchedule.data[index];
        const sd = new Date(moment(new Date(element.ScheduleDate)).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })).getTime();

        if (processedScheduleDates.includes(sd)) continue;

        socket.emit(SOCKET_EVENTS.onSocketData,
          {
            action: SOCKET_ACTION.SCHEDULE_DELETEITEM,
            module: MODULE.SCHEDULING,
            data: {
              selectedChannelSID: element.Channel?.SID ?? 0,
              selectedScheduleDate: sd,
              dataItems: resDeletedSchedule.data.filter(x => (new Date(moment(new Date(x.ScheduleDate)).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })).getTime()) == sd)
            },
            user: { name: user.name, _id: user._id }
          });

        processedScheduleDates.push(sd);
      }

    }
    return {
      isValidForException: isValidForException,
      inValidForException: inValidForException,
      nonScheduledSpots: nonScheduledSpots
    }

  }

  const onMakeException = async () => {

    if (!isValidForUpdateSpots()) {
      return;
    }

    var { isValidForException, inValidForException, nonScheduledSpots } = await deleteScheduledSpotsFromScheduling();

    if (isValidForException.length > 0) {

      var newData = [...bookingDetails];
      isValidForException.map(async (object) => {

        newData.map((item, index) => {
          if (item._id == object._id) {
            var purgedDataItem = {
              ...object,
            };
            newData.splice(index, 1, purgedDataItem);
            setBookingDetails(newData);

            // Click Purge Button Show purge page
            var purgedBookingDetails = newData.filter(
              (item) => item.Purge != true
            );
            setPurgedBookingDetails(purgedBookingDetails);
          }
        });
        bookingSocket.onPurge(object);
      });

      isValidForException.forEach(object=>{
        
        let finalCaptureData = SalesHelper.getSpotsData(object, LOGEVENT.MAKE_EXCEPTION, "Make Exception");
        API.SaveLogs(finalCaptureData);

      })
      await updateBookedSpots(isValidForException, false, BOOKINGUPDATETYPE.MAKE_EXCEPTION)
    }
    if (nonScheduledSpots.length > 0) {
      toast.info(`In selected spots ${nonScheduledSpots.length > 1 ? `${nonScheduledSpots.length} spots are` : `${nonScheduledSpots.length} spot is`}  not scheduled`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    if (inValidForException.length > 0) {
      toast.info(`Cannot make exception ${inValidForException.length > 1 ? `${inValidForException.length} scheduled spots are` : `${inValidForException.length} scheduled spot is`} scheduled before current date or schedule is lock !`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    contextMenuCancel();
  };

  const onPurge = async () => {

    var _isValidToUpdate = await isValidToUpdate();

    if (!_isValidToUpdate) {
      toast.error(`${selectedItemsRef.current.length > 1 ? lang.booked_spots_scheduled_error_message : lang.booking_is_used_in_schedule_show_spot_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    var newData = [...bookingDetails];
    selectedItemsRef.current.map(async (object) => {

      newData.map((item, index) => {
        if (item._id == object._id) {
          var purgedDataItem = {
            ...object,
            Purge: true,
            Comment: dataItem.Comment
          };
          newData.splice(index, 1, purgedDataItem);
          setBookingDetails(newData);

          // Click Purge Button Show purge page
          var purgedBookingDetails = newData.filter(
            (item) => item.Purge != true
          );
          setPurgedBookingDetails(purgedBookingDetails);
        }
      });
      bookingSocket.onPurge(object);
    });

    await updateBookedSpots(selectedItemsRef.current, false, BOOKINGUPDATETYPE.PURGE, { Comment: dataItem.Comment })
    contextMenuCancel();
  };


  const onUnPurge = async () => {
    var selectedCampaigns = selectedItemsRef.current[0].campaign;
    var checkCreditLimit = await API.isCreditAvaliable(selectedCampaigns, totalSpotRate)
    console.log(checkCreditLimit);
    if (!checkCreditLimit.success) {
      toast.error(checkCreditLimit.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    var newData = [...bookingDetails];
    selectedItemsRef.current.map(async (object) => {
      bookingDetails.map((item, index) => {
        if (item._id == object._id) {
          var purgedDataItem = {
            ...object,
            Purge: false,
            Comment: dataItem.Comment
          };
          newData.splice(index, 1, purgedDataItem);
          setBookingDetails(newData);
          var purgedBookingDetails = newData.filter(
            (item) => item.Purge != false
          );
          setPurgedBookingDetails(purgedBookingDetails);
        }
      });
      bookingSocket.onPurge(object);
    });


    await updateBookedSpots(selectedItemsRef.current, false, BOOKINGUPDATETYPE.UNPURGE, { Comment: dataItem.Comment })
    contextMenuCancel();
  };


  const updateBookingMakeGood = async () => {

    let _isValidToUpdate = await isValidToUpdate();

    if (!_isValidToUpdate) {
      toast.error(`${selectedItemsRef.current.length > 1 ? lang.booked_spots_scheduled_error_message : lang.booking_is_used_in_schedule_show_spot_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    let selectedItemIds = selectedItemsRef.current.map((x) => x._id);

    let response = await API.updateBookingSpotMakeGoodPurge(selectedItemIds, true, false);
    if (response.success) {
      selectedItemIds.map((id) => {

        if (response.scheduledSpots.length > 0 && !response.scheduledSpots.includes(id)) {
          let data = [...purgedBookingDetails];
          let index = data.findIndex(x => x._id == id);
          data.splice(index, 1);
          setBookingDetails(data);
          setPurgedBookingDetails(data);
        }
      });

      selectedItemsRef.current.forEach(x=>{
        let newObj = { ...x, 
          MakeGood: filterData.MakeGood ? false : true
        }

        let finalCaptureMakeGoodData = SalesHelper.getSpotsData(newObj, LOGEVENT.MAKE_GOOD, "Update Make Good");
        API.SaveLogs(finalCaptureMakeGoodData);
      })

      bookingSocket.onMakeGoodMultiple(selectedItemsRef.current);
      contextMenuCancel();
    }

  }

  const cancelEdit = () => {
    setShowTimeRange(false);
    setShowTransfer(false);
    setShowSpotRate(false);
    setShowRate10Sec(false);
    setShowCommercials(false);
    setShowEstimateCodePopup(false);
    contextMenuCancel();
  };

  const downloadExcel = async () => {

    if (purgedBookingDetails.length == 0) {
      return;
    }

    var storeData = purgedBookingDetails.map(x => {
      return {
        LineNumber: x.CampaignNo,
        Title: x.MediaEpisode.Title,
        AssetId: x.MediaEpisode.AssetId,
        Date: x.BookingDate > 0 ? moment(new Date(x.BookingDate)).format('DD-MM-YYYY') : '',
        Channel: x.Channel.FullChannelName,
        TimeRangeFrom: utility.convertMilisecondsToShortTimeString(x.TimeRangeFrom),
        TimeRangeTo: utility.convertMilisecondsToShortTimeString(x.TimeRangeTo),
        Product: x.Product ? x.Product.Name : '',
        Brand: x.Brand ? x.Brand.Name : '',
        SpotRate: x.SpotRate,
        Rate10Sec: x.Rate10Sec,
        Status: x.Status,
        Position: x.Position.Description,
        CampaignType: x.CampaignType.Description,
        DealType: x.DealType?.Description,
        IsPurged: x.Purge ?? false,
        MakeGood: x.MakeGood ?? false,
      }
    })
    console.log(storeData)
    var res = await API.exportCSV(storeData);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  //label style
  var labelStyle = {
    marginLeft: "15px",
    marginTop: "2px",
    fontWeight: "700",
    fontSize: "13px",
  };

  const isValidForUpdateSpots = () => {
    if (selectedItemsRef.current.length == 0) {
      toast.info(`${lang.please_select_atleast_one_item}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    //CHECK IF RECONSILED OR BILLING
    var ifAnyItemReconsiledBilled = selectedItemsRef.current.some((x) => x.Status == 'Bill Generated' || x.Status == 'Reconciled');
    if (ifAnyItemReconsiledBilled) {
      toast.error(`${lang.some_spots_are_reconciled_bill_generated_selected_spots}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const showPurgeUnPurgePage = () => {
    if (!isValidForUpdateSpots()) {
      return;
    }
    setPurgeUnPurgePageOpen(true);
  }



  const contextMenuModal = [
    {
      label: !filterData.Purge
        ? `${lang.ref_purged_label}`
        : `${lang.ref_unpurged_label}`,
      icon: "pi pi-fw pi-window-minimize",
      command: () => { showPurgeUnPurgePage() },
    },
    {
      label: `Make Exception`,
      icon: "pi pi-fw pi-check-square",
      command: () => { onMakeException() },
    },
    // { label: `${lang.ref_calculate_spot_rate_label}`, icon: "pi pi-fw pi-calendar-times", command: () => { CalculateSpotRate() }},
    // { label: `${lang.ref_calculate_fct_label}`, icon: "pi pi-fw pi-chart-pie", command: () => { CalculateFCT() }},
    {
      label: `${lang.ref_change_time_range_label}`,
      icon: "pi pi-fw pi-history",
      command: () => {
        TimeRange(true);
      },
    },
    // { label: `${lang.ref_transfer_label}`, icon: "pi pi-fw pi-sort-alt", command: () => { Transfer(true) }},
    {
      label: `${lang.ref_update_estimate_code_label}`,
      icon: "pi pi-fw pi-file-o",
      command: () => {
        setShowEstimateCodePopup(true);
      },
    },
    {
      label: `${lang.ref_change_commercial_label}`,
      icon: "pi pi-fw pi-th-large",
      command: () => {
        Commercials();
      },
    },
    {
      label: `${lang.ref_change_spot_rate_label}`,
      icon: "pi pi-fw pi-calendar-times",
      command: () => {
        SpotRate();
      },
    },
    {
      label: `${lang.ref_change_rate10sec_label}`,
      icon: "pi pi-fw pi-calendar-times",
      command: () => {
        Rate10Sec();
      },
    },
    {
      label: `Update MakeGood`,
      icon: "pi pi-fw pi-globe",
      command: () => {
        updateBookingMakeGood();
      },
    },
    {
      label: `${lang.ref_cancel_label}`,
      icon: "pi pi-fw pi-times",
      command: () => {
        contextMenuCancel();
      },
    },
  ]

  return (
    <>
      <div className="mt-2">
        <div className="flex-container-reverse flex-item-auto mb-3">

          <div className="ml-2">
            <div>
              <label htmlFor="TabView">{lang.year_label}</label>
            </div>
            <DropDownList
              data={years}
              name="Year"
              onChange={onChange}
              value={filterData.Year}
              style={{ width: "120px" }}
              validator={(value) => (value ? "" : "Please select value")}
            />
          </div>

          <div className="ml-2">
            <div>
              <label htmlFor="TabView">{lang.months_label}</label>
            </div>
            <DropDownList
              data={months}
              name="Month"
              style={{ width: "120px" }}
              value={filterData.Month}
              onChange={onChange}
              validator={(value) => (value ? "" : "Please select value")}
            />
          </div>

          <div className="ml-2">
            <div>
              <label htmlFor="TabView">{lang.sort_by_label}</label>
            </div>
            <DropDownList
              data={sortArray}
              name="SortBy"
              textField="label"
              dataItemKey="key"
              onChange={onSortChange}
              value={sorted}
              style={{ width: "120px" }}
            />
          </div>

          <div className="flex-item-auto">
            <h1 className="page-title txt-color-bludeDark">
              <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
              ></i>
              <span>{"Spots"}</span>
            </h1>
          </div>
        </div>

        <div className="flex-container-reverse m-1">
          <div style={labelStyle}>
            {lang.target_amount_label} :{" "}
            <div style={{ color: "#0295f7" }}>{header.TotalAmount}</div>
          </div>

          <div style={labelStyle}>
            {lang.target_seconds_label} :{" "}
            <div style={{ color: "#0295f7" }}>{header.TotalSeconds}</div>
          </div>

          <div style={labelStyle}>
            {lang.target_spots_label} :{" "}
            <div style={{ color: "#0295f7" }}>{header.TotalSpots}</div>
          </div>

          <div style={labelStyle}>
            {lang.campaign_no_label} :{" "}
            <div style={{ color: "#0295f7" }}>{header.CampaignNo}</div>
          </div>

          <div className="flex-item-auto" style={labelStyle}>

            <button style={{ margin: "0px 2px 2px 2px" }} type="button" title={lang.back_button_tooltip} onClick={() => navigate(-1)} className={`btn btn-default bg-primary btn-circle btn-my`} >
              <i className="fa fa-chevron-left" ></i>
            </button>
            {!filterData.Purge && <button style={{ margin: "0px 2px 2px 2px" }} type="button" title={lang.purge_button_tooltip} onClick={() => showPurgeUnPurgePage()} className={`btn btn-default btn-circle btn-my`} >
              <i className="pi pi-fw pi-window-minimize" ></i>
            </button>}
            {filterData.Purge && <button style={{ margin: "0px 2px 2px 2px" }} type="button" title={lang.unpurge_button_tooltip} onClick={() => showPurgeUnPurgePage()} className={`btn btn-default btn-circle btn-my`} >
              <i className="pi pi-fw pi-sort-alt-slash"></i>
            </button>}

            <RefreshButton onClick={() => { loadBookingDetails(filterData) }} />
            <PreferenceRoundButton
              icon={"gear"}
              title={lang.preference_button_tooltip}
              onClick={() => { setShowPreferences(!showPreferences) }}
            />
            <RoundButton
              icon={"download"}
              btnColor={"info"}
              style={{ margin: "0px 03px", color: 'white' }}
              // iconColor={"white"}
              title={'Export Spot'}
              onClick={downloadExcel}
            />

            <input
              name={"Purge"}
              type={"Checkbox"}
              value={filterData.Purge}
              onChange={onChangeFilterData}
              checked={filterData.Purge}
              style={{
                marginLeft: "10px",
                textAlign: "right",
                marginRight: "5px",
              }}
            />
            {lang.purged_label}

            <input
              name={"MakeGood"}
              type={"Checkbox"}
              value={filterData.MakeGood}
              onChange={onChangeFilterData}
              checked={filterData.MakeGood}
              style={{
                marginLeft: "10px",
                textAlign: "right",
                marginRight: "5px",
              }}
            />
            {lang.makegood_column}

          </div>
        </div>

        <div>
          <fieldset className="border rounded-4" >
            <div className="row pl-4">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="">{lang.from_date_label} </label>
                  <input type="date" className="form-control form-control-sm" name="FromDate" value={filterData.FromDate} onChange={onChangeFilterData} />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="">{lang.to_date_label} </label>
                  <input type="date" className="form-control form-control-sm" name="ToDate" value={filterData.ToDate} onChange={onChangeFilterData} />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="TabView">{lang.channel_label}</label>
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={[{ SID: 0, FullChannelName: 'All' }, ...location.state.Channels]}
                    name="Channel"
                    textField="FullChannelName"
                    dataItemKey="_id"
                    value={filterData.Channel}
                    onChange={onChangeFilterData}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="TabView">{lang.commercial_label}</label>
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={[{ SID: 0, Title: 'All' }, ...location.state.Commercials]}
                    name="Commercial"
                    textField="Title"
                    dataItemKey="_id"
                    value={filterData.Commercial}
                    onChange={onChangeFilterData}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="TabView">{lang.brand_label} </label>
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={[{ SID: 0, Name: 'All' }, ...location.state.Commercials.map((x) => x.Brand)]}
                    name="Brand"
                    textField="Name"
                    dataItemKey="_id"
                    value={filterData.Brand}
                    onChange={onChangeFilterData}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="TabView">{lang.product_label}</label>
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={[{ SID: 0, Name: 'All' }, ...location.state.Commercials.map((x) => x.Product)]}
                    name="Product"
                    textField="Name"
                    dataItemKey="_id"
                    value={filterData.Product}
                    onChange={onChangeFilterData}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="TabView">{lang.status_label}</label>
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={[{ SID: -1, Description: 'All' }, ...Object.keys(BOOKINGSTATUS).map((x) => { return { SID: BOOKINGSTATUS[x], Description: x } })]}
                    name="Status"
                    textField="Description"
                    dataItemKey="SID"
                    value={filterData.Status}
                    onChange={onChangeFilterData}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="TabView">{lang.position_label}</label>
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={[{ SID: 0, Description: 'All' }, ...Object.keys(BOOKINGPOSITIONS_ENUM).map((x) => { return { SID: BOOKINGPOSITIONS_ENUM[x], Description: x } })]}
                    name="Position"
                    textField="Description"
                    dataItemKey="SID"
                    value={filterData.Position}
                    onChange={onChangeFilterData}
                  />
                </div>
              </div>

              <div className="col" style={{ marginTop: '20px' }}>
                <div className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary btn-success" style={{ padding: '0px', margin: '0px 0px 0px 3px' }}>
                  <label className="" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                    <i className="fa-solid fa-magnifying-glass" onClick={() => { loadBookingDetails(filterData) }} />
                  </label>
                </div>

                <div className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary btn-danger ml-2" style={{ padding: '0px', margin: '0px 0px 0px 3px' }}>
                  <label className="" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                    <i className="fa-solid fa-xmark" onClick={() => { loadBookingDetails(defaultFilterValue), setFilterData(defaultFilterValue) }} />
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>


        <div>
          {isLoading ?
            <Loader height={"65vh"} /> :
            <Grid
              data={
                purgedBookingDetails.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
              }}
              dataItemKey={DATA_ITEM_KEY}
              onSelectionChange={onSelectionChange}
              resizable={true}
              onHeaderSelectionChange={onHeaderSelectionChange}
              rowRender={gridRowRender}
              style={{
                margin: "0px 10px 10px 10px",
                height: "56vh",
              }}
            >
              <GridNoRecords>{loadingStatus}</GridNoRecords>
              <Column
                field={SELECTED_FIELD}
                width="50px"
                headerSelectionValue={
                  purgedBookingDetails.findIndex(
                    (item) => !selectedState[idGetter(item)]
                  ) === -1
                }
                locked={true}
                filterable={false}
              />
              {dataColumns.map((column, index) => {
                if (column.type === COLUMNSTYPE.date) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={DateOnlyCell}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  );
                  // }else if (column.name === COLUMNSTYPE.time) {
                } else if (column.type === COLUMNSTYPE.datetime) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={DateCell}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  );
                  // }else if (column.name === COLUMNSTYPE.time) {
                } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={TimeCell}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  );
                } else if (column.name === "SID") {
                  return;
                  //we are not returning any value for SID column
                } else if (column.type === COLUMNSTYPE.image) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={ImageCell}
                      title={column.label}
                      width={column.width ?? 100}
                    />
                  );
                } else if (column.type === COLUMNSTYPE.select) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={LookupCell}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  );
                }
                else if (column.type === COLUMNSTYPE.toggle) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={MyToggleButton}
                      title={column.label}
                      width={column.width ?? 80}
                    />
                  );
                }
                else if (column.type === COLUMNSTYPE.checkbox) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={CheckboxCell}
                      title={column.label}
                      width={column.width ?? 80}
                    />
                  );
                }
                else if (column.type === COLUMNSTYPE.array) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={ArrayCell}
                      title={column.label}
                      width={column.width ?? 200}
                      format={column.format ?? "Description"} //display item in object
                    />
                  );
                }
                else if (column.type === COLUMNSTYPE.href) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={HrefCell}
                      title={column.label}
                      width={column.width ?? 200}
                      format={column.format}
                    />
                  );
                }
                else if (column.type === COLUMNSTYPE.enum) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={EnumCell}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  );
                } else if (column.type === COLUMNSTYPE.platform) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={PlatformCell}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  );
                }
                else if (column.type === COLUMNSTYPE.status) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={MyStatusCell}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  );
                }
                else if (column.type === COLUMNSTYPE.localdatetime) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={LocalDateTimeColumn}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  );
                }
                else if (column.type === COLUMNSTYPE.icon) {
                  return (
                    <GridColumn
                      key={index}
                      field={column.name}
                      cell={IconCell}
                      title={column.label}
                      width={column.width ?? 80}
                    />
                  );
                }
                else {
                  return (
                    column.type !== COLUMNSTYPE.hidden && (
                      <GridColumn
                        key={index}
                        field={column.name}
                        title={column.label}
                        width={column.width ?? 200}
                      />
                    )
                  );
                }
              })}
            </Grid>}
        </div>

        <ContextMenu ref={cm} model={filterData.Purge ? contextMenuModal.filter((x, i) => i != 1) : contextMenuModal} />

        {purgeUnPurgePageOpen && (
          <Dialog
            title={filterData.Purge ? `${lang.unpurged_label}` : `${lang.purged_label}`}
            onClose={onClosedPurgeUnPurgePage}
            width={"40vw"}
          >
            <div className="row">
              <div className=" col-12">
                <EditPageHeader onSubmit={onPurgeAndUnPurge} onCancel={onClosedPurgeUnPurgePage} showTitle={false} />
                <div className="row mt-2" >
                  <div className='col-12'>
                    <div className="form-group">
                      <label htmlFor="">{lang.comment_label} *</label>
                      <textarea name="Comment" type="text" className="form-control form-control-sm" id="Comment" value={dataItem.Comment} onChange={onChange} required />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        )}
        {showTimeRange && (
          <Dialog
            title={lang.change_time_range_dialog_header}
            onClose={cancelEdit}
            width={"50%"}
            height={"28%"}
          >
            <EditPageHeader
              title={""}
              onSubmit={updateTimeRange}
              onCancel={cancelEdit}
              showTitle={false}
            />
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="">{lang.start_time_label}</label>
                          <TimePickerWithFormat
                            className="form-control form-control-sm mt-1"
                            name="TimeRangeFrom"
                            value={dataItem.TimeRangeFrom}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-6">
                          <label htmlFor="">{lang.to_time_label}</label>
                          <TimePickerWithFormat
                            className="form-control form-control-sm mt-1"
                            name="TimeRangeTo"
                            value={dataItem.TimeRangeTo}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        )}

        {showTransfer && (
          <Dialog
            title={lang.transfer_dialog_header}
            onClose={cancelEdit}
            width={"25%"}
            height={"28%"}
          >
            <EditPageHeader
              title={""}
              onSubmit={onBookingTransfer}
              onCancel={cancelEdit}
              showTitle={false}
            />
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label>{lang.transfer_to_date_label}</label>
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        name="BookingTransferTo"
                        value={dataItem.BookingTransferTo}
                        onChange={onChange}
                        required={true}
                      />
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        )}

        {showSpotRate && (
          <Dialog
            title={lang.change_spot_rate_dialog_header}
            onClose={cancelEdit}
            width={"25%"}
            height={"28%"}
          >
            <EditPageHeader
              title={""}
              onSubmit={changeSpotRate}
              onCancel={cancelEdit}
              showTitle={false}
            />
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label>{lang.change_spot_rate_label}</label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        name="SpotRate"
                        value={dataItem.SpotRate}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        )}

        {showRate10Sec && (
          <Dialog
            title={lang.change_rate10sec_dialog_header}
            onClose={cancelEdit}
            width={"25%"}
            height={"28%"}
          >
            <EditPageHeader
              title={""}
              onSubmit={changeRate10Sec}
              onCancel={cancelEdit}
              showTitle={false}
            />
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label>{lang.change_rate10sec_label}</label>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        name="SpotRate"
                        value={dataItem.SpotRate}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        )}

        {showCommercials && (
          <Dialog
            title={lang.change_commercial_dialog_header}
            onClose={cancelEdit}
            width={"25%"}
            height={"28%"}
          >
            <EditPageHeader
              title={""}
              onSubmit={changeCommercials}
              onCancel={cancelEdit}
              showTitle={false}
            />
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label>{lang.change_commercial_label}</label>
                      <DropDownList
                        data={location.state.Commercials ?? []}
                        name="SelectedCommercial"
                        textField="Title"
                        dataItemKey="_id"
                        value={dataItem.SelectedCommercial}
                        onChange={onChange}
                        validator={(value) =>
                          value ? "" : "Please select the value"
                        }
                      />
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        )}

        {showEstimateCodePopup && (
          <Dialog
            title={lang.update_product_estimate_code_dialog_header}
            onClose={cancelEdit}
            width={"30%"}
            height={"25%"}
          >
            <div className="row mt-3">
              <div className="col-5 mt-2 ml-2">
                <label htmlFor="TabView">{lang.estimate_code_label} :</label>
              </div>
              <div className="col-6">
                <input
                  className="pl-2"
                  type="text"
                  style={{
                    border: "solid lightgrey 1px",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  name={"EstimateCode"}
                  onChange={onChange}
                  value={dataItem.EstimateCode}
                />
              </div>
            </div>

            <div className="row mt-3 mb-1">
              <div className="marlr">
                <ActionButton
                  title={lang.update_button_tooltip}
                  name={lang.update_button_text}
                  onClick={updateEstimateCode}
                />
                <ActionButton
                  title={lang.cancel_button_tooltip}
                  name={lang.cancel_button_text}
                  btnColor={"danger"}
                  onClick={cancelEdit}
                />
              </div>
            </div>
          </Dialog>
        )}
        {showPreferences && <UserPreference entityName="showspots" preferenceColumnENntityName="showspots" handleClose={() => { setShowPreferences(false); loadBookingDetails(filterData) }} />}
      </div>
      {/* footer */}

      <div
        className={
          isMinified
            ? "scheduleFooter scheduleFooterMinified"
            : "scheduleFooter"
        }
      >
        <div className="flex-container-reverse flex-item-auto p-2">
          <div className="footerText">
            {"Total Items"} : {purgedBookingDetails.length}
          </div>

          <div className="footerText flex-item-auto" style={labelStyle}>
            <span className="footerText mr-3">
              {lang.total_booked_spots_label} : {bookedSpots}
            </span>
            <span className="footerText mr-3">
              {lang.total_booked_seconds_label} : {bookedSeconds}
            </span>
            <span className="footerText">
              {lang.total_booked_amount_label} : {bookedAmount}
            </span>
          </div>

          <div className="footerText flex-item-auto" style={labelStyle}>
            <span className="footerText mr-3">
              {lang.selected_label} : {selectedItemsRef.current.length}
            </span>
            <span className="footerText mr-3">
              {lang.total_spot_rate} : {totalSpotRate}
            </span>
            <span className="footerText">
              {lang.total_fct_label} : {totalFCT}
            </span>
          </div>



        </div>
      </div>
    </>
  );
};
export default ShowSpots;
