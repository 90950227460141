/* eslint-disable */
import React from 'react';

const DashboardCard = (props) => {
    return (
        <>
            <div className="card shadow" style={{ overflow: 'hidden', borderRadius: '18px' }}>
                <div className="card-content">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4" style={{ padding: '30px' }}>
                                <div className="summaryDashboard_card" style={{ backgroundColor: props?.backgroundColor ?? 'lightGreen' }}></div>
                                <i className={`fa-sharp fa-solid fa-tv summaryDashboardicon_card`} style={{ padding: '0px', fontSize: '22px', marginTop: '5px', marginLeft: '2px', color: 'white' }}></i>
                            </div>
                            <div className='col text-center'>
                                <h3>{props?.count ?? 0}</h3>
                                <div>{props.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardCard;