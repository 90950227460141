import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../locale/useTranslation'
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { toast } from 'react-toastify';
import { DOCUMENT_TYPE, ENTITYNAME, LOGEVENT, MODULE } from '../constant/constant';
import { EditPageHeader } from '../../components/EditPageHeader';
import * as API from "../../framework/API/api";
import { utility } from '../utility/utilityProvider';

const DocumentEditForm = (props) => {

  const lang = useTranslation();
  let getDocumentTypeData = Object.keys(DOCUMENT_TYPE).map((key) => {
    return { _id: DOCUMENT_TYPE[key], Description: key }
  });

  const blankDataItem = {
    ...props.item,
    SID: props.item.SID ?? 0,
    Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description  ?? "",
    DocumentType: getDocumentTypeData.find(x => x._id == props.item.DocumentType) ?? {},
    Url: props.item.Url ?? "",
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [documentType, setDocumentType] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    loadCombo();
  }, [])

  const isValid = () => {
    if (dataItem.Description == undefined || dataItem.Description == "") {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.DocumentType == undefined || Object.keys(dataItem.DocumentType).length == 0) {
      toast.error(`${lang.please_select_document_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.doc == undefined || dataItem.doc == "" || dataItem.doc.size == 0) {
      toast.error(`${lang.please_upload_doc_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else if (e.target.name == 'doc' && e.target?.files) {
      if (e.target.files != undefined) {
        setFiles(e.target.files[0]);
      } else {
        setFiles(new Set());
      }
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const loadCombo = async () => {
    let documentTypeRes = Object.keys(DOCUMENT_TYPE).map((key) => {
      return { _id: DOCUMENT_TYPE[key], Description: key }
    })
    setDocumentType(documentTypeRes);
  }

  const handelSubmit = async () => {

    if (isValid()) {
      if (files.size > 0) {
        var doc = await API.uploadPDF(files);
        if (typeof doc === 'object') {
          dataItem.doc = doc.message;
        }else{
          dataItem.doc = doc;
        }
      }
      const saveData = {
        _id: dataItem._id,
        SID: dataItem.SID ?? 0,
        Description: dataItem.Description,
        DocumentType: dataItem.DocumentType._id ?? DOCUMENT_TYPE.Pdf,
        doc: files.size > 0 ? doc : dataItem.doc,
        Archive: dataItem.Archive,
      }
      let res = await API.saveData(ENTITYNAME.Document, saveData);
      if (res.success) {
        let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_DOCUMENT : LOGEVENT.UPDATE_DOCUMENT, module: MODULE.DOCUMENT, data: res.data, message: res.message };
        API.SaveLogs(logData);
        props.cancelEdit();
        props.refresh();
        utility.deleteLocalStorageItem(ENTITYNAME.Document);
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }

  }

  return (
    <>
      <div>
        <Dialog
          title={props.item.SID > 0 ? props.item.Description : `${lang.create_Document_dialog_header}`}
          onClose={props.cancelEdit}
          width="700px"
          height="300px"
        >
          <EditPageHeader onSubmit={handelSubmit} onCancel={props.cancelEdit} showTitle={false} />
          <div className='row mt-2'>
            <div className='col-12'>
              <div className='row'>
                <div className='col'>
                  <label>{lang.description_label} *</label>
                  <input type='text' className="form-control form-control-sm" name='Description' value={dataItem.Description} onChange={onChange} />
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-6'>
                  <label>{lang.document_type_label} *</label>
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={documentType}
                    name="DocumentType"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.DocumentType}
                    onChange={onChange}
                  />
                </div>
                <div className="col-5">
                  <label htmlFor="">{lang.upload_documents_label} *</label>
                  <input
                    name={'doc'}
                    className="form-control form-control-sm"
                    type={'text'}
                    value={dataItem.doc}
                    onChange={onChange}
                    disabled
                  />
                </div>
                <div className='col-1' style={{ marginTop: '16px', marginLeft: '-15px' }}>
                  <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{ padding: '0px', height: "32px", margin: '0px 0px 0px 3px' }}>
                    <label className="fa-solid fa-magnifying-glass" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                      <input name={'doc'} className='custom-file-updated-input form-control form-control-sm' type="file" accept=".pdf" hidden="hidden" onChange={onChange} />
                    </label>
                  </button>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-4'>
                  <input type="checkbox" name="Archive" value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
                  <label className='pl-1'>{lang.archive}</label>
                </div>
              </div>
            </div>
          </div>

        </Dialog>
      </div>
    </>
  )
}

export default DocumentEditForm
