/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, MEDIATYPEENUM } from "../../framework/constant/constant";
import { Grid, GridColumn as Column, getSelectedState, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { utility } from "../../framework/utility/utilityProvider";
import { getter } from "@progress/kendo-data-query";
import ArchiveIconCell from "../../components/ArchiveIconCell";
import OttAssetCollectionSelection from "../../framework/OTT/OttAssetCollectionSelection";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function AssetBundleEditForm(props) {
    
  const loc = useLocation();
    const { SID } = useParams();
    let blankDataItem = {
        Description: '',
        OttAsset: [],
        Archive: false
    }
    let isFromCopy = location.state?.copy;
    const lang = useTranslation();
    const [selectedState, setSelectedState] = useState({});
    const [searchText, setSearchText] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message);
    const navigate = useNavigate();
    const [addAsset, setAddAsset] = useState(false);
    const [ottAssets, SetOttAssets] = useState([]);
    const [dataItem, setDataItem] = useState(blankDataItem)

    useEffect(() => {
        //loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const addOttAssetRef = useRef();
    const setAddOttAssetRef = (data) => {
        addOttAssetRef.current = data;
    }

    const loadEditData = async () => {
        setLoadingStatus(lang.data_loading_error_message);
        let linkData = {
            link_collection: 'ott_assets',
            link_field: 'OttAsset'
        }
        let res = await API.getEntity(props.entityname, parseInt(SID), linkData);
        if (res.success) {
            setDataItem({
                ...res.data,
                Description:res.data.SID ? loc.state?.copy ? 'Copy of ' + res.data.Description : res.data.Description : res.data.Description
            });
            setFilterData(res.data.OttAsset);
            SetOttAssets(res.data.OttAsset);
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setLoadingStatus(lang.data_loading_error_message);
    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (dataItem.Description == undefined || dataItem.Description == "" || !dataItem.Description?.toString().trim()) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (ottAssets.length == 0) {
            toast.error(`${lang.atleast_attach_one_item_variation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        return true
    }

    const onSave = async () => {
        if (isValid()) {
            const saveData = {
                _id: dataItem._id,
                SID: loc.state?.copy ? 0 : parseInt(dataItem.SID),
                Description: dataItem.Description,
                OttAsset: ottAssets?.length > 0 ? ottAssets.map(x => x?._id) : [],
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Description", "=", dataItem.Description], ["Archive", "=", false]]
            }

            // need to implement validation
            if (loc.state?.copy) {
                delete saveData._id;
            }
            let res = await API.saveData(ENTITYNAME.DigitalSignAssetBundle, saveData);
            if (res.success) {
                navigate(-1);
                utility.deleteLocalStorageItem(ENTITYNAME.DigitalSignAssetBundle);
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const onSelectionChange = useCallback(
        (event) => {
            console.log(event);
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {
        console.log(event);
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        console.log(event)
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const handleSelectedDelete = () => {
        let selectedData = filterData.filter((item) => !selectedState[idGetter(item)])
        SetOttAssets(ottAssets.filter((item) => !selectedState[idGetter(item)]));
        setFilterData(selectedData);
        setSelectedState({});
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const onSearch = (searchText) => {
        setSearchText(searchText);
        let keyword = searchText?.toLowerCase() ?? "";
        if (keyword == undefined || keyword == "") {
            setFilterData(ottAssets);
            return;
        }
        let filterSearchData = ottAssets.filter((item) => {
            return JSON.stringify(item?.Description)?.toLowerCase()?.includes(keyword) || JSON.stringify(item?.Title)?.toLowerCase()?.includes(keyword);
        });
        if (filterSearchData.length > 0) {
            setLoadingStatus(lang.data_loading_error_message);
            setFilterData(filterSearchData);
        }
        else {
            setLoadingStatus(lang.no_record_found_error_message);
        }
        setFilterData(filterSearchData);
    };

    const addOttAsset = (index, isAddUp) => {
        setAddAsset(true);
        setAddOttAssetRef({
            Index: index,
            IsAddUp: isAddUp
        })
    }

    const handleAssetLibrary = (dataList) => {
        let data = [...ottAssets];
        let index = addOttAssetRef.current.Index;
        if (addOttAssetRef.current.IsAddUp) {
            let newData = utility.checkDuplicateInData(dataList, filterData, "Title");
            SetOttAssets([...ottAssets, ...newData]);
            setFilterData([...filterData, ...newData]);
        } else {
            let newData = utility.checkDuplicateInData(dataList, filterData, "Title");
            SetOttAssets([...ottAssets, ...newData]);
            setFilterData([...filterData, ...newData]);
        }
    }

    return (<>
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader title={(SID > 0) ? `${lang.edit_asset_bundle_editpage_header}` : `${lang.create_asset_bundle_editpage_header}`} onSubmit={onSave} onCancel={() => navigate(-1)} />
                <div className="row mt-2">
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.description_label} *</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Description"
                            onChange={onChange}
                            value={dataItem.Description}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <input type={"checkbox"} name={"Archive"}
                            value={dataItem.Archive}
                            checked={dataItem.Archive}
                            onChange={onChange} />
                        <label className='ml-1'>{lang.archive}</label>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <AddRoundButton style={{ marginTop: '5px' }} title={lang.add_ott_asset_button_tooltip} onClick={() => { addOttAsset(ottAssets.length - 1, false) }} />
                                {(ottAssets?.filter((item) => selectedState[idGetter(item)]).length != 0) && <DeleteRoundButton style={{ marginTop: '5px', marginLeft: '5px' }} title={lang.delete_all_button_tooltip} onClick={() => ConfirmDeleteAlert(() => handleSelectedDelete(), () => { })} />}
                                <div className="pull-right">
                                    <div className="input-group input-group mb-2" >
                                        <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" value={searchText} placeholder={lang.search_button_text} onChange={(e) => onSearch(e.target.value)} />
                                        <span className="input-group-text">
                                            <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Grid
                            data={filterData?.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: "multiple",
                            }}
                            onSelectionChange={onSelectionChange}
                            onHeaderSelectionChange={onHeaderSelectionChange}
                            style={{ height: "58vh", marginTop: "04px", overflow: "hidden" }}>
                            <GridNoRecords>{loadingStatus}</GridNoRecords>
                            <GridColumn
                                field={SELECTED_FIELD}
                                width="50px"
                                headerSelectionValue={
                                    filterData?.findIndex((item) => !selectedState[idGetter(item)]) === -1
                                }
                            />
                            <Column field="Description" title={lang.description_column} editable={false} />
                            <Column field="Title" title={lang.title_column} editable={false} />
                            <Column field="StartDate" title={lang.end_date_column} cell={DateOnlyCell} editable={false} />
                            <Column field="EndDate" title={lang.end_date_column} cell={DateOnlyCell} editable={false} />
                            <Column field="Archive" title={lang.archived_column} editable={false} cell={ArchiveIconCell} />
                        </Grid>
                    </div>
                </div>
                {/* Add total count fotter */}
                <div className="row mt-2">
                    <div className="col-12 text-right">
                        <label>{lang.selected_label} : {filterData.filter((item) => selectedState[idGetter(item)]).length}</label>
                        <label className="ml-2">{lang.total_label} : {filterData.length}</label>
                    </div>
                </div>
            </div>
        </div>
        {addAsset && <OttAssetCollectionSelection title={lang.select_assets_collection_header} entityname={ENTITYNAME.OttAsset} closeForm={() => setAddAsset(false)} setDataList={handleAssetLibrary} width={"60vw"} height={"64vh"} />}
    </>)
}

export default AssetBundleEditForm;