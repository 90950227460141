/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from "../../../../locale/useTranslation";
import { ENTITYNAME } from '../../../constant/constant';
import { OttPosterCollectionSelectionColumns } from '../../../utility/customColumns';
import CollectionSelection from '../../CollectionSelection';

const validateFileType = (e,setImage,name) => {

    let fileName = e.target.value;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      setImage((old)=>{
          return old.add({name:name,data:e.target.files[0]})
      })
      e.target.setCustomValidity("")
    } else {
      e.target.setCustomValidity("Only .jpg/.jpeg or .png files are allowed!")
    }
}
  
export const ImageBrowser = (props) => {

  const lang = useTranslation();
  let hidePosterLibrary = props.hidePosterLibrary ?? false;
  let name = props.name;
  const [value,_setValue] = useState();
  let valueRef = useRef();
  const setValue = (data) => {
    valueRef.current = data;
    _setValue(data);
  }
  const [openImageBank, setOpenImageBank] = useState(false);
  
  useEffect(() => {
    setValue(props.value);
  },[props.value])

  const onChange = (e) => {
    setValue(e.target.value);
    props.onChange(e);
  }

  const handleImageSelect = (dataList) => {
    props.setSelectedImage(dataList);
    setValue(dataList[0].ImageUrl);
  }

  return(<>      
      <div className="input-group">
        <input name={name}
            className="form-control form-control-sm"
            type={'text'}
            value={value}
            onChange={onChange}
            disabled={valueRef.current?.files?.size > 0 ? true : false}
        />
        {!hidePosterLibrary && <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
            style={{ height: "32px" ,margin : '0px 0px 0px 3px' }} onClick={() => { setOpenImageBank(true) }}><i className="fa fa-ellipsis-h" aria-hidden="true"></i>
        </button>}

      <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{padding :'0px' , height: "32px" ,margin : '0px 0px 0px 3px'}}>
        <label className="fa-solid fa-search" style={{padding : '5px 9px' ,margin : '0px',cursor : 'pointer'}}>
        <input name={name} className='custom-file-updated-input form-control form-control-sm' type="file" accept="image/*" hidden="hidden" onChange={(e) => { onChange(e) }}/>
        </label>
      </button>
        {/* validateFileType(e,setImageFiles,name); props.setImageSelected(true);  */}
        {/* <div className="custom-file-updated-message">{(!props.item.SID)?(!value?" ":((value.split('.').pop().toLowerCase()==="jpg" || value.split('.').pop().toLowerCase()==="jpeg" || value.split('.').pop().toLowerCase()==="png")?
      <p className="custom-file-updated-message-passed">{lang.file_selected_label}</p>:<p className="custom-file-updated-message-failed">{lang.only_jpg_jpeg_and_png_files_are_allowed_msg}</p>)):(oldValueChange?(value.split('.').pop().toLowerCase()==="jpg" || value.split('.').pop().toLowerCase()==="jpeg" || value.split('.').pop().toLowerCase()==="png")?
      <p className="custom-file-updated-message-passed">{lang.file_selected_label}</p>:<p className="custom-file-updated-message-failed">{lang.only_jpg_jpeg_and_png_files_are_allowed_msg}</p>:"")
  }</div> */}
      </div>
      <p className=" text-muted" style={{ fontSize: "10px" }}>{lang.poster_note_image_size_label}</p>
      {openImageBank && <CollectionSelection entityname={ENTITYNAME.OttPosters} customColumns={OttPosterCollectionSelectionColumns} title={lang.select_url_tooltip} closeForm={() => setOpenImageBank(!openImageBank)} wherestatement={props.wherestatement ?? ""} setDataList={handleImageSelect} mode={"single"} width={"50vw"} height={"64vh"} />}

    </>)
  };