import { utility } from "../../../framework/utility/utilityProvider";
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE, PLANNINGEVENTSTYLE, PLANNINGTYPE, PLATFORMTYPE, SLOT_DIVISIONS, SLOT_TIMES, TX_MODE, TX_STATUS } from "../../../framework/constant/constant";
import * as API from '../../../framework/API/api';

export const PlanningHelper = {};

const MILLISECONDSINADAY = 86400000;



//IF ANY CHANGES HERE MAKE CHANGES IN BACKEND CODE AS WELL IN (getPlanningColorPreference func --> planningHelper)
PlanningHelper.getPlanningColorPreference = (dataItem) => {

  var localStorage = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  var preferenceData = localStorage && localStorage.data && localStorage.data.length > 0 ? localStorage.data[0] : null;

  //if preferenceData in null, picking default value from constant
  if (preferenceData == undefined || Object.keys(preferenceData).length == 0) {
    preferenceData = { value: { EventStyle: PLANNINGEVENTSTYLE } };
  }

  var style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Primary');

  //1st PRIORTY (TXSTATUS == LIVE || DELAYED || REPEAT) --PREFERENCE

  if (dataItem?.TXStatus && dataItem?.TXStatus?.SID == TX_STATUS.Live) {

    style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Live Events');
    return { backgroundColor: style?.BackgroundColor, color: style?.ForegroundColor };

  } else if (dataItem?.TXStatus && dataItem?.TXStatus?.SID == TX_STATUS.SDD) {

    style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'SDD');
    return { backgroundColor: style?.BackgroundColor ?? "#669999", color: style?.ForegroundColor ?? "#000000" };

  } else if (dataItem?.TXStatus && dataItem?.TXStatus?.SID == TX_STATUS.Repeat) {

    style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Repeat');
    return { backgroundColor: style?.BackgroundColor, color: style?.ForegroundColor };

  }


  // preferenceData = preferenceData.value.IsGenreColorPreference ? 
  let genreColorPreferenceEnabled = preferenceData.value.IsGenreColorPreference ?? false;
  let genreObj = genreColorPreferenceEnabled ?
    dataItem?.mediaEpisode?.Genres && dataItem?.mediaEpisode?.Genres.length > 0 ? dataItem?.mediaEpisode?.Genres[0] : {} :
    {};

  let genreStyle = {};
  if (genreColorPreferenceEnabled && Object.keys(genreObj).length > 0) {
    genreStyle = preferenceData.value.GenreStyle.find((obj) => obj.genre.SID == genreObj.SID)
  }

  // Default Primary
  var planningType = dataItem.PlanningType ?? PLANNINGTYPE.Primary;


  // for real time reflection of invalid color as per discussion with harsh sir.
  // not doing same for valid because then primary repeat premier case will lose.
  // By Vishal 19-07-23
  if (dataItem.MediaDealRight) {
    if (dataItem.SlotDateTime < dataItem.MediaDealRight.PublishStartDate) planningType = PLANNINGTYPE.DealDateNotStarted;
    else if (dataItem.SlotDateTime > (dataItem.MediaDealRight.PublishEndDate + MILLISECONDSINADAY)) planningType = PLANNINGTYPE.DealDateExpired;

  }

  //2nd PRIORTY -- GENRE PREFERENCE
  if (genreStyle && Object.keys(genreStyle).length > 0) {
    return { backgroundColor: genreStyle.BackgroundColor, color: genreStyle.ForegroundColor };
  }


  //TXSTATUS == TX 3rd PRIORTY
  switch (planningType) {
    case PLANNINGTYPE.Premier:
      style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Premier');
      break;
    case PLANNINGTYPE.Repeat:
      style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Repeat');
      break;
    case PLANNINGTYPE.ExhibitionsExceeded:
      style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Invalid');
      break;
    case PLANNINGTYPE.RepeatsExceeded:
      style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Invalid');
      break;
    case PLANNINGTYPE.DealDateNotStarted:
      style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Invalid');
      break;
    case PLANNINGTYPE.DealDateExpired:
      style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Invalid');
      break;
    case PLANNINGTYPE.DealBlackout:
      style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Invalid');
      break;

    default:
      style = preferenceData.value.EventStyle.find((obj) => obj.EventType == 'Primary');
      break;
  }

  return { backgroundColor: style.BackgroundColor, color: style.ForegroundColor };

}


PlanningHelper.getColumnPreference = () => {

  var columnsPreference = [];

  var localStorage = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  var preferenceData = localStorage && localStorage.data && localStorage.data.length > 0 ? localStorage.data[0] : null;

  preferenceData?.value.SelectedColumns.map((obj) => {
    if (obj.name.includes('.')) {
      var keyValue = obj.name.split('.');
      var dataItem = {
        ...obj,
        key: keyValue[0],
        name: keyValue[1]
      }
      columnsPreference.push(dataItem)
    } else {
      var dataItem = {
        ...obj,
        name: obj.name
      }
      columnsPreference.push(dataItem)
    }
  })
  return columnsPreference;

}


PlanningHelper.getInvalidReason = (dataItem) => {

  // Default Blank
  const planningType = dataItem.PlanningType ?? PLANNINGTYPE.Primary;
  var invalidReason = "";

  if (planningType == PLANNINGTYPE.DealDateExpired) invalidReason = "Deal Date Expired";
  else if (planningType == PLANNINGTYPE.DealDateNotStarted) invalidReason = "Deal Date Not Started";
  else if (planningType == PLANNINGTYPE.ExhibitionsExceeded) invalidReason = "Exhibitions Exceeded";
  else if (planningType == PLANNINGTYPE.RepeatsExceeded) invalidReason = "Repeats Exceeded";

  // console.log(dataItem.mediaEpisode.Title);
  // console.log(planningType);
  // console.log(invalidReason);
  return invalidReason;

}

PlanningHelper.setPlanningPublishDataInLocalStorage = (data) => {
  utility.deleteSessionStorageItem(LOCALSTORAGE_KEY.planningPublishData);
  utility.setSessionValue(LOCALSTORAGE_KEY.planningPublishData, data);
}


PlanningHelper.getMediaDealRight = (mediaEpisode, startDate, selectedChannel) => {
  console.log(mediaEpisode.MediaDealRights);

  //if there is no media right then it would return null
  if (mediaEpisode.MediaDealRights.length == 0) {
    return null;
  }

  //-------------------------------------------------
  // filtering by channel only
  var validMediaDealRight = mediaEpisode.MediaDealRights.filter(x => x.Channel.some(c => c.SID == selectedChannel.SID));

  //if there is only one media right then, it will retuen first media and user dont need to select the media rights 
  if (validMediaDealRight.length == 1) return validMediaDealRight[0];


  //-------------------------------------------------
  // filtering by date & channel both
  validMediaDealRight = mediaEpisode.MediaDealRights.filter(x => x.PublishStartDate <= startDate.getTime() && (x.PublishEndDate + MILLISECONDSINADAY) >= startDate.getTime() && x.Channel.some(c => c.SID == selectedChannel.SID));

  //if there is only one media right then, it will retuen first media and user dont need to select the media rights 
  if (validMediaDealRight.length == 1) return validMediaDealRight[0];
  //It means there are one  media rights so false has been returned to not do add directlt show popup 
  else return false;
}



PlanningHelper.prepareDataforbackend = (data) => {

  return {

    SID: data.SID,
    Channel: { _id: data.Channel._id, SID: data.Channel.SID, FullChannelName: data.Channel.FullChannelName },
    MediaEpisode_id: data.MediaEpisode_id,
    SegmentDuration: data?.mediaEpisode?.Duration ?? 0,
    ScheduleDate: data.ScheduleDate,
    SlotDateTime: data.SlotDateTime,
    Segment_id: data.Segment_id,
    ParentProgramSchedule_id: data.ParentProgramSchedule_id,
    IsValid: data.IsValid,
    MediaDealRight_id: data.MediaDealRight_id,
    ExhibitionNumber: data.ExhibitionNumber,
    RepeatNumber: data.RepeatNumber,
    PlanningType: data.PlanningType,
    InvalidReason: data.InvalidReason,
    customTitle: data.customTitle ?? '',
    customDuration: data.customDuration ?? 0,
    TXStatus: data.TXStatus ?? { SID: 3, Description: 'Transmission' }
  };
}

PlanningHelper.getPlanningDropTime = (offSetHours, startTimeInMs) => {

  let startMilisecond = offSetHours * 3600000;
  return utility.convertMilisecondsToFormattedDateTimeString(parseInt(startTimeInMs) + startMilisecond);
}


//need to check this 
PlanningHelper.isMediaEpisodeValidForThisChannel = (dragItem, channel) => {


  if (dragItem && dragItem.MediaDealRights && dragItem.MediaDealRights.length != 0) {

    console.log(dragItem.MediaDealRights);


    var linearTvPublishings = dragItem.MediaDealRights.filter((item) => item.PlatformType.SID == PLATFORMTYPE[0].SID); // use enum

    console.log(linearTvPublishings);
    if (linearTvPublishings.length > 0) {


      // if rights are given for linear tv then we will filter for current channel
      var currentChannelRIghts = linearTvPublishings.filter((item) => item.Channel.filter((o) => o.SID == channel.SID).length != 0);
      if (currentChannelRIghts.length == 0) {

        return false;
      }
    }
  }
  return true;
}

PlanningHelper.getMediaEpisodeDealRights = async (mediaEpisode) => {

  var mediaDealRightsRes = await API.getData(ENTITYNAME.MediaDealRights, { query: [["media_id", "=", mediaEpisode._id,], ["PlatformType.SID", '=', PLATFORMTYPE[0].SID]] })

  if (mediaDealRightsRes.success) {
    return mediaDealRightsRes.data;
  }

  return [];
}

PlanningHelper.isValidLiveEvent = async (mediaEpisode, planningStartDate) => {

  console.log('here in isValidLiveEvent');
  console.log(mediaEpisode)
  console.log(planningStartDate);

  let mediaEpisodeDetailLiveEvent = mediaEpisode.mediaepisodedetailliveevent;

  ///TODO : FETCH MEDIA EPISODE DETAIL LIVE EVENT IN INTERNAL DRAG DROP CASE
  if (mediaEpisodeDetailLiveEvent == undefined) {
    console.log('fetching')
    let response = await API.getData(ENTITYNAME.MediaEpisodeDetailLiveEvent, { query: ['MediaEpisode_id', '=', mediaEpisode._id] })

    if (response.success && response.data.length > 0) {
      console.log(response.data[0])

      mediaEpisodeDetailLiveEvent = response.data[0];
      return PlanningHelper.validationForLiveEvent(mediaEpisodeDetailLiveEvent, planningStartDate)

    }

  } else {
    return PlanningHelper.validationForLiveEvent(mediaEpisodeDetailLiveEvent, planningStartDate);
  }

}

PlanningHelper.validationForLiveEvent = (mediaEpisodeDetailLiveEvent, planningStartDate) => {

  console.log('here validationForLiveEvent')
  console.log(mediaEpisodeDetailLiveEvent.StartDateTime);

  const currentDateInMillisec = utility.getLocalDateTimeToUtcMiliseconds(new Date());

  // if(mediaEpisodeDetailLiveEvent.IsScheduled){
  //   return { isValid : false ,message : "Live Event Already Scheduled" ,confirmPopup : false,saveLog : false };
  // }

  //CANNOT SCHEDULE MATCH IF LIVE EVENT START DATE TIME PASSED CURRENT DATE
  // if (currentDateInMillisec > mediaEpisodeDetailLiveEvent.StartDateTime){
  //   let msg = mediaEpisodeDetailLiveEvent.Type?.Description == "Live"
  //     ? `Cannot schedule ${mediaEpisodeDetailLiveEvent.Type.Description}, schedule date already passed`
  //     : `Cannot schedule ${mediaEpisodeDetailLiveEvent.Type.Description} before current date `;
  //   return { isValid : false ,message : msg, confirmPopup : false, saveLog : false };
  // }


  //CANNOT SCHEDULE MATCH BEFORE LIVE DATE
  if (planningStartDate < mediaEpisodeDetailLiveEvent.StartDateTime) {
    return { isValid: false, message: `Cannot schedule ${mediaEpisodeDetailLiveEvent.Type?.Description} before live start date`, confirmPopup: true, saveLog: false };
  }

  //ASK CONFIRMATION FOR LIVE EVENT ITEM DROP ON WRONG DATE TIME
  if (mediaEpisodeDetailLiveEvent.StartDateTime != planningStartDate) {
    return { isValid: false, message: `Can only be live on ${utility.convertMilisecondsToDateTimeString(mediaEpisodeDetailLiveEvent.StartDateTime)}`, confirmPopup: false, saveLog: true };
  }

  return { isValid: true, message: "", confirmPopup: false, saveLog: false };
}


PlanningHelper.loadPlanningPrefrence = async () => {

  var localStorage = utility.getValue(LOCALSTORAGE_KEY.planningpreference);

  // if planning preference is lost on refresh, we will re fetch it
  if (!localStorage || !localStorage.data || localStorage.data.length == 0) {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);

    // resetting local storage of preference
    utility.deleteLocalStorageItem(LOCALSTORAGE_KEY.planningpreference)
    await API.getDataLookup(ENTITYNAME.UserPreference, {
      query: [["entityName", "=", "planningpreference"], ["user_id", "=", user._id]],
      sort: { SID: 1 }, page: null, perPage: null
    }, LOCALSTORAGE_KEY.planningpreference);
  }
}

PlanningHelper.saveScheduleEntity = (mediaEpisode, selectedChannel, MediaDealRight, startDate, offSetTime, slotDuration, customTitle = '', customDuration = 0, segment_id = null, txStatus = { SID: 3, Description: 'Transmission' }) => {
  //start date should be in date format
  let dur = PlanningHelper.getRoundOffDuration(mediaEpisode.Duration, PlanningHelper.getRoundOffMultiplesBySlotDuration(slotDuration));
  let endDate = new Date(startDate.getTime() + parseInt(dur));
  endDate.setSeconds(0, 0); // removing seconds and milliseconds to capture block

  return {
    SID: 0,
    Temp_id: mediaEpisode._id.toString() + startDate.getTime().toString(),
    Channel: selectedChannel,
    MediaEpisode_id: mediaEpisode._id,
    ScheduleDate: startDate.getTime() + offSetTime,
    SlotDateTime: startDate.getTime() + offSetTime,
    Segment_id: segment_id,
    ParentProgramSchedule_id: null,
    ActualDuration: mediaEpisode.Duration,
    NominalDuration: mediaEpisode.Duration,
    IsValid: PlanningHelper.isMediaEpisodeValidForThisChannel(mediaEpisode, selectedChannel),
    MediaDealRight_id: MediaDealRight?._id ?? null,
    ExhibitionNumber: 0,
    RepeatNumber: 0,
    PlanningType: PLANNINGTYPE.Primary,
    InvalidReason: "",
    Description: mediaEpisode.Description,
    ChannelSID: selectedChannel.SID,
    mediaEpisode: mediaEpisode,
    Title: mediaEpisode.Title,
    StartDate: startDate,
    EndTime: endDate,
    customTitle: customTitle,
    customDuration: customDuration,
    TXStatus: txStatus
  }
}

PlanningHelper.getSelectedDurationType = (dataitem, durationType, slotDuration) => {

  console.log(durationType);

  let finalNominalDuration = dataitem.customDuration && dataitem.customDuration > 0 ? dataitem.customDuration : dataitem.NominalDuration;

  var dur = durationType == "ActualDuration" ? (dataitem.ActualDuration ?? finalNominalDuration) : finalNominalDuration

  return dur;

  // no need to round of now, adaptive height implemented

  // round off apply
  // return PlanningHelper.getRoundOffDuration(dur, PlanningHelper.getRoundOffMultiplesBySlotDuration(slotDuration));
}

PlanningHelper.getSlotTimeBySelectedStartType = (dataitem, startType, offSetTime) => {

  return startType == "Nominal" ? new Date(dataitem.ScheduleDate - offSetTime) : new Date(dataitem.SlotDateTime - offSetTime);

}

PlanningHelper.getRoundOffDuration = (duration, multiples = 5) => {

  // if duration in minutes is less than block size, do not minify
  const durInMin = duration / 60000;
  if (durInMin <= multiples) return duration;

  var milli = parseInt(duration);
  const date = new Date(milli)
  var hours = date.getUTCHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

  // round off minutes
  var extraminutues = (date.getUTCMinutes()) % multiples;
  const finalMinutes = ((date.getUTCMinutes()) - extraminutues).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

  return utility.convertStringWithFramesToMilliseconds(hours + ':' + finalMinutes + ':00:00');
}

PlanningHelper.getRoundOffMultiplesBySlotDuration = (slotDuration) => {

  const slotTimeindex = SLOT_TIMES.indexOf(slotDuration);
  return slotDuration / (SLOT_DIVISIONS[slotTimeindex]);

}

PlanningHelper.isValidDisplaySlotDateTime = (currentSlotStartTime, currentSlotEndTime, visibleStartTime, visibleEndTime) => {

  const appconfig = utility.getValue(LOCALSTORAGE_KEY.applicationConfiguration);
  const loadCompletePlanningGrid = (appconfig?.length > 0 && appconfig?.find(x => x?.Key == "loadCompletePlanningGrid")?.Value) ?? false;
  if(loadCompletePlanningGrid)return true;


  visibleEndTime = visibleEndTime < visibleStartTime ? 24 + visibleEndTime : visibleEndTime;
  let startHours = new Date(currentSlotStartTime).getUTCHours();
  let endHours = new Date(currentSlotEndTime).getUTCHours();
  endHours = endHours < startHours ? 24 + endHours : endHours

  // loading extra area -+ 1 Hour improves experience
  visibleStartTime = visibleStartTime >= 1 ? visibleStartTime - 1 : visibleStartTime;
  visibleEndTime = visibleEndTime <= 23 ? visibleEndTime + 1 : visibleEndTime;

  if (
    (startHours <= visibleStartTime && endHours >= visibleEndTime) ||
    (startHours >= visibleStartTime && startHours <= visibleEndTime) ||
    (endHours >= visibleStartTime && endHours <= visibleEndTime) ||
    (startHours >= visibleStartTime && endHours <= visibleEndTime)
  ) {
    return true;
  }
  else {
    return false;
  }
}

PlanningHelper.getDisplayData = (planningData, startDateTime, endDateTime) => {

  // let newDisplayData = planningData.filter((x) => PlanningHelper.isValidDisplaySlotDateTime(x.StartDate, x.EndTime, startDateTime, endDateTime));

  return [...planningData];

}

