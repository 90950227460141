/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback, startTransition } from "react";
import * as API from "../../framework/API/api"
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Splitter } from "@progress/kendo-react-layout";
import { utility } from "../../framework/utility/utilityProvider";
import { AUTOMATION, ENTITYNAME, FILEURL, LOCALSTORAGE_KEY, MEDIACATEGORIES, MODULE, OFFSET, PROTOTYPEMEDIACATEGORYGROUP_ENUM, REPLACEMENTMODE, SCHEDULECOLORPREFERENCE, SCHEDULETYPE, SEGMENTPOSITIONS_ENUM } from "../../framework/constant/constant";
import { getter, orderBy } from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn as Column, getSelectedState,
} from "@progress/kendo-react-grid";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { ScheduleMediaCollection } from "./ScheduleMediaCollection";
import { ScheduleBookedCommercialCollection } from "./ScheduleBookedCommercialCollection";
import { ScheduleTypeCell } from "../../framework/forms/helpercomponents/CustomGridCells/ScheduleTypeCell";
import { ContextMenu } from 'primereact/contextmenu';
import { ScheduleHeader } from "./ScheduleHeader";
import { Collection } from '../../framework/forms/Collection';
import SecondaryEventIcon from '@mui/icons-material/Image';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SecondaryEventPrototype from "../SecondaryEvent/SecondaryEventProtoType";
import ScheduleFooter from "./ScheduleFooter";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { SchedulePrototypeOverview } from "./SchedulePrototypeOverview";
import PlanningEditForm from "../Planning/PlanningEditForm";
import { SchedulePreference } from "./SchedulePreference"
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import GlobalReplacement from "./GlobalReplacement";
import MediaEpisodeHistory from "../Planning/MediaEpisodeHistory";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "../../locale/useTranslation";
import { SchedulingHelper } from "./helper/SchedulingHelper";
import { UserPreference } from "../userPreference/UserPreference";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import CheckLists from "../CheckList/CheckLists";
/*
onDrop --header and main grid data
handleDropOnChildrenGrid -- 1st children drop [segment,commercial,prototype]
if there is drop on first children then it is handle with he main onDrop fumction only
if it is prototype hen handleprototype function
src drop -> 1. media - onDrop() ( header and first children )
            2. booking onDrop() (with additional bookinId) 28 Jan//vishal Todo: MediaEpisode and booking object should be different
            3. prototype handleDropPrototype() (only on first children ) todo: on breackChildren as well
            4. internal onDrop()
*/

const Scheduler = (props) => {


    const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
    const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
    const offsetTime = offSetHours * 3600000
    const DATA_ITEM_KEY = '_id';
    const SELECTED_FIELD = 'selected';
    const SELECTED_HEADER = 'isHeaderSelected';
    const editField = "inEdit";
    const IS_BREAK = 'IsBreak';
    const UNDERRUN = 'UR';
    const OVERRUN = 'OR';
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const defaultTabNumber = utility.hasModuleRight(MODULE.SCHEDULE_MEDIA) ? "1" : utility.hasModuleRight(MODULE.SCHEDULE_BOOKED_COMMERCIAL) ? "2" : "3";
    const defaultShowFilter = utility.getSeassionValue(LOCALSTORAGE_KEY.publishData) === undefined || utility.getSeassionValue(LOCALSTORAGE_KEY.publishData) === null;


    //location helps us to pass state in Link component of react-dom-router    
    const [selectedStateForBookedComercial, setSelectedStateForBookedComercial] = useState([]);
    const [bookedCommercialSpots, setBookedCommercialSpots] = useState([]);
    const [pendingBookedCommercialSpots, setPendingBookedCommercialSpots] = useState([]);
    const [showFilter, setShowFilter] = useState(defaultShowFilter);
    const [ChannelList, setChannelList] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState({ name: "Select Channel ...", SID: -1 });
    const [date, setDate] = useState(new Date());
    const [deletedSchedules, setDeletedSchedules] = useState([]);
    const [isAutoSave, setIsAutoSave] = useState(true);
    const [saveDataArray, setSaveDataArray] = useState([]);
    const [tabNumber, setTabNumber] = useState(defaultTabNumber);
    const [categories, setCategories] = useState([]);// for grid expand change
    const [scheduleInfo, setScheduleInfo] = useState({
        parentCount: 0,
        commercialCount: 0,
        overRunUnderRunCount: 0,
        playlistStartTime: 0,
        playlistEndTime: 0
    });
    const navigate = useNavigate();
    const setStatusMessage = (message) => { props.setStatusMessage(message) };
    const [styleSheetSelectionOpen, setStyleSheetSelectionOpen] = useState(false);
    const [showSecondaryEventOverviewDialog, setShowSecondaryEventOverviewDialog] = useState(false);
    const [showPlanningEditForm, setShowPlanningEditForm] = useState(false);
    const [schedulePreference, setschedulePreference] = useState(false);
    const [selectedHeader, setSelectedHeader] = useState({});
    const [selectedHeadersDataItems, setSelectedHeadersDataItems] = useState([]);
    const [selectedAllHeaders, setSelectedAllHeaders] = useState(false);
    const [mediaCategoryTypePreferenceData, setMediaCategoryTypePreferenceData] = useState([]);
    const [lastSearchKeywordInBookedCommercial, setLastSearchKeywordInBookedCommercial] = useState("");
    const [showFilterGrid, setShowFilterGrid] = useState(false);
    const [showFilterGridData, setShowFilterGridData] = useState([]);
    const [showInsertPlaceHolderFilter, setShowInsertPlaceHolderFilter] = useState(false);
    const [loopBreak, setLoopBreak] = useState(false);
    const [searchWord, setSearchWord] = useState('false');
    const [showProgramReplacementDialog, setShowProgramReplacementDialog] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const schedulerChildrenFilterRef = useRef();
    const dataItemForContextMenuRef = useRef('');
    const [showGlobalReplacementDialog, setShowGlobalReplacementDialog] = useState(false);
    const [showCheckList, setShowCheckList] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);
    const [preferenceFilter, setPreferenceFilter] = useState(false);


    //state for schedule lock
    const [allowModification, setAllowModification] = useState(true);

    ////////////////////////////////////////
    const [schedulerData, setSchedulerData] = useState([]);
    const schedulerDataRef = useRef(schedulerData);
    const schedulerDataRefSetter = (data) => {
        schedulerDataRef.current = data;
        setSchedulerData(data);
    }

    const [selectedState, setSelectedState] = useState([]);//children multiple selection grid
    const selectedStateRef = useRef(selectedState);
    const selectedStateRefSetter = (data) => {
        selectedStateRef.current = data;
        setSelectedState(data);
        props.setSelectedState(data);
    }

    const cutPasteDataItemRef = useRef([]);
    const cutPasteDataItemRefSetter = (data) => {
        cutPasteDataItemRef.current = data;
        props.setCutPasteDataItem(data.length);
    }

    const copyPasteDataItemRef = useRef([]);
    const copyPasteDataItemSetter = (data) => {
        copyPasteDataItemRef.current = data;
        props.setCopyPasteDataItem(data.length);
    }
    const lang = useTranslation();


    useEffect(() => {

        //filter channel data
        API.getDataLookup(ENTITYNAME.Channel).then(json => {
            setChannelList(json.data.map(d => ({ name: d.FullChannelName, SID: d.SID, Color: d.Color })));
            json.data.length == 1 && setSelectedChannel({ name: json.data[0].FullChannelName, SID: json.data[0].SID, Color: json.data[0].Color })
        });

        // loading preference category
        loadMediaCategoryTypeForSchedulePreference();

        setScheduleColorPreference();


        if (!defaultShowFilter) {
            // Setting Schedule Filter Payload
            setScheduleLoadPayloadFromPlanning();

            // load main schedule
            console.log("loaddata  new called");
            loaddataNew();
        }


        document.addEventListener("copy", handleCopyByKeyboard);
        document.addEventListener("cut", handleCutByKeyboard);
        document.addEventListener("paste", handlePasteByKeyboard);
        document.addEventListener("keyup", handleDeleteByKeyboard);
        return () => {
            document.removeEventListener('copy', handleCopyByKeyboard);
            document.removeEventListener('cut', handleCutByKeyboard);
            document.removeEventListener('paste', handlePasteByKeyboard);
            document.removeEventListener('keyup', handleDeleteByKeyboard);
        }

    }, []);


    const onStyleSheetSelection = () => {
        console.log(selectedHeadersDataItems);
        if (selectedHeadersDataItems.length != 0) {
            styleSheetSelectionOpen ? setStyleSheetSelectionOpen(false) : setStyleSheetSelectionOpen(true);
        } else {
            toast.error('Select atleast one header', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onSchedulePreference = () => {
        setschedulePreference(() => !schedulePreference);
    }

    const onStyleSheetSelectionClose = (styleSheets) => {
        applyStyleSheet(styleSheets)
    }

    const onCheckList = () => {
        setShowCheckList(() => !showCheckList);
    }

    const hcm = useRef();//FOR SCHEDULER RIGHT CLICK ON HEADER: CONTEXT MENU
    const cm = useRef();//FOR SCHEDULER RIGHT CLICK ON CHILDREN ELEMENT : CONTEXT MENU
    const bc = useRef();//FOR SCHEDULER break children right Click

    const se = useRef();//FOR SCHEDULER Secondary Event right Click
    const bookedCommercialCollectionCm = useRef();//FOR BOOKED COMMERCIAL COLLECTION RIGHT CLICK : CONTEXT MENU
    const bookedCommercialCollectionRef = useRef();

    const payloadref = useRef();//added for bookingcommercial : used in refreshbookingcommercial


    const resetScheduleGrid = () => {

        setShowFilterGrid(false);
        setSaveDataArray([]);
        setDeletedSchedules([]);
        cutPasteDataItemRefSetter([]);
        copyPasteDataItemSetter([]);

    }

    const setScheduleMainGridColumns = async () => {

        var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        var columns = await API.getFormData(ENTITYNAME.SCHEDULE_MAIN_GRID, currentUserId);
        setDataColumns(columns);
        console.log("coluns finish");

    }

    const setScheduleLoadPayloadFromPlanning = async () => {

        var dataFromPlanning = utility.getSeassionValue(LOCALSTORAGE_KEY.publishData);
        utility.deleteSessionStorageItem(LOCALSTORAGE_KEY.publishData)

        if (dataFromPlanning) {
            const ScheduleDate = new Date(moment(new Date(dataFromPlanning.SlotDateTime - offsetTime)).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })).getTime() + offsetTime;
            const dateWithoutTime = utility.removeTimeFromDateTime(new Date(ScheduleDate - offsetTime));

            payloadref.current = {
                BookingDate: ScheduleDate - offsetTime,
                ScheduleDate: ScheduleDate,
                ScheduleEndDate: utility.addTimeToDateInMilliseconds(ScheduleDate, 23, 59, 59, 999),
                channelSID: dataFromPlanning.Channel.SID
            }

            setSelectedChannel(dataFromPlanning.Channel)
            setDate(() => dateWithoutTime);

        }

    }

    const setScheduleLoadPayloadFromFilter = async (selecteddate = date) => {

        var scheduleStart = utility.getLocalDateTimeToUtcMiliseconds(selecteddate) + offsetTime;

        payloadref.current = {
            BookingDate: scheduleStart - offsetTime,
            ScheduleDate: scheduleStart,
            ScheduleEndDate: utility.addTimeToDateInMilliseconds(scheduleStart, 23, 59, 59, 999),
            channelSID: selectedChannel.SID
        }

    }

    const setScheduleColorPreference = async () => {

        await API.getDataLookup(ENTITYNAME.UserPreference, {
            query: [["entityName", "=", "schedulepreference"], ["user_id", "=", user._id]],
            sort: { SID: 1 },
            page: null,
            perPage: null
        }, LOCALSTORAGE_KEY.schedulepreference);
    }


    const loaddataNew = async () => {
        try {

            resetScheduleGrid();

            if (!payloadref.current) {
                toast.error("Missing Payload", { position: toast.POSITION.TOP_RIGHT });
                return;
            }

            // Setting Schedule Main Grid Columns
            await setScheduleMainGridColumns();

            setStatusMessage(() => "Loading Schedule, Please wait...")
            const json = await API.getScheduling(payloadref.current);
            setStatusMessage(() => "Loading Schedule Completed !!")

            console.log(json);

            if (json.success == false) {
                toast.error(json.message, { position: toast.POSITION.TOP_RIGHT });
                return;
            }

            await loadBookingCommercialdata(payloadref.current.BookingDate, payloadref.current.channelSID);
            setTabNumber(defaultTabNumber); // intentionally done as booking tab is not reflecting on real time date change.


            var parentData = json.data.filter(data => {
                if (!data.ParentProgramSchedule_id) {
                    return data;
                }
            });

            var orderedParent = parentData.sort((a, b) => a.SlotDateTime - b.SlotDateTime);

            var finalData = [];

            for (var i = 0; i < orderedParent.length; i++) {
                var parent = orderedParent[i];
                var style = SchedulingHelper.getScheduleColorPreference(parent);
                parent.Style = style;
                // 1st level children
                var childrens = json.data.filter(maindata => maindata.ParentProgramSchedule_id == parent._id).sort((a, b) => a.SlotDateTime - b.SlotDateTime);

                var rippledChildrens = getRippleChildrens(parent, childrens, orderedParent);

                parent.Info = '';
                let lastRippleChildren = rippledChildrens[rippledChildrens.length - 1];
                if (lastRippleChildren?._id.includes('footer')) {
                    parent.Info = lastRippleChildren.Type == SCHEDULETYPE.OverRun ? OVERRUN + '-' + utility.convertMilisecondsToStringWithFrames(lastRippleChildren.Duration) : UNDERRUN + '-' + utility.convertMilisecondsToStringWithFrames(lastRippleChildren.Duration);
                }

                // 2nd level children
                var childrensWithBreakChildrens = rippledChildrens.map(child => {
                    // if child is break
                    if (child[IS_BREAK]) {
                        var breakChildrens = json.data.filter(breakChild => breakChild.ParentProgramSchedule_id == child._id).sort((a, b) => a.SlotDateTime - b.SlotDateTime);
                        breakChildrens = getRippleBreakChildrens(child, breakChildrens);

                        let lastBreakRippleChildren = breakChildrens[breakChildrens.length - 1];
                        child.Info = '';
                        if (lastBreakRippleChildren?._id.includes('footer')) {
                            child.Info = lastBreakRippleChildren.Type == SCHEDULETYPE.OverRun ? OVERRUN + '-' + utility.convertMilisecondsToStringWithFrames(lastBreakRippleChildren.Duration) : UNDERRUN + '-' + utility.convertMilisecondsToStringWithFrames(lastBreakRippleChildren.Duration);
                        }

                        return { ...child, children: breakChildrens }
                    } else {
                        return child;
                    }

                });

                finalData.push({ ...parent, BreakNo: 0, children: childrensWithBreakChildrens });
            }

            console.log(finalData);
            updateCommercialOverRunUnderRunCounts(finalData)

            schedulerDataRefSetter(finalData)

            filterPendingBookedSpots();

        } catch (error) {
            console.log("error", error);
        }
    };


    const loadMediaCategoryTypeForSchedulePreference = async () => {
        let mediaCategoryTypeData = await SchedulingHelper.loadMediaCategoryTypeForSchedulePreference();
        setMediaCategoryTypePreferenceData(mediaCategoryTypeData);
    }

    const updateSchedulingPreferenceStyle = () => {


        const allParents = schedulerData.filter(data => {
            if (!data.ParentProgramSchedule_id) {
                return data;
            }
        });

        for (let index = 0; index < allParents.length; index++) {

            setSchedulerData((old) => {

                old[index].Style = SchedulingHelper.getScheduleColorPreference(old[index]);
                old[index].children = getRippleChildrens(old[index], old[index].children, old)

                return [...old]
            })

        }

    }

    const showSecondaryEventOverviewDialogProp = () => {

        try {

            if (selectedState.length == 0 || selectedState[0].Prototypes.length == 0) {
                toast.error("No Secondary Events", {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            setShowSecondaryEventOverviewDialog(old => !old)
        }
        catch (e) {
            console.log(e);
            toast.error("No Secondary Events", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const setShowPlanningEditFormDialog = () => {
        setShowPlanningEditForm(old => !old);

    }

    const updateCommercialOverRunUnderRunCounts = (finalData) => {
        setScheduleInfo(SchedulingHelper.getCommercialOverRunUnderRunCounts(finalData))
    }

    const refreshBookingCommercialdata = async () => {
        if (payloadref.current) await loadBookingCommercialdata(payloadref.current.BookingDate, payloadref.current.channelSID);
        filterPendingBookedSpots();
    }

    const loadBookingCommercialdata = async (BookingDate, ChannelSID) => {
        console.log("cominghere In Booked Commercials");
        let data = await SchedulingHelper.loadBookingCommercialdata(BookingDate, ChannelSID);
        if (data) {
            setBookedCommercialSpots(data);
            setPendingBookedCommercialSpots(data);
        }
    }

    const filterPendingBookedSpots = async (e) => {
        try {
            if (e) setLastSearchKeywordInBookedCommercial(() => e.target.value);
            var keyword = e ? e.target.value : lastSearchKeywordInBookedCommercial;

            // need to improve this logic
            var allChildren = [];
            schedulerData.map(a => a.children.map(x => {
                if ((x.Type ?? 0) > SCHEDULETYPE.Segment) {
                    allChildren.push(x);
                }
            }));


            var bookedSpots = allChildren.filter(a => a.Booking_id && a.Booking_id != "");

            console.log(bookedSpots)

            var chilrenInsideBreak = [];
            allChildren.map(a => {
                if (a[IS_BREAK]) {
                    a.children.map(x => {
                        if (x.Booking_id && x.Booking_id != "") {
                            chilrenInsideBreak.push(x);
                        }
                    })
                }
            });

            bookedSpots = [...bookedSpots, ...chilrenInsideBreak];

            var plottedBookedSpots = []
            for (var x = 0; x < bookedSpots.length; x++) {
                plottedBookedSpots.push(bookedSpots[x].Booking_id)
            }

            var pendingSpots = bookedCommercialSpots.filter((spot) => !plottedBookedSpots.includes(spot._id));

            if (keyword != "") pendingSpots = pendingSpots.filter((spot) => spot.Title.toLowerCase().includes(keyword.toLowerCase()) || spot.AssetId.toLowerCase().includes(keyword.toLowerCase()));

            setPendingBookedCommercialSpots(pendingSpots);

        } catch (error) {
            console.log("error", error);
        }
    }




    const getRippleChildrens = (parent, childrens, allParentsData) => {

        var startTime = parent.SlotDateTime;

        // removing under rover run if exists
        childrens = childrens.filter(data => {
            if (data.Type != SCHEDULETYPE.OverRun && data.Type != SCHEDULETYPE.UnderRun) {
                return data;
            }
        });

        var rippledChildren = childrens.map(item => {
            item.SlotDateTime = startTime;
            startTime = startTime + item.Duration;
            item.Prototypes = item.Prototypes ?? [];
            item.Style = SchedulingHelper.getScheduleColorPreference(item);
            return item
        })

        var nextParent = SchedulingHelper.getNextParent(parent, allParentsData);
        if (nextParent) {
            var footer = SchedulingHelper.getFooter(parent, rippledChildren, nextParent, selectedChannel)
            if (footer) {
                footer.Style = SchedulingHelper.getScheduleColorPreference(footer);
                rippledChildren.push(footer);
            }
        }



        return rippledChildren;
    }

    const childrenSave = async (parentIndex) => {

        let schedulerDataLocal = [...schedulerDataRef.current];

        let dataToSave = schedulerDataLocal[parentIndex].children;
        if (isAutoSave) {

            let response = await API.saveSchedule({
                schedules: dataToSave,
                deletedschedules: []
            });

            console.log(response);

            if (response.success) {

                response.data.savedschedules.map((res, i) => {
                    if (res.success && res.message == 'inserted') {
                        schedulerDataLocal[parentIndex].children[i] = res.data;
                    }

                });


            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }


        } else {
            setSaveDataArray((old) => {
                return [...old, ...dataToSave]
            })
        }

        schedulerDataRefSetter(schedulerDataLocal);
    }

    const breakChildrenSave = async (parentIndex, childIndex) => {

        let schedulerDataLocal = [...schedulerDataRef.current];

        let dataToSave = schedulerDataLocal[parentIndex].children[childIndex].children;
        if (isAutoSave) {

            let response = await API.saveSchedule({
                schedules: dataToSave,
                deletedschedules: []
            });

            console.log(response);

            if (response.success) {

                response.data.savedschedules.map((res, i) => {
                    if (res.success && res.message == 'inserted') {
                        schedulerDataLocal[parentIndex].children[childIndex].children[i] = res.data;
                    }

                });


            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }


        } else {
            setSaveDataArray((old) => {
                return [...old, ...dataToSave]
            })
        }

        schedulerDataRefSetter(schedulerDataLocal);
    }

    const addItemInSchedulerGrid = (parentIndex, dropIndex, dataToAdd) => {
        let schedulerDataLocal = [...schedulerDataRef.current];

        //adding
        schedulerDataLocal[parentIndex].children.splice(dropIndex, 0, ...dataToAdd);

        //rippling
        schedulerDataLocal[parentIndex].children = getRippleChildrens(schedulerDataLocal[parentIndex], schedulerDataLocal[parentIndex].children, schedulerDataLocal);

        schedulerDataRefSetter(schedulerDataLocal);
    }

    const addBreakItemInSchedulerGrid = (parentIndex, childIndex, dropIndex, dataToAdd) => {

        let schedulerDataLocal = [...schedulerDataRef.current];

        //adding
        schedulerDataLocal[parentIndex].children[childIndex].children.splice(dropIndex, 0, ...dataToAdd);

        //rippling
        schedulerDataLocal[parentIndex].children[childIndex].children = getRippleBreakChildrens(schedulerDataLocal[parentIndex].children[childIndex], schedulerDataLocal[parentIndex].children[childIndex].children);

        schedulerDataRefSetter(schedulerDataLocal)
    }


    //grid rowRender start==============================================

    //header level
    const GridRowRenderSchedule = (tr, props) => {
        const trProps = {
            // style: SCHEDULECOLORPREFERENCE.header,
            style: props.dataItem.Style,
            draggable: false,
            onDrop: (e) => OnDrop(e, props.dataItem),
            onContextMenu: (e) => {
                bc.current.hide(e);
                cm.current.hide(e);
                hcm.current.show(e);
                setSelectedHeader(props.dataItem); console.log(selectedHeader);
                dataItemForContextMenuRef.current = props.dataItem;
                selectedStateRefSetter([dataItemForContextMenuRef.current]);
            },
            onDragOver: e => { e.preventDefault() },
            onWheel: (e) => { hcm.current.hide(e); cm.current.hide(e); bc.current.hide(e) },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    //segment and promo level
    const GridRowRenderScheduleChildren = (tr, props) => {

        const trProps = {
            style: props.dataItem.Style,
            draggable: true,
            onDragStart: (e) => {
                var propsData = [props.dataItem];
                e.dataTransfer.clearData();
                e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.ProgramSchedule, propsData }));
            },
            onDrop: (e) => handleDropOnChildrenGrid(e, props.dataItem),
            onDragOver: (e) => { e.preventDefault() },
            onContextMenu: (e) => {
                hcm.current.hide(e);
                bc.current.hide(e);
                cm.current.show(e);
                schedulerChildrenFilterRef.current = props.children[e.target.cellIndex].props;
                dataItemForContextMenuRef.current = props.dataItem
                selectedStateRefSetter([dataItemForContextMenuRef.current]);
            },
            onWheel: (e) => { hcm.current.hide(e); cm.current.hide(e); bc.current.hide(e) },
        };
        if (!props.dataItem[IS_BREAK]) {
            var children = tr.props.children.map((d, i) => {
                if (i !== 0) {
                    return d;
                } else {
                    return <th className="k-header k-hierarchy-cell k-header">
                    </th>
                }
            })
            return React.cloneElement(tr, { ...trProps }, children);
        } else {

            return React.cloneElement(tr, { ...trProps }, tr.props.children);
        }
    };

    //break level
    const GridRowRenderScheduleBreakChildren = (tr, props) => {

        const trProps = {
            style: props.dataItem.Style,
            draggable: true,
            onDragStart: (e) => {
                const propsData = [props.dataItem]
                e.dataTransfer.clearData();
                e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.BREAK_CHILDREN, propsData }));
            },
            onDrop: (e) => {
                console.log(props.dataIndex);
                onDropBreakChildren(e, props.dataItem, props.dataIndex);
            },
            onDragOver: (e) => { e.preventDefault() },
            onContextMenu: (e) => {
                hcm.current.hide(e);
                cm.current.hide(e);
                bc.current.show(e);
                schedulerChildrenFilterRef.current = props.children[e.target.cellIndex].props;
                dataItemForContextMenuRef.current = props.dataItem
                selectedStateRefSetter([dataItemForContextMenuRef.current]);
            },
            onWheel: (e) => { hcm.current.hide(e); cm.current.hide(e); bc.current.hide(e) },
        };

        return React.cloneElement(tr, { ...trProps }, tr.props.children);


    };

    const GridRowRenderScheduleSecondaryEvents = (tr, props) => {

        var style = SCHEDULECOLORPREFERENCE.lightgrey;

        const trProps = {
            style: style,
            draggable: false,
            onContextMenu: (e) => { se.current.show(e); dataItemForContextMenuRef.current = props.dataItem; console.log(dataItemForContextMenuRef.current) },
            onWheel: (e) => { se.current.hide(e) }
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const GridRowRenderBookedCommercial = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                const propsData = [props.dataItem]

                // On Media Library Drag Start, if One Item Is Selected, We are setting drag item as selected item
                if (selectedStateForBookedComercial && selectedStateForBookedComercial.length == 1) {
                    setSelectedStateForBookedComercial(propsData);
                }

                e.dataTransfer.clearData();
                if (selectedStateForBookedComercial.length > 1) {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.Booking, propsData: selectedStateForBookedComercial }));

                } else {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.Booking, propsData }));
                }

            },

            onDragOver: e => { e.preventDefault() },
            onContextMenu: (e) => {
                bookedCommercialCollectionRef.current = props.dataItem;
                bookedCommercialCollectionCm.current.show(e);
            },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const GridRowRenderPrototypes = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                e.dataTransfer.clearData();
                e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.Prototype, dataItem: [props.dataItem] }));
                console.log(first)
            },
            // onDrop: (e) => handleDropPrototype(e, props.dataItem),
            onDragOver: e => { e.preventDefault() }
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    //grid rowRender end==============================================

    //drop start=====================================================
    // drop start=====================================

    //from header level
    const OnDrop = async (e, dataItem) => {

        /* expected sources - 
               1. ENTITYNAME.ProgramSchedule = internal drop
               2. ENTITYNAME.MediaEpisode = external drop from media
               3. ENTITYNAME.Booking = from booking
               4. ENTITYNAME.Prototype = from prototype secondary event & not allowed for header & seperately managed by handleDropPrototype()
       */

        const draggedData = JSON.parse(e.dataTransfer.getData("text"));
        const src = draggedData.src;
        var dragItem = draggedData.propsData;
        const isInternal = src == ENTITYNAME.ProgramSchedule;
        e.dataTransfer.clearData();

        //booking validation
        if (src == ENTITYNAME.Booking && !SchedulingHelper.validBookingDrop(dragItem[0], dataItem)) {

            toast.error('Not a valid range!', {
                position: toast.POSITION.TOP_RIGHT
            });

            return;

        }

        if (isValidDrop(src, dataItem, dragItem) == false) {
            return;
        }

        //checks if it is parent or child
        const isParent = dataItem.ParentProgramSchedule_id ? false : true;
        //stores parent id
        const parent_id = isParent ? dataItem._id : dataItem.ParentProgramSchedule_id;

        //data to save
        var dataToSaveInSchedulerGrid = dragItem.map(data => {
            return {
                BreakNo: isParent ? 0 : dataItem.BreakNo,
                // if internal drag then media episode id will be dragged mediaepisode id
                MediaEpisode_id: src == ENTITYNAME.MediaEpisode ? data._id : data.MediaEpisode_id,
                ParentProgramSchedule_id: parent_id,
                ScheduleDate: utility.getLocalDateTimeToUtcMiliseconds(date),
                SlotDateTime: dataItem.SlotDateTime + dataItem.Duration,
                Channel: selectedChannel,
                TcIn: data.TcIn,
                TcOut: data.TcOut,
                Type: 0,
                Booking_id: src == ENTITYNAME.Booking ? data._id : '',

                TimeRange: src == ENTITYNAME.Booking ? utility.convertMilisecondsToShortTimeString(data.TimeRangeFrom) +
                    " - " + utility.convertMilisecondsToShortTimeString(data.TimeRangeTo) : '',

                _id: isInternal || src == ENTITYNAME.BREAK_CHILDREN ? data._id : null,
                Duration: data.Duration,
                mediaEpisode: src == ENTITYNAME.MediaEpisode ? { ...data } : { ...data.mediaEpisode },
                [IS_BREAK]: data[IS_BREAK] ?? false,
                children: data[IS_BREAK] ? data.children : []

            };
        })


        if (src == ENTITYNAME.BREAK_CHILDREN) {

            //-----Deleting----------
            var { level_0_index, level_1_index, level_2_index } = SchedulingHelper.getBreakChildInfo(dragItem[0], schedulerDataRef.current);
            deleteBreakItemFromSchedulerGrid(level_0_index, level_1_index, level_2_index);
            //-----Deleted-----------     

            //-----Inserting---------                
            let { level_0_index: parentIndex, level_1_index: childIndex, isBreak, isHeader } = SchedulingHelper.getChildInfo(dataItem, schedulerDataRef.current);

            if (isBreak) {
                if (!dragItem.some(item => item.mediaEpisode.MediaCategory.SID == dataItem.mediaEpisode.MediaCategory.SID)) {
                    toast.error('Please choose right media category', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return;
                }

                dataToSaveInSchedulerGrid = dataToSaveInSchedulerGrid.map(saveData => {
                    saveData.ParentProgramSchedule_id = dataItem._id;
                    return saveData;
                });
                addBreakItemInSchedulerGrid(parentIndex, childIndex, 0, dataToSaveInSchedulerGrid)
            } else {
                let dropIndex = 0;
                if (!isHeader) {
                    dropIndex = childIndex + 1;
                }

                addItemInSchedulerGrid(parentIndex, dropIndex, dataToSaveInSchedulerGrid);
            }

            //-----Inserted----------

            //-----Saving------------
            breakChildrenSave(parentIndex, childIndex);
            childrenSave(level_0_index);
            //-----Saved-------------


        }
        //we need to save and delete dragged item from scheduler when dragged internally
        else if (isInternal) {


            //-----Deleting----------
            var { level_0_index, level_1_index } = SchedulingHelper.getChildInfo(dragItem[0], schedulerDataRef.current);
            deleteItemFromSchedulerGrid(level_0_index, level_1_index);
            //-----Deleted-----------   


            //-----Inserting---------                
            var { level_0_index: parentIndex, level_1_index: childIndex, isBreak, isHeader } = SchedulingHelper.getChildInfo(dataItem, schedulerDataRef.current);

            if (isBreak) {
                if (!dragItem.some(item => item.mediaEpisode.MediaCategory.SID == dataItem.mediaEpisode.MediaCategory.SID)) {
                    toast.error('Please choose right media category', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return;
                }


                dataToSaveInSchedulerGrid = dataToSaveInSchedulerGrid.map(saveData => {
                    saveData.ParentProgramSchedule_id = dataItem._id;
                    return saveData;
                });

                addBreakItemInSchedulerGrid(parentIndex, childIndex, 0, dataToSaveInSchedulerGrid)

            } else {
                let dropIndex = 0;
                if (!isHeader) {
                    dropIndex = childIndex + 1;
                }

                addItemInSchedulerGrid(parentIndex, dropIndex, dataToSaveInSchedulerGrid);
            }

            //-----Inserted----------

            //-----Saving------------
            if (level_0_index == parentIndex && !isBreak) {
                childrenSave(parentIndex);
            } else if (isBreak) {
                breakChildrenSave(parentIndex, childIndex);
                childrenSave(level_0_index);
            } else {
                childrenSave(parentIndex);
                childrenSave(level_0_index);
            }
            //-----Saved-------------

        }
        //external
        else {

            //-----Inserting---------                
            let { level_0_index: parentIndex, level_1_index: childIndex, isBreak, isHeader } = SchedulingHelper.getChildInfo(dataItem, schedulerDataRef.current);

            if (isBreak) {
                if (!dragItem.some(item => item.MediaCategory.SID == dataItem.mediaEpisode.MediaCategory.SID)) {
                    toast.error('Please choose right media category', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return;
                }
                dataToSaveInSchedulerGrid = dataToSaveInSchedulerGrid.map(saveData => {
                    saveData.ParentProgramSchedule_id = dataItem._id;
                    return saveData;
                });
                addBreakItemInSchedulerGrid(parentIndex, childIndex, 0, dataToSaveInSchedulerGrid)
            } else {
                let dropIndex = 0;
                if (!isHeader) {
                    dropIndex = childIndex + 1;
                }

                addItemInSchedulerGrid(parentIndex, dropIndex, dataToSaveInSchedulerGrid);
            }
            //-----Inserted----------
            console.log("filtering bpooked spotsddd");

            //-----Saving------------
            if (isBreak) {
                breakChildrenSave(parentIndex, childIndex);
            } else {
                childrenSave(parentIndex);
            }
            //-----Saved-------------

        }


        if (src == ENTITYNAME.Booking) {

            console.log("filtering bpooked spots");
            deleteDraggedItemFromBookingCollection(dragItem.map(data => data._id));
            // filterPendingBookedSpots();
        }

        updateCommercialOverRunUnderRunCounts(schedulerData);


    }

    //from segment and promo level
    const handleDropOnChildrenGrid = (e, dataItem) => {

        // if there is drop on first children then it is handle with he main onDrop fumction only
        //if it is prototype hen handleprototype function
        var dragItem = JSON.parse(e.dataTransfer.getData("text"));
        const src = dragItem.src;

        if (src == ENTITYNAME.Prototype) {
            handleDropPrototype(e, dataItem);
        }

        else {
            //for media and booked items
            OnDrop(e, dataItem)
        }
    }

    //from break level
    const onDropBreakChildren = async (e, dataItem, dropIndex) => {

        var dragItem = JSON.parse(e.dataTransfer.getData("text"));

        const src = dragItem.src;
        const isInternal = src == ENTITYNAME.BREAK_CHILDREN;//this means break children grid
        const isInternal_Grid = src == ENTITYNAME.ProgramSchedule;//this means programschedule grid


        if (dragItem.propsData) {
            dragItem = dragItem.propsData;
        } else if (src == ENTITYNAME.Booking) {
            dragItem = [{ ...dragItem }]
        }
        //if multiple items were dragged on selecting
        else {
            dragItem = dragItem.selectedState;
        }

        e.dataTransfer.clearData();

        if (src == ENTITYNAME.Booking && !SchedulingHelper.validBookingDrop(dragItem[0], dataItem)) {
            toast.error('Not a valid range!', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (isValidDrop(src, dataItem, dragItem) == false) {
            console.log('isvaliddrop');
            return;
        }

        // TODO 
        var { breakChildParent } = SchedulingHelper.getBreakChildInfo(dataItem, schedulerDataRef.current);

        console.log(dragItem);

        if (dragItem.some(item => (isInternal || isInternal_Grid ? item.mediaEpisode.MediaCategory.SID : item.MediaCategory.SID) != breakChildParent.mediaEpisode.MediaCategory.SID)) {
            toast.error('Only ' + breakChildParent.mediaEpisode.MediaCategory.Description + ' is allowed to drop in this break', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        const dataToSaveInSchedulerGrid = dragItem.map(data => {
            return {
                BreakNo: dataItem.BreakNo,
                // if internal drag then media episode id will be dragged mediaepisode id
                MediaEpisode_id: src == ENTITYNAME.MediaEpisode ? data._id : data.MediaEpisode_id,
                ParentProgramSchedule_id: dataItem.ParentProgramSchedule_id,
                ScheduleDate: utility.getLocalDateTimeToUtcMiliseconds(date),
                SlotDateTime: dataItem.SlotDateTime + dataItem.Duration,
                Channel: selectedChannel,
                TcIn: data.TcIn,
                TcOut: data.TcOut,
                Type: 0,
                Booking_id: src == ENTITYNAME.Booking ? data._id : '',
                TimeRange: src == ENTITYNAME.Booking ? utility.convertMilisecondsToShortTimeString(data.TimeRangeFrom) +
                    " - " + utility.convertMilisecondsToShortTimeString(data.TimeRangeTo) : '',
                _id: isInternal || isInternal_Grid ? data._id : null,
                Duration: data.Duration,
                mediaEpisode: src == ENTITYNAME.MediaEpisode ? { ...data } : { ...data.mediaEpisode },

            };
        });

        //break Children grid
        if (isInternal) {

            let { level_0_index, level_1_index, level_2_index } = SchedulingHelper.getBreakChildInfo(dragItem[0], schedulerDataRef.current);

            //-----Deleting----------
            deleteBreakItemFromSchedulerGrid(level_0_index, level_1_index, level_2_index);
            //-----Deleted-----------            

            //-----Inserting---------      
            let { level_0_index: parentIndex, level_1_index: childIndex, level_2_index: dropIndex } = SchedulingHelper.getBreakChildInfo(dataItem, schedulerDataRef.current);
            addBreakItemInSchedulerGrid(parentIndex, childIndex, dropIndex + 1, dataToSaveInSchedulerGrid);
            //-----Inserted----------

            //-----Saving------------
            await breakChildrenSave(parentIndex, childIndex);
            //-----Saved-------------


        }
        //program schedule grid
        else if (isInternal_Grid) {

            //-----Deleting----------
            let { level_0_index, level_1_index } = SchedulingHelper.getChildInfo(dragItem[0], schedulerDataRef.current);

            deleteItemFromSchedulerGrid(level_0_index, level_1_index);
            //-----Deleted-----------  

            //-----Inserting---------
            let { level_0_index: parentIndex, level_1_index: childIndex, level_2_index: dropIndex } = SchedulingHelper.getBreakChildInfo(dataItem, schedulerDataRef.current);

            addBreakItemInSchedulerGrid(parentIndex, childIndex, dropIndex + 1, dataToSaveInSchedulerGrid);
            //-----Inserted----------

            //-----Saving------------
            await childrenSave(level_0_index);
            await breakChildrenSave(parentIndex, childIndex);
            //-----Saved-------------

        }
        //external
        else {
            //-----Inserting---------
            let { level_0_index: parentIndex, level_1_index: childIndex, level_2_index: dropIndex } = SchedulingHelper.getBreakChildInfo(dataItem, schedulerDataRef.current);

            addBreakItemInSchedulerGrid(parentIndex, childIndex, dropIndex + 1, dataToSaveInSchedulerGrid);
            //-----Inserted----------

            //-----Saving------------
            await breakChildrenSave(parentIndex, childIndex);
            //-----Saved-------------

        }

        if (dataToSaveInSchedulerGrid.Booking_id != "") filterPendingBookedSpots();

        updateCommercialOverRunUnderRunCounts(schedulerDataRef.current);
    }

    const handleDropPrototype = async (e, dataItem) => {
        console.log("handleprototypeDrop")

        var dragItem = JSON.parse(e.dataTransfer.getData("text"));
        e.dataTransfer.clearData();
        const src = dragItem.src;

        //checks if it is parent or child
        const isParent = dataItem.ParentProgramSchedule_id ? false : true;

        const isFooter = dataItem.Type == SCHEDULETYPE.UnderRun || dataItem.Type == SCHEDULETYPE.OverRun;

        //not allowed for segments
        if (src == ENTITYNAME.Prototype && (isParent || isFooter)) {
            toast.error('Prototype drop not allowed for headers and footers', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //stores parent id
        const parent_id = isParent ? dataItem._id : dataItem.ParentProgramSchedule_id;
        //stores parent
        const parent = SchedulingHelper.getParent(parent_id, schedulerDataRef.current);
        //stores parent index
        const parentIndex = schedulerData.indexOf(parent)

        //adding prototype in primary event as embedded array
        setSchedulerData(old => {

            old[parentIndex].children = old[parentIndex].children.map(item => {

                if (item.SlotDateTime == dataItem.SlotDateTime) {
                    var isValidPrototype = SchedulingHelper.IsValidPrototype(dragItem, item, schedulerData, setStatusMessage);
                    if (isValidPrototype) {
                        // UpdateDynamicTextValue will insert Text1, text2 etc values if prototype is dynamic
                        item.Prototypes.push(UpdateDynamicTextValue(dragItem, item));
                        setStatusMessage(() => dragItem.Name + " Applied Successfully !!");
                    }
                }
                return item;
            });
            schedulerDataRef.current = old;
            return [...old];
        })


        if (isAutoSave) {

            let response = await API.saveSchedule({
                schedules: schedulerData[parentIndex].children,
                deletedschedules: []
            });

            console.log(response);

        }
        else {
            setSaveDataArray((old) => {
                return [...old, ...schedulerData[parentIndex].children]
            })
        }

        console.log(schedulerData[parentIndex].children);

    }



    const isValidDrop = (src, dataItem, dragItem) => {

        const isInternal = src == ENTITYNAME.ProgramSchedule;
        //checks if it is parent or child
        const isParent = dataItem.ParentProgramSchedule_id ? false : true;
        if (dragItem.find(item => item[IS_BREAK] == true) && dataItem[IS_BREAK]) {
            toast.error('Break item cant be dropped on breakItem', {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (isInternal) {
            //not allowed for segments
            if (dragItem.find(item => item.Type == SCHEDULETYPE.Segment)) {
                toast.error('Not Allowed for Segments', {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }

            //not allowed for filter
            if (showFilter) {
                toast.error('Not Allowed on filter', {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }

            if (dataItem?.Type == SCHEDULETYPE.UnderRun || dataItem?.Type == SCHEDULETYPE.OverRun || dragItem.find(item => item.Type == SCHEDULETYPE.UnderRun) || dragItem.find(item => item.Type == SCHEDULETYPE.OverRun)) {
                //because dropped on itself or dropped on under run or overun dataItem

                toast.error('Invalid Drop', {
                    position: toast.POSITION.TOP_RIGHT
                });

                return false;
            }
        }

        if (src == ENTITYNAME.Prototype && isParent) {
            toast.error('Prototype drop does not allowed on header', {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        //Validating Booking Attached Program On Drop
        if (src == ENTITYNAME.Booking && dragItem[0].Program != undefined && Object.keys(dragItem[0].Program).length > 0 && dataItem.mediaEpisode._id != dragItem[0].Program._id) {
            toast.error(`Dropped commercial is valid for ${dragItem[0].Program.Title} only`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
    }

    const handlePopupChannelDropdown = (event) => {
        setSelectedChannel(event.value);
    }
    // drop end=====================================

    //drop end=======================================================

    const handleFilterDialog = () => {
        console.log("coming Here Filter");
        setShowFilter(!showFilter);
    }

    const handleChangeDate = (event) => {
        setDate(event.value);
    }

    const handlePopupConfirm = async () => {

        if (selectedChannel.SID < 0) {
            toast.error("Please select Channel", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        handleFilterDialog();

        await setScheduleLoadPayloadFromFilter();

        loaddataNew();
    }

    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
        if (newValue == 2) {
            // filter pending booked spots based on whatsa already scheduled
            filterPendingBookedSpots();
        }
    };

    //splitter variable and onChange function
    const [horizontalPanes, setHorizontalPanes] = React.useState([
        {},
        {
            size: "25%",
            collapsible: true
        },
    ]);

    const [verticalPanes, setVerticalPanes] = React.useState([
        {},
        { size: "30%", collapsible: true }
    ]);

    const onVerticalSplitterChange = (event) => {
        setVerticalPanes(event.newState);
    };

    const onHorizontalSplitterChange = (event) => {
        setHorizontalPanes(event.newState);
    };

    const MyTimeCell = (props) => {
        return <TimeCell {...props} />;
    }

    const mySecondaryEventCountCell = (props) => {

        const IconsStyle = (color) => {
            return { color: color ?? "#E28C00", fontSize: "1.6em", size: "2.6em", marginRight: "0.12em" };
        }

        return (
            <td>
                <SecondaryEventIcon style={IconsStyle(props.style.color)} /><span style={{ fontWeight: "400", fontSize: "14px", }}>{props.dataItem?.Prototypes?.length ?? 0}</span>

            </td>
        );
    }

    const HasChildrenLocal = (selectedheader) => {
        console.log(selectedheader);
        return selectedheader.children.some(x => x.Type != SCHEDULETYPE.Segment && ((x.Booking_id && x.Booking_id.length > 0) || (x.children && x.children.length > 0 && x.children.some(c => c.Booking_id && c.Booking_id.length > 0))));
    }

    const HasReconciledChildrenLocal = (selectedheader) => {
        console.log(selectedheader);
        return selectedheader.children.some(x => (x.IsReconciled) || (x.children && x.children.length > 0 && x.children.some(c => c.IsReconciled)));
    }

    const MyScheduleTypeCell = (props) => {
        return <ScheduleTypeCell {...props} />
    };

    const onSelectionChange = (event) => {
        const ctrlKey = event.ctrlKey;
        const rowIndex = event.startRowIndex;
        const dataItems = event.dataItems;
        const dataItem = dataItems[rowIndex];

        if (ctrlKey) {
            let alreadySelected = selectedState.some(item => item._id == dataItem._id);

            if (alreadySelected) {
                selectedStateRefSetter(selectedState.filter(item => item._id != dataItem._id))

            } else {
                if (dataItem.Type == SCHEDULETYPE.OverRun || dataItem.Type == SCHEDULETYPE.UnderRun) {
                    return;
                }
                selectedStateRefSetter([...selectedState, dataItem]);
            }
        } else {
            let alreadySelected = selectedState.length == 1 && selectedState[0]._id == dataItem._id ? true : false
            if (alreadySelected) {
                selectedStateRefSetter([]);
            } else {
                if (dataItem.Type == SCHEDULETYPE.OverRun || dataItem.Type == SCHEDULETYPE.UnderRun) {
                    return;
                }
                selectedStateRefSetter([dataItem]);
            }
        }
    };

    const onAllHeaderSelectionChange = (event) => {

        var allSelected = event.dataItems.map((item) => {

            var dataItem = {
                ...item,
                [SELECTED_HEADER]: !selectedAllHeaders
            }

            return dataItem;

        });

        if (!selectedAllHeaders) {
            // console.log(allSelected);
            setSelectedHeadersDataItems(allSelected);
        } else {
            // console.log(allSelected);
            setSelectedHeadersDataItems([]);
        }

        setSelectedAllHeaders(!selectedAllHeaders);
        schedulerDataRefSetter(allSelected);

    }

    //item Change for inline editing in grid 
    const itemChange = (event) => {

        //to check isHeaderSelected and add dataItem in array
        if (!event.dataItem[SELECTED_HEADER]) {
            setSelectedHeadersDataItems([...selectedHeadersDataItems, event.dataItem]);
        } else {
            var remove = selectedHeadersDataItems.filter((obj) => obj._id != event.dataItem._id);
            setSelectedHeadersDataItems(remove);
        }

        const field = event.field || "";
        const newData = schedulerData.map((item) =>
            item._id === event.dataItem._id
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        schedulerDataRefSetter(newData);

    };

    //cut
    const schedulerChildrenCut = (dataItem, isKeyboard = false) => {
        copyPasteDataItemSetter([]);

        if (isKeyboard) {
            cutPasteDataItemRefSetter([...cutPasteDataItemRef.current, ...selectedStateRef.current.filter(data => data.Type != SCHEDULETYPE.Segment)]);
            setSchedulerData((old) => {

                old.map((parent, i) => {

                    selectedStateRef.current.filter(data => data.Type != SCHEDULETYPE.Segment).map(data => {

                        let index = parent.children.findIndex(children => children._id == data._id);
                        if (index != -1) {
                            parent.children.splice(index, 1);
                            old[i].children = getRippleChildrens(old[i], parent.children, old)
                        }

                    });

                    return parent;
                })

                updateCommercialOverRunUnderRunCounts(old);
                schedulerDataRef.current = old;
                return [...old]
            });

        } else {
            if (selectedState.length > 1) {
                cutPasteDataItemRefSetter([...cutPasteDataItemRef.current, ...selectedState.filter(data => data.Type != SCHEDULETYPE.Segment)]);
                setSchedulerData((old) => {

                    old.map((parent, i) => {

                        selectedState.filter(data => data.Type != SCHEDULETYPE.Segment).map(data => {

                            let index = parent.children.findIndex(children => children._id == data._id);
                            if (index != -1) {
                                parent.children.splice(index, 1);
                                old[i].children = getRippleChildrens(old[i], parent.children, old)
                            }

                        });

                        return parent;
                    })

                    updateCommercialOverRunUnderRunCounts(old);
                    schedulerDataRef.current = old;
                    return [...old]
                });

            } else {
                var parent = SchedulingHelper.getParent(dataItem.ParentProgramSchedule_id, schedulerDataRef.current)
                var parentIndex = schedulerData.indexOf(parent);

                setSchedulerData((old) => {
                    old[parentIndex].children = getRippleChildrens(old[parentIndex], old[parentIndex].children.filter(item => item._id != dataItem._id), old)
                    updateCommercialOverRunUnderRunCounts(old);
                    schedulerDataRef.current = old;
                    return [...old]
                })

                cutPasteDataItemRefSetter([...cutPasteDataItemRef.current, dataItem])
            }

        }
    }

    const schedulerChildrenCopy = (dataItem, isKeyboard = false) => {
        if (isKeyboard) {
            copyPasteDataItemSetter(selectedStateRef.current.filter(data => data.Type != SCHEDULETYPE.Segment));
        } else {
            if (selectedState.length > 1) {
                copyPasteDataItemSetter(selectedState.filter(data => data.Type != SCHEDULETYPE.Segment))
            } else {
                copyPasteDataItemSetter([dataItem]);
            }
        }

    }

    //paste
    const schedulerChildrenPaste = async (dataItem, isKeyboard = false) => {

        if (cutPasteDataItemRef.current.length == 0 && copyPasteDataItemRef.current.length == 0) {
            toast.error('First cut or copy item.', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var parent;
        var parentIndex;
        var childrenIndex;

        if (isKeyboard) {
            if (selectedStateRef.current.length == 0 || selectedStateRef.current.length > 1) {
                toast.error('Select an area where you want to paste.', {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            parent = SchedulingHelper.getParent(selectedStateRef.current[0].ParentProgramSchedule_id, schedulerDataRef.current);
            parentIndex = schedulerDataRef.current.indexOf(parent);
            childrenIndex = schedulerDataRef.current[parentIndex].children.findIndex(item => item._id == selectedStateRef.current[0]._id);
        } else {
            parent = SchedulingHelper.getParent(dataItem.ParentProgramSchedule_id, schedulerDataRef.current)
            parentIndex = schedulerData.indexOf(parent);
            childrenIndex = schedulerData[parentIndex].children.findIndex(item => item._id == dataItem._id);
        }

        var schedulerDataCopy = schedulerDataRef.current;

        //for cut paste ---------------------------------------------------
        if (cutPasteDataItemRef.current.length > 0) {
            schedulerDataCopy[parentIndex].children.splice(childrenIndex + 1, 0, ...cutPasteDataItemRef.current)
            schedulerDataCopy[parentIndex].children = getRippleChildrens(schedulerDataCopy[parentIndex], schedulerDataCopy[parentIndex].children, schedulerDataCopy)
            updateCommercialOverRunUnderRunCounts(schedulerDataCopy);
        }
        //for copy paste ---------------------------------------------------
        else {
            var copyDataItem = copyPasteDataItemRef.current.map(data => {
                var newData = Object.assign({}, data);
                delete newData['_id'];
                newData.SID = 0;
                return newData;
            });
            schedulerDataCopy[parentIndex].children.splice(childrenIndex + 1, 0, ...copyDataItem)
            schedulerDataCopy[parentIndex].children = getRippleChildrens(schedulerDataCopy[parentIndex], schedulerDataCopy[parentIndex].children, schedulerDataCopy)
            updateCommercialOverRunUnderRunCounts(schedulerDataCopy);
            schedulerDataRefSetter(schedulerDataCopy)
        }
        //---------------------------------------------------
        if (isAutoSave) {
            setStatusMessage('Pasting...')
            const response = await API.saveSchedule({ schedules: schedulerDataRef.current[parentIndex].children, deletedschedules: [] })
            console.log(response);
            if (response.success) {
                response.data.savedschedules.map((response, i) => {
                    if (response.response?.insertedId) {
                        schedulerDataRef.current[parentIndex].children[i]._id = response.response.insertedId;
                    }
                })
                setStatusMessage('Pasted')
            } else {
                setStatusMessage('Error in pastin')
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loaddataNew();
            }

        }
        //---------------------------------------------------
        else {
            setSaveDataArray(old => {
                return [...old, ...schedulerDataRef.current[parentIndex].children];
            })
        }

        cutPasteDataItemRefSetter([]);
        // copyPasteDataItemSetter([]); //Commenting it shoulld be keep strored for nexr paste


    }

    const onSchedulerChildrenFilter = () => {
        var newSchedulerData = [];
        var dataItem = schedulerChildrenFilterRef.current.dataItem;
        var field = schedulerChildrenFilterRef.current.field;
        var field2 = schedulerChildrenFilterRef.current.field;
        var field3 = schedulerChildrenFilterRef.current.field;;
        var dotIndex = field.indexOf('.');
        if (dotIndex != -1) {
            field = field.slice(null, dotIndex);
            field2 = field2.slice(dotIndex + 1);
            dotIndex = field2.indexOf('.');
            if (dotIndex != -1) {
                field2 = field2.slice(null, dotIndex);
                dotIndex = field3.lastIndexOf('.');
                field3 = field3.slice(dotIndex + 1);
                dataItem = dataItem?.[field]?.[field2]?.[field3];
                setSearchWord(dataItem);
                schedulerData.map(parent => {
                    var newData = parent.children.filter(child => child?.[field]?.[field2]?.[field3] == dataItem);
                    newSchedulerData.push(...newData);
                });
            } else {
                dataItem = dataItem?.[field]?.[field2];
                setSearchWord(dataItem);
                schedulerData.map(parent => {
                    var newData = parent.children.filter(child => child?.[field]?.[field2] == dataItem);
                    newSchedulerData.push(...newData);
                });
            }

        } else {
            dataItem = dataItem?.[field];
            setSearchWord(dataItem);
            schedulerData.map(parent => {
                var newData = parent.children.filter(child => child[field] == dataItem);
                newSchedulerData.push(...newData);
            });
        }
        setShowFilterGridData(newSchedulerData);
        setShowFilterGrid(true);
    }

    //children grid
    const DetailComponent = (props) => {

        var data = props.dataItem.children.map(item => {
            item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id)
            return item;
        });
        // console.log(data);

        return (<>
            <Grid
                data={orderBy(data, [
                    {
                        field: "SlotDateTime",
                        dir: "asc",
                    },
                ])}
                rowRender={GridRowRenderScheduleChildren}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: true,
                    cell: false,
                    mode: {
                        value: 'multiple',
                        label: 'Multiple selection mode'
                    },
                }}
                onSelectionChange={onSelectionChange}
                className={'no-headers-grid'}
                detail={BreakGrid}
                expandField="expanded"
                onExpandChange={expandChange2}

            >

                {dataColumns.map((col) => gridPreferenceColumn(col))}

                {/* <Column width="120px" field="SlotDateTime" title={lang.time_column} cell={MyTimeCell} />
                <Column width="180px" field="mediaEpisode.Title" title={lang.title_column} />
                <Column width="140px" field="mediaEpisode.AssetId" title={lang.assetId_column} />
                <Column width="140px" field="MediaId" title={lang.media_id_column} />
                <Column width="140px" field="mediaEpisode.MediaCategory.Description" title={lang.media_category_column} />
                <Column width="100px" field="Duration" title={lang.duration_column} cell={MyTimeCell} />
                <Column width="140px" field="Type" title={lang.type_column} cell={MyScheduleTypeCell} />
                <Column width="100px" field="Prototypes.length" title={lang.prototypes_column} cell={mySecondaryEventCountCell} />
                <Column width="140px" field="Info" title={lang.info_column} style={{ color: "#FFFFFF" }} /> */}

            </Grid>
        </>
        );

    };

    const gridPreferenceColumn = (col) => <Column key={col._id + Math.random()} width={col.width} field={col.name} title={col.label} cell={col.type == 'time' ? MyTimeCell : col.type == 'date' ? DateOnlyCell : col.type == 'MyScheduleTypeCell' ? MyScheduleTypeCell : col.type == 'mySecondaryEventCountCell' ? mySecondaryEventCountCell : null} />
    //children grid
    const BreakGrid = (props) => {
        var data = props.dataItem.children.map(item => {
            item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id);
            return item;
        });;

        return (
            <Grid
                data={data}
                rowRender={GridRowRenderScheduleBreakChildren}
                className={'no-headers-grid'}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: true,
                    cell: false,
                    mode: {
                        value: 'multiple',
                        label: 'Multiple selection mode'
                    },
                }}
                onSelectionChange={onSelectionChange}
            >
                {dataColumns.map((col) => gridPreferenceColumn(col))}

                {/* <Column width="120px" field="SlotDateTime" title={lang.time_column} cell={MyTimeCell} />
                <Column width="180px" field="mediaEpisode.Title" title={lang.title_column} />
                <Column width="140px" field="mediaEpisode.AssetId" title={lang.assetId_column} />
                <Column width="140px" field="MediaId" title={lang.media_id_column} />
                <Column width="140px" field="mediaEpisode.MediaCategory.Description" title={lang.media_category_column} />
                <Column width="100px" field="Duration" title={lang.duration_column} cell={MyTimeCell} />
                <Column width="140px" field="Type" title={lang.type_column} cell={MyScheduleTypeCell} />
                <Column width="100px" field="Prototypes.length" title={lang.prototypes_column} cell={mySecondaryEventCountCell} />
                <Column width="140px" field="TimeRange" title={"Time Range"} /> */}
                {/* <Column width="140px" field="Info" title={lang.info_column} style={{ color: "#FFFFFF" }} /> */}
            </Grid>
        );

    };

    const expandChange = (event) => {

        event.dataItem.expanded = event.value;
        setCategories([...categories]);
        if (!event.value || event.dataItem.details) {
            return;
        }
        let index = event.dataIndex;
        setCategories(schedulerData[index].children);
    };

    const expandChange2 = (event) => {
        event.dataItem.expanded = event.value;
        setCategories([...categories]);
        if (!event.value || event.dataItem.details) {
            return;
        }
        let index = event.dataIndex;
        setCategories([schedulerData[index]]);
    };

    //export start============================================
    const exportPlanetCast = async () => { SchedulingHelper.downloadschedule(payloadref.current, AUTOMATION.PLANETCAST); }

    const handleExport = async () => { SchedulingHelper.downloadschedule(payloadref.current, AUTOMATION.EVERTZ); }

    const handlePdfExport = async () => { SchedulingHelper.downloadschedule(payloadref.current, AUTOMATION.PDF); }

    const downloadExcel = async () => { SchedulingHelper.downloadschedule(payloadref.current, AUTOMATION.AMAGI_AS_RUN); }
    //export end============================================


    const applyStyleSheet = async (styleSheetList) => {
        console.log("applyStyleSheet")

        // var parentData = schedulerData.filter(data => {
        //     if (!data.ParentProgramSchedule_id) {
        //         return data;
        //     }
        // });
        var parentData = selectedHeadersDataItems;

        // looping all parents of playlist for now
        for (var i = 0; i < parentData.length; i++) {
            // looping style sheet list
            for (var s = 0; s < styleSheetList.length; s++) {
                // looping prototypes attached inside style sheet
                for (var x = 0; x < styleSheetList[s].Prototypes.length; x++) {
                    //adding prototype in primary event as embedded array
                    setSchedulerData(old => {
                        old[i].children = old[i].children.map(item => {
                            // handling null
                            item.Prototypes = item.Prototypes ? item.Prototypes : [];

                            if (item.Type != SCHEDULETYPE.UnderRun && item.Type != SCHEDULETYPE.OverRun) {
                                var isValidPrototype = SchedulingHelper.IsValidPrototype(styleSheetList[s].Prototypes[x], item, schedulerData, setStatusMessage);
                                if (isValidPrototype) item.Prototypes.push(UpdateDynamicTextValue(styleSheetList[s].Prototypes[x], item));

                            }

                            return item;
                        });
                        schedulerDataRef.current = old;
                        return [...old];
                    })

                    if (isAutoSave) {
                        let response = await API.saveSchedule({
                            schedules: schedulerData[i].children,
                            deletedschedules: []
                        });
                        console.log(response);
                    }
                    else {
                        setSaveDataArray((old) => {
                            return [...old, ...schedulerData[i].children]
                        })
                    }
                }
            }
        }

        setStatusMessage(() => "Style Sheet Applied Successfully !!");
    }

    const handleScheduleCopyByButton = () => {
        copyPasteDataItemSetter(selectedState)
    }

    const handleScheduleCutByButton = () => {
        for (let index = 0; index < selectedState.length; index++) {
            var parent = SchedulingHelper.getParent(selectedState[index].ParentProgramSchedule_id)
            var parentIndex = schedulerData.indexOf(parent);

            setSchedulerData((old) => {
                old[parentIndex].children = getRippleChildrens(old[parentIndex], old[parentIndex].children.filter(item => item._id != selectedState[index]._id), old)
                updateCommercialOverRunUnderRunCounts(old);
                schedulerDataRef.current = old;
                return [...old]
            })

        }

        cutPasteDataItemRefSetter(selectedState)
    }

    const handleSchedulePasteByButton = async () => {
        if (selectedState.length == 0 || selectedState.length > 1) {
            toast.error('Select only one dataItem where u want to paste', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            await schedulerChildrenPaste(selectedState[0])
        }
    }

    // auto save toggle changes
    const handleAutoSave = (e) => {
        setIsAutoSave(e.target.value)
    }

    // auto save toggle changes
    const onHeaderExpandChange = (e) => {
        // testing expand all and collapse all header
        var parentData = schedulerData.filter(data => {
            if (!data.ParentProgramSchedule_id) {
                return data;
            }
        });

        for (var i = 0; i < parentData.length; i++) {
            //dataitem, vakue, dataIndex
            var event = { dataItem: parentData[i], dataIndex: i, value: e.target.value };
            expandChange(event);
        }

    }

    const saveSecondaryChangedData = (prototypeOverviewData, scheduleItem) => {

        //stores parent
        const parent = SchedulingHelper.getParent(scheduleItem.ParentProgramSchedule_id);
        //stores parent index
        const parentIndex = schedulerData.indexOf(parent)

        //adding prototype in primary event as embedded array
        setSchedulerData(old => {

            old[parentIndex].children = old[parentIndex].children.map(item => {

                if (item.SlotDateTime == scheduleItem.SlotDateTime) {
                    item.Prototypes = prototypeOverviewData;
                }
                return item;
            });
            schedulerDataRef.current = old;
            return [...old];
        })


        if (isAutoSave) {
            API.saveSchedule({
                schedules: schedulerData[parentIndex].children,
                deletedschedules: []
            });
        }
        else {
            setSaveDataArray((old) => {
                return [...old, ...schedulerData[parentIndex].children]
            })
        }

        showSecondaryEventOverviewDialogProp();
    }

    //function to update data for header
    const onUpdateFromPlanningEditForm = async (updatedData) => {
        console.log(updatedData);

        var parent = SchedulingHelper.getParent(updatedData._id, schedulerDataRef.current)
        var parentIndex = schedulerData.indexOf(parent);


        if (!isAutoSave) {
            // Setting Header Start Time First and then rrippling its children
            setSchedulerData((old) => {
                old[parentIndex].SlotDateTime = updatedData.SlotDateTime;

                // ripplig cureent item
                old[parentIndex].children = getRippleChildrens(old[parentIndex], old[parentIndex].children, old)

                schedulerDataRef.current = old;
                return [...old]
            })

            // setting for save
            setSaveDataArray((old) => {
                // adding next header and its children for save update
                return [...old, ...schedulerData[parentIndex], ...schedulerData[parentIndex].children]
            })

        }
        else {
            // Setting Header Start Time First and then rrippling its children
            setSchedulerData((old) => {
                old[parentIndex].SlotDateTime = updatedData.SlotDateTime;

                // ripplig cureent item 
                old[parentIndex].children = getRippleChildrens(old[parentIndex], old[parentIndex].children, old)
                schedulerDataRef.current = old;
                return [...old]
            })

            // using next parent header and children without footer
            var savingList = [...schedulerData[parentIndex].children.filter(item => item._id.toLowerCase().indexOf("-footer") === -1), schedulerData[parentIndex]]

            console.log(savingList);
            var response = await API.saveSchedule({ schedules: savingList, deletedschedules: [] });
            console.log(response);

            if (!response.success) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }

    };

    const oncloseEditForm = (dataItem) => {
        console.log("Cancelled");
        setShowPlanningEditFormDialog()
    };

    const openPlanning = () => {
        // we will save here in local storage.
        var data = { planningdate: new Date(moment(date)), channels: [selectedChannel] }
        SchedulingHelper.setPlanningPublishDataInLocalStorage(data);

        // Now routing
        navigate("/home/planning");

    }

    const handleCopyByKeyboard = () => {
        if (cutPasteDataItemRef.current.length > 0) {
            toast.error('First paste the cut item or refresh data from header.', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        cutPasteDataItemRefSetter([]);
        schedulerChildrenCopy(null, true);
    }
    const handleCutByKeyboard = () => {

        schedulerChildrenCut(null, true);
    }

    const handlePasteByKeyboard = (e) => {
        if (e.target.localName == 'input') {
            return;
        }
        schedulerChildrenPaste(null, true)
    }

    const UpdateDynamicTextValue = (prototype, scheduleItem) => {
        if (prototype.SecondaryEventDynamicRules && prototype.SecondaryEventDynamicRules.length > 0) {
            for (let index = 0; index < prototype.SecondaryEventDynamicRules.length; index++) {
                /// dynamically creating properties inside prototype object of type Text + TextNumber in prototype Rule
                prototype['Text' + prototype.SecondaryEventDynamicRules[index].TextNumber] = SchedulingHelper.GetParsedText(scheduleItem, prototype.SecondaryEventDynamicRules[index], schedulerDataRef.current);

            }
        }
        return prototype;
    }

    const setSelectedPlaceHolderMedia = (selectedMedia) => {
        insertPlaceHolder(dataItemForContextMenuRef.current, selectedMedia[0])
    }

    const getRippleBreakChildrens = (parent, childrens) => {
        var startTime = parent.SlotDateTime;
        childrens = childrens.filter(data => {
            if (data.Type != SCHEDULETYPE.OverRun && data.Type != SCHEDULETYPE.UnderRun) {
                return data;
            }
        });
        var rippledChildren = childrens.map(item => {
            item.SlotDateTime = startTime;
            startTime = startTime + item.Duration;
            item.Style = SchedulingHelper.getScheduleColorPreference(item);
            return item
        });

        var footer = SchedulingHelper.getBreakFooter(parent, childrens, selectedChannel);


        if (footer) {
            footer.Style = SchedulingHelper.getScheduleColorPreference(footer);
            rippledChildren.push(footer);
        }


        return rippledChildren;

    }

    const rippleChildren = (parentIndex) => {
        let schedulerDataLocal = [...schedulerDataRef.current];
        //rippling
        schedulerDataLocal[parentIndex].children = getRippleChildrens(schedulerDataLocal[parentIndex], schedulerDataLocal[parentIndex].children, schedulerDataLocal);

        schedulerDataRefSetter(schedulerDataLocal);
    }

    const insertPlaceHolder = async (dataItem, mediaEpisode) => {

        var isParent = dataItem.ParentProgramSchedule_id == null ? true : false;//to check if inserted on header
        var parent = isParent ? dataItem : SchedulingHelper.getParent(dataItem.ParentProgramSchedule_id, schedulerDataRef.current);
        var parentIndex = schedulerData.findIndex(data => data._id == parent._id);
        var droppedIndex = isParent ? 0 : schedulerData[parentIndex].children.findIndex(item => item._id == dataItem._id) + 1;

        var breakItem = SchedulingHelper.getBreakItem(isParent, dataItem, mediaEpisode, mediaEpisode.Duration, selectedChannel);
        breakItem.style = SchedulingHelper.getScheduleColorPreference(breakItem);
        breakItem.children = getRippleBreakChildrens(breakItem, breakItem.children);

        var fillDuration = 0;
        if (loopBreak) {
            while (fillDuration + mediaEpisode.Duration <= parent.Duration + 1000) // adding 1000 for 1 sec  
            {
                addItemInSchedulerGrid(parentIndex, droppedIndex, [breakItem]);
                fillDuration = fillDuration + mediaEpisode.Duration;
            }

        }
        else addItemInSchedulerGrid(parentIndex, droppedIndex, [breakItem]);


        await childrenSave(parentIndex);
        if (loopBreak) {
            rippleChildren(parentIndex);
            setLoopBreak(false);
        }

    }

    const programReplacement = async (replaceDataItem) => {
        if (selectedHeader == {}) {
            toast.error("Please Select Header First", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (replaceDataItem.length == 0) {
            toast.error('Select one program to replace with', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        var mediaEpisode = replaceDataItem[0];

        const saveData = {
            SID: 0,
            Channel: selectedChannel,
            MediaEpisode_id: mediaEpisode._id,
            ScheduleDate: selectedHeader.SlotDateTime,
            SlotDateTime: selectedHeader.SlotDateTime,
            Segment_id: null,
            ParentProgramSchedule_id: null,
            IsValid: true,
            MediaDealRight_id: null,
        }


        var response = await API.ReplaceProgram(saveData, selectedHeader) // true for loadchildren


        if (response.success) {
            var parentIndex = schedulerData.findIndex(header => header._id == selectedHeader._id);
            var schedlerDataCopy = [...schedulerData];
            schedlerDataCopy.splice(parentIndex, 1, { ...response.data.header, mediaEpisode: mediaEpisode, Duration: mediaEpisode.Duration, children: response.data.children, Style: SchedulingHelper.getScheduleColorPreference(response.data.header) });
            schedlerDataCopy[parentIndex].children = getRippleChildrens(schedulerData[parentIndex], response.data.children, schedulerData);
            schedulerDataRefSetter(schedlerDataCopy);
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const showGlobalReplacement = () => {
        if (selectedStateRef.current.length == 0) {
            return;
        }
        if (selectedStateRef.current.some(item => item.Type == SCHEDULETYPE.Segment || item.Type == SCHEDULETYPE.UnderRun || item.Type == SCHEDULETYPE.OverRun || item[IS_BREAK])) {
            toast.error('not allowed', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        setShowGlobalReplacementDialog(true);
    }

    const gloabalFindAndReplace = async (selectedItems, replaceWith, isBreakInclude, replaceMethod) => {
        var replaceArrayIndexes = [];
        var schedulerDataCopy = [...schedulerData];

        if (REPLACEMENTMODE[replaceMethod] != REPLACEMENTMODE.Selected) {

            schedulerDataCopy.map((parent, pi) => {
                parent.children.map((children, ci) => {
                    if (children[IS_BREAK] && isBreakInclude) {
                        children.children.map((breackChild, bi) => {
                            if (breackChild?.mediaEpisode?._id == selectedItems[0]?.mediaEpisode?._id) {
                                replaceArrayIndexes.push([pi, ci, bi]);
                            }
                        });
                    }
                    if (!children[IS_BREAK] && children?.mediaEpisode?._id == selectedItems[0]?.mediaEpisode?._id) {
                        replaceArrayIndexes.push([pi, ci]);
                    }

                })
            });
        }

        switch (REPLACEMENTMODE[replaceMethod]) {
            case REPLACEMENTMODE.ALL:
                await globalReplace(replaceWith, replaceArrayIndexes);
                break;
            case REPLACEMENTMODE.Alternate:
                await globalReplace(replaceWith, replaceArrayIndexes.filter((replaceArrayIndex, i) => i % 2 == 0));
                break;
            case REPLACEMENTMODE.Random:
                await globalReplace(replaceWith, replaceArrayIndexes, true);
                break;
            case REPLACEMENTMODE.Selected:

                replaceArrayIndexes = selectedItems.map(item => {
                    let { level_0_index: index_0, level_1_index: index_1, isInsideBreak } = SchedulingHelper.getChildInfo(item, schedulerDataRef.current);
                    if (isInsideBreak) {
                        let { level_0_index, level_1_index, level_2_index } = SchedulingHelper.getBreakChildInfo(item, schedulerDataRef.current);
                        return [level_0_index, level_1_index, level_2_index];
                    } else {
                        return [index_0, index_1];
                    }
                });
                await globalReplace(replaceWith, replaceArrayIndexes);

                break;

            default:
                await globalReplace(replaceWith, replaceArrayIndexes);
                break;
        }
    }

    const globalReplace = async (replaceWith, replaceArrayIndexes, random = false) => {
        var replaceWithIndex = 0;
        var schedulerDataCopy = [...schedulerData];
        var filterGridCopy = [...showFilterGridData];
        replaceArrayIndexes.map(async replaceIndex => {
            if (random) {
                replaceWithIndex = Math.floor(Math.random() * replaceWith.length);
            }
            if (!random && replaceWith.length == replaceWithIndex) {
                replaceWithIndex = 0;
            }
            if (replaceIndex.length == 2) {
                schedulerDataCopy[replaceIndex[0]].children.splice(replaceIndex[1], 1, {
                    ...schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]], MediaEpisode_id: replaceWith[replaceWithIndex]._id,
                    MediaId: replaceWith[replaceWithIndex].AssetId, mediaEpisode: replaceWith[replaceWithIndex]
                });

                let filterIndex = filterGridCopy.findIndex(filterData => filterData._id == schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]]._id);
                if (filterIndex > -1) {
                    filterGridCopy.splice(filterIndex, 1, {
                        ...schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]], MediaEpisode_id: replaceWith[replaceWithIndex]._id,
                        MediaId: replaceWith[replaceWithIndex].AssetId, mediaEpisode: replaceWith[replaceWithIndex]
                    })
                }
                replaceWithIndex++;
                //-----Saving------------
                await childrenSave(replaceIndex[0]);
                //-----Saved-------------
            }
            if (replaceIndex.length == 3) {
                schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]].children.splice(replaceIndex[2], 1, { ...schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]].children[replaceIndex[2]], MediaEpisode_id: replaceWith[replaceWithIndex]._id, MediaId: replaceWith[replaceWithIndex].AssetId, mediaEpisode: replaceWith[replaceWithIndex] });

                let filterIndex = filterGridCopy.findIndex(filterData => filterData._id == schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]].children[replaceIndex[2]]._id);
                if (filterIndex > -1) {
                    filterGridCopy.splice(filterIndex, 1, { ...schedulerDataCopy[replaceIndex[0]].children[replaceIndex[1]].children[replaceIndex[2]], MediaEpisode_id: replaceWith[replaceWithIndex]._id, MediaId: replaceWith[replaceWithIndex].AssetId, mediaEpisode: replaceWith[replaceWithIndex] })
                }
                replaceWithIndex++;
                //-----Saving------------
                await breakChildrenSave(replaceIndex[0], replaceIndex[1]);
                //-----Saved-------------
            }
        });

        setShowFilterGridData(filterGridCopy);
        schedulerDataRefSetter(schedulerDataCopy);
    }

    const cancelGlobalReplacementDialog = () => {
        setShowGlobalReplacementDialog(false);
    }

    const autoFillBookedCommercials = () => {

        var schedlerDataCopy = [...schedulerData];

        var bookedCommercialSpots = pendingBookedCommercialSpots.sort((a, b) => a.SpotRate > b.SpotRate);

        for (let index = 0; index < bookedCommercialSpots.length; index++) {
            const bookedSpot = bookedCommercialSpots[index];

            var startTime = bookedSpot.BookingDate + bookedSpot.TimeRangeFrom;
            var endTime = bookedSpot.BookingDate + bookedSpot.TimeRangeTo;

            var validChildren = [];

            schedulerData.map(sch => {
                sch.children.map(child => {
                    // valid breaks
                    // slot date time >= bookingStartTime
                    // SlotDateTime + Duration <= bookingEndTime
                    if (child[IS_BREAK] && child.SlotDateTime >= startTime && (child.SlotDateTime + child.Duration) <= endTime) validChildren = [...validChildren, child];
                })
            });

            if (validChildren.length == 0) continue;

            const placementBreak = validChildren.sort((a, b) => a.children.length - b.children.length)[0];

            var childInfo = SchedulingHelper.getChildInfo(placementBreak, schedulerDataRef.current)

            var dataToSave = {
                BreakNo: placementBreak.BreakNo ?? 0,
                MediaEpisode_id: bookedSpot.mediaEpisode._id,
                ParentProgramSchedule_id: placementBreak._id,
                ScheduleDate: utility.getLocalDateTimeToUtcMiliseconds(date),
                SlotDateTime: placementBreak.SlotDateTime + bookedSpot.Duration,
                Channel: selectedChannel,
                TcIn: bookedSpot.TcIn,
                TcOut: bookedSpot.TcOut,
                Type: 0,
                Booking_id: bookedSpot._id,
                Duration: bookedSpot.Duration,
                mediaEpisode: bookedSpot.mediaEpisode,
                [IS_BREAK]: false,
                TimeRange: utility.convertMilisecondsToShortTimeString(bookedSpot.TimeRangeFrom) + " - " + utility.convertMilisecondsToShortTimeString(bookedSpot.TimeRangeTo),
                children: []
            };

            let dropIndex = schedlerDataCopy[childInfo.level_0_index].children[childInfo.level_1_index].children.length;
            if (SchedulingHelper.validBookingDrop(bookedSpot, schedlerDataCopy[childInfo.level_0_index].children[childInfo.level_1_index])) {
                schedlerDataCopy[childInfo.level_0_index].children[childInfo.level_1_index].children.splice(dropIndex, 0, dataToSave);
            }
        }

        filterPendingBookedSpots()

        //-----Saving------------
        schedlerDataCopy.map((header, headerindex) => {

            header.children.map((child, childindex) => {
                if (child[IS_BREAK] && child.children.some(x => x.Booking_id && x.Booking_id != "")) {
                    child.children = getRippleBreakChildrens(child, child.children);
                    breakChildrenSave(headerindex, childindex);
                }
            })
        })

        schedulerDataRefSetter(schedlerDataCopy);


    }

    const onDateChangeLeft = async () => {
        var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1); //need to set previous date
        await setScheduleLoadPayloadFromFilter(newDate);
        loaddataNew();
        setDate(newDate);
    }

    const onDateChangeRight = async () => {
        var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1); //need to set next date
        await setScheduleLoadPayloadFromFilter(newDate);
        loaddataNew();
        setDate(newDate);
    }

    // for column preference
    const onPreferenceFilter = () => {
        setPreferenceFilter(!preferenceFilter);
        loaddataNew();
    }
    // delete start=====================================

    const handleScheduleDeleteByButton = async () => {

        if (selectedStateRef.current.length > 1 && selectedStateRef.current.some(item => item._id.includes("-footer"))) {
            toast.error("Footer cannot be deleted along with other selected elements", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (selectedStateRef.current.some(item => item.IsReconciled)) {
            toast.error("Selected Items Contains Reconciled Items. Process Aborted !!", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        // delete footer
        if (selectedStateRef.current[0]._id && selectedStateRef.current[0]._id.includes("-footer")) {
            deleteFooter(selectedStateRef.current[0]);
            return;
        }

        setSchedulerData((old) => {
            var newData = old.map(o => {
                o.children = o.children.filter(item => {
                    if (item[IS_BREAK]) {
                        item.children = item.children.filter(data => {
                            if (!selectedStateRef.current.some(i => i._id == data._id)) {
                                return data;
                            }
                        });
                        return item;
                    }
                    if (!selectedStateRef.current.some(i => i._id == item._id)) {
                        return item;
                    }
                })
                return { ...o, children: getRippleChildrens(o, o.children, old) }
            })
            updateCommercialOverRunUnderRunCounts(newData);
            schedulerDataRef.current = old;
            return newData;
        })

        if (!isAutoSave) {
            setDeletedSchedules((old) => [...old, ...selectedStateRef.current])

        } else {
            const response = await API.saveSchedule({ schedules: [], deletedschedules: selectedStateRef.current })
            console.log(response);
            setDeletedSchedules([])
            setSelectedState([])
        }
        selectedStateRefSetter([]);
        toast.success('Deleted successfully', {
            position: toast.POSITION.TOP_RIGHT
        });

        filterPendingBookedSpots();

    }

    const deleteAllFooter = async () => {
        var footerExists = true;
        while (footerExists) {
            // filterring footers only
            var footerData = schedulerData.reduce(function (result, parent) {

                var footer = parent.children.find(maindata => maindata.Type == SCHEDULETYPE.UnderRun || maindata.Type == SCHEDULETYPE.OverRun)
                if (footer) {
                    result.push(footer);
                }
                return result;
            }, []);

            console.log(footerData);

            //if found , we ill delete otherwise break the while loop  
            if (footerData && footerData.length > 0) {
                var firstFooter = footerData.sort((a, b) => a.SlotDateTime - b.SlotDateTime)[0]
                await deleteFooter(firstFooter);
            }
            else {
                console.log("No Footer Exists");
                footerExists = false;
            }
        }
    }

    const deleteAllSecondaryEvent = async () => {
        console.log("deleteAllSecondaryEvent")

        var parentData = schedulerData.filter(data => {
            if (!data.ParentProgramSchedule_id) {
                return data;
            }
        });

        for (var i = 0; i < parentData.length; i++) {
            //removing prototypes
            setSchedulerData(old => {
                old[i].children = old[i].children.map(item => {
                    item.Prototypes = [];
                    return item;
                });
                schedulerDataRef.current = old;
                return [...old];
            })


            if (isAutoSave) {
                let response = await API.saveSchedule({
                    schedules: schedulerData[i].children,
                    deletedschedules: []
                });
                console.log(response);
            }
            else {
                setSaveDataArray((old) => {
                    return [...old, ...schedulerData[i].children]
                })
            }
        }

        setStatusMessage(() => "All Secondary Events Deleted Successfully !!");
    }

    const deleteFooter = async (selectedItem) => {

        console.log("deletefooter");

        const allParents = schedulerData.filter(data => {
            if (!data.ParentProgramSchedule_id) {
                return data;
            }
        });

        var parent = SchedulingHelper.getParent(selectedItem.ParentProgramSchedule_id, schedulerDataRef.current)
        var parentIndex = schedulerData.indexOf(parent);
        var nextParent = SchedulingHelper.getNextParent(parent, allParents);
        var nextParentIndex = schedulerData.indexOf(nextParent);


        // Remove Footer ELement
        if (!isAutoSave) {
            // Setting Header Start Time First and then rrippling its children
            setSchedulerData((old) => {
                old[nextParentIndex].SlotDateTime = selectedItem.SlotDateTime;

                old[nextParentIndex].children = getRippleChildrens(old[nextParentIndex], old[nextParentIndex].children.filter(item => item._id != selectedItem._id), old)

                // ripplig cureent item so that footer gets removed.
                old[parentIndex].children = getRippleChildrens(old[parentIndex], old[parentIndex].children.filter(item => item._id != selectedItem._id), old)

                // setting scheduleinfo
                updateCommercialOverRunUnderRunCounts(old)
                schedulerDataRef.current = old;
                return [...old]
            })

            // setting for save
            setSaveDataArray((old) => {
                // adding next header and its children for save update
                return [...old, ...schedulerData[nextParentIndex], ...schedulerData[nextParentIndex].children]
            })

        }
        else {
            // Setting Header Start Time First and then rrippling its children
            setSchedulerData((old) => {
                old[nextParentIndex].SlotDateTime = selectedItem.SlotDateTime;

                old[nextParentIndex].children = getRippleChildrens(old[nextParentIndex], old[nextParentIndex].children.filter(item => item._id != selectedItem._id), old)

                // ripplig cureent item so that footer gets removed.
                old[parentIndex].children = getRippleChildrens(old[parentIndex], old[parentIndex].children.filter(item => item._id != selectedItem._id), old)

                // setting scheduleinfo
                updateCommercialOverRunUnderRunCounts(old)
                schedulerDataRef.current = old;
                return [...old]
            })

            // using next parent header and children without footer
            var savingList = [...schedulerData[nextParentIndex].children.filter(item => item._id.toLowerCase().indexOf("-footer") === -1), schedulerData[nextParentIndex]]

            console.log(savingList);
            var response = await API.saveSchedule({ schedules: savingList, deletedschedules: [] });
            console.log(response);

            if (!response.success) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const deleteSelectedSecondaryEvent = async (selectedItem) => {

        console.log("deleteSelectedSecondaryEvent")

        //check selectedItem is not empty
        if (selectedItem && selectedItem.PrototypeSource) {
            toast.error('Please select a secondary event', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //stores parent id
        const parent_id = selectedState[0].ParentProgramSchedule_id;
        //stores parent
        const parent = SchedulingHelper.getParent(parent_id, schedulerDataRef.current);
        //stores parent index
        const parentIndex = schedulerData.indexOf(parent)

        //adding prototype in primary event as embedded array
        setSchedulerData(old => {

            old[parentIndex].children = old[parentIndex].children.map(item => {

                if (item.SlotDateTime == selectedState[0].SlotDateTime) {
                    const index = item.Prototypes.indexOf(selectedItem);
                    if (index > -1) { // only splice array when item is found
                        item.Prototypes.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
                return item;
            });
            schedulerDataRef.current = old;
            return [...old];
        })


        if (isAutoSave) {

            let response = await API.saveSchedule({
                schedules: schedulerData[parentIndex].children,
                deletedschedules: []
            });

            console.log(response);

        }
        else {
            setSaveDataArray((old) => {
                return [...old, ...schedulerData[parentIndex].children]
            })
        }

        console.log(schedulerData[parentIndex].children);

    }

    const schedulerBreakChildrenDelete = async (dataItem) => {
        // delete footer
        if (dataItem._id && dataItem._id.includes("-footer")) {
            toast.error("Can't delete footer.", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        var { level_0_index, level_1_index, level_2_index } = SchedulingHelper.getBreakChildInfo(dataItem, schedulerDataRef.current);

        deleteBreakItemFromSchedulerGrid(level_0_index, level_1_index, level_2_index);

        // filtering pending booked spots to reflect on right side
        if (dataItem.Booking_id && dataItem.Booking_id != "") filterPendingBookedSpots();

        if (!isAutoSave) {
            if (dataItem._id) {
                setDeletedSchedules((old) => {
                    return [...old, dataItem]
                })

            }

        } else {

            var response = await API.saveSchedule({ schedules: [], deletedschedules: dataItem });
            console.log(response);
        }
    }

    const handleDeleteByKeyboard = async (event) => {
        if (event.key == 'Delete' || event.keyCode == 46 || event.code == 'Delete') {
            if (selectedStateRef.current.length == 0) {
                toast.error('Select item to delete', {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            } else {
                await handleScheduleDeleteByButton();
            }
        }
    }
    // here api save and delete action takes place
    const handleScheduleDataSave = async () => {
        console.log(saveDataArray);
        if (!isAutoSave) {

            const response = await API.saveSchedule({ schedules: saveDataArray, deletedschedules: deletedSchedules })
            console.log(response);
            setSaveDataArray(() => [])
            setDeletedSchedules(() => [])
            toast.error('Saved', {
                position: toast.POSITION.TOP_RIGHT
            });

        } else {
            toast.error('Auto Save is ON ! \nAll data will automatically save !', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const scheduleHeaderDelete = async () => {

        // delete footer
        if (selectedHeader == {}) {
            toast.error("Please Select Header First", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        console.log(selectedHeader);

        if (HasChildrenLocal(selectedHeader)) {
            toast.error("Cannot Delete !! Selected Header Contains Booked Commercials", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (HasReconciledChildrenLocal(selectedHeader)) {
            toast.error("Cannot Delete !! Selected Header Contains Reconciled Items", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //if autoSave is false then we are saving in a list and during the final saving we will send the delete array list
        if (!isAutoSave) {
            // adding header and children in delete list
            setDeletedSchedules((old) => {
                return [...old, selectedHeader]
            })

            // rremoving items of this header from saving children list
            setSaveDataArray((old) => old.filter(data => {
                if (data.ParentProgramSchedule_id != selectedHeader._id) return data;
            }))

            var parent = SchedulingHelper.getParent(selectedHeader._id, schedulerDataRef.current)
            var parentIndex = schedulerData.indexOf(parent);

            setSchedulerData((old) => {
                old.splice(parentIndex, 1)
                updateCommercialOverRunUnderRunCounts(old);
                schedulerDataRef.current = old;
                return [...old]
            })
        }
        else {
            var parent = SchedulingHelper.getParent(selectedHeader._id, schedulerDataRef.current)
            var parentIndex = schedulerData.indexOf(parent);

            var response = await API.saveSchedule({ schedules: [], deletedschedules: selectedHeader });
            console.log(response);

            if (response.success) {
                setSchedulerData((old) => {
                    old.splice(parentIndex, 1)
                    updateCommercialOverRunUnderRunCounts(old);
                    schedulerDataRef.current = old;
                    return [...old]
                })
            }
        }

        filterPendingBookedSpots();
    }

    const schedulerChildrenDelete = async (dataItem) => {

        // delete footer
        if (dataItem._id && dataItem._id.includes("-footer")) {
            deleteFooter(dataItem);
            return;
        }

        if (dataItem.IsReconciled) {
            toast.error("Selected Item Is Reconciled. Process Aborted !!", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        console.log(dataItem);
        //if autoSave is false then we are saving in a list and during the final saving we will send the delete array list
        if (!isAutoSave) {
            if (dataItem._id) {
                setDeletedSchedules((old) => {
                    return [...old, dataItem]
                })

            } else {
                setSaveDataArray((old) => old.filter(data => {
                    if (data.MediaEpisode_id == dataItem.MediaEpisode_id && data.ParentProgramSchedule_id == dataItem.ParentProgramSchedule_id) {
                    } else {
                        console.log(data);
                        return data
                    }
                }))
            }

            var parent = SchedulingHelper.getParent(dataItem.ParentProgramSchedule_id, schedulerDataRef.current)
            var parentIndex = schedulerData.indexOf(parent);

            setSchedulerData((old) => {
                old[parentIndex].children = getRippleChildrens(old[parentIndex], old[parentIndex].children.filter(item => item._id != dataItem._id), old)
                updateCommercialOverRunUnderRunCounts(old);
                schedulerDataRef.current = old;
                return [...old]
            })


        } else {
            var parent = SchedulingHelper.getParent(dataItem.ParentProgramSchedule_id, schedulerDataRef.current)
            var parentIndex = schedulerData.indexOf(parent);

            var response = await API.saveSchedule({ schedules: [], deletedschedules: dataItem });
            console.log(response);

            if (response.success) {
                setSchedulerData((old) => {
                    var children = old[parentIndex].children.filter(item => item._id != dataItem._id);
                    old[parentIndex].children = getRippleChildrens(old[parentIndex], children, old)
                    updateCommercialOverRunUnderRunCounts(old);
                    schedulerDataRef.current = old;
                    return [...old]
                })
            }
        }

        filterPendingBookedSpots();
    }

    const deleteItemFromSchedulerGrid = (parentIndex, childIndex, isRipple) => {

        var schedulerDataLocal = [...schedulerDataRef.current];
        //deleting
        schedulerDataLocal[parentIndex].children.splice(childIndex, 1);

        if (isRipple) {
            schedulerDataLocal[parentIndex].children = getRippleChildrens(schedulerDataLocal[parentIndex], schedulerDataLocal[parentIndex].children, schedulerDataLocal)
        }

        schedulerDataRefSetter(schedulerDataLocal);

    }

    const deleteBreakItemFromSchedulerGrid = (parentIndex, childIndex, breakChildIndex, isRipple = true) => {

        let schedulerDataLocal = [...schedulerDataRef.current];

        //deleting
        schedulerDataLocal[parentIndex].children[childIndex].children.splice(breakChildIndex, 1);

        if (isRipple) {
            schedulerDataLocal[parentIndex].children[childIndex].children = getRippleBreakChildrens(schedulerDataLocal[parentIndex].children[childIndex], schedulerDataLocal[parentIndex].children[childIndex].children);
        }

        schedulerDataRefSetter(schedulerDataLocal);

    }

    const deleteDraggedItemFromBookingCollection = (draggedBookingIds) => {
        setPendingBookedCommercialSpots(pendingBookedCommercialSpots.filter(item => !draggedBookingIds.includes(item._id)))
    }

    // delete end=====================================

    return <>
        <div style={{ width: '100%', height: '85%' }}>
            <ScheduleHeader downloadExcel={downloadExcel} schedulerData={schedulerData} setShowFilterGrid={setShowFilterGrid} setShowFilterGridData={setShowFilterGridData} date={date} selectedChannel={selectedChannel} isAutoSave={isAutoSave} handleScheduleDataSave={handleScheduleDataSave} handleAutoSave={handleAutoSave} refresh={() => { loaddataNew() }} handleScheduleDeleteByButton={handleScheduleDeleteByButton} handleExport={handleExport} showGlobalReplacement={showGlobalReplacement}
                handleScheduleCopyByButton={handleScheduleCopyByButton} handleScheduleCutByButton={handleScheduleCutByButton} handleSchedulePasteByButton={handleSchedulePasteByButton} openPlanning={openPlanning} handleFilterDialog={handleFilterDialog} deleteAllFooter={deleteAllFooter} deleteAllSecondaryEvent={deleteAllSecondaryEvent}
                onStyleSheetSelection={onStyleSheetSelection} handlePdfExport={handlePdfExport} onSchedulePreference={() => onSchedulePreference()} onCheckList={() => onCheckList()} setSearchWord={setSearchWord} exportPlanetCast={exportPlanetCast} onDateChangeLeft={onDateChangeLeft} onDateChangeRight={onDateChangeRight} />


            <Splitter
                panes={horizontalPanes}
                onChange={onHorizontalSplitterChange}
                style={{ height: '95%', marginTop: '-15px' }}
            >

                <Splitter
                    panes={verticalPanes}
                    orientation={"vertical"}
                    onChange={onVerticalSplitterChange}
                >

                    {/* parent grid */}

                    {showFilterGrid ? <Grid
                        style={{ userSelect: "none", height: '100%' }}
                        data={orderBy(showFilterGridData.map(item => {
                            item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id)
                            return item;
                        }), [
                            {
                                field: "SlotDateTime",
                                dir: "asc",
                            },
                        ])}
                        rowRender={GridRowRenderScheduleChildren}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: true,
                            cell: false,
                            mode: {
                                value: 'multiple',
                                label: 'Multiple selection mode'
                            },
                        }}
                        onSelectionChange={onSelectionChange}
                    >

                        <Column width="102px" field="SlotDateTime" title={lang.time_column} cell={MyTimeCell} />
                        <Column width="180px" field="mediaEpisode.Title" title={lang.title_column} />
                        <Column width="140px" field="mediaEpisode.AssetId" title={lang.assetId_column} />
                        <Column width="140px" field="MediaId" title={lang.media_id_column} />
                        <Column width="140px" field="mediaEpisode.MediaCategory.Description" title={lang.media_category_column} />
                        <Column width="100px" field="Duration" title={lang.duration_column} cell={MyTimeCell} />
                        <Column width="140px" field="Type" title={lang.type_column} cell={MyScheduleTypeCell} />
                        <Column width="65px" field="Prototypes.length" title={lang.prototypes_column} cell={mySecondaryEventCountCell} />

                    </Grid> :
                        <Grid
                            style={{ userSelect: "none", height: '100%' }}
                            data={orderBy(schedulerData, [
                                {
                                    field: "SlotDateTime",
                                    dir: "asc",
                                },
                            ])}
                            detail={DetailComponent}
                            expandField="expanded"
                            onExpandChange={expandChange}
                            rowRender={GridRowRenderSchedule}
                            onItemChange={itemChange}
                            editField={editField}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_HEADER}
                            selectable={{
                                enabled: true,
                                // drag: false,
                                // cell: false,
                                mode: "multiple",
                            }}
                            onHeaderSelectionChange={onAllHeaderSelectionChange}
                        >
                            <Column field={SELECTED_HEADER} width="50px" cell={CheckboxCell} editor='boolean' />
                            {dataColumns.map((col) => gridPreferenceColumn(col))}

                            {/* add checkbox here */}
                            {/* <Column field={SELECTED_HEADER} width="50px" cell={CheckboxCell} editor='boolean' />
                            <Column width="115px" field="SlotDateTime" title={lang.time_column} cell={MyTimeCell} />
                            <Column width="180px" field="mediaEpisode.Title" title={lang.title_column} />
                            <Column width="140px" field="mediaEpisode.AssetId" title={lang.assetId_column} />
                            <Column width="140px" field="MediaId" title={lang.media_id_column} />
                            <Column width="140px" field="mediaEpisode.MediaCategory.Description" title={lang.media_category_column} />
                            <Column width="100px" field="Duration" title={lang.duration_column} cell={MyTimeCell} />
                            <Column width="140px" field="Type" title={lang.type_column} cell={MyScheduleTypeCell} />
                            <Column width="95px" field="" title={lang.prototypes_column} style={{ color: "#FFFFFF" }} />
                            <Column width="140px" field="Info" title={lang.info_column} style={{ color: "#FFFFFF" }} /> */}

                        </Grid>

                    }



                    {/* Secondary Evennts */}
                    <div style={{ height: '100%' }}>
                        <p style={{ margin: '0px', fontWeight: 'bold' }}> # {lang.secondary_events_label} </p>
                        <Grid

                            data={selectedState[0] == undefined ? [] : selectedState[0].Prototypes}
                            rowRender={GridRowRenderScheduleSecondaryEvents}
                            style={{
                                height: "95%",
                            }}
                            st
                        >
                            <Column field="Name" title={lang.name_column} width="80px" />
                            <Column field="ID" title={lang.id_column} width="80px" />
                            <Column field="ProtoTypeSource.Name" title={lang.source_column} width="120px" />
                            <Column field="StartTriggerType.Description" title={lang.start_type_column} width="100px" />
                            <Column field="StartOffset" title={lang.start_offset_column} cell={MyTimeCell} width="100px" />
                            <Column field="EndTriggerType.Description" title={lang.end_type_column} width="100px" />
                            <Column field="EndOffset" title={lang.end_offset_column} cell={MyTimeCell} width="100px" />
                            <Column field="Text1" title={lang.text_1_column} width="180px" />
                            <Column field="Text2" title={lang.text_2_column} width="180px" />
                            <Column field="Text3" title={lang.text_3_column} width="180px" />

                        </Grid>
                        <ContextMenu ref={se} model={
                            [
                                { label: `${lang.delete_actionbutton_menutext}`, icon: 'pi pi-fw pi-trash', command: () => { deleteSelectedSecondaryEvent(dataItemForContextMenuRef.current); } },

                            ]
                        } />
                    </div>
                </Splitter>

                <Box sx={{ width: '97%', typography: 'body1' }} >
                    <TabContext value={tabNumber} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                {utility.hasModuleRight(MODULE.SCHEDULE_MEDIA) && <Tab label={lang.media_label} value="1" />}
                                {utility.hasModuleRight(MODULE.SCHEDULE_BOOKED_COMMERCIAL) && <Tab label={lang.booked_label} value="2" />}
                                {utility.hasModuleRight(MODULE.SCHEDULE_PROTOTYPE) && <Tab label={lang.prototypes_label} value="3" />}
                            </TabList>
                        </Box>
                        {utility.hasModuleRight(MODULE.SCHEDULE_MEDIA) && <TabPanel value={"1"}>
                            {payloadref.current && <ScheduleMediaCollection title={lang.media_library_dialog_header} entityname={ENTITYNAME.MediaEpisode} payload={payloadref.current} />}
                        </TabPanel>}
                        {utility.hasModuleRight(MODULE.SCHEDULE_BOOKED_COMMERCIAL) && <TabPanel value={"2"}>
                            <ScheduleBookedCommercialCollection autoFillBookedCommercials={autoFillBookedCommercials} refreshBookingCommercialdata={refreshBookingCommercialdata} pendingBookedCommercialSpots={pendingBookedCommercialSpots} filterPendingBookedSpots={filterPendingBookedSpots} gridRowRender={GridRowRenderBookedCommercial} setSelectedState={setSelectedStateForBookedComercial} selectedState={selectedStateForBookedComercial} wherestatement={['Channel.SID', '=', selectedChannel.SID]} />
                            {/* <ContextMenu
                                ref={bookedCommercialCollectionCm}
                                model={[
                                    { label: `Make Good`, icon: "pi pi-fw pi-window-minimize", command: () => { onBookedCommercialMakeGood(bookedCommercialCollectionRef.current) } },
                                ]}
                            /> */}
                        </TabPanel>}

                        {utility.hasModuleRight(MODULE.SCHEDULE_PROTOTYPE) && <TabPanel value={"3"} >

                            <Collection entityname="prototype" hideArchive={true} gridstyle={{
                                height: "63vh",
                                width: '100%'
                            }} showCommandCell={false} showAdd={false} showPreferenceButton={true} showBack={false} gridRowRender={GridRowRenderPrototypes} showTitle={false} wherestatement={['Channel.SID', '=', selectedChannel.SID]} />

                        </TabPanel>}

                    </TabContext>
                </Box>

            </Splitter>
        </div>
        <ContextMenu ref={hcm} model={
            [
                { label: `${lang.edit_actionbutton_menutext}`, icon: 'pi pi-fw pi-pencil', command: () => { setShowPlanningEditFormDialog() } },
                { label: `${lang.delete_header_actionbutton_menutext}`, icon: 'pi pi-fw pi-trash', command: () => { scheduleHeaderDelete() } },
                //Insert Place Holder
                {
                    label: `${lang.insert_break_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-envelope', command: () => {
                        if (selectedState.length == 0 || selectedState.length > 1) {
                            toast.error('Select target item from schedule', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            setShowInsertPlaceHolderFilter(old => !old);
                        }
                    }
                },
                {
                    label: `${"Create Breaks"}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-envelope', command: () => {
                        if (selectedState.length == 0 || selectedState.length > 1) {
                            toast.error('Select target item from schedule', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            setLoopBreak(true);
                            setShowInsertPlaceHolderFilter(old => !old);
                        }
                    }
                },
                { label: `${lang.program_replacement_actionbutton_menutext}`, icon: 'pi pi-fw pi-trash', command: () => { setShowProgramReplacementDialog(true) } },
                { label: `${lang.history_actionbutton_menutext}`, icon: 'pi pi-fw pi-history', command: () => { setShowHistory(true) } },

            ]
        } />
        <ContextMenu ref={cm} model={
            [
                //filter
                showFilterGrid ? { label: `${lang.remove_filter_actionbutton_menutext}`, icon: 'pi pi-fw pi-filter', command: () => { setSearchWord('false'); setShowFilterGrid(false); } } :
                    { label: `${lang.filter_actionbutton_menutext}`, icon: 'pi pi-fw pi-filter', command: () => { onSchedulerChildrenFilter(); } },


                //Insert Place Holder
                {
                    label: `${lang.insert_break_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-envelope', command: () => {
                        if (selectedState.length == 0 || selectedState.length > 1) {
                            toast.error('Select target item from schedule', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            setShowInsertPlaceHolderFilter(old => !old);
                        }
                    }
                },


                //delete
                { label: `${lang.delete_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-trash', command: () => { schedulerChildrenDelete(dataItemForContextMenuRef.current); } },
                //cut
                { label: `${lang.cut_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-minus', command: () => { schedulerChildrenCut(dataItemForContextMenuRef.current); } },
                //copy
                {
                    label: `${lang.copy_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-copy', command: () => {
                        schedulerChildrenCopy(dataItemForContextMenuRef.current);
                    }
                },
                //paste
                { label: `${lang.paste_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-plus', command: () => { schedulerChildrenPaste(dataItemForContextMenuRef.current); } },
                //se overview
                { label: `${lang.se_overview_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-book', command: () => { showSecondaryEventOverviewDialogProp() } },
                { label: `${lang.global_replacement_actionbutton_menutext}`, icon: 'pi pi-fw pi-sync', command: () => { showGlobalReplacement() } },


            ]
        } />
        <ContextMenu ref={bc} model={
            [

                //delete
                { label: `${lang.delete_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-trash', command: () => { schedulerBreakChildrenDelete(dataItemForContextMenuRef.current); } },
                //cut
                { label: `${lang.cut_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-minus', command: () => { schedulerChildrenCut(dataItemForContextMenuRef.current); } },
                //copy
                {
                    label: `${lang.copy_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-copy', command: () => {
                        schedulerChildrenCopy(dataItemForContextMenuRef.current);
                    }
                },
                //paste
                { label: `${lang.paste_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-plus', command: () => { schedulerChildrenPaste(dataItemForContextMenuRef.current); } },
                //se overview
                { label: `${lang.se_overview_actionbutton_menutext}`, disabled: showFilterGrid, icon: 'pi pi-fw pi-book', command: () => { showSecondaryEventOverviewDialogProp() } },
                { label: `${lang.global_replacement_actionbutton_menutext}`, icon: 'pi pi-fw pi-sync', command: () => { showGlobalReplacement(); } },


            ]
        } />



        {/* <ScheduleFooter onPreferenceFilter={onPreferenceFilter} cutPasteDataItem={cutPasteDataItemRef.current.length} copyPasteDataItem={copyPasteDataItem.length} selectedState={selectedState.length} scheduleInfo={scheduleInfo} statusMessage={statusMessage} onHeaderExpandChange={onHeaderExpandChange} searchWord={searchWord} /> */}

        {
            preferenceFilter && <UserPreference entityName={ENTITYNAME.SCHEDULE_MAIN_GRID} handleClose={onPreferenceFilter} />
        }

        {showFilter && <Dialog title={lang.select_channels_dialog_header} onClose={handleFilterDialog} width={350}
            height={500}>
            <div><label>{lang.channel_label} :</label></div>

            <div className="mb-3">
                <DropDownList
                    data={ChannelList.map(c => ({ name: c.name, SID: c.SID }))}
                    textField="name"
                    dataItemKey="SID"
                    value={selectedChannel}
                    onChange={handlePopupChannelDropdown}
                />

            </div>
            <div><label>{lang.date_label}:</label></div>
            <div><Calendar value={date} onChange={handleChangeDate} /></div>



            <DialogActionsBar>
                <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={handleFilterDialog}>
                    {lang.cancel_button_text}
                </button>
                <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={handlePopupConfirm}>
                    {lang.confirm_button_text}
                </button>
            </DialogActionsBar>
        </Dialog>}
        {styleSheetSelectionOpen && <CollectionSelection addButtonTitle={"Apply"} entityname={ENTITYNAME.SecondaryEventStyleSheet} wherestatement={['Channel.SID', '=', selectedChannel.SID]} setDataList={onStyleSheetSelectionClose} closeForm={onStyleSheetSelection} existingItems={[]} checkDuplicate={false} width={"40vw"} height={"40vh"} />}
        {schedulePreference && <SchedulePreference closeForm={onSchedulePreference} mediaCategoryTypePreferenceData={mediaCategoryTypePreferenceData} entityname={ENTITYNAME.UserPreference} updateSchedulingPreferenceStyle={updateSchedulingPreferenceStyle} />}
        {showSecondaryEventOverviewDialog &&
            <SchedulePrototypeOverview DATA_ITEM_KEY={DATA_ITEM_KEY} SELECTED_FIELD={SELECTED_FIELD}
                selectedItem={selectedState[0]} MyTimeCell={MyTimeCell}
                setShowSecondaryEventOverviewDialog={showSecondaryEventOverviewDialogProp}
                saveSecondaryChangedData={saveSecondaryChangedData} />
        }

        {showPlanningEditForm && <PlanningEditForm isOpenFromSchedule={true} onClose={oncloseEditForm} dataItem={{ ...selectedHeader, Title: selectedHeader.mediaEpisode.Title }} onUpdateFromPlanningEditForm={onUpdateFromPlanningEditForm} closeEditForm={oncloseEditForm} />}

        {showInsertPlaceHolderFilter && <Dialog
            title={lang.place_holder_dialog_header}
            width={"600px"}
            onClose={() => {
                setShowInsertPlaceHolderFilter(old => !old);
            }}
        >
            <div className='row' style={{ marginLeft: "-02px", width: "570px" }}>
                <div className="col-12">

                    <CollectionSelection
                        entityname={ENTITYNAME.MediaEpisode}
                        wherestatement={['IsBreak', '=', true]}
                        setDataList={setSelectedPlaceHolderMedia}
                        mode={"single"}
                        closeForm={() => {
                            setShowInsertPlaceHolderFilter(old => !old);
                        }}
                    />

                </div>
            </div>
        </Dialog>}

        {showProgramReplacementDialog && <Dialog
            title={lang.place_holder_dialog_header}
            width={"600px"}
            onClose={() => {
                setShowProgramReplacementDialog(old => !old);
            }}
        >
            <div className='row' style={{ marginLeft: "-02px", width: "570px" }}>
                <div className="col-12">

                    <CollectionSelection
                        title={`${lang.select_program_to_replace_with_collection_title} ` + selectedStateRef.current[0]?.mediaEpisode.Title}
                        entityname={ENTITYNAME.MediaEpisode}
                        wherestatement={['MediaCategory.isLong', '=', true]}
                        setDataList={programReplacement}
                        mode={"single"}
                        closeForm={() => {
                            setShowProgramReplacementDialog(false);
                        }}
                    />

                </div>
            </div>
        </Dialog>}

        {showGlobalReplacementDialog &&
            <GlobalReplacement gloabalFindAndReplace={gloabalFindAndReplace} selectedState={selectedStateRef.current} closeForm={cancelGlobalReplacementDialog} />}

        {showHistory && <MediaEpisodeHistory selectedData={selectedState[0].mediaEpisode} closeForm={() => setShowHistory(false)} />}
        {showCheckList &&
            <CheckLists
                closeForm={onCheckList}
                data={schedulerData}
            />}
        <ToastContainer />
    </>
}




export default Scheduler;