/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../API/api";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../forms/helpercomponents/CustomGridCells/DateCell";
import { LookupCell } from "../forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../forms/helpercomponents/CustomGridCells/ImageCell";
import EditForm from "../forms/helpercomponents/editForm";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, FILEURL, SOCKET_EVENTS, SOCKET_ACTION, RAIL_STATUS, LOGEVENT } from "../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../forms/helpercomponents/CustomGridCells/TimeCell";
import { collectionMenuModel } from "../forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { utility } from "../utility/utilityProvider";
import { ArrayCell } from "../forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../forms/helpercomponents/buttons/PreferenceRoundButton";
import { EnumCell } from "../forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../forms/helpercomponents/buttons/RoundButton";
import { PlatformCell } from "../forms/helpercomponents/CustomGridCells/PlatformCell";
import MyStatusCell from "../../components/MyStatusCell";
import { IconCell } from "../forms/helpercomponents/CustomGridCells/IconCell";
import socket from "../socket/socket";
import { getter } from "@progress/kendo-data-query";
import { MyToggleButton } from "../forms/helpercomponents/CustomGridCells/MyToggleButton";
import { Dialog } from "@progress/kendo-react-dialogs";
import EditRoundButton from "../forms/helpercomponents/buttons/EditRoundButton";
import CollectionSelection from "../forms/CollectionSelection";
import { ConfirmAlert } from "../../ConfirmAlert";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "../forms/helpercomponents/Loader";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export const RailsCollection = (props) => {
  //possible props
  let page_take = props.page_take ?? 50;
  let showAdd = props.showAdd ?? true;
  let showBack = props.showBack ?? true;
  let showImport = props.showImport ?? false;
  let showExport = props.showExport ?? true;
  let showArchive = props.showArchive ?? true;
  let showCommandCell = props.showCommandCell ?? true;
  let showEdit = props.showEdit ?? true;
  let showDelete = props.showDelete ?? true;
  let showTitle = props.showTitle ?? true;
  let gridRowRender = props.gridRowRender;
  let CustomEditForm = props.customEditForm ?? EditForm;
  let filterable = props.filterable ?? false;
  let sortable = props.sortable ?? true;
  let isPageable = props.isPageable ?? true;
  let wherestatement = props.wherestatement;
  let andWhereStatement = props.andWhereStatement;
  let sort = props.sort ?? { SID: 1 };
  let customColumns = props.customColumns ?? [];
  let openNewEditForm = props.openNewEditForm ?? false;
  let myTitle = props.title ?? "";
  let myMenuModel = props.menuModel ?? collectionMenuModel;
  // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022
  let entityName = props.entityname;
  let showPreferenceButton = props.showPreferenceButton ?? true;
  let hideArchiveProps = props.hideArchive ?? false;
  let disableMutliSelect = props.disableMutliSelect ?? false;

  //Add this for media management preference column
  const preferenceColumnENntityName =
    props.preferenceEntityName && props.preferenceEntityName.length > 0
      ? props.preferenceEntityName
      : entityName;
  // example custome columns[]
  const [gridData, setGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({ SID: 1 });
  const [warningDialog, setWarningDialog] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showPreferences, setShowPreferences] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showArchived, setShowArchived] = useState(false);
  const [hideArchive, setHideArchive] = useState(hideArchiveProps);
  const [selectedState, setSelectedState] = useState({});
  const [showOttCampaign, setShowOttCampaign] = useState(false);
  const [ottCampaignGridData, setOttCampaignGridData] = useState([]);
  const [showAttachOttCampaign, setShowAttachOttCampaign] = useState(false);
  const [railsStatusItem, setRailsStatusItem] = useState({
    _id: 0,
    Description: "All"
  });
  const initialSort = { field : Object.keys(sort)[0], dir : sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc"}
  const [gridSort, setGridSort] = useState([initialSort]);

  const selectedItemsRef = useRef([]);
  const setSelectedItem = (items) => {
    selectedItemsRef.current = items;
  };

  const selectedOttCampaignRef = useRef(null);
  const setSelectedOttCampaignRef = (data) => {
    selectedOttCampaignRef.current = data;
  }

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  //only to get wherestatement from href cell
  const location = useLocation();
  const lang = useTranslation();

  let href = location.state ? location.state.href : false;
  if (href) {
    wherestatement = location.state.wherestatement;
  }

  const onSearch = (e) => {
    e.preventDefault();
    let text = e.target.value?.trim();
    setSearchText(text);
  };

  // Search 
  useEffect(() => {
    let sortEvent = gridSort[0] ?? initialSort;
    if (searchText != "") {
      const timeOut = setTimeout(() => {
        setIsLoading(true);
        loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, railsStatusItem._id);
      }, 700);
      return () => clearTimeout(timeOut);
    } else {
      setIsLoading(true);
      setPage({ skip: 0, take: page_take });
      loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, railsStatusItem._id);
    }
  }, [searchText]);

  const handleRailStausChange = (e) => {
    loaddata(
      { per_page: page_take, current_page: 1 },
      wherestatement,
      andWhereStatement,
      searchText,
      false,
      e.target.value._id
    );
    console.log(e.target.value);
    setRailsStatusItem(e.target.value);
  }

  const enterEdit = (item) => {
    console.log("onEnterEdit");
    if (openNewEditForm === false) {
      setOpenForm(true);
      setEditItem(item);
    } // need to navigate to full page edit view
    else {
      navigate(`${item.SID}`, {
        state: {
          copy: item.copy ? true : false,
          quickEdit: item.quickEdit ? true : false,
        },
      });
    }
  };

  //publish for OTT
  const onPublish = async (itemID) => {
    console.log("publish");
    const res = await API.onPublishSeason(itemID);
    if (res.success) {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleNavigate = (item) => {
    if (item?.Module) {
      window.open(item.path, "MyTargetWindowName");
    } else {
      navigate(`${item.path}`, { state: item });
    }
  }

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleDelete = async (dataItem) => {
    console.log(dataItem);
    onConfirmDelete(dataItem);
  };

  const handleArchive = async (dataItem) => {
    if (entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season) {
      var resArchiveUpdated = await API.onChangeArchiveInSeriesSeason({
        _id: dataItem._id,
        archive: !dataItem.Archive,
        entityName: entityName,
      });
    } else {
      var resArchiveUpdated = await API.saveData(entityName, {
        ...dataItem,
        Archive: !dataItem.Archive,
      });
    }

    if (resArchiveUpdated.success) {
      console.log(`Archive is been updated to ${!dataItem.Archive}`);
      let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
      API.SaveLogs(logData);
      let sortEvent = gridSort[0] ?? initialSort;
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, railsStatusItem._id);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname });
      utility.deleteLocalStorageItem(entityName);
    } else {
      console.log(resArchiveUpdated.message);
    }
  };

  const onCloseUserPrefence = () => {
    loaddata(
      { per_page: page_take, current_page: 1 },
      wherestatement,
      andWhereStatement,
      searchText,
      showArchived,
      railsStatusItem._id
    );
    setShowPreferences(!showPreferences);
  };

  const setAsDefault = async (data) => {
    let setAsDefaultRes = await API.setAsDefault(entityName, data._id);
    if (setAsDefaultRes.success) {
      loaddata(
        { per_page: page_take, current_page: 1 },
        wherestatement,
        andWhereStatement,
        searchText,
        showArchived,
        railsStatusItem._id
      );
      socket.emit(SOCKET_EVENTS.onSocketData, {
        action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY,
        module: MODULE.ALL,
        data: props.entityname,
      });
      utility.deleteLocalStorageItem(entityName);
    } else {
      toast.error(setAsDefaultRes.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onShowOttCampaign = async (item) => {
    setSelectedOttCampaignRef(item);
    setOttCampaignGridData(item.Campaigns);
    setShowOttCampaign(true);
  }

  const onAttachCampaign = (item) => {
    setSelectedOttCampaignRef(item);
    setShowAttachOttCampaign(true);
  }

  //Edit Row
  const MyCommandCell = (props) => (
    <MenuCommandCell
      {...props}
      publish={onPublish}
      history={history}
      enterEdit={enterEdit}
      cancelEdit={handleCancelEdit}
      item={editItem}
      dataColumns={dataColumns}
      onDelete={handleDelete}
      onArchive={handleArchive}
      showEdit={showEdit}
      showDelete={showDelete}
      toNavigate={handleNavigate}
      myMenuModel={myMenuModel}
      openNewEditForm={openNewEditForm}
      setAsDefault={setAsDefault}
      showOttCampaign={onShowOttCampaign}
      attachCampaign={onAttachCampaign}
    />
  );

  const MyCustomToggleButton = (props) => (
    <MyToggleButton {...props} entityName={entityName} size={"small"} />
  );

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const Mystatuscell = (props) => (
    <div>
      <MyStatusCell
        title={props.dataItem.CurrentStatus}
        titleColor="#333"
        tileBackColor="#72eda9"
      />
    </div>
  );

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive, isActiveRail) => {
    console.log("here");

    if (props.gridColumns) {
      setDataColumns(props.gridColumns);
      setGridData(props.gridData);
      setIsLoading(false);
    } else {
      try {
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
        let columns = await API.getFormData(
          preferenceColumnENntityName,
          currentUserId
        );
        let json = await API.getRailsWithCampaign(
          {
            query: wherestatement,
            page: pagination.current_page,
            perPage: pagination.per_page,
            archive: archive,
            sort: finalSort,
          },
          columns,
          searchText,
          isActiveRail
        );

        setGridData(json.data);
        setTotal(json.pagination.total);
        //if custmoe colum is coming from compemnent level then no need  a column from Server API
        customColumns.length > 0
          ? setDataColumns(customColumns)
          : setDataColumns(columns);

        // console.log(columns)
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    }
  };

  const handleErrorDialogClose = () => {
    setDeleteError(!deleteError);
    setDeleteErrorMessage("");
  };

  const errorHandler = (message) => {
    setWarningDialog(!warningDialog);
    setDeleteError(true);
    setDeleteErrorMessage(message);
  };

  const onConfirmDelete = async (dataItem) => {
    const res = await API.deleteData(props.entityname, dataItem.SID);
    console.log("Loading");
    if (!res.success) {
      console.log(res.message);
      errorHandler(res.message);
      return;
    }
    let current_page = page.skip / page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort

    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, '', showArchived, railsStatusItem._id);
    socket.emit(SOCKET_EVENTS.onSocketData, {
      action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY,
      module: MODULE.ALL,
      data: props.entityname,
    });
    utility.deleteLocalStorageItem(props.entityname);
  };

  const pageChange = async (e) => {
    let current_page = e.page.skip / e.page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort;
    await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, railsStatusItem._id);
    setPage(e.page);
    cancelSelectedItem();
  };

  const onArchiveClick = () => {
    setShowArchived(!showArchived);
    let sortEvent = gridSort[0] ?? initialSort;
    if (!showArchived) {
      loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, true, railsStatusItem._id);
    } else {
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, false, railsStatusItem._id);
    }
    cancelSelectedItem();
  };

  const refreshCollection = () => {
    setIsLoading(true);
    let sortEvent = gridSort[0] ?? initialSort
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, railsStatusItem._id);
    setRailsStatusItem({
      _id: 0,
      Description: "All"
    })
  };

  const downloadExcel = async () => {
    if (gridData.length == 0) {
      toast.info(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let res = await API.exportGridExcel(gridData, dataColumns);
    console.log(res.data);
    if (res.success) {
      window.open(FILEURL.BASEURL + "downloadReport/" + res.data);
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);

    var selectedIds = Object.keys(newSelectedState);
    var tempSelected = [];

    selectedIds.map((value) => {
      if (newSelectedState[value]) {
        var filter = gridData.find((obj) => obj._id == value);
        tempSelected.push(filter);
      }
    });

    setSelectedItem(tempSelected);
  };

  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    if (checked) {
      selectedIds.map((value) => {
        if (newSelectedState[value]) {
          let filtered = gridData.find((obj) => obj._id == value);
          tempSelected.push(filtered);
        }
      });
    }
    setSelectedItem(tempSelected);
  };

  const onArchiveButtonClick = async () => {
    if (selectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_atleast_one_item}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    for (let i = 0; i < selectedItemsRef.current.length; i++) {
      const item = selectedItemsRef.current[i];
      await handleArchive(item);
    }
    cancelSelectedItem();
  };

  const onEnterEdit = () => {
    enterEdit({ SID: 0 });
    cancelSelectedItem();
  };

  const cancelSelectedItem = () => {
    setSelectedItem([]);
    setSelectedState({});
  };

  const editOttCampaign = (props) => {
    var data = props.dataItem;
    window.open(`/home/OttCampaign/${data.SID}`, '_blank');
  }

  const deleteDeAttachOttCampaign = async (data) => {

    let finalData = {
      ...data,
      Rails: data.Rails.filter(x => x != selectedOttCampaignRef.current._id)
    }

    let res = await API.saveData(ENTITYNAME.OttCampaign, finalData);

    let logData = { event: LOGEVENT.DEATTACH_CAMPAIGN, module: MODULE.RAILS, data: res.data, message: res.message };
    API.SaveLogs(logData);

    if (res.success) {
      // for ui 
      let deleteData = ottCampaignGridData.filter(x => x._id != finalData._id);
      setOttCampaignGridData(deleteData);
      toast.success(lang.deattach_successfully_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      refreshCollection();
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const myOttCampaignCommandCell = (props) => {
    return (
      <div className="mt-1" style={{ display: "flex" }}>
        <EditRoundButton onClick={() => editOttCampaign(props)} />
        <RoundButton icon="trash" onClick={() => ConfirmAlert(() => deleteDeAttachOttCampaign(props.dataItem), () => { }, lang.confirm_deattach_label, lang.are_you_sure_do_you_want_to_deattach_campaign_label)} />
      </div>
    );
  }

  const handleSelectedOttCampaign = async (data) => {

    // Add rails in ottcampaign
    let updatedData = []
    let duplicateData = [];
    data.map(item => {
      if (item.Rails && item.Rails.includes(selectedOttCampaignRef.current._id)) {
        duplicateData.push(item);
      }
      updatedData.push({ ...item, Rails: [...item.Rails, selectedOttCampaignRef.current._id] });
    });

    if (duplicateData.length == data.length) {
      toast.info(lang.rail_is_already_attach_in_selected_campaign_info_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let msg = "";
    if (duplicateData.length != 0) {
      let temp = duplicateData.map((obj) => obj["Name"]);
      msg = utility.subString(temp.join(", "), 50) + lang.already_exists_global_error_message;
    }

    toast.info(lang.successfully_add_campaign_message + '\n' + msg, {
      position: toast.POSITION.TOP_RIGHT
    });

    let res = await API.saveData(ENTITYNAME.OttCampaign, updatedData);

    let logData = { event: LOGEVENT.INSERT_CAMPAIGN, module: MODULE.RAILS, data: res.data, message: res.message };
    API.SaveLogs(logData);

    if (res.success) {
      refreshCollection();
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const onSortChange = (e) => {
    setGridSort(e.sort)
    let sortEvent = e.sort[0] ?? sort
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, railsStatusItem._id);
  }

  return (
    <div>
      {showTitle && (
        <>
          <h1 className="page-title txt-color-bludeDark">
            <i
              className="fa fa-table marlr"
              style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{myTitle === "" ? props.entityname : myTitle}</span>
          </h1>
        </>
      )}
      <div className="flex-container">
        <div className="martb mar">
          {showBack && (
            <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
          )}

          {showAdd && <AddRoundButton onClick={onEnterEdit}></AddRoundButton>}
        </div>

        <div className="flex-container-reverse flex-item-auto">
          <div className="input-group input-group w-300 martb">
            <input
              type="text"
              className="form-control shadow-inset-2 pt-0 pb-0"
              id="searchInput"
              placeholder={lang.search_button_text}
              onChange={onSearch}
              onFocus={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(true);
                }
              }}
              onBlur={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(false);
                }
              }}
            />
            <button
              id="search"
              style={{
                border: "0px",
                backgroundColor: "white",
                height: "38px",
              }}
            >
              <div className="input-group-append">
                <span className="input-group-text">
                  <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                </span>
              </div>
            </button>
          </div>
          {showPreferenceButton && (
            <div className="m-1" style={{ paddingTop: "12px" }}>
              <PreferenceRoundButton
                icon={"gear"}
                title={lang.preference_button_tooltip}
                onClick={() => {
                  setShowPreferences(!showPreferences);
                }}
              />
            </div>
          )}
          <div className="mt-1" style={{ paddingTop: "12px" }}>
            <RefreshButton onClick={refreshCollection} />
          </div>
          {showArchive && disableMutliSelect && (
            <div className="mt-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"inbox"}
                // style={{ margin: "0px 03px" }}
                // iconColor={"white"}
                title={showArchived ? lang.un_archive_label : lang.archive}
                onClick={onArchiveButtonClick}
              ></RoundButton>
            </div>
          )}
          {showImport && (
            <div className="mt-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"upload"}
                btnColor={"warning"}
                // style={{ margin: "0px 03px" }}
                // iconColor={"white"}
                title={lang.import_file_label}
                onClick={() =>
                  navigate("/home/DataMigration", {
                    state: { mediaCategory: props.mediaCategory },
                  })
                } //route navigate to data migration module
              />
            </div>
          )}
          {showExport && (
            <div className="m-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"download"}
                btnColor={"info"}
                style={{ color: "white" }}
                // iconColor={"white"}
                title={lang.export_button_tooltip}
                onClick={downloadExcel}
              />
            </div>
          )}

          {!hideArchive && (
            <div className="d-inline mt-3 mr-2">
              <input
                type="checkbox"
                name="FilteredArchive"
                checked={showArchived}
                onClick={onArchiveClick}
                onChange={() => { }}
              />{" "}
              {lang.archive}
            </div>
          )}
          <div className="d-inline mr-2 col-2" style={{ marginTop: '-9px' }}>
            <label htmlFor="OttRailType">{lang.status_label}</label>
            <DropDownList
              data={Object.keys(RAIL_STATUS).map((obj, index) => { return { _id: index, Description: obj } })}
              style={{
                backgroundColor: 'white',
              }}
              name="railsStatus"
              textField="Description"
              dataItemKey="_id"
              value={railsStatusItem}
              onChange={(e) => handleRailStausChange(e)}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loader />}

      {!isLoading && (
        <Grid
          style={
            props.gridstyle ?? {
              height: "76vh",
            }
          }
          // data={gridData}
          rowRender={gridRowRender}
          filterable={filterable}
          sort={gridSort}
          sortable={sortable}
          onSortChange={onSortChange}
          pageable={isPageable}
          skip={page.skip}
          take={page.take}
          onPageChange={pageChange}
          total={total}
          resizable={true}
          data={gridData.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: disableMutliSelect,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          dataItemKey={DATA_ITEM_KEY}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
        >
          {/* Multi Select Checkbox row */}
          {disableMutliSelect && (
            <GridColumn
              field={SELECTED_FIELD}
              width="50px"
              headerSelectionValue={
                gridData.findIndex(
                  (item) => !selectedState[idGetter(item)]
                ) === -1
              }
              locked={true}
              filterable={false}
            />
          )}

          {/* Edit Row */}
          {showCommandCell && (
            <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
          )}

          {/* Rows Loop */}
          {dataColumns.map((column, index) => {
            if (column.type === COLUMNSTYPE.date) {
              return (
                <GridColumn
                  sortable={true}
                  key={index}
                  field={column.name}
                  cell={DateOnlyCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (column.type === COLUMNSTYPE.datetime) {
              return (
                <GridColumn
                  sortable={true}
                  key={index}
                  field={column.name}
                  cell={DateCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (
              column.name.includes("Duration") ||
              column.type == COLUMNSTYPE.time
            ) {
              return (
                <GridColumn
                  sortable={true}
                  key={index}
                  field={column.name}
                  cell={TimeCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.name === "SID") {
              return;
              //we are not returning any value for SID column
            } else if (column.type === COLUMNSTYPE.image) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={ImageCell}
                  title={column.label}
                  width={column.width ?? 100}
                />
              );
            } else if (column.type === COLUMNSTYPE.select) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={MyDatalookupCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.type === COLUMNSTYPE.toggle) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={MyCustomToggleButton}
                  title={column.label}
                  width={85}
                />
              );
            } else if (column.type === COLUMNSTYPE.checkbox) {
              return (
                <GridColumn
                  sortable={true}
                  key={index}
                  field={column.name}
                  cell={CheckboxCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            } else if (column.type === COLUMNSTYPE.array) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={ArrayCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format ?? "Description"} //display item in object
                />
              );
            } else if (column.type === COLUMNSTYPE.href) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={HrefCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format}
                />
              );
            } else if (column.type === COLUMNSTYPE.enum) {
              return (
                <GridColumn
                  sortable={true}
                  key={index}
                  field={column.name}
                  cell={EnumCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.type === COLUMNSTYPE.platform) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={PlatformCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.type === COLUMNSTYPE.status) {
              return (
                <GridColumn
                  sortable={true}
                  key={index}
                  field={column.name}
                  cell={Mystatuscell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.type === COLUMNSTYPE.localdatetime) {
              return (
                <GridColumn
                  sortable={true}
                  key={index}
                  field={column.name}
                  cell={LocalDateTimeColumn}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.type === COLUMNSTYPE.icon) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={IconCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            } else {
              return (
                column.type !== COLUMNSTYPE.hidden && (
                  <GridColumn
                    sortable={true}
                    key={index}
                    field={column.name}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                )
              );
            }
          })}
        </Grid>
      )}

      {openForm && (
        <CustomEditForm
          {...props} //to get other props from customEditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          refresh={() =>
            loaddata(
              { per_page: page_take, current_page: 1 },
              wherestatement,
              andWhereStatement,
              searchText,
              showArchived,
              railsStatusItem._id
            )
          } //to refresh the collection when data added
          item={editItem}
          dataColumns={dataColumns}
        />
      )}

      {deleteError && (
        <DeleteErrorDialogBox
          message={`Data of ${props.title ?? props.entityname
            } con Not be deleted  Due to Foreign Key Inclusion`}
          onClose={handleErrorDialogClose}
          errorMessage={deleteErrorMessage}
          title={props.title}
        />
      )}
      {showPreferences && (
        <UserPreference
          entityName={entityName}
          preferenceColumnENntityName={props.preferenceEntityName}
          handleClose={onCloseUserPrefence}
        />
      )}

      {showOttCampaign && <Dialog
        title={lang.ott_campaign_dialog_header}
        onClose={() => setShowOttCampaign(false)}
        width={"700px"}
      >
        <Grid data={ottCampaignGridData} style={{ height: "35vh", marginTop: "5px" }}>
          <GridColumn cell={myOttCampaignCommandCell} width={"70px"} />
          <GridColumn field="Name" title={lang.name_column} editable={false} />
          <GridColumn field="FromDate" title={lang.from_date_column} cell={DateOnlyCell} editable={false} />
          <GridColumn field="ToDate" title={lang.to_date_column} cell={DateOnlyCell} editable={false} />
        </Grid>
      </Dialog>}
      {showAttachOttCampaign && <CollectionSelection entityname={ENTITYNAME.OttCampaign} wherestatement={["Archive", "=", false]} closeForm={() => setShowAttachOttCampaign(false)} width="700px" setDataList={handleSelectedOttCampaign} />}
    </div>
  );
};