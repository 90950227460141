/* eslint-disable */
import React, { useEffect, useState } from 'react';
import * as API from '../../../framework/API/api_digitalSign';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../../locale/useTranslation';
import { toast, ToastContainer } from 'react-toastify';
import { Dialog } from '@progress/kendo-react-dialogs';

const DigitalSignSignUpForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        name: '',
        email: '',
        password: '',
        companyName: '',
        countryCode: '',
        phone: '',
        industry: {},
        networkScreenSize: {},
        howDidYouFoundUs: {},
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showPassword, setShowPassword] = useState(false);
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const [dataValue, setDataValue] = useState({
        countryCodes: [],
        industries: [],
        networkScreenSizes: [],
        howDidYouFoundUs: [],
    });

    useEffect(() => {
        loadCombo();
    }, []);

    const loadCombo = async () => {
        let res = await API.getSignupMasters();
        if (res.success) {
            setDataValue(res.data);
            setDataItem({ ...dataItem, countryCode: res.data.countryCodes[0].Value });
        } else {
            toast.error(res.message);
        }
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        console.log(dataItem);
        if (dataItem.name == undefined || dataItem.name == '' || !dataItem.name?.toString().trim()) {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.email == undefined || dataItem.email == "" || !emailRegex.test(dataItem.email)) {
            toast.error(lang.please_enter_email_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.password == undefined || dataItem.password == '' || !dataItem.password?.toString().trim()) {
            toast.error(lang.please_enter_password_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.companyName == undefined || dataItem.companyName == '' || !dataItem.companyName?.toString().trim()) {
            toast.error(lang.please_enter_company_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.countryCode == undefined || dataItem.countryCode == "") {
            toast.error(lang.please_select_country_code_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.phone == undefined || dataItem.phone == '' || !dataItem.phone?.toString().trim()) {
            toast.error(lang.please_enter_phone_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.industry == undefined || Object.keys(dataItem.industry).length == 0) {
            toast.error(lang.please_select_industry_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.networkScreenSize == undefined || Object.keys(dataItem.networkScreenSize).length == 0) {
            toast.error(lang.please_select_network_screen_size_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.howDidYouFoundUs == undefined || Object.keys(dataItem.howDidYouFoundUs).length == 0) {
            toast.error(lang.please_select_how_did_you_found_us_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;
    }

    const handleSignup = async (e) => {
        e.preventDefault();

        if (!isValid()) return;
        let finalData = {
            name: dataItem.name,
            email: dataItem.email,
            password: dataItem.password,
            companyName: dataItem.companyName,
            countryCode: dataItem.countryCode,
            phone: dataItem.phone,
            industry: dataItem.industry ? {
                _id: dataItem.industry?._id,
                Description: dataItem.industry?.Description
            } : {},
            networkScreenSize: dataItem.networkScreenSize ? {
                _id: dataItem.networkScreenSize?._id,
                Description: dataItem.networkScreenSize?.Description
            } : {},
            howDidYouFoundUs: dataItem.howDidYouFoundUs ? {
                _id: dataItem.howDidYouFoundUs?._id,
                Description: dataItem.howDidYouFoundUs?.Description
            } : {},
        }
        console.log(finalData);
        let res = await API.onDigitalSignup(finalData);
        console.log(res);
        if (res.success) {
            toast.info(res.message);
            props.onClose();
        } else {
            toast.error(res.message);
        }
    };

    const handleTogglePassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    return (
        <Dialog
            title={lang.sign_up_dialog_header}
            onClose={props.onClose}
            width={500}
            height={700}
        >
            <div className="card-body" style={{ marginTop: '-7%' }}>
                <h3 style={{ fontSize: '2rem', lineHeight: 1, fontWeight: 600, letterSpacing: '.1rem' }} className="card-title text-center">{lang.sign_up_label}</h3>
                <div className="form-group">
                    <label htmlFor="name">{lang.name_label} *</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        name="name"
                        placeholder="Enter name"
                        value={dataItem.name}
                        onChange={onChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">{lang.email_address_label} *</label>
                    <input
                        type="email"
                        className="form-control form-control-sm"
                        name="email"
                        placeholder="Enter email"
                        value={dataItem.email}
                        onChange={onChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">{lang.password_label} *</label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control form-control-sm"
                        name="password"
                        placeholder="Password"
                        value={dataItem.password}
                        onChange={onChange}
                    />
                    <i style={{ position: "absolute", top: "34.6%", right: "42px", cursor: "pointer" }} className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={handleTogglePassword} />
                </div>
                <div className="form-group">
                    <label htmlFor="companyName">{lang.company_name_label} *</label>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        name="companyName"
                        placeholder="Enter Company Name"
                        value={dataItem.companyName}
                        onChange={onChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="countryCode">{lang.country_code_label} *</label>
                    <select
                        className="form-control form-control-sm"
                        name="countryCode"
                        value={dataItem.countryCode}
                        onChange={onChange}
                    >
                        {dataValue.countryCodes.map((country) => (
                            <option key={country.SID} value={country.Value}>
                                {country.Description} ({country.Value})
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="phone">{lang.phone_no_label} *</label>
                    <input
                        type="number"
                        className="form-control form-control-sm"
                        name="phone"
                        placeholder="Enter phone number"
                        value={dataItem.phone}
                        onChange={onChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="industry">{lang.industries_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: 'white' }}
                        data={dataValue.industries}
                        dataItemKey="_id"
                        textField="Description"
                        name='industry'
                        value={dataItem.industry}
                        onChange={onChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="networkScreenSize">{lang.network_screen_size_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: 'white' }}
                        data={dataValue.networkScreenSizes}
                        dataItemKey="_id"
                        textField="Description"
                        name='networkScreenSize'
                        value={dataItem.networkScreenSize}
                        onChange={onChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="howYouFoundUs">{lang.how_did_you_found_us_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: 'white' }}
                        data={dataValue.howDidYouFoundUs}
                        dataItemKey="_id"
                        textField="Description"
                        name='howDidYouFoundUs'
                        value={dataItem.howDidYouFoundUs}
                        onChange={onChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary btn-block" onClick={handleSignup} >{lang.sign_up_label}</button>
            </div>
            <ToastContainer />
        </Dialog>
    )
}

export default DigitalSignSignUpForm;