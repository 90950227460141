/* eslint-disable */
import React, { memo, useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTranslation } from "../../locale/useTranslation";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { toast } from "react-toastify";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import * as API from "../../framework/API/api";
import { utility } from "../../framework/utility/utilityProvider";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const editField = "inEdit";

export const MakeGoodSpotTransferCollection = memo((props) => {
  const lang = useTranslation();
  const [dataItem, setDataItem] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    setGridData(props.data.BookedSpots);
  }, []);

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  };

  const itemChange = (event) => {
    if (!event.dataItem[SELECTED_FIELD]) {
      setSelectedItem([...selectedItem, event.dataItem]);
    } else {
      var remove = selectedItem.filter((obj) => obj._id != event.dataItem._id);
      setSelectedItem(remove);
    }
    const field = event.field || "";
    const newData = gridData.map((item) =>
      item._id === event.dataItem._id
        ? {
            ...item,
            [field]: event.value,
          }
        : item
    );
    setGridData(newData);
  };

  const gridRowRender = (tr, props) => {
    const trProps = {
      onContextMenu: (e) => {
        ref.current = props.dataItem;
        cm.current.show(e);
      },
    };
    return React.cloneElement(tr, { ...trProps }, tr.props.children);
  };

  const transferToDate = async () => {
    var transferdateinMiliSec = new Date(dataItem.transferDate).getTime(); //utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.transferDate)    );

    if (dataItem.transferDate == undefined) {
      setStatusMessage(
        `${lang.please_select_transfer_date_booking_transfer_error_message}`
      );
      toast.error(
        `${lang.please_select_transfer_date_booking_transfer_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }

    if (selectedItem.length == 0) {
      setStatusMessage(
        `${lang.please_select_item_booking_transfer_error_message}`
      );
      toast.error(`${lang.please_select_item_booking_transfer_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    //selected item sort by lineNumber
    let data = selectedItem.sort((a, b) =>
      a.LineNumber > b.LineNumber ? 1 : -1
    );

    let invalidToTransfer = [];
    let prevLineNumber = 0;
    let newLineNumber = 0;
    let validToTransfer = []

    for (let i = 0; i < data.length; i++) {
      const bookedSpot = data[i];

      if (
        bookedSpot.Campaign.PeriodFrom > transferdateinMiliSec ||
        bookedSpot.Campaign.PeriodTo < transferdateinMiliSec
      ) {
        // setStatusMessage(`${lang.select_item_date_between_from_and_to_error_message}`);
        // toast.error(`${lang.select_item_date_between_from_and_to_error_message}`, {
        //     position: toast.POSITION.TOP_RIGHT
        // });
        invalidToTransfer.push(bookedSpot);
        continue;
      }

      if (bookedSpot.BookingDate > transferdateinMiliSec) {
        // setStatusMessage(`${lang.please_transfer_error_date_greater_than_bookingdate_message}`);
        // toast.error(`${lang.please_transfer_error_date_greater_than_bookingdate_message}`, {
        //     position: toast.POSITION.TOP_RIGHT
        // });
        invalidToTransfer.push(bookedSpot);
        continue;
      }

      if (prevLineNumber != bookedSpot.LineNumber) {
        newLineNumber = await utility.GetFreshMaxLineNo(bookedSpot.Campaign_Id);
      }

      let updatedata = {
        _id: bookedSpot._id,
        LineNumber: newLineNumber,
        MakeGood: false,
        BookingDate: transferdateinMiliSec,
      };
      let res = await API.saveData(ENTITYNAME.Booking, updatedata);

      if (res.success) {
        let logCapture = { event: LOGEVENT.MAKE_GOOD_TRANSFER, module: MODULE.SHOW_SPOTS, data: updatedata, message: "Make Good Transfer Successfull", _id: bookedSpot.Campaign_Id + '_' + updatedata?._id };
        API.SaveLogs(logCapture);

        setStatusMessage(
          `${lang.booking_transfer_succesfully_to_booking_transfer_error_message}` +
            dataItem.transferDate
        );
        toast.success(
          `${lang.booking_transfer_succesfully_to_booking_transfer_error_message}` +
            dataItem.transferDate,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        validToTransfer.push(updatedata)
        props.refreshdata();
        props.closeForm();
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      prevLineNumber = bookedSpot.LineNumber;
    }

    if (invalidToTransfer.length > 0) {
      setStatusMessage(
        `${invalidToTransfer.length} booked spot are invalid to transfer`
      );
      toast.error(
        `${invalidToTransfer.length} booked spot are invalid to transfer`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const refreshMakeGoodSpotCollection = () => {
    setGridData(props.data.BookedSpots);
    props.refreshdata();
  };

  return (
    <Dialog
      title={lang.makegoodspottransfer_dialog_header}
      onClose={props.closeForm}
      width={"820px"}
      height={"470px"}
    >
      <div className="flex-container mb-2">
        <div className="mt-1">
          <label htmlFor="">{lang.transfer_to_label}</label>
        </div>
        <div className="col">
          <input
            style={{ width: "160px" }}
            type="date"
            className="form-control form-control-sm"
            name="transferDate"
            value={dataItem.transferDate}
            onChange={onChange}
          />
        </div>
        <div className="mt-0">
          <ActionButton
            title={lang.transfer_button_tooltip}
            name={lang.transfer_button_text}
            onClick={() => transferToDate()}
          />
        </div>
        <div className="flex-container-reverse flex-item-auto">
          <div className="input-group input-group w-300">
            <input
              type="text"
              className="form-control shadow-inset-2 pt-0 pb-0"
              id="searchInput"
              aria-label="type 2 or more letters"
              placeholder={lang.search_button_text}
            />
            <button
              id="search"
              style={{
                border: "0px",
                backgroundColor: "white",
                height: "40px",
              }}
            >
              <div className="input-group-append">
                <span className="input-group-text">
                  <i style={{ margin: "5px 0px" }} className="fa fa-search" />
                </span>
              </div>
            </button>
          </div>
          <div className="mr-2" style={{ marginTop: "2px" }}>
            <RoundButton
              icon={"refresh"}
              title={lang.refresh_button_tooltip}
              onClick={refreshMakeGoodSpotCollection}
            />
          </div>
        </div>
      </div>

      <Grid
        data={gridData}
        editField={editField}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          mode: "single",
        }}
        dataItemKey={DATA_ITEM_KEY}
        onItemChange={itemChange}
        rowRender={gridRowRender}
        style={{ height: "330px" }}
      >
        <Column
          field={SELECTED_FIELD}
          width="80px"
          cell={CheckboxCell}
          filterable={false}
          editor="boolean"
        />
        <Column
          field="BookingDate"
          title={lang.booking_date_column}
          cell={DateOnlyCell}
          width="125px"
        />
        <Column field="SpotRate" title={lang.spot_rate_column} width="120px" />
        <Column field="LineNumber" title={lang.line_no_column} width="120px" />
        <Column
          field="TimeRangeFrom"
          title={lang.time_range_from_column}
          cell={TimeCell}
          width="120px"
        />
        <Column
          field="TimeRangeTo"
          title={lang.time_range_to_column}
          cell={TimeCell}
          width="120px"
        />
        <Column field="Rate10Sec" title={lang.rate10sec_column} width="120px" />
      </Grid>
      <div className='text-right mt-2' style={{ position: 'fixed', height: '30px', width: "820px", marginLeft: '-16px', background: "#3a3633" }}>
          <span className="mr-1" style={{ color: 'white' }}>{statusMessage}</span>
      </div>
    </Dialog>
  );
});
