/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ENTITYNAME, LOGEVENT, MODULE, PAYMENTMODE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";

export const AgencyEditForm = (props) => {
  const [dataItem, setDataItem] = useState({
    _id: props.item._id,
    SID: props.item.SID,
    Name: props.item.copy
      ? "Copy of " + props.item.Name
      : props.item.Name ?? "",
    Code: props.item.Code ?? "",
    Email: props.item.Email ?? "",
    DefaultTax: props.item.DefaultTax ?? {},
    IsCreditUnlimited: props.item.IsCreditUnlimited ?? false,
    Archive: props.item.Archive ?? false,
    PAN: props.item.PAN ?? "",
    GST: props.item.GST ?? "",
    CreditLimit: props.item.CreditLimit ?? 0,
    // PaymentMode: Object.keys(PAYMENTMODE).map((key, index) => {
    //   return { _id: index, Description: key };
    // }),
    Address: props.item.Address ?? "",
    PinCode: props.item.PinCode ?? "",
    PointOfContact: props.item.PointOfContact ?? "",
    BankDetail : props.item.BankDetail ?? {},    
    AgencyType: props.item.AgencyType ?? {}
  });
  console.log(props.item)
  console.log(dataItem)
  const [defaultTax, setDefaultTax] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);
  const [bankDetailItem , setBankDetailItem] = useState(props.item.BankDetail ?? {})
  const [agencyType, setAgencyType] = useState([]);
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const lang = useTranslation();

  useEffect(() => {
    loadcombo();
  }, []);

  // const loadEditData = async () => {
  //   let res = await API.getEntity(ENTITYNAME.Agency, parseInt(SID));
  //   let PAymentModeData =  Object.keys(PAYMENTMODE).map((key, index) => {
  //       return { _id: index, Description: key };
  //     })
  //     if(res.success) {
  //       let data = {
  //         ...res.data,
  //         PaymentMode : res.data.PaymentMode,
  //         AccountName : res.data.AccountName,
  //         BankName : res.data.BankName,
  //         AccountNumber : res.data.AccountNumber,
  //         SwiftCode : res.data.SwiftCode,
  //         IbanNumber : res.data.IbanNumber,
  //         Branch : res.data.Branch,
  //         Address : res.data.Address,
  //       }
  //       console.log(res.data);
  //     }
  // }

  const isValid = () => {
    if (
      dataItem.Name == undefined ||
      dataItem.Name == "" ||
      !dataItem.Name?.toString().trim()
    ) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (
      dataItem.Code == undefined ||
      dataItem.Code == "" ||
      !dataItem.Code?.toString().trim()
    ) {
      toast.error(`${lang.please_enter_code_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (
      dataItem.Address == undefined ||
      dataItem.Address == "" ||
      !dataItem.Address.trim()
    ) {
      toast.error(`${lang.please_enter_address_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (
      dataItem.PinCode == undefined ||
      dataItem.PinCode == "" ||
      !dataItem?.PinCode?.toString().trim()
    ) {
      toast.error(`${lang.please_enter_pin_code_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (
      dataItem.PointOfContact == undefined ||
      dataItem.PointOfContact == "" ||
      !dataItem.PointOfContact.trim()
    ) {
      toast.error(`${lang.please_enter_point_of_contact_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (
      dataItem.Email == undefined ||
      dataItem.Email == "" ||
      !emailRegex.test(dataItem.Email)
    ) {
      toast.error(`${lang.please_enter_email_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.CreditLimit < 0) {
      toast.error(
        `${lang.please_enter_positive_value_in_creditlimit_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return false;
    }
    if (!dataItem.DefaultTax || Object.keys(dataItem.DefaultTax).length == 0) {
      toast.error(`${lang.please_select_default_tax_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const loadcombo = async () => {
    var defaultTax = await API.getDataLookup(ENTITYNAME.Tax, {
      sort: { Description: 1 },
    });
    setDefaultTax(defaultTax.data);
    if (props.item.SID == 0) {
      setDataItem({
        ...dataItem,
        DefaultTax: utility.getDefaultItem(defaultTax.data),
      });
    }
    let paymentModeRes = Object.keys(PAYMENTMODE).map((key, index) => {
      return { _id: index, Description: key };
    });
    setPaymentMode(paymentModeRes);

    var agencyTypeRes = await API.getDataLookup(ENTITYNAME.AgencyType, {sort: { Description: 1 },});
    setAgencyType(agencyTypeRes.data);
  };

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else if (e.target.name == "IsCreditUnlimited") {
      setDataItem({
        ...dataItem,
        IsCreditUnlimited: !dataItem.IsCreditUnlimited,
        CreditLimit: 0,
      });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };
  const handleChange = (e) => {
    setBankDetailItem({ ...bankDetailItem ,[e.target.name]: e.target.value });
  }

  const handleSubmit = async () => {
    if (!isValid()) return;

    let localData = {
      _id: dataItem._id,
      SID: dataItem.SID ?? 0,
      Name: dataItem.Name,
      Code: dataItem.Code,
      Address: dataItem.Address,
      PinCode: dataItem.PinCode.toString(),
      PointOfContact: dataItem.PointOfContact,
      Email: dataItem.Email,
      PAN: dataItem.PAN,
      GST: dataItem.GST,
      CreditLimit: parseInt(dataItem.CreditLimit),
      DefaultTax: {
        _id: dataItem.DefaultTax._id,
        Description: dataItem.DefaultTax.Description,
      },
      IsCreditUnlimited: dataItem.IsCreditUnlimited ?? false,
      AgencyType: {
        _id: dataItem.AgencyType._id,
        Description: dataItem.AgencyType.Description,
      },
      Archive: dataItem.Archive ?? false,
      BankDetail: bankDetail,
      checkDuplicate: true,
      query: [["Name", "=", dataItem.Name]],
    };
    console.log(localData)

    let res = await API.saveData(ENTITYNAME.Agency, localData);
    if (res.success) {
      let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE_AGENCY : LOGEVENT.INSERT_AGENCY, module: MODULE.AGENCY, data: res.data, message: res.message };
      API.SaveLogs(logData);
      props.cancelEdit();
      props.refresh();
      utility.deleteLocalStorageItem(ENTITYNAME.Agency);
      return;
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  
      let bankDetail = {
        ...bankDetailItem,
        PaymentMode : bankDetailItem.PaymentMode,
        AccountName : bankDetailItem.AccountName,
        BankName : bankDetailItem.BankName,
        AccountNumber : bankDetailItem.AccountNumber,
        SwiftCode : bankDetailItem.SwiftCode,
        IbanNumber : bankDetailItem.IbanNumber,
        Branch : bankDetailItem.Branch,
        Address : bankDetailItem.Address,
     }

  

  const borderWithTitle = {
    position: "relative",
    fontSize: "12px",
    top: "-0.9em",
    marginLeft: "0.8em",
    display: "inline",
    backgroundColor: "white",
  };



  return (
    <>
      <Dialog
        title={
          props.item.Name ? props.item.Name : `${lang.agency_dialog_header}`
        }
        onClose={props.cancelEdit}
        width={"60%"}
      >
        <div className="row">
          <div className="col-12">
            <EditPageHeader
              title={""}
              onSubmit={handleSubmit}
              onCancel={props.cancelEdit}
              showTitle={false}
            />
            <div className="row">
              <div className="col-12">
                <div className="row mt-2">
                  <div className="col-4 form-group">
                    <label htmlFor="TabView">{lang.name_label} *</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="Name"
                      onChange={onChange}
                      value={dataItem.Name}
                    />
                  </div>
                  <div className="col-4 form-group">
                    <label htmlFor="TabView">{lang.code_label} *</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="Code"
                      onChange={onChange}
                      value={dataItem.Code}
                    />
                  </div>
                  <div className="col-4 form-group">
                    <label htmlFor="TabView">
                      {lang.point_of_contact_label} *
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="PointOfContact"
                      onChange={onChange}
                      value={dataItem.PointOfContact}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-4">
                    <label htmlFor="TabView">{lang.email_label} *</label>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-sm"
                        name="Email"
                        value={dataItem.Email}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <label htmlFor="TabView">{lang.address_label} *</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="Address"
                        onChange={onChange}
                        value={dataItem.Address}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <label htmlFor="TabView">{lang.pincode_label} *</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="PinCode"
                        onChange={onChange}
                        value={dataItem.PinCode}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 form-group">
                    <label htmlFor="TabView">{lang.pan_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="PAN"
                      value={dataItem.PAN}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-4 form-group">
                    <label htmlFor="TabView">{lang.gst_number_label}</label>
                    <input
                      className="form-control form-control-sm"
                      name="GST"
                      type="text"
                      onChange={onChange}
                      value={dataItem.GST}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="TabView">{lang.credit_limit_label} *</label>
                    <div className="form-group">
                      <input
                        className="form-control form-control-sm"
                        disabled={dataItem.IsCreditUnlimited}
                        type="number"
                        name="CreditLimit"
                        onChange={onChange}
                        value={dataItem.CreditLimit}
                        min={0}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 form-group">
                    <label htmlFor="TabView">{lang.default_tax_label} *</label>
                    <DropDownList
                      style={{ backgroundColor: "white" }}
                      data={defaultTax}
                      name="DefaultTax"
                      textField="Description"
                      dataItemKey="_id"
                      value={dataItem.DefaultTax}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-4 form-group">
                    <label htmlFor="TabView">{lang.agency_type_label} *</label>
                    <DropDownList
                      style={{ backgroundColor: "white" }}
                      data={agencyType}
                      name="AgencyType"
                      textField="Description"
                      dataItemKey="_id"
                      value={dataItem.AgencyType}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-3 mt-4">
                    <input
                      type="checkbox"
                      name="Archive"
                      value={dataItem.Archive}
                      checked={dataItem.Archive}
                      onChange={onChange}
                    />
                    <label htmlFor="TabView" className="pl-2">
                      {lang.archive}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <input
                      type="checkbox"
                      name="IsCreditUnlimited"
                      value={dataItem.IsCreditUnlimited}
                      checked={dataItem.IsCreditUnlimited}
                      onChange={onChange}
                    />
                    <label htmlFor="TabView" className="pl-2">
                      {lang.is_credit_unlimited_label}
                    </label>
                  </div>
                  
                </div>
              </div>
              <div
                className="mt-3"
                style={{
                  margin: "0px 0px 0px 30px",
                  height: "195px",
                  width: "55vw",
                  border: "0.05em solid #c4c2c2",
                  borderRadius: "5px!important",
                }}
              >
                <div style={borderWithTitle}>
                  &nbsp; {lang.bank_details_label} &nbsp;
                </div>
                <div className="row" style={{ marginLeft: "4px", marginRight: "3px" }}>
                  <div className="col-4">
                  <label>{lang.payment_mode_label}</label>
                    <DropDownList
                      style={{ backgroundColor: "white" }}
                      data={paymentMode}
                      name="PaymentMode"
                      textField="Description"
                      dataItemKey="_id"
                      value={bankDetailItem.PaymentMode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-4">
                  <label>{lang.account_name_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="AccountName"
                      value={bankDetailItem.AccountName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-4">
                  <label>{lang.bank_name_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="BankName"
                      value={bankDetailItem.BankName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-2" style={{ marginLeft: "4px" ,marginRight: "3px" }}>
                  <div className="col-4">
                  <label>{lang.account_number_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="AccountNumber"
                      value={bankDetailItem.AccountNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-4">
                  <label>{lang.swift_code_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="SwiftCode"
                      value={bankDetailItem.SwiftCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-4">
                  <label>{lang.iban_number_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="IbanNumber"
                      value={bankDetailItem.IbanNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-2" style={{ marginLeft: "4px", marginRight: "3px" }}>
                  <div className="col-4">
                  <label>{lang.branch_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="Branch"
                      value={bankDetailItem.Branch}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-8">
                  <label>{lang.address_label}</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="Address"
                      value={bankDetailItem.Address}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
