/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { Grid, GridColumn as Column, getSelectedState, GridNoRecords } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL, PAYMENTMODE } from "../../framework/constant/constant";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell"
import { useTranslation } from "../../locale/useTranslation";
import { getter } from "@progress/kendo-data-query";
import { utility } from "../../framework/utility/utilityProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from 'moment';
import { ConfirmAlert } from '../../ConfirmAlert';
import { RadioButton } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from "react-toastify";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";

const GeneratedBillCollection = () => {

  const lang = useTranslation();
  const SELECTED_FIELD = "selected";
  const DATA_ITEM_KEY = "_id";
  const idGetter = getter(DATA_ITEM_KEY);
  const [selectedBilling, setSelectedBilling] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [companyMaster, setCompanyMaster] = useState({});
  const selectedBillingLineRef = React.useRef({});
  const [generatedBills, setGeneratedBills] = useState([]);
  const [generatedBillData, setGeneratedBillData] = useState([]);
  const [statusMsg, setStatusMsg] = useState(lang.please_wait_loading_data_billing_error_message);
  const [agency, setAgency] = useState([]);
  const [client, setClient] = useState([]);
  const [selectedOption, setSelectedOption] = useState("AgencyButton");
  const [showPaymentReceived, setShowPaymentReceived] = useState(false);
  const [paymentReceivedDataIteam , setPaymentReceivedDataIteam] = useState({
    ReceivedDate: moment(new Date()).format("YYYY-MM-DD"),
    PaymentMode: {},
    ChequeNo: '',
    Description: '',

  })

  const defaultDates = utility.getFirstAndLastDateOfMonth();

  const blankDataItem = {
    StatusMsg: "",
    Agency: { SID: 0, Name: "All" },
    Client: { SID: 0, Name: "All" },
    From: defaultDates.FirstDate,
    To: defaultDates.LastDate
  }
  const [dataItem, setDataItem] = useState(blankDataItem);

  useEffect(() => {
    let from = new Date(dataItem.From).getTime();
    let to = new Date(dataItem.To).getTime();
    loadData(from, to);
  }, []);


  const loadData = async (fromDate, toDate) => {

    setGeneratedBills([]);
    setGeneratedBillData([]);

    setStatusMsg(lang.please_wait_loading_data_billing_error_message);
    // COMPANY MASTER
    let companyMasters = await API.getDataLookup(ENTITYNAME.Company, { sort: { Name: 1 } });
    setCompanyMaster(companyMasters.data);

    // CLIENT INFO
    let clientInfo = await API.getDataLookup(ENTITYNAME.ClientInfo);
    setClientInfo(clientInfo.data[0]);

    let generatedBills = await API.loadGeneratedBills([['InvoiceDate', '>=', fromDate], ['InvoiceDate', '<=', toDate]]);

    // CLEARING LOCAL STORAGE OF CLIENT AND AGENCY TO LOAD FRESH DATA EVERY TIME
    utility.deleteLocalStorageItem(ENTITYNAME.Agency);
    utility.deleteLocalStorageItem(ENTITYNAME.Client);

    let agencyRes = await API.getDataLookup(ENTITYNAME.Agency, { sort: { Name: 1 } });
    console.log(agencyRes);
    setAgency([{ SID: 0, Name: "All" }, ...agencyRes.data]);
    let clientRes = await API.getData(ENTITYNAME.Client, { query: ['Archive', '=', false], sort: { Name: 1 } });
    setClient([{ SID: 0, Name: "All" }, ...clientRes.data]);


    if (generatedBills.success) {
      setGeneratedBills(generatedBills.data);
      setGeneratedBillData(generatedBills.data);
      setStatusMsg(generatedBills.data.length == 0 && lang.no_data_found_error_message);
    } else {
      console.log(generatedBills.message)

    }

  }

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedBilling,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedBilling(newSelectedState);
      onSelection(newSelectedState);
    }
  );

  const onSelection = (selectedState) => {
    let selectedDataList = [];
    //loop for setting the selected obj in array
    generatedBills.forEach(async (obj) => {
      let key = obj._id;
      //if true means checked
      if (selectedState[key]) {
        selectedDataList.push(obj);
        selectedBillingLineRef.current = obj;

      }
    });
  }

  const onChangeRadioButtton = (e) => {
    setSelectedOption(e.target.name);
    let item = {}
    if (e.target.name == "AgencyButton") {
      item = { ...dataItem, Client: { SID: 0, Name: "All" } }
    } else {
      item = { ...dataItem, Agency: { SID: 0, Name: "All" } }
    }
    setDataItem(item);
    let from = new Date(dataItem.From).getTime();
    let to = new Date(dataItem.To).getTime();
    loadData(from, to);
  }

  const onChange = async (e) => {

    let filteredData = [];

    if (e.target.name == 'From') {
      let from = new Date(e.target.value).getTime();
      let to = new Date(dataItem.To).getTime();
      setDataItem({ ...dataItem, From: e.target.value });
      loadData(from, to);
      selectedBillingLineRef.current = {};
      setSelectedBilling({});
      
    }
    else if (e.target.name == 'To') {
      let from = new Date(dataItem.From).getTime();
      let to = new Date(e.target.value).getTime();
      setDataItem({ ...dataItem, To: e.target.value });
      loadData(from, to);
      selectedBillingLineRef.current = {};
      setSelectedBilling({});
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
      //filter on dropdown basis
      if (e.target.name == 'Agency' && dataItem.Client.SID != 0) {
        if (e.target.value.SID == 0) {
          filteredData = generatedBillData.filter((x) => x.Client._id == dataItem.Client._id);
        } else {
          filteredData = generatedBillData.filter((x) => x[e.target.name]._id == e.target.value._id && x.Client._id == dataItem.Client._id);
        }
      } else if (e.target.name == 'Client' && dataItem.Agency.SID != 0) {
        if (e.target.value.SID == 0) {
          filteredData = generatedBillData.filter((x) => x.Agency._id == dataItem.Agency._id);
        } else {
          filteredData = generatedBillData.filter((x) => x[e.target.name]._id == e.target.value._id && x.Agency._id == dataItem.Agency._id);
        }
      } else if (e.target.value.SID == 0) {
          filteredData = [...generatedBillData]
        } else {
          filteredData = generatedBillData.filter((x) => x[e.target.name]._id == e.target.value._id);
        }
      setGeneratedBills(filteredData);
      selectedBillingLineRef.current = {};
    }

  }

  const getTelecastdata = async (item) => {
    let billingDataToPrint = {};
    billingDataToPrint.FromDate = item.PeriodFrom;
    billingDataToPrint.ToDate = item.PeriodTo;
    billingDataToPrint.Campaign = item.Campaign;
    billingDataToPrint.BillingLine = item.Campaign.BillingLine;
    billingDataToPrint.Channel = item.Channel;
    billingDataToPrint.InvoiceNo = item.InvoiceNo;
    billingDataToPrint.Brand = item.Brand;
    billingDataToPrint.IsBrandSelected = item.IsBrandSelected;
    billingDataToPrint.TotalAmount = item.TotalAmount;
    billingDataToPrint.CommissionAmount = item.CommissionAmount;
    billingDataToPrint.DiscountAmount = item.DiscountedAmount;
    billingDataToPrint.IsTaxApplicable = item.Campaign.IsTaxApplicable;
    billingDataToPrint.TaxAmount = item.TaxAmount;
    billingDataToPrint.ValidatedAmount = item.ValidatedAmount;
    billingDataToPrint.Tax1 = item.Tax.Tax1;
    billingDataToPrint.Tax2 = item.Tax.Tax2;
    billingDataToPrint.Tax = item.Tax;
    billingDataToPrint.BankDetails = item.BankDetails;
    billingDataToPrint.InvoiceDate = utility.datetimeFormatV2(item.InvoiceDate);
    billingDataToPrint.PeriodFrom = utility.datetimeFormatV2(item.PeriodFrom);
    billingDataToPrint.PeriodTo = utility.datetimeFormatV2(item.PeriodTo);
    billingDataToPrint.AmountInWords = utility.numbersToWords(parseInt(item.ValidatedAmount));
    billingDataToPrint.Company = companyMaster[0];
    billingDataToPrint.GeneratedBill_Id = item._id.toString();
    billingDataToPrint.Commercials = item.Campaign.Commercials;
    billingDataToPrint.CampaignId = item.Campaign._id;
    billingDataToPrint.IsSaveNextHouseNo = false;
    billingDataToPrint.IsCommercialSelected = item.IsCommercialSelected;
    billingDataToPrint.IsAgency = item.IsAgency;
    billingDataToPrint.SelectedCommercial = selectedBillingLineRef.current.Commercial
    let invoiceDetails = await API.getInvoiceDetails(billingDataToPrint);
    console.log(invoiceDetails);
    let invoiceTelecastDetails = await API.getInvoiceTelecastDetails(billingDataToPrint);
    console.log(invoiceTelecastDetails);
    billingDataToPrint.InvoiceDetails = invoiceDetails.data;
    billingDataToPrint.InvoiceTelecastDetails = invoiceTelecastDetails.data;
    billingDataToPrint.ClientInfo = clientInfo;

    return billingDataToPrint;
  }

  const printBill = async (saveNextHouseNo) => {
    let billingDataToPrint = await getTelecastdata(selectedBillingLineRef.current);
    console.log(billingDataToPrint);
    setStatusMsg(lang.please_wait_generating_bill);

    let res = await API.printBill(billingDataToPrint);
    if (res.success) {
      let url = FILEURL.BASEURL + 'downloadReport/' + res.data;
      setTimeout(() => {
        window.open(url, "_blank");
        setStatusMsg(lang.bill_generated_successfully);
      }, 6000);
    } else {
      console.log(res);
      setStatusMsg(res.message);
    }

  }


  const printBillInPdf = async () => {

    setStatusMsg(lang.please_wait_generating_bill);

    let filters = {
      ...selectedBillingLineRef.current, FromDate: selectedBillingLineRef.current.PeriodFrom, ToDate: selectedBillingLineRef.current.PeriodTo,
      GeneratedBill_Id: selectedBillingLineRef.current._id.toString(), SelectedCommercial: selectedBillingLineRef.current.Commercial, CampaignId: selectedBillingLineRef.current.Campaign._id
    };

    console.log(filters);
    let telecastData = await API.getInvoiceTelecastDetails(filters);
    console.log(telecastData);

    if (telecastData.success) {

      let telecastDataInPdf = { ...selectedBillingLineRef.current };

      telecastDataInPdf.InvoiceTelecastDetails = telecastData.data.map((booking) => {
        return {
          BookingDate: moment(new Date(booking.BookingDate)).format('YYYY-MMM-DD'),
          SalesUnit: (booking?.CampaignType?.Description??"") + "-" + (booking?.TimeRange?.TimeRange??""),
          ProgramName: booking.program.Title ? booking.program.Title : "NA",
          ActualOnAir: utility.convertMilisecondsToStringWithFrames(booking.ActualOnAir),
          Caption: booking.commercial.Title,
          AssetId: booking.commercial.AssetId,
          BrandName: booking.commercial.Brand.Name,
          SpotRate: booking.SpotRate,
          Duration: booking.commercial.Duration / 1000 // duration in seconds

        }
      });
      telecastDataInPdf.InvoiceNo = selectedBillingLineRef.current.InvoiceNo;

      let res = await API.printTelecastInPdf(telecastDataInPdf);
      console.log(res.data)
      if (res.success) {
        let filePath = FILEURL.BASEURL + 'downloadReport/' + res.data;
        setTimeout(() => {
          window.open(filePath, "_blank");
          setStatusMsg(lang.bill_generated_successfully);
        }, 5000);
      } else {
        console.log(res.message);
        setStatusMsg(res.message);
      }

    }

  }

  const printBillInExcel = async () => {

    setStatusMsg(lang.please_wait_generating_bill);

    let filters = {
      ...selectedBillingLineRef.current, FromDate: selectedBillingLineRef.current.PeriodFrom, ToDate: selectedBillingLineRef.current.PeriodTo,
      GeneratedBill_Id: selectedBillingLineRef.current._id.toString(), SelectedCommercial: selectedBillingLineRef.current.Commercial, CampaignId: selectedBillingLineRef.current.Campaign._id
    };

    let telecastData = await API.getInvoiceTelecastDetails(filters);

    let telecastDataInExcel = { ...selectedBillingLineRef.current };
    telecastDataInExcel.InvoiceNo = selectedBillingLineRef.current.InvoiceNo;

    telecastDataInExcel.InvoiceTelecastDetails = telecastData.data.map((booking) => {
      return {
        BookingDate: moment(new Date(booking.BookingDate)).format('YYYY-MMM-DD'),
        SalesUnit: (booking?.CampaignType?.Description??"") + "-" + (booking?.TimeRange?.TimeRange??""),
        ProgramName: booking.program.Title ? booking.program.Title : "NA",
        ActualOnAir: utility.convertMilisecondsToStringWithFrames(booking.ActualOnAir),
        Caption: booking.commercial.Title,
        AssetId: booking.commercial.AssetId,
        BrandName: booking.commercial.Brand.Name,
        SpotRate: booking.SpotRate,
        Duration: booking.commercial.Duration / 1000
      }
    });


    let res = await API.printTelecastInExcel(telecastDataInExcel);
    if (res.success) {
      let filePath = FILEURL.BASEURL + 'downloadReport/' + res.data;
      window.open(filePath);
      setStatusMsg(lang.bill_generated_successfully);
    } else {
      console.log(res.message);
      setStatusMsg(res.message);
    }
  }
  //TODO : server side search
  //implementing search for client side
  const handleSearch = (e) => {
    let defaultKeys = ['InvoiceNo', "Campaign.CampaignNo", "Campaign.Title", "Campaign.Agency.Name", "Campaign.Client.Name"];
    let filteredData = [];
    let searchValue = e.target.value?.trim();

    if (searchValue == undefined || searchValue == '') {
      filteredData = [...generatedBillData]
    } else {
      defaultKeys.map((key) => {
        let embedded = key;
        let filter = [];
        if (key.includes('.')) {
          embedded = key.split('.');
          if (embedded.length == 3) {
            filter = generatedBillData.filter((x) => x[embedded[0]] && x[embedded[0]][embedded[1]][embedded[2]].toLowerCase().includes(searchValue.toLowerCase()));
          } else {
            filter = generatedBillData.filter((x) => x[embedded[0]] && x[embedded[0]][embedded[1]].toLowerCase().includes(searchValue.toLowerCase()));
          }
        } else {
          filter = generatedBillData.filter((x) => x[embedded] && x[embedded].toLowerCase().includes(searchValue.toLowerCase()));
        }

        if (filter.length > 0) {
          filteredData.push(...filter);
        }
      })
    }
    const uniqData = [...new Set(filteredData)];
    setGeneratedBills(uniqData);
  };


  const onCancelInvoice = async () => {
    
    if(selectedBillingLineRef.current.IsPaymentReceived){
      setStatusMsg(lang.payment_already_received_error_message);
      toast.error(lang.payment_already_received_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setStatusMsg(lang.cancel_invoice_waiting_message);
    console.log(selectedBillingLineRef.current._id);
    let res = await API.cancelInvoice(selectedBillingLineRef.current._id);
    if (res.success) {
      console.log(res.message);
      setStatusMsg(res.message);
      let fromDate = new Date(dataItem.From).getTime();
      let toDate = new Date(dataItem.To).getTime();
      loadData(fromDate, toDate);
    } else {
      console.log(res.message);
    }

  }

  const onEDIFormat = async (printBillingLine) => {

    console.log(printBillingLine);
    let filters = {
      ...selectedBillingLineRef.current, FromDate: selectedBillingLineRef.current.PeriodFrom, ToDate: selectedBillingLineRef.current.PeriodTo,
      GeneratedBill_Id: selectedBillingLineRef.current._id.toString(), SelectedCommercial: selectedBillingLineRef.current.Commercial
    };
    let telecastData = await API.getInvoiceTelecastDetails(filters);

    if (telecastData.success) {
      let invoiceDetailsToPrint = { ...selectedBillingLineRef.current, InvoiceTelecastDetails: telecastData.data, PrintBillingLines: printBillingLine };
      console.log(invoiceDetailsToPrint);
      let res = await API.printBillInTextFormat(invoiceDetailsToPrint);
      if (res.success) {
        setStatusMsg(lang.bill_generated_successfully);
        let filePath = FILEURL.BASEURL + 'downloadReport/' + res.data;
        window.open(filePath);
      }

    } else {
      setStatusMsg(lang.telecast_certificate_details_not_found_error_message);

    }

  }

  const onTextFormat = async () => {
    setStatusMsg(lang.please_wait_generating_bill);

    ConfirmAlert(
      () => { onEDIFormat(true) }, // on Click yes
      () => { onEDIFormat(false) }, // on click no
      lang.export_edi_format_label, //title
      lang.do_you_want_print_campaign_billing_lines_label // message
    )

  }

  const openPaymentReceived = () => {
    if(Object.keys(selectedBillingLineRef.current).length == 0){
      toast.info(lang.please_select_one_item_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if(selectedBillingLineRef.current.IsPaymentReceived){
      toast.success(lang.payment_already_received_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setShowPaymentReceived(true);
  }

  const onChangePaymentReceived = (e) => {
    setPaymentReceivedDataIteam({...paymentReceivedDataIteam, [e.target.name]: e.target.value});
  }

  const isValidPaymentReceived =() => {

    if(paymentReceivedDataIteam.ReceivedDate == "" || paymentReceivedDataIteam.ReceivedDate == undefined){
      toast.error(lang.please_select_received_date_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if(Object.keys(paymentReceivedDataIteam.PaymentMode).length == 0){
      toast.error(lang.please_select_payment_mode_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if((paymentReceivedDataIteam.PaymentMode._id == PAYMENTMODE.Cheque) && paymentReceivedDataIteam.ChequeNo == "" || paymentReceivedDataIteam.ChequeNo == undefined){
      toast.error(lang.please_enter_cheque_no_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if(paymentReceivedDataIteam.Description == "" || paymentReceivedDataIteam.Description == undefined){
      toast.error(lang.please_enter_description_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  }

  const handleSubmitPaymentReceived = async () => {

    if(!isValidPaymentReceived()) return;

    let data = {
      ...selectedBillingLineRef.current,
      IsPaymentReceived : true,
      PaymentDetails : {
        ReceivedDate : new Date(paymentReceivedDataIteam.ReceivedDate).getTime(),
        PaymentMode: paymentReceivedDataIteam.PaymentMode.Description,
        ChequeNo: paymentReceivedDataIteam.PaymentMode._id == PAYMENTMODE.Cheque &&  paymentReceivedDataIteam.ChequeNo,
        Description: paymentReceivedDataIteam.Description
      }
    }
    console.log(data);
    let res = await API.saveData(ENTITYNAME.GeneratedBills, data)
    if(res.success){
      toast.success(`${lang.payment_received_successfully}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowPaymentReceived(false);
      generatedBills.forEach((item) => {
        if(item._id == data._id){
          item.IsPaymentReceived = true;
          item.PaymentDetails = data.PaymentDetails;
        }
      })
      setSelectedBilling({
        ReceivedDate: moment(new Date()).format("YYYY-MM-DD"),
        PaymentMode: {},
        ChequeNo: '',
        Description: '',
      });
    }else{
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const rowRender = (trElement, props) => {
    const available = props.dataItem.IsPaymentReceived;
    const green = {
      backgroundColor: "rgb(55, 180, 0,0.32)",
    };
    const red = {
      backgroundColor: "white",
    };
    const trProps = {
      style: available ? green : red,
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children
    );
  };

  const showConfirmCancelInvoice = async () => {
    setStatusMsg(lang.please_wait_cancel_invoice_message);

    ConfirmAlert(
      () => { onCancelInvoice() }, // on Click yes
      () => { }, // on click no
      lang.cancel_invoice_label, //title
      lang.do_you_want_cancel_this_invoice_label // message
    )

  }

  return <>
    <h1 className="page-title txt-color-bludeDark">
      <i
        className="fa fa-table marlr"
        style={{ fontSize: "20px", color: "GrayText" }}
      ></i>
      <span>{lang.bill_generate_label}</span>
    </h1>
    <div className="row">
      <div className='col-md-9 d-flex mr-4'>
        <div >
          <span>{lang.from_date_label}</span>
          <input
            className="form-control form-control-sm"
            name="From"
            type={'date'}
            value={dataItem.From}
            onChange={onChange}
          />
        </div>

        <div className="ml-2">
          <span>{lang.to_date_label}</span>
          <input
            className="form-control form-control-sm"
            name="To"
            type={'date'}
            value={dataItem.To}
            onChange={onChange}
          />
        </div>
        <div className="ml-2">
          <div className="form-group">
            <div className="row ml-1">
              <RadioButton
                name="AgencyButton"
                checked={selectedOption == "AgencyButton"}
                label={lang.agency_label}
                onClick={onChangeRadioButtton}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="ClientButton"
                className="ml-4"
                checked={selectedOption == "ClientButton"}
                label={lang.client_label}
                onClick={onChangeRadioButtton}
              />
            </div>
            {selectedOption == "AgencyButton" ?
              <DropDownList
                style={{ backgroundColor: "white", width: '180px' }}
                data={agency}
                name="Agency"
                textField="Name"
                dataItemKey="_id"
                value={dataItem.Agency}
                onChange={onChange}
              /> :
              <DropDownList
                style={{ backgroundColor: "white", width: '180px' }}
                data={client}
                name="Client"
                textField="Name"
                dataItemKey="_id"
                value={dataItem.Client}
                onChange={onChange}
              />
            }
          </div>
        </div>

        <div className="ml-4 input-group input-group w-300  mt-4" >
          <input type="text" className="form-control form-control-sm shadow-inset-2 pt-0 pb-0" id="searchInput" aria-label="type 2 or more letters" onChange={handleSearch} placeholder={lang.search_button_text} />
          <button id="search" style={{ border: "0px", backgroundColor: "white", height: "31px" }} >
            <div className="input-group-append" style={{height: "31px"}}>
              <span className="input-group-text">
                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
              </span>
            </div>
          </button>
        </div>
      </div>
      {Object.keys(selectedBillingLineRef.current).length != 0 && <div className="mt-4 mr-2 d-flex flex-row-reverse flex-grow-1">

        <RoundButton title={lang.print_pdf_button_tooltip} icon="print" onClick={() => printBill(false)}/>
        <RoundButton title={lang.print_telecast_certificate_in_pdf_button_tooltip} icon="file-pdf-o" onClick={() => printBillInPdf(false)}/>
        <RoundButton title={lang.print_telecast_certificate_in_excel_button_tooltip} icon="file-excel" onClick={() => printBillInExcel(false)}/>
        <RoundButton title={lang.cancel_invoice_button_tooltip} icon="times" style={{ color: 'red' }} onClick={showConfirmCancelInvoice}/>
        <RoundButton title={lang.to_txt_button_toolip} icon="file-text-o" onClick={() => onTextFormat()}/>
        <RoundButton title={lang.payment_received_button_tooltip} icon="credit-card" onClick={() => openPaymentReceived()}/>

      </div>}
    </div>
    <div className="row mt-2">
      <div className="col-md-12">
        <Grid data={generatedBills.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedBilling[idGetter(item)]
        }))}
          selectedField={SELECTED_FIELD}
          dataItemKey={DATA_ITEM_KEY}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "single",
          }}
          resizable={true}
          rowRender={rowRender}
          onSelectionChange={onSelectionChange}
          style={{ height: "61vh" }}>
          <GridNoRecords>
            {statusMsg}
          </GridNoRecords>

          <Column field="InvoiceNo" title={lang.invoice_no_column} width={120} />
          <Column field="Campaign.CampaignNo" title={lang.campaign_no_column} width={120} />
          <Column field="Campaign.Title" title={lang.campaign_column} width={150} />
          <Column field="Campaign.Agency.Name" title={lang.agency_column} width={150} />
          <Column field="Campaign.Client.Name" title={lang.client_column} width={150} />
          <Column field="InvoiceDate" title={lang.invoice_date_column} width={100} cell={DateOnlyCell} />
          <Column field="PeriodFrom" title={lang.period_from_column} width={100} cell={DateOnlyCell} />
          <Column field="PeriodTo" title={lang.period_to_column} width={100} cell={DateOnlyCell} />
          <Column field="TotalAmount" title={lang.total_amount_column} width={120} />
          <Column field="ValidatedAmount" title={lang.validated_amount_column} width={150} />
          <Column field="TotalSpots" title={lang.total_spot_column} width={150} />
          <Column field="DiscountPercentage" title={lang.discount_percentage_column} width={150} />
          <Column field="DiscountedAmount" title={lang.discount_amount_column} width={150} />
          <Column field="IsPaymentReceived" title={lang.is_paymnet_received_column} width={150} />
          <Column field="PaymentDetails.ReceivedDate" title={lang.payment_received_date_column} cell={DateOnlyCell} width={150} />
          <Column field="PaymentDetails.PaymentMode" title={lang.payment_mode_column} width={150}/>
        </Grid>
      </div>

    </div>

    <div className="row scheduleFooter">
      <div className="col-md-6 mt-2" style={{ textAlign: "left" }}>
        <span style={{ fontWeight: "400", fontFamily: "Roboto", fontSize: "12px", color: "#ffffff" }}>{statusMsg}</span>
      </div>

      <div className="col-md-6 mt-2" style={{ textAlign: "right" }}>
        <span style={{ fontWeight: "400", fontFamily: "Roboto", fontSize: "12px", color: "#ffffff" }}>Count : {generatedBills.length}</span>
      </div>

    </div>

    {showPaymentReceived && <Dialog
      title={lang.payment_received_label}
      onClose={() => setShowPaymentReceived(false)}
      width={"20vw"}
    >
      <div className="row">
        <div className="col-12">
          <EditPageHeader onSubmit={handleSubmitPaymentReceived} onCancel={() => setShowPaymentReceived(false)} showTitle={false} />
          <div className="row mt-2">
            <div className="col-12">
              <label>{lang.received_date_label} *</label>
              <input 
                type="date"
                className="form-control form-control-sm"
                name="ReceivedDate" 
                onChange={onChangePaymentReceived}
                value={paymentReceivedDataIteam.ReceivedDate}/>
            </div>
            <div className="col-12 mt-2">
              <label>{lang.payment_mode_label} *</label>
              <DropDownList
                data={Object.keys(PAYMENTMODE).map((item) => {
                  return { "Description": item, "_id": PAYMENTMODE[item] }
                })}
                style={{ backgroundColor: 'white' }}
                name="PaymentMode"
                textField="Description"
                dataItemKey="_id"
                value={paymentReceivedDataIteam.PaymentMode}
                onChange={onChangePaymentReceived}
              />
            </div>
          </div>
          <div className="row mt-2">
            {paymentReceivedDataIteam.PaymentMode._id == PAYMENTMODE.Cheque && <div className="col-12">
              <label>{lang.cheque_no_label} *</label>
              <input 
                type="text"
                className="form-control form-control-sm"
                name="ChequeNo" 
                onChange={onChangePaymentReceived}
                value={paymentReceivedDataIteam.ChequeNo}
              />
            </div>}
            <div className="col-12 mt-2">
              <label>{lang.description_label} *</label>
              <input 
                type="text"
                className="form-control form-control-sm"
                name="Description" 
                onChange={onChangePaymentReceived}
                value={paymentReceivedDataIteam.Description}
              />
            </div>
          </div>
  
        </div>
      </div>
    </Dialog>}
  </>
}




export default GeneratedBillCollection;