
export const CheckboxCell = (props) => {

    const field = props.field || "";
    
    const value = props.dataItem == undefined ? false : props.dataItem[field] ?? false;

    var disable = props.disable == undefined ? props.dataItem.inEdit==undefined ? false : props.dataItem.inEdit : props.disable;
   
    return <td>
     <input type={"checkbox"} disabled={disable} checked={value} style={{width : "15px",height : "15px" }} onChange={() => {props.onChange({
            dataItem:props.dataItem,
            field:props.field,
            value: !value,
          })}}/>
    </td>;
  };