import { useEffect, useState } from "react";
import { useTranslation } from "../../locale/useTranslation";
import { ImageBrowser } from "../forms/helpercomponents/selectFile/ImageBrowser";
import { RadioButton } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { THEMES } from "../constant/constant";
import { Switch } from "@mui/material";

const OttSettingPlatformForm = (props) => {

    const lang = useTranslation();
    console.log(props)

    const blankDataItem = {
        logo: "",
        showBottomMenu: true,
        homePage: {},
        showHamburgerMenu: false,
        hamburgerMenu: {},
        theme: {},
        logoSize: 30,
        applicationColor: "",
        logoPostion: "logoPostionLeft"
    }

    const [dataItem, setDataItem] = useState(props.dataItem?.value ?? blankDataItem);
    const [selectedOption, setSelectedOption] = useState(props.dataItem?.value?.logoPostion ?? "logoPostionLeft");

    useEffect(() => {
        setDataItem(props.dataItem?.value ?? blankDataItem);
        setSelectedOption(props.dataItem?.value?.logoPostion ?? "logoPostionLeft");
    }, [props.dataItem])

    const onChange = (e) => {

        if (e.target.name == "showBottomMenu") {
            setDataItem({ ...dataItem, showBottomMenu: !dataItem.showBottomMenu });
            props.handlePlatformDetails({ key: props.platformSID, value: { ...dataItem, showBottomMenu: !dataItem.showBottomMenu } })
        }
        else if (e.target.name == "showHamburgerMenu") {
            setDataItem({ ...dataItem, showHamburgerMenu: !dataItem.showHamburgerMenu });
            props.handlePlatformDetails({ key: props.platformSID, value: { ...dataItem, showHamburgerMenu: !dataItem.showHamburgerMenu } })
        }
        else if (e.target.name == "logoPostionLeft" || e.target.name == "logoPostionCenter" || e.target.name == "logoPostionRight") {
            setSelectedOption(e.target.name);
            props.handlePlatformDetails({ key: props.platformSID, value: { ...dataItem, logoPostion: e.target.name } })
        }
        else if (e.target.name == 'logo' && e.target?.files) {
            if (e.target.files != undefined) {
                props.setPlatformImageFile(e.target.files[0])
                setDataItem({ ...dataItem, logo: e.target.files[0] });
                props.handlePlatformDetails({ key: props.platformSID, value: { ...dataItem, logo: e.target.files[0] } })
            } else {
                props.setPlatformImageFile(new Set())
                setDataItem({ ...dataItem, logo: new Set() });
                props.handlePlatformDetails({ key: props.platformSID, value: { ...dataItem, logo: new Set() } })
            }
        }
        else if(e.target.name == 'showProviderIcon'){
            setDataItem({ ...dataItem, showProviderIcon: !dataItem.showProviderIcon });
            props.handlePlatformDetails({ key: props.platformSID, value: { ...dataItem, showProviderIcon: !dataItem.showProviderIcon } })
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
            props.handlePlatformDetails({ key: props.platformSID, value: { ...dataItem, [e.target.name]: e.target.value } })
        }

    }

    const handleNextWindowRoute = (path) => {
        window.open(path, "_blank") //to open new tab
    }

    const handleLogoImageSelect = (dataList) => {
        setDataItem({ ...dataItem, logo: dataList[0].ImageUrl });
        props.handlePlatformDetails({ key: props.platformSID, value: { ...dataItem, logo: dataList[0].ImageUrl } })
    }

    return (
        <div>
            <div className="row mt-3">
                <div className="col-6">
                    <label >{lang.application_color_label}</label>
                </div>
                <div className="col">
                    <input
                        type="color"
                        name="applicationColor"
                        className="cursor-pointer"
                        value={dataItem.applicationColor}
                        onChange={onChange}
                    />
                    <span className="ml-2">{dataItem?.applicationColor}</span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-6">
                    <label>{lang.logo_label} *</label>
                </div>
                <div className="col-6">
                    <ImageBrowser wherestatement={['OttPosterTypeSID', '=', 4]} name={'logo'} value={dataItem.logo} onChange={onChange} setSelectedImage={handleLogoImageSelect} />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-6">
                    <label >{lang.logo_size_label}</label>
                </div>
                <div className="col-2">
                    <input
                        name={"logoSize"}
                        type="number"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.logoSize}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-6"><label>{lang.logo_position_label} *</label></div>
                <div className="col-6">
                    <RadioButton
                        name="logoPostionLeft"
                        checked={selectedOption == "logoPostionLeft"}
                        label="Left"
                        onClick={onChange}
                    />
                    <RadioButton
                        name="logoPostionCenter"
                        className="ml-4"
                        checked={selectedOption == "logoPostionCenter"}
                        label="Center"
                        onClick={onChange}
                    />
                    <RadioButton
                        name="logoPostionRight"
                        checked={selectedOption == "logoPostionRight"}
                        className="ml-4"
                        label="Right"
                        onClick={onChange}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-6"><label>{lang.theme_label} *</label></div>
                <div className="col-4">
                    <DropDownList
                        data={props.themeData}
                        style={{ backgroundColor: 'white' }}
                        name="theme"
                        textField="Name"
                        dataItemKey="_id"
                        value={dataItem.theme}
                        onChange={onChange}
                    />
                </div>
                <span className="col-1">
                    <i title={lang.open_theme_new_tab_label} className="fa fa-external-link mt-1" style={{ marginLeft: "-15px" }} onClick={(e) => { e.preventDefault(); handleNextWindowRoute(`/home/theme/${dataItem.theme.SID}`) }}></i>
                </span>
                <span className="col-1">
                    <i title={lang.refresh_theme_label} className="fa fa-arrows-rotate fa-fw img-circle" style={{ marginLeft: "-40px" }} onClick={props.refreshCombo}></i>
                </span>
            </div>
            <div className="row mt-3">
                <div className="col-6 mt-2"><label>{lang.show_provider_icon_label} *</label></div>
                <div className="col-6">
                    <Switch name={"showProviderIcon"} checked={dataItem.showProviderIcon} value={dataItem.showProviderIcon} onChange={onChange} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-6">
                    <label className="mr-2" htmlFor="TabView">{lang.show_bottom_menu_label}</label>
                    <input type="checkbox" name={"showBottomMenu"} value={dataItem.showBottomMenu} checked={dataItem.showBottomMenu} onChange={onChange} />
                </div>
            </div>
            {!dataItem.showBottomMenu && <div className="row mt-4">
                <div className="col-6"><label>{lang.home_page_label} *</label></div>
                <div className="col-4">
                    <DropDownList
                        data={props.pageData}
                        style={{ backgroundColor: 'white' }}
                        name="homePage"
                        textField="Name"
                        dataItemKey="_id"
                        value={dataItem.homePage}
                        onChange={onChange}
                    />
                </div>
                <span className="col-1 mt-1">
                    <i title={lang.open_theme_new_tab_label} className="fa fa-external-link cursor-pointer" style={{ marginLeft: "-15px" }} onClick={(e) => { e.preventDefault(); handleNextWindowRoute(`/home/Page/${dataItem.homePage.SID}`) }}></i>
                </span>
                <span className="col-1 mt-1">
                    <i title={lang.refresh_theme_label} className="fa fa-arrows-rotate img-circle cursor-pointer" style={{ marginLeft: "-40px" }} onClick={props.refreshCombo}></i>
                </span>
            </div>}
            <div className="row mt-2">
                <div className="col-6 mt-3">
                    <label className="mr-2" htmlFor="TabView">{lang.show_hamburger_menu_label}</label>
                    <input type="checkbox" name={"showHamburgerMenu"} value={dataItem.showHamburgerMenu} checked={dataItem.showHamburgerMenu} onChange={onChange} />
                </div>
                {dataItem.showHamburgerMenu && <div className="col-6">
                    <label>{lang.hamburger_menu_label} *</label>
                    <DropDownList
                        data={props.menuData}
                        style={{ backgroundColor: 'white' }}
                        name="hamburgerMenu"
                        textField="Name"
                        dataItemKey="_id"
                        value={dataItem.hamburgerMenu}
                        onChange={onChange}
                    />
                </div>}
            </div>
        </div>
    );
}

export default OttSettingPlatformForm;
