/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, MEDIATYPE, MEDIATYPEENUM } from "../../framework/constant/constant";
import { Grid, GridColumn as Column, getSelectedState, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { utility } from "../../framework/utility/utilityProvider";
import { getter } from "@progress/kendo-data-query";
import ArchiveIconCell from "../../components/ArchiveIconCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat"

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function VariationEditForm(props) {

    const { SID } = useParams();

    let blankDataItem = {
        Name: "",
        Channel: {},
        MediaType: [],
        MediaEpisodes: [],
        Archive: false
    }

    const lang = useTranslation();
    const [channel, setChannel] = useState([]);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [openCollectionMediaEpisode, setOpenCollectionMediaEpisode] = useState(false);
    const [selectedState, setSelectedState] = useState({});
    const [searchText, setSearchText] = useState("");
    const [filterData, setFilterData] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message);
    const [slotData, setSlotData] = useState("00:00:00:00");
    const [showSlotEditForm, setShowSlotEditForm] = useState(false);
    const selectedItemRef = useRef(null)
    const navigate = useNavigate();

    const [dataItem, setDataItem] = useState(blankDataItem)

    useEffect(() => {
        loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const loadEditData = async () => {

        setLoadingStatus(lang.data_loading_error_message);

        let linkData = {
            link_collection: 'mediaepisode',
            link_field: 'MediaEpisodes'
        }
        let res = await API.getEntity(ENTITYNAME.Variation, parseInt(SID), linkData);
        if (res.data.length == 0) {
            res = await API.getEntity(ENTITYNAME.Variation, parseInt(SID));
        }
        if (res.success) {
            setDataItem(res.data);
            let mediaData = res?.data?.MediaEpisodes ?? [];
            if (mediaData.length > 0) {
                mediaData.map(x => {
                    let slotDetail = res?.data?.SlotDetail?.find(y => y?.MediaEpisode_id == x?._id);
                    if (slotDetail) {
                        x.Slot = utility.convertMilisecondsToStringWithFrames(slotDetail.Slot);
                    } else {
                        x.Slot = "00:00:00:00";
                    }
                    return x;
                })
            };
            setGridData(mediaData)
            setFilterData(mediaData)
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setLoadingStatus(lang.no_record_found_error_message);
    }

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
        setMediaTypes(MEDIATYPE);
    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }

        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (Object.keys(dataItem.MediaType).length == 0) {
            toast.error(`${lang.please_select_media_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (gridData.length == 0) {
            toast.error(`${lang.atleast_attach_one_item_variation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (dataItem.MediaType.SID == MEDIATYPEENUM.FIXED) {
            let check = gridData.find(x => x.Slot == undefined || x.Slot == "00:00:00:00" || x.Slot.includes("_"));
            if (check) {
                toast.error(lang.missing_slot_time_for_error_message + check.AssetId, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false
            }
        }
        return true
    }

    const onSave = async () => {
        if (isValid()) {
            const saveData = {
                ...dataItem,
                Channel: {
                    _id: dataItem.Channel._id,
                    SID: dataItem.Channel.SID,
                    FullChannelName: dataItem.Channel.FullChannelName
                },
                MediaType: dataItem.MediaType,
                MediaEpisodes: gridData?.length > 0 ? gridData.map(x => x?._id) : [],
                SlotDetail: (dataItem.MediaType.SID == MEDIATYPEENUM.FIXED && gridData?.length > 0) ? gridData.map(x => {
                    return {
                        MediaEpisode_id: x?._id,
                        Slot: utility.convertStringWithFramesToMilliseconds(x?.Slot) ?? 0
                    }
                }) : [],
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Channel.SID", "=", dataItem.Channel.SID], ["Name", "=", dataItem.Name], ["Archive", "=", false]]
            }
            console.log(saveData)
            let res = await API.saveData(ENTITYNAME.Variation, saveData);
            console.log(res)
            if (res.success) {
                navigate("/home/Variation");
                utility.deleteLocalStorageItem(ENTITYNAME.Variation);
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const viewCollectionMediaEpisode = () => {
        setOpenCollectionMediaEpisode(!openCollectionMediaEpisode);
    }

    const gridSave = (dataList) => {
        let updateData = dataList.map(x => {
            return {
                ...x,
                Slot: "00:00:00:00"
            }
        }) ?? [];
        let newData = utility.checkDuplicateInData(updateData, gridData, "Title");
        setGridData([...gridData, ...newData]);
        setFilterData([...filterData, ...newData]);
    }

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const handleSelectedDelete = () => {
        let selectedData = filterData.filter((item) => !selectedState[idGetter(item)])
        setGridData(gridData.filter((item) => !selectedState[idGetter(item)]));
        setFilterData(selectedData);
        setSelectedState({});
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const onSearch = (searchText) => {

        setSearchText(searchText);

        let keyword = searchText?.toLowerCase() ?? "";

        if (keyword == undefined || keyword == "") {
            setFilterData(gridData);
            return;
        }

        let filterSearchData = gridData.filter((item) => {
            return JSON.stringify(item?.Title)?.toLowerCase()?.includes(keyword) || JSON.stringify(item?.AssetId)?.toLowerCase()?.includes(keyword);
        });

        setFilterData(filterSearchData);

    };

    const editSlot = (dataItem, selectedId) => {
        selectedItemRef.current = selectedId;
        setShowSlotEditForm(true);
        setSlotData(dataItem);
    }

    const myEditCommandCell = (props) => {
        return (
            <td>
                <span className="ml-3">{props?.dataItem?.Slot}</span>
                <button
                    className="btn btn-sm btn-icon btn-icon-only" style={{ marginTop: '-4px', color: 'blue' }}
                    onClick={() => editSlot(props?.dataItem?.Slot, props?.dataItem?._id)}
                ><i className="fa fa-edit"></i>
                </button>
            </td>
        );
    }

    const handleEditSlot = () => {
        let allUpdated = gridData.map(x => {
            if (selectedItemRef.current == x._id) {
                x.Slot = slotData;
            }
            return x;
        });
        let filteredUpdated = filterData.map(x => {
            if (selectedItemRef.current == x._id) {
                x.Slot = slotData;
            }
            return x;
        });

        setGridData(allUpdated);
        setFilterData(filteredUpdated);
        setShowSlotEditForm(false);
    }

    return (<>
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader title={(SID > 0) ? `${lang.edit_variation_editpage_header}` : `${lang.create_variation_editpage_header}`} onSubmit={onSave} onCancel={() => navigate("/home/Variation")} />
                <div className="row mt-2">
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.name_label} *</label>
                        <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={onChange} />
                    </div>
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.media_type_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={mediaTypes}
                            name="MediaType"
                            textField="Name"
                            dataItemKey="SID"
                            value={dataItem.MediaType}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <input type={"checkbox"} name={"Archive"}
                            value={dataItem.Archive}
                            checked={dataItem.Archive}
                            onChange={onChange} />
                        <label className='ml-1'>{lang.archive}</label>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <AddRoundButton style={{ marginTop: '5px' }} title={lang.add_media_episode_button_tooltip} onClick={viewCollectionMediaEpisode} />
                                {(filterData.filter((item) => selectedState[idGetter(item)]).length != 0) && <DeleteRoundButton style={{ marginTop: '5px', marginLeft: '5px' }} title={lang.delete_all_button_tooltip} onClick={() => ConfirmDeleteAlert(() => handleSelectedDelete(), () => { })} />}
                                <div className="pull-right">
                                    <div className="input-group input-group mb-2" >
                                        <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" value={searchText} placeholder={lang.search_button_text} onChange={(e) => onSearch(e.target.value)} />
                                        <span className="input-group-text">
                                            <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Grid
                            data={filterData?.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: "multiple",
                            }}
                            onSelectionChange={onSelectionChange}
                            onHeaderSelectionChange={onHeaderSelectionChange}
                            style={{ height: "58vh", marginTop: "04px", overflow: "hidden" }}>
                            <GridNoRecords>{loadingStatus}</GridNoRecords>
                            <GridColumn
                                field={SELECTED_FIELD}
                                width="50px"
                                headerSelectionValue={
                                    filterData?.findIndex((item) => !selectedState[idGetter(item)]) === -1
                                }
                            />
                            <Column field="Title" title={lang.title_column} editable={false} />
                            <Column field="MediaCategory.Description" title={lang.media_category_column} editable={false} />
                            <Column field="MediaCategoryType.Description" title={lang.media_category_type_column} editable={false} />
                            <Column field="AssetId" title={lang.asset_id_column} editable={false} />
                            <Column field="Archive" title={lang.archived_column} editable={false} cell={ArchiveIconCell} />
                            {dataItem?.MediaType?.SID == MEDIATYPEENUM.FIXED && <Column field="Slot" title={lang.slot_column} editable={false} cell={myEditCommandCell} />}
                        </Grid>
                    </div>
                </div>

                {/* Add total count fotter */}
                <div className="row mt-2">
                    <div className="col-12 text-right">
                        <label>{lang.selected_label} : {filterData.filter((item) => selectedState[idGetter(item)]).length}</label>
                        <label className="ml-2">{lang.total_label} : {filterData.length}</label>
                    </div>
                </div>
            </div>
        </div>
        {openCollectionMediaEpisode && <CollectionSelection addButtonTitle={lang.select_collection_button_text} title={lang.select_media_episode_collection_header} entityname={ENTITYNAME.MediaEpisode} setDataList={gridSave} wherestatement={['MediaCategory.isLong', '=', false]} closeForm={viewCollectionMediaEpisode} width={"50vw"} height={"65vh"} />}
        {showSlotEditForm &&
            <Dialog
                title={lang.edit_slot_dialog_header}
                onClose={() => setShowSlotEditForm(false)}
                width={"300px"}
            >
                <EditPageHeader onSubmit={handleEditSlot} showTitle={false} onCancel={() => setShowSlotEditForm(false)}></EditPageHeader>
                <div className="row mt-2">
                    <div className="col-12">
                        <label htmlFor="Slot">{lang.slot_label} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="Slot"
                            value={slotData}
                            onChange={(e) => setSlotData(e.target.value)}
                        />
                    </div>
                </div>
            </Dialog>
        }
    </>)
}

export default VariationEditForm;