/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
    Grid,
    GridColumn,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../API/api";
import { ASSETTYPE, COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY } from "../constant/constant";
import { ImageCell } from "../forms/helpercomponents/CustomGridCells/ImageCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ArrayCell } from "../forms/helpercomponents/CustomGridCells/ArrayCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../utility/utilityProvider";
import { TimeCell } from "../forms/helpercomponents/CustomGridCells/TimeCell";
import { CheckboxCell } from "../forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../forms/helpercomponents/CustomGridCells/HrefCell";
import { LookupCell } from "../forms/helpercomponents/CustomGridCells/LookupCell";
import { MyToggleButton } from "../forms/helpercomponents/CustomGridCells/MyToggleButton";
import { DateOnlyCell } from "../forms/helpercomponents/CustomGridCells/DateCell";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EnumCell } from "../forms/helpercomponents/CustomGridCells/EnumCell";
import { ProviderCell } from "../forms/helpercomponents/CustomGridCells/ProviderCell";
import { Loader } from "../forms/helpercomponents/Loader";
import SaveButton from "../forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../forms/helpercomponents/buttons/CancelButton";

const DATA_ITEM_KEY = "SID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const searchByColumn = [{ name: "Title" }, { name: "Description" }];

const OttAssetCollectionSelection = memo((props) => {
    const lang = useTranslation();
    const [gridData, setGridData] = useState([]);
    const [originalGridData, setoriginalGridData] = useState([]);
    const [dataColumns, setDataColumns] = useState(props.customColumns ?? []);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedState, setSelectedState] = useState({});
    const [count, setCount] = useState(0);
    const [assetType, setAssetType] = useState([]);
    const [providers, setProviders] = useState([]);

    let [selectedProvider, setSelectedProvider] = useState({ _id: 0, Description: 'All' });

    let [selectedAssetType, setSelectedAssetType] = useState({ ID: 0, Description: 'All' });

    const gridRef = useRef();
    const per_page = props.perPage ?? 50;
    var currentPage = 1

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const MyCustomToggleButton = (props) => (
        <MyToggleButton
            {...props}
            entityName={props.entityname}
            size={"small"}
        />
    );

    useEffect(() => {
        loadcombo();
        loaddata();
    }, []);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                loaddata();
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            loaddata();
        }
    }, [searchText]);

    const loadcombo = async () => {

        let assetTypeRes = (Object.keys(ASSETTYPE).map((item) => {
            return { "Description": item, "ID": ASSETTYPE[item] }
        }));
        assetTypeRes.unshift({ ID: 0, Description: 'All' });
        setAssetType(assetTypeRes);

        let providerRes = await API.getDataLookup(ENTITYNAME.OttProvider, { query: ["Archive", "=", false] });
        providerRes.data.unshift({ _id: 0, Description: 'All' });
        setProviders(providerRes.data);

        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let columns = await API.getFormData(props.entityname, currentUserId);
        setDataColumns(columns);

    }

    const getFilterQueryAssetTypee = () => {
        let query = [];
        query.push(selectedAssetType.ID != 0 ? ['OttAssetTypeSID', '=', selectedAssetType.ID] : []);
        query.push(selectedProvider._id != 0 ? ['Provider', '=', selectedProvider._id] : [])
        return query;
    }

    const loaddata = async () => {
        try {
            console.log(searchText);
            setIsLoading(true);
            var json = json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: currentPage, query: getFilterQueryAssetTypee() }, searchByColumn, searchText);

            setoriginalGridData(json.data);
            setIsLoading(false);
            setGridData(json.data);
            setCount(json.pagination.total);


        } catch (error) {
            console.log("error", error);
        }
    };

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);

        }, [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {

        if (props.mode == 'single') {
            toast.info(`${lang.select_any_one_collection_selection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

    }, []);

    const checkIfItemExist = (item) => {

        var isFound = false;
        var existingEntries = props.existingItems ?? [];
        // console.log("checking duplicate")
        // console.log(item.SID);
        // console.log(props);
        if (props.checkDuplicate ?? false) {
            isFound = existingEntries.some(element => {
                console.log("props");
                console.log(props);
                if (element.SID === item.SID) {
                    return true;
                }

                return false;
            });
        }

        return isFound;

    };

    //to sendDataList to RailsEditForm and to close the collection
    const onSelection = () => {
        var msg = `${lang.please_select_first_collection_error_message}`

        var selectedDataList = [];
        //loop for setting the selected obj in array
        gridData.map((obj) => {
            var key = obj.SID;
            //if true means checked
            if (selectedState[key]) {
                // checking duplicate as well
                if (!checkIfItemExist(obj)) {
                    selectedDataList.push(obj);
                } else {
                    msg = `${lang.already_attached_collection_error_message}`
                }
            }
        })

        if (selectedDataList.length != 0) {
            props.setDataList(selectedDataList);
            props.closeForm();
        } else {
            // alert(msg)
            toast.info(msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    //load for infinit scroll

    const loadInfiniteData = async () => {
        currentPage++;
        if (count > originalGridData.length) {
            var json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: currentPage, query: getFilterQueryAssetTypee() }, searchByColumn, searchText);
            setoriginalGridData((old) => [...old, ...json.data]);
            setGridData((old) => [...old, ...json.data]);
        }

    }

    //for infinite scroll
    const scrollHandler = async (event) => {
        const e = event.nativeEvent;
        if (e.target.className.indexOf('k-grid-content') === -1) {
            return;
        }

        if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
            await loadInfiniteData()
        }
    };

    const onChangeAssets = (e) => {
        selectedAssetType = e.target.value;
        setSelectedAssetType(e.target.value);
        currentPage = 1;
        loaddata();
    }

    const onChangeProvider = (e) => {
        selectedProvider = e.target.value;
        setSelectedProvider(e.target.value);
        currentPage = 1;
        loaddata();
    }

    return (
        <Dialog
            title={props.title ?? `${lang.add_dialog_header}`}
            onClose={props.closeForm}
            width={props.width ?? "75vw"}
            height={props.height ?? "60vh"}>
            <div>
                <div className="row ml-1 mb-3" >
                    <div className="col-3">
                        <div className="row">
                            <SaveButton title={props.addButtonTitle ?? lang.add_button_text} style={{ backgroundColor: 'green', borderRadius: "4px" }} onClick={onSelection}></SaveButton>
                            <CancelButton onClick={props.closeForm}></CancelButton>
                        </div>
                    </div>
                    <div className="col-3" style={{ marginTop: '-10px' }}>
                        <label style={{ fontSize: '10px' }}>{lang.provider_label}</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={providers}
                            textField="Description"
                            dataItemKey="_id"
                            value={selectedProvider}
                            onChange={onChangeProvider}
                        />
                    </div>
                    <div className="col-3" style={{ marginTop: '-10px' }}>
                        <label style={{ fontSize: '10px' }}>{lang.asset_type_label}</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={assetType}
                            textField="Description"
                            dataItemKey="ID"
                            value={selectedAssetType}
                            onChange={onChangeAssets}
                        />
                    </div>
                    <div className="col-3" >
                        <div className="input-group input-group mb" >
                            <input
                                type="text"
                                className="form-control shadow-inset-2 pt-0 pb-0"
                                id="searchInput"
                                placeholder={lang.search_button_text}
                                onChange={onSearch}
                                onFocus={(e) => {
                                    if (props.setOutSideFocus) {
                                        props.setOutSideFocus(true);
                                    }
                                }}
                                onBlur={(e) => {
                                    if (props.setOutSideFocus) {
                                        props.setOutSideFocus(false);
                                    }
                                }}
                            /> <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div>

                {isLoading && <Loader height="40vh" />}

                {!isLoading &&
                    <Grid
                        data={gridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}

                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: props.mode ?? "multiple",
                        }}
                        ref={gridRef}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        // onHeaderSelectionChange={props.mode == 'single' ? () => {} : onHeaderSelectionChange}
                        style={{ height: "42vh", wdith: "60vw" }}
                        onScroll={scrollHandler}
                    >
                        <GridColumn
                            field={SELECTED_FIELD}
                            width="50px"
                            headerSelectionValue={
                                gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1
                            }
                            locked={true}
                        />
                        {dataColumns.map((column, index) => {
                            if (column.type === COLUMNSTYPE.date) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={DateOnlyCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                );
                                // }else if (column.name === COLUMNSTYPE.time) {
                            } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={TimeCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                );
                            } else if (column.name === "SID") {
                                return;
                                //we are not returning any value for SID column
                            } else if (column.type === COLUMNSTYPE.image) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={ImageCell}
                                        title={column.label}
                                        width={column.width ?? 100}
                                    />
                                );
                            } else if (column.type === COLUMNSTYPE.select) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={MyDatalookupCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.toggle) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={MyCustomToggleButton}
                                        title={column.label}
                                        width={column.width ?? 80}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.checkbox) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={CheckboxCell}
                                        title={column.label}
                                        width={column.width ?? 80}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.array) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={ArrayCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        format={column.format ?? "Description"} //display item in object
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.href) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={HrefCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        format={column.format}
                                    />
                                );
                            }
                            else if (column.type === COLUMNSTYPE.enum) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={EnumCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                );
                            } else if (column.type === COLUMNSTYPE.provider) {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        cell={ProviderCell}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                );
                            }
                            else {
                                return (
                                    column.type !== COLUMNSTYPE.hidden && (
                                        <GridColumn
                                            key={index}
                                            field={column.name}
                                            title={column.label}
                                            width={column.width ?? 200}
                                        />
                                    )
                                );
                            }
                        })}
                    </Grid>}
            </div>
            <div className="text-right mt-1">{`Total : ${count}`}</div>
        </Dialog>
    );
});

export default OttAssetCollectionSelection;
