/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import * as API_DigiSign from "../../framework/API/api_digitalSign";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { ENTITYNAME, LOCALSTORAGE_KEY, MODULE, SOCKET_EVENTS, SOCKET_ACTION, LOGEVENT, SCREEN_STATUS } from "../../framework/constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import MyStatusCell from "../../components/MyStatusCell";
import { getter } from "@progress/kendo-data-query";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { utility } from "../../framework/utility/utilityProvider";
import socket from "../../framework/socket/socket";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActivateScreenPoup from "./activateScreenPoup";
import { ScreenGroupEditForm } from "./ScreenGroupEditForm";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export const DigisignScreenCollection = (props) => {
    //possible props
    let page_take = props.page_take ?? 50;
    let showAdd = props.showAdd ?? true;
    let showBack = props.showBack ?? true;
    let showArchive = props.showArchive ?? true;
    let showCommandCell = props.showCommandCell ?? true;
    let showEdit = props.showEdit ?? true;
    let showDelete = props.showDelete ?? true;
    let showTitle = props.showTitle ?? true;
    let gridRowRender = props.gridRowRender;
    let CustomEditForm = props.customEditForm ?? EditForm;
    let filterable = props.filterable ?? false;
    let sortable = props.sortable ?? true;
    let isPageable = props.isPageable ?? true;
    let wherestatement = props.wherestatement ?? [];
    let andWhereStatement = props.andWhereStatement ?? [];
    let sort = props.sort ?? { SID: 1 };
    let customColumns = props.customColumns ?? [];
    let openNewEditForm = props.openNewEditForm ?? false;
    let myTitle = props.title ?? "";
    let myMenuModel = props.menuModel ?? collectionMenuModel;
    let entityName = props.entityname;
    let hideArchiveProps = props.hideArchive ?? false;
    let enableMutliSelect = props.enableMutliSelect ?? false;
    let module = props?.module;
    let showLogs = props.showLogs ?? false;
    let doLoad = props.isLoading ?? true;

    //Add this for media management preference column
    const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;
    // example custome columns[]
    const [gridData, setGridData] = useState([]);
    const [total, setTotal] = useState(1);
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState({ SID: 1 });
    const [warningDialog, setWarningDialog] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [dataColumns, setDataColumns] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [hideArchive, setHideArchive] = useState(hideArchiveProps);
    const [selectedState, setSelectedState] = useState({});
    const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
    const [gridSort, setGridSort] = useState([initialSort]);
    const [showActivateScreenPopup, setShowActivateScreenPopup] = useState(false);
    const [isInsertGroupScreen, setIsInsertGroupScreen] = useState(false);

    const [groups, setGroups] = useState([]);
    const [showScreenGroupPopup, setShowScreenGroupPopup] = useState(false);
    const [status, setStatus] = useState([]);
    const [dataItem, setDataItem] = useState({
        group: { _id: 0, Name: 'All' },
        status: { _id: 0, Description: 'All' }
    });
    const [showAttachGroupPopup, setShowAttachGroupPopup] = useState(false);
    const selectedAttachGroupRef = useRef({});

    const selectedItemsRef = useRef([]);
    const setSelectedItem = (items) => {
        selectedItemsRef.current = items;
    }

    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    //only to get wherestatement from href cell
    const location = useLocation();
    const lang = useTranslation();

    let href = location.state ? location.state.href : false;
    if (href) {
        wherestatement = location.state.wherestatement;
    }

    useEffect(() => {
        setShowArchived(false);
        doLoad ? setIsLoading(true) : setIsLoading(false);
        setPage({ skip: 0, take: page_take });
        loadCombo();
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, searchText, false);
    }, [props.entityname, props.gridData, props.title, props.wherestatement]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        let sortEvent = gridSort[0] ?? initialSort;

        let newWhereStatement = [...wherestatement];

        if(dataItem.group._id != 0){
            newWhereStatement = [...newWhereStatement, ["screenGroup._id", "=", dataItem.group._id]]
        }

        if(dataItem.status._id != 0){
            newWhereStatement = [...newWhereStatement, ["active", "=", dataItem.status._id == SCREEN_STATUS.Active ]]
        }

        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setIsLoading(true);
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText, showArchived);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setIsLoading(true);
            setPage({ skip: 0, take: page_take });
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText, showArchived);
        }
    }, [searchText]);

    const loadCombo = async () => {
        const groupRes = await API_DigiSign.getDataLookup(ENTITYNAME.DigitalSignScreenGroup, { query: ["Archive", "=", false] });
        setGroups(groupRes.data);
        const statusRes = Object.keys(SCREEN_STATUS).map((key) => {
            return { _id: SCREEN_STATUS[key], Description: key }
        });
        setStatus(statusRes);
    }

    const loaddata = async (pagination, wherestatement, searchText, archive) => {
        try {
            let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
            let json = await API_DigiSign.getDigitalSignScreen(
                { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort },
                searchText
            );
            setGridData(json?.data ?? []);
            setTotal(json?.pagination?.total);
        } catch (error) {
            console.log("error", error);
        }
        setIsLoading(false);
    };

    const onKillUserSession = (userData) => {
        delete userData["Module"];
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
        toast.success(`${userData.name} session has been killed successfully`, { position: toast.POSITION.TOP_RIGHT });
    }

    const enterEdit = (item) => {
        console.log("onEnterEdit");
        if (openNewEditForm === false) {
            setOpenForm(true);
            setEditItem(item);
        } // need to navigate to full page edit view
        else {
            navigate(`${item.SID}`, {
                state: {
                    copy: item.copy ? true : false,
                    quickEdit: item.quickEdit ? true : false,
                    item: item,
                    prevPath: location.pathname
                }
            });
        }
    };

    const handleNavigate = (item) => {
        if (item?.Module) {
            window.open(item.path, "MyTargetWindowName");
        } else {
            navigate(`${item.path}`, { state: item });
        }
    }

    const handleSubmit = async (dataItem, dataToSave) => {
        console.log("handleSUbmit");
        //if datatosave is null then dataitem is datasave
        dataToSave = dataToSave == null ? dataItem : dataToSave;

        let isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

        if (!isDuplicate) {
            setOpenForm(true);
            toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let res = await API_DigiSign.saveData(props.entityname, dataToSave);
        console.log(res);
        if (dataItem?.IsDefault) {
            await API.setAsDefault(entityName, dataItem?._id)
        }
        if (res.success) {
            if (showLogs) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: module, data: res.data, message: res.message };
                API.SaveLogs(logData);
            }
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
        utility.deleteLocalStorageItem(props.entityname)
        //to load grid with new data
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, searchText, showArchived);
        setOpenForm(false);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleDelete = async (dataItem) => {
        onConfirmDelete(dataItem)
    };

    const handleArchive = async (dataItem) => {
        let resArchiveUpdated = await API_DigiSign.saveData(entityName, {
            ...dataItem,
            Archive: !dataItem.Archive
        })

        if (resArchiveUpdated.success) {
            console.log(`Archive is been updated to ${!dataItem.Archive}`);
            if (module) {
                let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
                API.SaveLogs(logData);
            }
            let sortEvent = gridSort[0] ?? initialSort;
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            console.log(resArchiveUpdated.message);
        }
    }

    const setAsDefault = async (data) => {

        var setAsDefaultRes = await API.setAsDefault(entityName, data._id);
        if (setAsDefaultRes.success) {
            let sortEvent = gridSort[0] ?? initialSort;
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            toast.error(setAsDefaultRes.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const handleAttachGroup = (data) => {
        selectedAttachGroupRef.current = data;
        setShowAttachGroupPopup(true);
    }

    const handleNewGroupCreate = (data) => {
        selectedAttachGroupRef.current = data;
        setIsInsertGroupScreen(true);
        setShowScreenGroupPopup(true);
    }

    //Edit Row
    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            showLogs={showLogs}
            enterEdit={enterEdit}
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            item={editItem}
            dataColumns={dataColumns}
            onDelete={handleDelete}
            onArchive={handleArchive}
            showEdit={showEdit}
            showDelete={showDelete}
            toNavigate={handleNavigate}
            myMenuModel={myMenuModel}
            module={module}
            openNewEditForm={openNewEditForm}
            onKillUserSession={onKillUserSession}
            setAsDefault={setAsDefault}
            onAttachGroup={handleAttachGroup}
            onNewGroupCreate={handleNewGroupCreate}
        />
    );

    const Mystatuscell = (props) => {

        const field = props.field

        let tileBackColor = props.dataItem[field] ? "#63aa43" : "#E72929";
        let status = props.dataItem[field] ? "Active" : "Inactive";

        return <MyStatusCell title={status} tileBackColor={tileBackColor} />

    }

    const handleErrorDialogClose = () => {
        setDeleteError(!deleteError);
        setDeleteErrorMessage("");
    }
    const errorHandler = (message) => {
        setWarningDialog(!warningDialog);
        setDeleteError(true);
        setDeleteErrorMessage(message);
    }

    const onConfirmDelete = async (dataItem) => {
        const res = await API.deleteData(props.entityname, dataItem.SID);
        console.log("Loading");
        if (!res.success) {
            console.log(res.message);
            errorHandler(res.message);
            return;
        }
        let current_page = page.skip / page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, '', showArchived);
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
        utility.deleteLocalStorageItem(props.entityname);
    }

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived);
        setPage(e.page);
        cancelSelectedItem()
    };

    const onArchiveClick = () => {
        setShowArchived(!showArchived);
        let sortEvent = gridSort[0] ?? initialSort;
        if (!showArchived) {
            loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, true);
        } else {
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, false);
        }
        cancelSelectedItem();
    }

    const refreshCollection = () => {
        setIsLoading(true);
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, searchText, showArchived);
        setGridSort([initialSort]);
        loadCombo();
        setDataItem({
            group: { _id: 0, Name: 'All' },
            status: { _id: 0, Description: 'All' }
        })
    }

    const onSortChange = (e) => {
        setGridSort(e.sort)
        let sortEvent = e.sort[0] ?? sort;
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived);
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];

        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                let filter = gridData.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });

        setSelectedItem(tempSelected);
    };

    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];

        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    var filtered = gridData.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItem(tempSelected);
    };

    const onArchiveButtonClick = async () => {
        if (selectedItemsRef.current.length == 0) {
            toast.error(`${lang.please_select_atleast_one_item}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        for (let i = 0; i < selectedItemsRef.current.length; i++) {
            const item = selectedItemsRef.current[i];
            await handleArchive(item);
        }
        cancelSelectedItem();
    }

    const cancelSelectedItem = () => {
        setSelectedItem([]);
        setSelectedState({});
    }

    let timeOut;
    const handleColumnResize = (event) => {
        let lockedCol = event.columns.filter((item) => item?.locked).length ?? 1;
        try {
            if (event.index == 0) return;
            if (dataColumns == undefined || dataColumns.length == 0 || dataColumns.length < event.index) return;
            let column = dataColumns[event.index - lockedCol];
            let payload = {
                entityName: column?.entityName,
                name: column?.name,
                width: event.newWidth
            };
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
                API.updateUserPreferenceColumnWidth(payload);
            }, 700);
        } catch (error) {
            console.error("Error handling column resize:", error);
        }
    };

    const handleFiltersChange = (e) => {

        setIsLoading(true);

        let localFilterItem = { ...dataItem, [e.target.name]: e.target.value }

        setDataItem({ ...dataItem, [e.target.name]: e.target.value });

        console.log('here');

        let newWhereStatement = [...wherestatement];

        if(localFilterItem.group._id != 0){
            newWhereStatement = [...newWhereStatement, ["screenGroup._id", "=", localFilterItem.group._id]]
        }

        if(localFilterItem.status._id != 0){
            newWhereStatement = [...newWhereStatement, ["active", "=", localFilterItem.status._id == SCREEN_STATUS.Active ]]
        }

        console.log(newWhereStatement);
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText, showArchived);
        setPage({ skip: 0, take: page_take });
    }

    const handleSelectedGroup = async (dataItem) => {

        let data = dataItem.length > 0 ? dataItem[0] : {};
        let finalData = {
            ...selectedAttachGroupRef.current,
            screenGroup: {
                _id: data?._id,
                SID: data?.SID,
                Name: data?.Name,
                Description: data?.Description
            }
        }
        let res = await API_DigiSign.saveData(ENTITYNAME.DigitalSignScreen, finalData);
        if (res.success) {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setShowAttachGroupPopup(false);
        refreshCollection();
    }

    return (
        <div>
            {showTitle && (
                <h1 className="page-title txt-color-bludeDark">
                    <i
                        className="fa fa-table marlr"
                        style={{ fontSize: "20px", color: "GrayText" }}
                    ></i>
                    <span>{myTitle === "" ? props.entityname : myTitle}</span>
                </h1>
            )}
            <div className="flex-container">
                <div className="martb mar">
                    {showBack && (
                        <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                    )}
                    {showAdd && (
                        <AddRoundButton onClick={() => setShowActivateScreenPopup(true)}></AddRoundButton>
                    )}
                    {showArchive && enableMutliSelect && selectedItemsRef.current.length != 0 && (
                        <ActionButton title={showArchived ? lang.un_archive_label : lang.archive} name={showArchived ? lang.un_archive_label : lang.archive}
                            onClick={onArchiveButtonClick} />
                    )}
                </div>

                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group w-300 martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                    <div className="mt-1" style={{ paddingTop: "12px" }}>
                        <RefreshButton
                            onClick={refreshCollection}
                        />
                    </div>
                    {!hideArchive && <div className="d-inline mt-3 mr-2">
                        <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
                    </div>}
                    <div className="d-inline col-2" style={{ marginTop: '-9px' }}>
                        <label htmlFor="Node">{lang.group_label}</label>
                        <span className='float-right cursor-pointer'
                            style={{
                                padding: '2px 5px 0px 4px', border: '1px solid #ced4da',
                                fontSize: '10px', borderRadius: '10px', margin: '4px'
                            }}
                            onClick={() => { setShowScreenGroupPopup(true); setIsInsertGroupScreen(false); }} title="Add Screen Group">
                            <i className="fa fa-plus"></i>
                        </span>

                        <DropDownList
                            data={[{ _id: 0, Name: 'All' }, ...groups]}
                            style={{ backgroundColor: 'white' }}
                            name="group"
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.group}
                            onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>
                    <div className="d-inline col-2" style={{ marginTop: '-9px' }}>
                        <label htmlFor="Node">{lang.status_label}</label>
                        <DropDownList
                            data={[{ _id: 0, Description: 'All' }, ...status]}
                            style={{ backgroundColor: 'white' }}
                            name="status"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.status}
                            onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
            {!isLoading &&
                <Grid
                    style={props.gridstyle ?? {
                        height: "76vh",
                    }}
                    sort={gridSort}
                    sortable={sortable}
                    onSortChange={onSortChange}
                    rowRender={gridRowRender}
                    filterable={filterable}
                    pageable={isPageable}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    resizable={true}
                    onColumnResize={handleColumnResize}
                    data={
                        gridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: enableMutliSelect,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                >

                    {/* Multi Select Checkbox row */}
                    {enableMutliSelect && (<GridColumn
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={
                            gridData.findIndex(
                                (item) => !selectedState[idGetter(item)]
                            ) === -1
                        }
                        locked={true}
                        filterable={false}
                    />
                    )}

                    {/* Edit Row */}
                    {showCommandCell && (
                        <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
                    )}
                    <GridColumn title="Name" field="Name" width={200} />
                    <GridColumn title="Active ago" field="ActiveAgo" width={200} />
                    <GridColumn title={"Status"} field="active" width={150} cell={Mystatuscell} />
                    <GridColumn title={"No. of Playlist"} field="playlist" width={200} cell={ArrayCell} format="length"/>
                    <GridColumn title={"Screen Group"} field="screenGroup.Name" width={200} />
                </Grid>}

            {openForm && (
                <CustomEditForm
                    {...props}
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    refresh={refreshCollection}
                    item={editItem}
                    dataColumns={dataColumns}
                />
            )}

            {deleteError && <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
            {showActivateScreenPopup && <ActivateScreenPoup onClose={(refresh = false) => {
                setShowActivateScreenPopup(false);
                if (refresh) {
                    refreshCollection();
                }
            }} />}
            {showScreenGroupPopup && <ScreenGroupEditForm isInsertGroupScreen={isInsertGroupScreen} refresh={refreshCollection} cancelEdit={() => setShowScreenGroupPopup(false)} handleSelectedGroup={handleSelectedGroup} />}

            {showAttachGroupPopup && <CollectionSelection entityname={ENTITYNAME.DigitalSignScreenGroup} wherestatement={["Archive", "!=", true]} title={lang.attach_screen_group_dialog_header} addButtonTitle={lang.select_button_text} closeForm={() => setShowAttachGroupPopup(false)} seletedState = {[selectedAttachGroupRef.current?.screenGroup]} setDataList={handleSelectedGroup} width="650px" mode="single" />}
        </div>
    );
};