/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Dialog } from '@progress/kendo-react-dialogs';
import ActionButton from '../../../framework/forms/helpercomponents/buttons/ActionButton';
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import * as API from "../../../framework/API/api";
import { ENTITYNAME } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";

const CampaignFilter = (props) => {

    const lang = useTranslation();
    const blankDataItem = {
        PeriodFrom: '',
        PeriodTo: '',
        Channels: [],
        CampaignType: {},
        DealType: {},
        Agency: {},
        Client: {},
        Product: {},
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [openForm, setOpenForm] = useState(false);
    const [campaignTypes, setCampaignTypes] = useState([]);
    const [agencys, setAgencys] = useState([]);
    const [clients, setClients] = useState([]);
    const [products, setProducts] = useState([]);
    const [dealTypes, setDealTypes] = useState([]);
    const [channels, setChannels] = useState([]);
    const [isCleared, setIsCleared] = useState(true);

    useEffect(() => {
        loadcombo();
        setIsCleared(true);
    }, []);

    const loadcombo = async () => {
        let channelRes = await API.getValidChannels(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannels(channelRes.data);
        let campaignTypeRes = await API.getDataLookup(ENTITYNAME.CampaignType, { sort: { Description: 1 } });
        setCampaignTypes(campaignTypeRes.data);
        let agencyRes = await API.getDataLookup(ENTITYNAME.Agency, { sort: { Name: 1 } });
        setAgencys(agencyRes.data);
        let clientRes = await API.getData(ENTITYNAME.Client, { query : ['Archive','=',false], sort: { Name: 1 } });
        setClients(clientRes.data);
        let productRes = await API.getDataLookup(ENTITYNAME.Product, { sort: { Name: 1 } });
        setProducts(productRes.data);
        let dealTypeRes = await API.getDataLookup(ENTITYNAME.DealType, { sort: { Description: 1 } });
        setDealTypes(dealTypeRes.data);
    }

    const onChange = (e) => {

        if (e.target.value) {
            setIsCleared(false);
        }
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const onSearchClick = () => {
        props.toGetFilterDataItem(dataItem);
        setOpenForm(false);
    }

    const onClear = () => {
        setDataItem(blankDataItem);
        setIsCleared(true);
        props.onClear();
        props.toGetFilterDataItem(blankDataItem);
    }

    return <>

        <button style={{ border: "0px", backgroundColor: "white", height: props.height ?? '40px' }} onClick={() => setOpenForm(true)} aria-label="Advanced search options">
            <div className="input-group-append" >
                <span className="input-group-text" style={{ backgroundColor: isCleared ? "white" : "black" ,padding : '6px 12px 4px 12px'}}>
                    <i style={{ margin: "5px 0px", color: isCleared ? "black" : "white" }} className="fa fa-list" />
                </span>
            </div>
        </button>


        {openForm &&
            <Dialog title={lang.search_filter_dialog_header} width={"600px"} onClose={() => { setOpenForm(false) }}>
                <div className='row' style={{ marginLeft: "-02px", width: "570px" }}>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-4 mt-1">
                                <label htmlFor="TabView">{lang.channel_label} </label>
                            </div>
                            <div className="col">
                                <MultiSelect
                                    data={channels}
                                    name="Channels"
                                    textField="FullChannelName"
                                    dataItemKey="_id"
                                    value={dataItem.Channels}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 mt-1">
                                <label htmlFor="TabView">{lang.campaign_type_label}</label>
                            </div>
                            <div className="col">
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={campaignTypes}
                                    name="CampaignType"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.CampaignType}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 mt-1">
                                <label htmlFor="TabView">{lang.deal_type_label} </label>
                            </div>
                            <div className="col-8">
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={dealTypes}
                                    name="DealType"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.DealType}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 mt-1">
                                <label htmlFor="TabView">{lang.agency_label}</label>
                            </div>
                            <div className="col">
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={agencys}
                                    name="Agency"
                                    textField="Name"
                                    dataItemKey="_id"
                                    value={dataItem.Agency}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 mt-1">
                                <label htmlFor="TabView">{lang.client_label}</label>
                            </div>
                            <div className="col">
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={clients}
                                    name="Client"
                                    textField="Name"
                                    dataItemKey="_id"
                                    value={dataItem.Client}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 mt-1">
                                <label htmlFor="TabView">{lang.product_label}</label>
                            </div>
                            <div className="col">
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={products}
                                    name="Product"
                                    textField="Name"
                                    dataItemKey="SID"
                                    value={dataItem.Product}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 mt-1">
                                <label htmlFor="">{lang.period_from_label} </label>
                            </div>
                            <div className="col">
                                <input type="date" className="form-control form-control-sm" name="PeriodFrom" value={dataItem.PeriodFrom} onChange={onChange} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-4 mt-1">
                                <label htmlFor="">{lang.period_to_label} </label>
                            </div>
                            <div className="col">
                                <input type="date" className="form-control form-control-sm" name="PeriodTo" value={dataItem.PeriodTo} onChange={onChange} />
                            </div>
                        </div>

                        <div className="row mt-3" style={{ textAlign: "right" }}>
                            <div className="col-12">
                                <ActionButton title={lang.clear_button_tooltip} name={lang.clear_button_text} btnColor={"danger"} onClick={onClear} />
                                <ActionButton title={lang.search_button_tooltip} name={lang.search_button_text} onClick={onSearchClick} />
                            </div>
                        </div>
                    </div>
                </div>


            </Dialog>}
    </>
}




export default CampaignFilter;