/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api"
import { ENTITYNAME } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Checkbox } from "@progress/kendo-react-inputs";
import { utility } from "../../framework/utility/utilityProvider";

const TechnicalDetailPrototypeEditForm = (props) => {

    const blankdataItem = {
        SID: props.item.SID,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        Distributer: props.item.Distributer ?? "",
        AudioCodec: props.item.AudioCodec ?? {},
        VideoCodec: props.item.VideoCodec ?? {},
        DeliveryMethod: props.item.DeliveryMethod ?? {},
        VideoAspectRatio: props.item.VideoAspectRatio ?? {},
        VideoType: props.item.VideoType ?? {},
        Archive: props.item.Archive ?? false,
        EnableCaption: props.item.EnableCaption ?? false,

    }
    const [audioCodec, setAudioCodec] = useState([]);
    const [videoCodec, setVideoCodec] = useState([]);
    const [videoAspectRatio, setVideoAspectRatio] = useState([]);
    const [videoType, setVideoType] = useState([]);
    const [deliveryMethod, setDeliveryMethod] = useState([]);
    const [dataItem, setDataItem] = useState(blankdataItem);
    const lang = useTranslation();


    useEffect(() => {
        loadData();
    }, [])


    const loadData = async () => {

        var audioCodecRes = await API.getDataLookup(ENTITYNAME.AudioCodec);
        setAudioCodec(audioCodecRes.data);
        var videoCodecRes = await API.getDataLookup(ENTITYNAME.VideoCodec);
        setVideoCodec(videoCodecRes.data);
        var videoAspectRatioRes = await API.getDataLookup(ENTITYNAME.VideoAspectRatio);
        setVideoAspectRatio(videoAspectRatioRes.data);
        var videoTypeRes = await API.getDataLookup(ENTITYNAME.VideoType);
        setVideoType(videoTypeRes.data);
        var deliveryMethodRes = await API.getDataLookup(ENTITYNAME.DeliveryMethod);
        setDeliveryMethod(deliveryMethodRes.data);

    }

    const isValid = () => {
        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.DeliveryMethod).length == 0) {
            toast.error(`${lang.please_select_delivery_method_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.AudioCodec).length == 0) {
            toast.error(`${lang.please_select_audio_codec_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.VideoCodec).length == 0) {
            toast.error(`${lang.please_select_video_codec_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.VideoAspectRatio).length == 0) {
            toast.error(`${lang.please_select_video_aspect_ratio_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.VideoType).length == 0) {
            toast.error(`${lang.please_select_video_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {

            const saveData = {
                SID: props.item.SID ?? 0,
                Description: dataItem.Description,
                AudioCodec: dataItem.AudioCodec,
                VideoCodec: dataItem.VideoCodec,
                DeliveryMethod: dataItem.DeliveryMethod,
                VideoAspectRatio: dataItem.VideoAspectRatio,
                VideoType: dataItem.VideoType,
                Archive: dataItem.Archive,
                EnableCaption: dataItem.EnableCaption,
                checkDuplicate: true,
                query: [["Description", "=", dataItem.Description]]
            }

            var res = await API.saveData(ENTITYNAME.TechnicalDetailsPrototype, saveData);
            if (res.success) {
                props.cancelEdit();
                props.refresh();
                utility.deleteLocalStorageItem(ENTITYNAME.TechnicalDetailsPrototype);
                return;
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    return (
        <>
            <Dialog
                title={props.item.Description ? props.item.Description : lang.create_technical_details_prototype_header_dialog}
                onClose={props.cancelEdit}
                width={"600px"}
            >
                <Form
                    render={() => (
                        <FormElement>
                            <div className="row">
                                <div className="col-12">
                                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <label>{lang.description_label} *</label>
                                            <input
                                                name={"Description"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChange}
                                                value={dataItem.Description}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label>{lang.delivery_method_label} *</label>
                                            <DropDownList
                                                style={{ backgroundColor: 'white' }}
                                                data={deliveryMethod}
                                                name={"DeliveryMethod"}
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.DeliveryMethod}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <label>{lang.audio_codec_label} *</label>
                                            <DropDownList
                                                style={{ backgroundColor: 'white' }}
                                                data={audioCodec}
                                                name={"AudioCodec"}
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.AudioCodec}
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label>{lang.video_codec_label} *</label>
                                            <DropDownList
                                                style={{ backgroundColor: 'white' }}
                                                data={videoCodec}
                                                name={"VideoCodec"}
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.VideoCodec}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <label>{lang.video_aspect_ratio_label} *</label>
                                            <DropDownList
                                                style={{ backgroundColor: 'white' }}
                                                data={videoAspectRatio}
                                                name={"VideoAspectRatio"}
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.VideoAspectRatio}
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label>{lang.video_type_label} *</label>
                                            <DropDownList
                                                style={{ backgroundColor: 'white' }}
                                                data={videoType}
                                                name={"VideoType"}
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.VideoType}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <Checkbox
                                                name={"Archive"}
                                                style={{ marginLeft: "5px", marginBottom: "10px" }}
                                                value={dataItem.Archive}
                                                onChange={onChange}
                                            />
                                            <label className="pl-1">{lang.archive}</label>
                                        </div>
                                        <div className="col">
                                            <Checkbox
                                                name={"EnableCaption"}
                                                style={{ marginLeft: "5px", marginBottom: "10px" }}
                                                value={dataItem.EnableCaption}
                                                onChange={onChange}
                                            />
                                            <label className="pl-1">{lang.enable_caption_label}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>
        </>)
};

export default TechnicalDetailPrototypeEditForm;