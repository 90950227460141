/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";
import * as API from "../../framework/API/api_digitalSign";
import Card from "../../components/Card";
import { ENTITYNAME, PLAYLIST_STATUS } from "../../framework/constant/constant";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import MyStatusCell from "../../components/MyStatusCell";
import { utility } from "../../framework/utility/utilityProvider";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { DigitalSignHelper } from "./helper/DigitalSignHelper";

const ScreenEditForm = () => {

  const { SID } = useParams();
  const loc = useLocation();
  const lang = useTranslation();

  const blankDataItem = {
    SID: 0,
    Name: "",
    screenGroup: {},
    SerialNumber: "",
    playlist: [],
    Orientation: 0,
    Archive: false,
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [showScreenGroupPopup, setShowScreenGroupPopup] = useState(false);
  const [showPlaylistPopup, setShowPlaylistPopup] = useState(false);
  const [selectedOrientation, setSelectedOrientation] = useState(0);

  const orientations = [0, 90, 180, 270];

  const [name, setName] = useState();
  const nameRef = useRef();

  useEffect(() => {
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const loadEditData = async () => {
    let res = loc?.state?.item ?? {};
    delete res?.screenGroupId;
    console.log(res);
    setDataItem(res);
    setName(res?.Name ?? "");
    setSelectedOrientation(res?.Orientation);
  }

  const handleInputClick = (value) => {
    setSelectedOrientation(value);
    onChange({ target: { name: 'Orientation', value } });
    setDataItem({ ...dataItem, Orientation: value });
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, Orientation: value });
    API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
  };

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const borderStyle = {
    border: "1px solid #ced4da", /* Your existing border styles */
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", /* Add a subtle box shadow for a 3D effect */
    backgroundColor: "#FAFAFA",
    borderRadius: "8px",
  }

  const colorText = {
    color: "#919496",
    letterSpacing: '1px',
    textTransform: "uppercase"
  }

  const onNameChange = async (name) => {
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, Name: name });
    let res = await API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
    if (res.success) {
      toast.success(lang.update_successfully_label, {
        position: toast.POSITION.TOP_RIGHT
      });
      setDataItem({ ...dataItem, Name: name });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const handleSelectedGroup = async (data) => {

    let selectedGroupValue = data && data.length > 0 ? data[0] : {};

    if (dataItem.screenGroup._id == selectedGroupValue._id) {
      toast.error(lang.same_screen_group_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, screenGroup: selectedGroupValue });
    let res = await API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
    if (res.success) {
      let playlist = await API.getData(ENTITYNAME.DigitalSignPlaylist, { query: ["screenGroup", "in", [selectedGroupValue._id]] });
      setDataItem({ ...finalData, playlist: playlist.data });
      toast.success(lang.update_successfully_message, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    setShowScreenGroupPopup(false);
  }

  const myCommandCell = (props) => {
    return (
      <td>
        <RoundButton icon="external-link" onClick={() => window.open(`/home/PlaylistItem?_id=${props.dataItem._id}`)} />
      </td>
    );
  }

  const MyPlaylistStatuscell = (props) => (
    <div>
      <MyStatusCell style={{ width: '100px' }} title={utility.getKeyByValue(PLAYLIST_STATUS, props.dataItem.Status)} tileBackColor="#508D4E" />
    </div>
  )

  return (<>
    <div className="row m-1 mb-5">
      <div className="col-12">
        <h1 className="page-title txt-color-bludeDark d-flex" style={{ marginLeft: '-10px' }}>
          <i
            className="fa fa-table marlr"
            style={{ fontSize: "20px", color: "GrayText" }}
          ></i>
          <input ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} onKeyUp={(e) => {
            if (e.key == 'Enter') {
              nameRef.current.blur();
            }
          }} onBlur={() => onNameChange(name)} />
        </h1>

        <div className="row">
          <div className="col-7">
            <div className="border p-2 form-group with-3d-effect mb-2" style={borderStyle}>
              <div className="row p-3">
                <div className="col-5">
                  <label style={colorText}>{lang.status_label}</label>
                  <br />
                  <div style={{ fontSize: '15px' }} className={`badge badge-${dataItem.active ? "success" : "danger"}`}>{dataItem.active ? "Active" : "Inactive"}</div>
                </div>
                <div style={{ borderLeft: '1px solid #E0E0E0', marginLeft: "-1px" }}></div>
                <div className="col-2 text-center cursor-pointer" onClick={() => setShowPlaylistPopup(true)}>
                  <label style={colorText}>{lang.playlist_label}</label>
                  <div >{dataItem?.playlist?.length ?? 0}</div>
                </div>
                <div style={{ borderLeft: '1px solid #E0E0E0', marginLeft: "-1px" }}></div>
                <div className="col-3 text-center cursor-pointer" onClick={() => setShowScreenGroupPopup(true)}>
                  <label style={colorText}>{lang.screen_group_label}</label>
                  <div>{dataItem?.screenGroup?.Name ?? ''}</div>
                </div>
                <div style={{ borderLeft: '1px solid #E0E0E0', marginLeft: "-1px" }}></div>
                <div className="col">
                  <label style={colorText}>{lang.player_label}</label>
                  <div>Mac OS</div>
                </div>
              </div>
            </div>
            <div className="border p-2 form-group with-3d-effect mb-2 mt-2" style={borderStyle}>
              <label style={colorText}>{lang.orientation_label}</label>
              <div className="row">
                <div className="d-flex m-3">
                  {orientations.map((orientation, index) => (
                    <input
                      key={index}
                      type="number"
                      name="Orientation"
                      className={`form-control form-control-sm ml-${index === 0 ? 1 : 2}`}
                      placeholder={`${orientation}°`}
                      value={orientation}
                      readOnly
                      onClick={() => handleInputClick(orientation)}
                      style={{
                        borderColor: selectedOrientation === orientation ? 'black' : '',
                        borderWidth: selectedOrientation === orientation ? '2px' : ''
                      }}
                    />
                  ))}
                  <i className="fa fa-television m-2" style={{ fontSize: "13px" }}></i>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <div className="border p-2 form-group with-3d-effect mb-2" style={{ ...borderStyle, height: '110px' }}>
                  <div className="row">
                    <div className="col-12" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", top: '25px' }}>
                      <i className="fa fa-power-off" style={{ fontSize: "20px", color: "purple" }}></i>
                      <div style={{ textTransform: "uppercase" }}>{lang.restart_label}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="border p-2 form-group with-3d-effect mb-2" style={{ ...borderStyle, height: '120px' }}>
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center" style={colorText} >{lang.cpu_usage_label}</div>
                      <div className="text-center" style={{ position: 'relative', top: '20px' }}>0%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="border p-2 form-group with-3d-effect mb-2" style={{ ...borderStyle, height: "340px" }}>
              <div className="row">
                <div className="col-12">
                  <div className="float-left mt-1" style={colorText}>{lang.playing_now_label}
                  </div>
                  {dataItem?.playlistItem?.length > 0 && <div className='float-right mt-1'>
                    <RoundButton style={{ float: 'right' }} icon="external-link" onClick={() => window.open(`/home/PlaylistItem?_id=${dataItem?.playlistId}`)} />
                  </div>}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <Grid data={dataItem?.playlistItem} resizable={true} style={{ height: "280px" }}>
                    <GridColumn field="content.url" title="Thumbnail" cell={ImageCell} />
                    <GridColumn field="content.fileName" title="Title" />
                    <GridColumn field="content.fileName" title="Description" />
                    <GridColumn field="overlay.length" title="Overlays" />
                    <GridColumn field="content.resource_type" title="Type" />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <Card header="START TIME" description="Time DLF" />
              </div>
              <div className="col-4">
                <Card header="VIDEO CODECS" description="Time DLF" />
              </div>
              <div className="col-4">
                <Card header="BROWSER RESOLUTION" description="Time DLF" />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Card header="DEVICE PIXEL RATIO" description="Time DLF" />
              </div>
              <div className="col-4">
                <Card header="PLAYER CLOCK" description="Time DLF" />
              </div>
              <div className="col-4">
                <Card header="PLAYER TIMEZONE" description="Time DLF" />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Card header="APP SHELL VERSION" description="Time DLF" />
              </div>
              <div className="col-4">
                <Card header="APP VERSION" description="Time DLF" />
              </div>
              <div className="col-4">
                <Card header="PAIRED TIME" description="Time DLF" />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Card header="USER AGENT" description="Time DLF" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
    {showScreenGroupPopup && <CollectionSelection title={lang.add_screen_group_dialog_header} entityname={ENTITYNAME.DigitalSignScreenGroup} wherestatement={["Archive", "=", false]} closeForm={() => setShowScreenGroupPopup(false)} setDataList={handleSelectedGroup} seletedState = {[dataItem?.screenGroup]} width="600px" Height="300px" mode="single" />}
    {showPlaylistPopup && <Dialog title={lang.playlist_dialog_header} onClose={() => setShowPlaylistPopup(false)} width="650px">
      <Grid
        data={dataItem?.playlist}
        style={{ height: "350px" }}
      >
        <GridColumn cell={myCommandCell} width="60px" />
        <GridColumn title="Name" field="Name" width={200} />
        <GridColumn title="Status" field="Status" width={200} cell={MyPlaylistStatuscell} />
      </Grid>
    </Dialog>}

  </>
  )
}
export default ScreenEditForm