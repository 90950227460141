import React, { useEffect, useRef, useState } from 'react'
import { ArrayCell } from "../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { HrefCell } from "../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { TimeCell } from "../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { ImageCell } from "../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { CheckboxCell } from "../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { getSelectedState, Grid, GridColumn } from "@progress/kendo-react-grid";
import EditRoundButton from '../framework/forms/helpercomponents/buttons/EditRoundButton';
import { COLUMNSTYPE, ENTITYNAME, LOGEVENT, MODULE } from '../framework/constant/constant';
import { DateOnlyCell, LocalDateTimeColumn } from '../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { LookupCell } from '../framework/forms/helpercomponents/CustomGridCells/LookupCell';
import { getter } from '@progress/kendo-data-query';
import { useTranslation } from '../locale/useTranslation';
import { ContextMenu } from 'primereact/contextmenu';
import { toast } from 'react-toastify';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import MultiVersioningForm from '../modules/MediaLibrary/MultiVersioningForm';
import CollectionSelection from '../framework/forms/CollectionSelection';
import * as API from "../framework/API/api";
import { ConfirmDeleteAlert } from '../ConfirmAlert';
import { Dialog } from '@progress/kendo-react-dialogs';
import { EditPageHeader } from './EditPageHeader';
import { TimePickerWithFormat } from '../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import { utility } from '../framework/utility/utilityProvider';
import MediaEpisodeHistory from '../modules/Planning/MediaEpisodeHistory';
import { CellRender, RowRender } from '../modules/Segment/renderers';
import { myTimeCellMilliseconds } from '../framework/forms/helpercomponents/inline grid cell/MyTimeCellInMillisconds';
import { orderBy } from '@progress/kendo-react-data-tools';
import { DropdownCell } from '../framework/forms/helpercomponents/CustomGridCells/DropdownCell';
import UpdateMultipleEpisodeDialog from './UpdateMultipleEpisodeDialog';

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

const CardListGridView = (props) => {

    const {
        data,
        setData,
        dataColumns,
        onEditDataItem,
        onArchiveEpisodes,
        enableSelection,
        setSelectedData,
        showUpdateMultiple,
        setShowUpdateMultiple,
    } = props

    const lang = useTranslation();
    const cm = useRef(); //RIGHT CLICK : CONTEXT MENU
    const ref = useRef();

    const [selectedState, setSelectedState] = useState({});
    const [selectedEpisode, setSelectedEpisode] = useState([]);
    const [showFormatsCollection, setShowFormatsCollection] = useState(false);
    const [showUpdateDuration, setShowUpdateDuration] = useState(false);
    const [dataItem, setDataItem] = useState({
        Duration: selectedEpisode[0]?.Duration ? utility.convertMilisecondsToStringWithFrames(selectedEpisode[0]?.Duration) : ""
    });
    const [showHistory, setShowHistory] = useState(false);

    const initialSort = [{ field: "_id", dir: "asc" }];
    const [sort, setSort] = useState(initialSort);

    useEffect(() => {
        setSelectedState(props.selectedState);
        setSelectedEpisode(data);
    }, [props.selectedState]);

    const MyCommandCell = (props) => (
        <td style={props.style} className={props.className} colSpan={props.colSpan} role={"gridcell"}>
            <div style={{ display: "flex", marginTop: '15px' }}>
                <EditRoundButton onClick={() => onEditDataItem(0, props.dataItem)} />
            </div>
        </td>
    )

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const gridRowRender = (tr, props) => {

        const trProps = {
            onContextMenu: (e) => {
                ref.current = props.dataItem;
                if (Object.keys(selectedState).length == 0 && enableSelection) {
                    toast.error(`${lang.please_select_atleast_one_item}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return;
                }
                if (enableSelection) {
                    cm.current.show(e);
                }
            },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });

        setSelectedState(newSelectedState);

        //need to get selected
        let selectedItems = data.filter((x) => newSelectedState[x._id]);
        setSelectedEpisode(selectedItems);
        setSelectedData(selectedItems)
    };

    const contextMenuCancel = () => {
        setSelectedEpisode([]);
        setSelectedState({});
        setSelectedData([]);
    };

    const handleFormatDataList = async (dataList) => {

        if (dataList.length > 0) {
            selectedEpisode.forEach((item) => {
                item.Formats = dataList;
            });
            setSelectedEpisode(selectedEpisode);
            setSelectedData(selectedEpisode);
            let media = selectedEpisode.map(x => {
                return {
                    _id: x._id,
                    SID: x.SID,
                    AssetId: x.AssetId,
                    Duration: x.Duration
                }
            })
            let payload = {
                media: media,
                formats: dataList
            }
            let saveFormats = await API.attachFormats(payload);
            console.log(saveFormats);
            if (saveFormats.success) {
                toast.success(`${lang.format_attached_success_message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                // Log capture
                selectedEpisode.forEach((item) => {
                    let logData = { event: LOGEVENT.UPDATE_EPISODE, module: MODULE.EPISODE, data: item, message: saveFormats.message };
                    API.SaveLogs(logData);
                })
            } else {
                toast.error(saveFormats.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            contextMenuCancel();
        }
    }

    const deleteAllFormats = async () => {
        for (const element of selectedEpisode) {
            let saveFormats = await API.saveData(ENTITYNAME.MediaEpisode, { _id: element._id, Formats: [] });
            console.log(saveFormats);
            // Log capture
            let logData = { event: LOGEVENT.UPDATE_EPISODE, module: MODULE.EPISODE, data: element, message: saveFormats.message };
            API.SaveLogs(logData);
        }
        toast.success(`${lang.formats_deleted_success_message}`, {
            position: toast.POSITION.TOP_RIGHT,
        });
        contextMenuCancel();
    }

    const handleDurationSave = async () => {
        if (!dataItem.Duration || dataItem.Duration == "" || dataItem.Duration == "00:00:00:00" || dataItem.Duration.includes('_') || utility.convertStringWithFramesToMilliseconds(dataItem.Duration) == 0) {
            toast.error(`${lang.please_select_duration_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        let media = selectedEpisode.map(x => {
            return {
                ...x,
                Duration: utility.convertStringWithFramesToMilliseconds(dataItem.Duration) ?? 0
            }
        });
        let res = await API.saveData(ENTITYNAME.MediaEpisode, media);
        if (res.success) {
            // Log capture
            media.forEach((item) => {
                let logData = { event: LOGEVENT.UPDATE_EPISODE, module: MODULE.EPISODE, data: item, message: res.message };
                API.SaveLogs(logData);
            })
            // update duration in grid
            media.forEach((item) => {
                let index = data.findIndex(x => x._id == item._id);
                data[index].Duration = item.Duration;
            })
            toast.success(`${lang.duration_updated_success_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setShowUpdateDuration(false);
        contextMenuCancel();
    }
    const historyRef = useRef();
    const setHistoryRef = (data) => {
        historyRef.current = data
    }

    const history = (item) => {
        console.log('history');
        console.log(item[0])
        setHistoryRef(item);
        setShowHistory(true);
    };

    const multipleUpdateFormClose = () => {
        setShowUpdateMultiple(false);
    }

    let timeOut;
    const handleColumnResize = (event) => {
      let lockedCol = event.columns.filter((item) => item?.locked).length ?? 1;
      try {
        if (event.index == 0) return;
        if (dataColumns == undefined || dataColumns.length == 0 || dataColumns.length < event.index) return;
        let column = dataColumns[event.index - lockedCol];
        let payload = {
          entityName: column?.entityName,
          name: column?.name, 
          width: event.newWidth
        };
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          API.updateUserPreferenceColumnWidth(payload);
        }, 700);
      } catch (error) {
        console.error("Error handling column resize:", error);
      }
    };

    return (
        <div style={{ padding: '5px' }}>
            <ExcelExport data={data} ref={props._export}>
                <Grid
                    style={{
                        height: "65.5vh",
                        width: '30vw'
                    }}
                    data={orderBy(data.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    })), sort)}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    rowRender={gridRowRender}
                    resizable={true}
                    onColumnResize={handleColumnResize}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                    selectable={{
                        enabled: props.enableSelection ?? false,
                        cell: false,
                        mode: "multiple",
                    }}
                    onSelectionChange={onSelectionChange}
                >
                    <GridColumn cell={MyCommandCell} width={"60px"} locked={true} />
                    {/* Rows Loop */}
                    {dataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateOnlyCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                    sortable={true}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={TimeCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                    sortable={true}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                            //we are not returning any value for SID column
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ImageCell}
                                    title={column.label}
                                    width={100}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyDatalookupCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.checkbox) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={CheckboxCell}
                                    title={column.label}
                                    width={80}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ArrayCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                    format={column.format ?? "Description"} //display item in object
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.href) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={HrefCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                    format={column.format}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.localdatetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={LocalDateTimeColumn}
                                    title={column.label}
                                    width={200}
                                    format={column.format}
                                    sortable={true}
                                />
                            );
                        }
                        else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={column.width ?? 200}
                                        sortable={true}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>
            </ExcelExport>

            <ContextMenu
                ref={cm}
                model={[
                    {
                        label: `${lang.ref_attach_formats_label}`,
                        icon: "pi pi-fw pi-paperclip",
                        command: () => {
                            setShowFormatsCollection(true);
                        },
                    },
                    {
                        label: `${lang.ref_delete_formats_label}`,
                        icon: "pi pi-fw pi-trash",
                        command: () => {
                            ConfirmDeleteAlert(() => deleteAllFormats(), () => { });
                        },
                    },
                    {
                        label: !props.archiveEpisode ? `${lang.archive}` : `${lang.un_archive_label}`,
                        icon: "pi pi-fw pi-window-minimize",
                        command: () => {
                            onArchiveEpisodes(selectedEpisode, props.archiveEpisode);
                            contextMenuCancel();
                        },
                    },
                    {
                        label: lang.update_slot_duration_label,
                        icon: "pi pi-fw pi-pencil",
                        command: () => {
                            setShowUpdateDuration(() => true);
                        },
                    },
                    {
                        label: 'Update multiple',
                        icon: "pi pi-fw pi-pencil",
                        command: () => {
                            if (selectedEpisode.length == 0) {
                                toast.warn('Please select atleast one item', {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                return;
                            }
                            setShowUpdateMultiple(true);
                        },
                    },
                    {
                        label: lang.show_history_button_text,
                        icon: "pi pi-fw pi-history",
                        command: () => {
                            history(selectedEpisode);
                        },
                    },
                    {
                        label: `${lang.ref_cancel_label}`,
                        icon: "pi pi-fw pi-times",
                        command: () => {
                            contextMenuCancel();
                        },
                    },

                ]}
            />

            {props.showMultiVersionPopup && <MultiVersioningForm selectedData={selectedEpisode} closeForm={() => { props.setShowMutliVersionPopup(false); contextMenuCancel(); }} />}
            {showFormatsCollection && <CollectionSelection title={lang.select_format_collection} entityname={ENTITYNAME.Format} setDataList={handleFormatDataList} closeForm={() => setShowFormatsCollection(false)} mode={'single'} width={"50vw"} height={"64vh"} />}
            {showUpdateDuration && <Dialog title={lang.update_slot_duration_label} onClose={() => setShowUpdateDuration(false)} width={400} height={200}>
                <EditPageHeader title={""} onSubmit={handleDurationSave} onCancel={() => setShowUpdateDuration(false)} showTitle={false} />
                <div className='mt-2'>
                    <label htmlFor="">{lang.duration_label} *</label>
                    <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="Duration"
                        value={dataItem.Duration}
                        onChange={(e) => setDataItem({ ...dataItem, Duration: e.target.value })}
                    />
                </div>
            </Dialog>}
            {showHistory && <MediaEpisodeHistory selectedData={historyRef.current} closeForm={() => setShowHistory(false)} />}


            {showUpdateMultiple && <UpdateMultipleEpisodeDialog refresh={props.loadEpisodes} selectedEpisode={selectedEpisode} setSelectedEpisode={setSelectedEpisode} closeForm={multipleUpdateFormClose} />}

        </div>
    )
}

export default CardListGridView