import Card from 'react-bootstrap/Card';
import { OTT_POSTER_TYPE } from '../../../constant/constant';
import { utility } from '../../../utility/utilityProvider';

export const ImageCell = (props) => {
  const field = props.field || "";
  const width = props.width ?? "50px";
  const height = props.height ?? "50px";
  const posterType = field.toLowerCase().includes('portrait') ? OTT_POSTER_TYPE.Portrait : OTT_POSTER_TYPE.Landscape
  var value = '';
  if (field.includes('.')) {
    //For field value coming as example :- MediaCategory.Duration
    var keys = field.split('.');
    value = props.dataItem[keys[0]] ? props.dataItem[keys[0]][keys[1]] ? props.dataItem[keys[0]][keys[1]] : '' : '';
  }
  else {
    value = props.dataItem[field];
  }
  var url = utility.getPosterUrl(value, posterType);
  var imgStyle = { objectFit: "cover", height: height, borderRadius: "8px", width: width }

  return <td className={props?.className ?? ''} style={{ ...props?.style, height: height }}>

    <Card.Img src={url} style={{ ...imgStyle, objectFit: props?.objectFit ?? imgStyle.objectFit }} />
  </td>;
};