/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, PROTOTYPEMEDIACATEGORYGROUP, PROTOTYPETRIGGERTYPE, SEGMENTPOSITIONS } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { utility } from "../../framework/utility/utilityProvider";
import { EditPageHeader } from "../../components/EditPageHeader";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import SecondaryEventPrototypeRules from "./SecondaryEventPrototypeRules";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PrototypeRestrictionEditForm from "./PrototypeRestrictionEditForm";


export const SecondaryEventPrototype = (props) => {

  const { SID } = useParams();
  const loc = useLocation();
  const navigate = useNavigate();

  const [dataItem, setDataItem] = useState({
    SID: SID,
    Name: '',
    Channel: {},
    ID: '',
    ProtoTypeSource: {},
    Group: PROTOTYPEMEDIACATEGORYGROUP[0],
    SegmentPosition: SEGMENTPOSITIONS[0],
    StartTriggerType: PROTOTYPETRIGGERTYPE[1],
    EndTriggerType: PROTOTYPETRIGGERTYPE[2],
    StartOffset: "00:00:00:00",
    EndOffset: "00:00:00:00",
    Archive: false,
    IsEndNegative: false,
    IsStartNegative: false,
    MediaEpisode: {},
    Content: {},
    ManualPrototypeId : '',
    isManualPrototypeId: false
  });

  const [channel, setChannel] = useState([]);
  const [prototypeSource, setPrototypeSource] = useState([]);
  const [openMediaEpisodeSelection, setOpenMediaEpsodeSelection] = useState(false);
  const [selectedMediaEpisode, setSelectedMediaEpisode] = useState({ Title: '' });
  const [rulesData, setRulesData] = useState([]);
  const lang = useTranslation();
  const [tabNumber, setTabNumber] = React.useState("1");
  const [prototypeRestriction, setPrototypeRestriction] = useState([]);
  const [contents, setContents] = useState([]);
  const [hideManualId, setHideManualID] = useState(true);

  const setRestrictionData = (data) => {
    setPrototypeRestriction(data);
  }

  useEffect(() => {
    loadcombo();
    if (SID > 0) {
      loadEditData();
      if(!loc.state?.copy){
        setHideManualID(false);
      }
    }
  }, []);

  const loadcombo = async () => {
    let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannel(channelRes.data);
    setDataItem({...dataItem , Channel : utility.getDefaultItem(channelRes.data)})
    let prototypeSourceRes = await API.getDataLookup(
      ENTITYNAME.ProtoTypeSource, { sort: { Name: 1 } }
    );
    setPrototypeSource(prototypeSourceRes.data);
    let contentRes = await API.getDataLookup(ENTITYNAME.ProtoTypeContent, { sort: { Description: 1 } });
    setContents(contentRes.data);
  };

  const onChange = async (e) => {

    let value = e.target.value;

    if (e.target.name == "IsStartNegative") {
      setDataItem({
        ...dataItem,
        IsStartNegative: !dataItem.IsStartNegative,
      });
    }
    else if (e.target.name == "IsEndNegative") {
      setDataItem({
        ...dataItem,
        IsEndNegative: !dataItem.IsEndNegative,
      });
    }
    else if (e.target.name == "Archive") {
      setDataItem({
        ...dataItem,
        Archive: !dataItem.Archive,
      });

    }
    else if (e.target.name === "isManualPrototypeId") {
      setDataItem({ ...dataItem, isManualPrototypeId: !dataItem.isManualPrototypeId });
    }
    else if (e.target.name === "Content") {
      //check
      const updatedContent = await API.getAndSavePrototypeContentId(
        value.SID, { ...dataItem.Content, isSaveNextnumber: false }
      );
      if (!updatedContent.success) return;
      setDataItem(() => ({ ...dataItem, [e.target.name]: value, ID: updatedContent.data.prototypeNumber, }));
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };
  const borderWithTitle = {
    position: "relative",
    fontWeight: "400",
    fontSize: "10px",
    top: "-0.9em",
    marginLeft: "0.8em",
    display: "inline",
    backgroundColor: "white",
  };

  const isValid = () => {

    if ((SID==0 || loc.state?.copy) && (!dataItem.Content || Object.keys(dataItem.Content).length == 0)) {
      toast.error(`${lang.please_select_content_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Name == "" || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.Channel).length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.ProtoTypeSource).length == 0) {
      toast.error(`${lang.please_select_source_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.StartOffset.includes("_") || dataItem.StartOffset === undefined) {
      toast.error(`${lang.invalid_startoffset_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.EndOffset.includes("_") || dataItem.EndOffset === undefined) {
      toast.error(`${lang.invalid_endoffset_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);

  };

  const checkDuplicatePrototypeId = async () => {
    let query = [["ID", "=", dataItem.ManualPrototypeId]];
    let res = await API.getData(ENTITYNAME.Prototype, { query: query });
    return res.data.length > 0;
  }

  const onSave = async () => {

    if (!isValid()) return;

    let id = dataItem.isManualPrototypeId ? dataItem.ManualPrototypeId : dataItem.ID;

    if (parseInt(SID) == 0 && dataItem.isManualPrototypeId) {

      let isduplicatePrototypeId = await checkDuplicatePrototypeId();

      if (isduplicatePrototypeId) {
        toast.error(id + `${lang.already_exists_global_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

    }

    if ((!dataItem._id || loc.state.copy) && !dataItem.isManualPrototypeId) {
      const updatedContent = await API.getAndSavePrototypeContentId(
        dataItem.Content.SID,
        { ...dataItem.Content, isSaveNextnumber: true }
      );
      id = updatedContent.data.prototypeNumber;
      if (!updatedContent.success) {
        toast.error(`${lang.error_while_generating_prototype_number_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
    }
    console.log(selectedMediaEpisode)
    const saveData = {
     // ...dataItem,
      SID: loc.state?.copy ? 0 : dataItem.SID,
      ID: id,
      Name:dataItem.Name,
      Channel:{_id:dataItem.Channel._id, SID:dataItem.Channel.SID,FullChannelName:dataItem.Channel.FullChannelName},
      ProtoTypeSource:{_id:dataItem.ProtoTypeSource._id, SID:dataItem.ProtoTypeSource.SID,Name:dataItem.ProtoTypeSource.Name, Layer:dataItem.ProtoTypeSource.Layer },
      StartOffset: utility.convertStringWithFramesToMilliseconds(dataItem.StartOffset),
      EndOffset: utility.convertStringWithFramesToMilliseconds(dataItem.EndOffset),
      StartTriggerType:{ ID:dataItem.StartTriggerType.ID, Description:dataItem.StartTriggerType.Description} ,
      EndTriggerType: {ID:dataItem.EndTriggerType.ID, Description:dataItem.EndTriggerType.Description},
      SegmentPosition: {ID:dataItem.SegmentPosition.ID, Description: dataItem.SegmentPosition.Description},
      Group: {ID:dataItem.Group.ID, Description:dataItem.Group.Description},
      MediaEpisode: { 
        _id: selectedMediaEpisode._id,
        SID: selectedMediaEpisode.SID,
        Description: selectedMediaEpisode.Description ,
        AssetId:selectedMediaEpisode.AssetId
    },
      SecondaryEventDynamicRules: rulesData,
      IsStartNegative: dataItem.IsStartNegative ?? false,
      IsEndNegative:dataItem.IsEndNegative ?? false,
      SecondaryEventRestriction: prototypeRestriction,
      Content: {
        _id: dataItem.Content?._id,
        SID: dataItem.Content?.SID,
        Description: dataItem.Content?.Description
      },
      Archive: dataItem.Archive ?? false,
      isManualPrototypeId: dataItem.isManualPrototypeId ?? false,
      checkDuplicate: true,
      query: [["Name", "=", dataItem.Name]]
    };

    delete saveData.isManualPrototypeId
    delete saveData.ManualPrototypeId

    if (loc.state?.copy) {
      delete saveData._id;
    }

    console.log(saveData);
    let res = await API.saveData(ENTITYNAME.Prototype, saveData);
    console.log(res);
    if (res.success) {
      navigate(-1);
    }
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  };

  const loadEditData = async () => {
    let res = await API.getEntity(props.entityname, parseInt(SID));
    console.log(res.data);
    if (res.success) {
      setSelectedMediaEpisode(res.data.MediaEpisode);
      setDataItem({
        ...res.data,
        Name: loc.state?.copy ? 'Copy of ' + res.data.Name : res.data.Name,
        ID: loc.state?.copy ? "" : res.data.ID,
        Content: loc.state?.copy ? {} : res.data.Content,
        StartOffset: utility.convertMilisecondsToStringWithFrames(res.data.StartOffset),
        EndOffset: utility.convertMilisecondsToStringWithFrames(res.data.EndOffset),
      });
      setRulesData(res.data.SecondaryEventDynamicRules ?? []);
      setPrototypeRestriction(res.data.SecondaryEventRestriction ?? []);

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onSelectMediaEpisode = () => {
    setOpenMediaEpsodeSelection(true)
  }

  const onSetSelectedMediaEpisode = async (dataList) => {
    setSelectedMediaEpisode(dataList[0]);
  }

  const setRules = (data) => {
    setRulesData(() => data)
  }

  return (
    <>
      <div className="row m-1">
        <div className="col-12">
          <EditPageHeader title={`${lang.prototype_sub_menu} -> ` + (dataItem.Name ? dataItem.Name : `${lang.new_title_editpageheader}`)} onSubmit={onSave} onCancel={() => navigate(-1)} />
          <div className="row mt-3">
            <div className="col-4">
              <label htmlFor="">{lang.id_label} :-</label>
              <span className="float-right">
                {hideManualId && <>
                  <input
                    name={"isManualPrototypeId"}
                    type={"Checkbox"}
                    value={dataItem.isManualPrototypeId}
                    onChange={onChange}
                    checked={dataItem.isManualPrototypeId}
                  />
                  <label className="pl-1" style={{fontSize: '13px'}}>{lang.manual_prototype_id_label}</label>
                </>}
              </span>
                {dataItem.isManualPrototypeId ?
                  <div>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="ManualPrototypeId"
                      value={dataItem.ManualPrototypeId}
                      onChange={onChange}
                    />
                  </div>
                :  <div>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="ID"
                  value={dataItem.ID}
                  onChange={onChange}
                  disabled
                />
              </div>}
            </div>

            {(SID == 0 || loc.state?.copy) && <div className="col-4">
              <label htmlFor="TabView">{lang.content_label} *</label>
              <DropDownList
                style={{ backgroundColor: "white" }}
                data={contents}
                name="Content"
                textField="Description"
                dataItemKey="_id"
                value={dataItem.Content}
                onChange={onChange}
              />
            </div>}
            <div className="col-4">
              <label htmlFor="TabView">{lang.segment_position_label} *</label>
              <DropDownList
                style={{ backgroundColor: "white" }}
                data={SEGMENTPOSITIONS}
                defaultValue={SEGMENTPOSITIONS[0]}
                name="SegmentPosition"
                textField="Description"
                dataItemKey="ID"
                value={dataItem.SegmentPosition}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4">
              <label htmlFor="">{lang.name_column} *</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="Name"
                value={dataItem.Name}
                onChange={onChange}
                required
              />
            </div>
            <div className="col-4">
              <label htmlFor="TabView">{lang.channel_label} *</label>
              <DropDownList
                style={{ backgroundColor: "white" }}
                data={channel}
                name="Channel"
                textField="FullChannelName"
                dataItemKey="_id"
                value={dataItem.Channel}
                onChange={onChange}
              />
            </div>
            <div className="col-4">
              <label htmlFor="TabView">{lang.source_label} *</label>
              <DropDownList
                style={{ backgroundColor: "white" }}
                data={prototypeSource}
                name="ProtoTypeSource"
                textField="Name"
                dataItemKey="_id"
                value={dataItem.ProtoTypeSource}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4">
              <label htmlFor="TabView">{lang.group_label} *</label>
              <DropDownList
                style={{ backgroundColor: "white" }}
                data={PROTOTYPEMEDIACATEGORYGROUP}
                name="Group"
                textField="Description"
                dataItemKey="ID"
                value={dataItem.Group}
                onChange={onChange}
              />
            </div>
            <div className="col">
              <label htmlFor="TabView">{lang.media_label}</label>
              <input name="MediaEpisode" type="text" className="form-control form-control-sm" value={selectedMediaEpisode.Title} onChange={() => ""} disabled={true} />
            </div>
            <div className="mt-1 mr-5" style={{marginLeft: '-13px'}}>
              <button style={{ margin: "21px 2px 2px 2px" }} type="button" title={lang.add_media_episode_button_tooltip} onClick={onSelectMediaEpisode} className={` ml-2 btn btn-default btn-circle btn-my`} >
                <i className="fa fa-ellipsis" ></i>
              </button>
              <button style={{ margin: "21px 2px 2px 2px" }} type="button" title={lang.remove_media_episode_button_tooltip} onClick={() => setSelectedMediaEpisode({ Title: '' })} className={`btn btn-default btn-circle btn-my`} >
                <i className="fa fa-trash" ></i>
              </button>
              {openMediaEpisodeSelection && <CollectionSelection addButtonTitle={lang.select_button_text} title={lang.select_media_episode_collection_header} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setOpenMediaEpsodeSelection(!openMediaEpisodeSelection)} setDataList={(dataList) => onSetSelectedMediaEpisode(dataList)} mode={'single'} width={"50vw"} height={"60vh"} />}
            </div>
            <div className="col mt-4">
              <input
                name={"Archive"}
                type={"Checkbox"}
                value={dataItem.Archive}
                onChange={onChange}
                checked={dataItem.Archive}
              />
              <label className="pl-1">{lang.archive}</label>
            </div>
          </div>
          <div className="row ml-1 mt-3">
            <div
              className="col-5"
              style={{ height: "130px", fontSize: "10px", margin: "06px 0px 0px 0px", border: "1px solid grey"}}
            >
              <div style={borderWithTitle}>
                &nbsp; {lang.start_type_label} &nbsp;
                <div className="row mt-1">
                  <div className="col-3 mt-4">
                    <span style={{ fontSize: "12px" }}>{lang.start_offset_label} *</span>
                  </div>
                  <div className="col-5">
                    <div className="form-group" style={{ textAligh: "left" }}>
                      <label htmlFor="">{lang.time_hh_mm_ss_ff_label}</label>
                      <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="StartOffset"
                        value={dataItem.StartOffset}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                  <span className="col-4">
                    <input
                      name={"IsStartNegative"}
                      type={"Checkbox"}
                      checked={dataItem.IsStartNegative}
                      onChange={onChange}
                      style={{
                        marginTop: "30px",
                        textAlign: "right",
                        marginRight: "5px",
                      }}
                    />
                    {lang.negative_label}
                  </span>
                  {/* <div className="col-4 mt-4">
                                  <span style={{fontSize:"12px"}}>Start Offset Negattive</span>
                              </div>                                                 */}
                </div>
                <div className="row">
                  <div className="col-3" style={{ marginTop: "0px" }}>
                    <span style={{ fontSize: "12px" }}>{lang.start_type_label} *</span>
                  </div>
                  <div className="col-9">
                    <div className="form-group">
                      <label htmlFor="TabView"></label>
                      <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={PROTOTYPETRIGGERTYPE}
                        name="StartTriggerType"
                        textField="Description"
                        dataItemKey="ID"
                        defaultValue={PROTOTYPETRIGGERTYPE[1]}
                        value={dataItem.StartTriggerType}
                        onChange={onChange}
                        validator={(value) =>
                          value ? "" : "Please select the value"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-5 ml-4"
              style={{
                height: "130px",
                fontSize: "10px",
                margin: "06px 0px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <div style={borderWithTitle}>
                &nbsp; {lang.end_type_label} &nbsp;
                <div className="row mt-1">
                  <div className="col-3 mt-4">
                    <span style={{ fontSize: "12px" }}>{lang.end_offset_label} *</span>
                  </div>
                  <div className="col-5">
                    <div className="form-group" style={{ textAligh: "left" }}>
                      <label htmlFor="">{lang.time_hh_mm_ss_ff_label}</label>
                      <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="EndOffset"
                        value={dataItem.EndOffset}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                  <span className="col-4" style={{ marginRight: "0px" }}>
                    <input
                      name={"IsEndNegative"}
                      type={"Checkbox"}
                      value={dataItem.IsEndNegative}
                      onChange={onChange}
                      checked={dataItem.IsEndNegative}
                      style={{
                        marginTop: "30px",
                        textAlign: "right",
                        marginRight: "5px",
                      }}
                    />
                    {lang.negative_label}
                  </span>
                </div>
                <div className="row">
                  <div className="col-3" style={{ marginTop: "0px" }}>
                    <span style={{ fontSize: "12px" }}>{lang.end_type_label} *</span>
                  </div>
                  <div className="col-9">
                    <div className="form-group">
                      <label htmlFor="TabView"></label>
                      <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={PROTOTYPETRIGGERTYPE}
                        name="EndTriggerType"
                        textField="Description"
                        dataItemKey="ID"
                        value={dataItem.EndTriggerType}
                        onChange={onChange}
                        validator={(value) =>
                          value ? "" : "Please select the value"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab label={lang.dynamic_rules_label} value="1" />
                  <Tab label={lang.restriction_label} value="2" />
                </TabList>
              </Box>
              <TabPanel value={"1"}>
                <SecondaryEventPrototypeRules data={rulesData} setRulesData={setRules} />
              </TabPanel>
              <TabPanel value={"2"}>
                <PrototypeRestrictionEditForm data={prototypeRestriction} setRestrictionData={setRestrictionData} />
              </TabPanel>

            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
};
export default SecondaryEventPrototype;
