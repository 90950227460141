/* eslint-disable */
import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from "../../components/EditPageHeader";
import * as API from "../../framework/API/api";
import {
  ENTITYNAME,
  SALESSTATUS,
} from "../../framework/constant/constant";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";

const OttProductEditForm = (props) => {
  const lang = useTranslation();
  const [segments, setSegments] = useState([]);
  const [showPaymentPlan, setShowPaymentPlan] = useState(false);


  const blankDataItem = {
    ...props.item,
    Title: props.item.copy
      ? "Copy of " + props.item.Title
      : props.item.Title ?? "",
    Description: props.item.Description,
    SalesStatus: props.item.SalesStatus ?? SALESSTATUS[0],
    Segments: props.item.Segments ?? [],
    Archive: props.item.Archive ?? false,
  };

  useEffect(() => {
    loadcombo();
  }, []);

  const [dataItem, setDataItem] = useState(blankDataItem);

  const loadcombo = async () => {
    let segmentsRes = await API.getDataLookup(ENTITYNAME.OttSegment, { query: ["Archive", '=', false], sort: { Description: 1 } });
    setSegments(segmentsRes.data);
  };

  const onChangeForm = (e) => {
    if (e.target.name == 'Archive') {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const isValid = () => {
    if (dataItem.Title == "" || dataItem.Title == undefined) {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.Segments.length == 0) {
      toast.error(`${lang.please_select_segment_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (Object.keys(dataItem.SalesStatus).length == 0) {
      toast.error(`${lang.please_select_sales_status_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (isValid()) {
      const saveData = {
        SID: dataItem.SID ?? 0,
        Title: dataItem.Title,
        Description: dataItem.Description,
        SalesStatus: {
          _id: dataItem.SalesStatus._id,
          SID: dataItem.SalesStatus.SID,
          Name: dataItem.SalesStatus.Name,
        },
        Segments: dataItem.Segments.map((x) => {
          return {
            _id: x._id,
            SID: x.SID,
            Description: x.Description
          }
        }),
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Title", "=", dataItem.Title]],
      };

      let res = await API.saveData(ENTITYNAME.OttProduct, saveData);

      if (res.success) {
        props.cancelEdit();
        props.refresh();
        utility.deleteLocalStorageItem(ENTITYNAME.OttProduct)
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };



  // const handlePaymentPlanList = (dataList) => {
  //   //check duplicate
  //   setDataItem({...dataItem,PaymentPlan : [...dataItem.PaymentPlan,...dataList] })
  // }
  // const removePaymentPlan = (item) => {
  //   var newList = dataItem.PaymentPlan.filter((x) => x._id != item._id);
  //   setDataItem({...dataItem,PaymentPlan : newList })
  // }

  // const MyCommandCell = (props)=>(
  //   <div className="mt-1" style={{display:"flex"}}>
  //   <DeleteRoundButton onClick={()=>ConfirmDeleteAlert(()=>removePaymentPlan(props.dataItem),()=>{})}/>
  //   </div>
  // )

  return (
    <>
      <Dialog
        title={
          props.item.Title ?? `${lang.create_ott_product_type_dialog_header}`
        }
        onClose={props.cancelEdit}
        width={"800px"}
      >
        <EditPageHeader
          title={""}
          onSubmit={handleSubmit}
          onCancel={props.cancelEdit}
          showTitle={false}
        />
        <Form
          initialValues={dataItem}
          render={(formRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-12">
                  <div className="row mt-2">
                    <div className="col-6">
                      <label>{lang.title_label} *</label>
                      <input
                        name={"Title"}
                        type="text"
                        style={{
                          border: "solid lightgrey 1px",
                          height: "32px",
                        }}
                        onChange={onChangeForm}
                        value={dataItem.Title}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.description_label} *</label>
                      <input
                        name={"Description"}
                        type="text"
                        style={{
                          border: "solid lightgrey 1px",
                          height: "32px",
                        }}
                        onChange={onChangeForm}
                        value={dataItem.Description}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <label htmlFor="TabView">
                        {lang.sales_status_label} *
                      </label>
                      <DropDownList
                        style={{
                          backgroundColor: "white",
                        }}
                        data={SALESSTATUS}
                        name="SalesStatus"
                        textField="Name"
                        dataItemKey="SID"
                        value={dataItem.SalesStatus}
                        onChange={onChangeForm}
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="TabView">{lang.segment_label} *</label>
                      <MultiSelect
                        style={{
                          backgroundColor: "white",
                        }}
                        data={segments}
                        name="Segments"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Segments}
                        onChange={onChangeForm}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 mt-2 ml-1">
                      <input type={'checkbox'}
                        name={"Archive"}
                        label={lang.archive}
                        value={dataItem.Archive}
                        checked={dataItem.Archive}
                        onClick={onChangeForm}
                        onChange={() => { }}
                      />
                      <label className='ml-2'>{lang.archive}</label>
                    </div>
                  </div>

                  {/* <div className="row mt-3">
                  
                  <div className="col-8">
                       <AddRoundButton onClick={() => setShowPaymentPlan(true)} title={lang.add_payment_plan_tooltip}/>
                  </div>
                  <div className="col-4">
                       <h5 className="pull-right">{lang.payment_plan_label}</h5>
                  </div>
                  </div>  
                   <div className="row ml-2 mr-2">
                  <Grid data={dataItem.PaymentPlan} style={{height : '180px' ,margin:'5px 0px'}}>
                      <GridColumn cell={MyCommandCell} width={"35px"} locked={true} />
                      <GridColumn field="Description" title={lang.description_label} editable={false}  />
                      <GridColumn field="OttPeriod.Name" title={lang.ott_period_label} editable={false} />
                      <GridColumn field="Currency.Description" title={lang.currency_label} editable={false}/>
                      <GridColumn field="Amount" title={lang.amount_label} editable={false} />          
                  </Grid>
                  </div> {showPaymentPlan && <CollectionSelection entityname={ENTITYNAME.PaymentPlan} title={lang.select_payment_plan_collection} closeForm={() => setShowPaymentPlan(false)} setDataList={handlePaymentPlanList} width={"50vw"} height={"60vh"}/>} */}
                </div>
              </div>
            </FormElement>
          )}
        />
      </Dialog>
    </>
  );
};

export default OttProductEditForm;
