/* eslint-disable */
import { Dialog } from '@progress/kendo-react-dialogs'
import React, { useState, useEffect, useRef } from 'react'
import { ENTITYNAME } from "../../../framework/constant/constant";
import * as API from "../../../framework/API/api";
import { Form, FormElement } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../../components/EditPageHeader";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { toast } from 'react-toastify';
import { useTranslation } from '../../../locale/useTranslation';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import DeleteRoundButton from '../../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import EditRoundButton from '../../../framework/forms/helpercomponents/buttons/EditRoundButton';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';
import { TimeRangePicker } from '../../../framework/forms/helpercomponents/timepicker/TimeRangePicker';
import { utility } from '../../../framework/utility/utilityProvider';
import { ShortTimeCell } from '../../../framework/forms/helpercomponents/CustomGridCells/TimeCell';

export const FCT = (props) => {

  useEffect(() => {
    loadcombo();
  }, []);

  const blankDataItem = {
    Channel: [],
    FCT: [],
    FromTime: "00:00",
    ToTime: "00:00",
    CampaignType: "",
    Rate10Sec: '',
    Days: []
  }
  const [campaignType, setCampaignType] = useState([]);
  const [i, setI] = useState(0);
  const [dataItem, setDataItem] = useState(blankDataItem);

  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
    selectedIndexRef.current = data;
  }

  const [channel, setChannel] = useState([]);
  const [openFCT, setOpenFCT] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const lang = useTranslation();



  const onChangeForm = (e) => {
    if (e.target.name == "FromTime" || e.target.name == "ToTime") {
      setDataItem({ ...dataItem, [e.target.name]: utility.validateShortTimeString(e.target.value) });
    }else{
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const onChangeDay = (e) => {
    let tempDays = dataItem.Days;
    if (e.target.name == 'SelectAll') {
      tempDays = e.target.value ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] : [];
    }
    else {
      if (e.target.value) {
        tempDays.push(e.target.name);
      } else {
        tempDays = tempDays.includes(e.target.name) ? tempDays.filter((Day) => Day != e.target.name) : tempDays;
      }
    }

    setDataItem({ ...dataItem, Days: tempDays });

  }

  const loadcombo = async () => {
    let channelData = await API.getDataLookup(ENTITYNAME.Channel);
    setChannel(channelData.data);
    let CampaignTypeData = await API.getDataLookup(ENTITYNAME.CampaignType);
    setCampaignType(CampaignTypeData.data);
  }

  const isValid = () => {

    if (Object.keys(dataItem.Channel).length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.FCT == "" || dataItem.FCT == undefined) {
      toast.error(`${lang.please_enter_fct_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.FCT <= 0) {
      toast.error(`${lang.fct_should_be_greater_than_zero_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(dataItem.FCT <= props.totalSeconds){
      toast.error(`${lang.fct_should_not_be_greater_than_campaign_target_seconds_error_message} - ${props.totalSeconds}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.FromTime == "00:00" || dataItem.FromTime == undefined) {
      toast.error(`${lang.please_enter_from_time_label}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ToTime == "00:00" || dataItem.ToTime == undefined) {
      toast.error(`${lang.please_enter_to_time_label}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(dataItem.FromTime >= dataItem.ToTime){
      toast.error(`${lang.from_time_should_be_less_than_To_time_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });    
      return false;
    }
    if (Object.keys(dataItem.CampaignType).length == 0) {
      toast.error(`${lang.please_select_campaign_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Rate10Sec == "" || dataItem.Rate10Sec == undefined) {
      toast.error(`${lang.please_enter_rate10sec_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Rate10Sec < 0) {
      toast.error(`${lang.rate_10_sec_can_not_be_negative_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(dataItem.Days.length == 0){
      toast.error(`${lang.please_select_atleast_one_day_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(!editMode && props.data.some(x=> x.Channel.SID == dataItem.Channel.SID && x.FromTime == utility.convertShortTimeStringToMilliSeconds(dataItem.FromTime) && x.ToTime == utility.convertShortTimeStringToMilliSeconds(dataItem.ToTime) && JSON.stringify(x.Days) === JSON.stringify(dataItem.Days))){
      toast.error(`${lang.fct_already_exists_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onAddFCT = async () => {

    if (isValid()) {
      const saveData = {...dataItem,
        Channel: {
          _id:dataItem.Channel._id,
          SID:dataItem.Channel.SID,
          FullChannelName: dataItem.Channel.FullChannelName,
        },
        FCT: dataItem.FCT,
        FromTime: utility.convertShortTimeStringToMilliSeconds(dataItem.FromTime),
        ToTime: utility.convertShortTimeStringToMilliSeconds(dataItem.ToTime),
        CampaignType: {
        _id: dataItem.CampaignType._id,
        SID: dataItem.CampaignType.SID,
        Description: dataItem.CampaignType.Description
        },
        Rate10Sec: dataItem.Rate10Sec,

      }
      console.log(saveData);
      if (!saveData.success) {
        setOpenFCT(false)
        props.setDataList([...props.data, saveData])
        setOpenFCT(false)
        setEditMode(false)
        setI(i + 1);
        setDataItem(blankDataItem);
      }
      else {
        toast.error(saveData.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  const handleSubmit = async () => {
    if (editMode) {
      editFCT()
    }
    else {
      onAddFCT();
    }
  }

  const MyCommandCell = (props) => (
    <div className="mt-1" style={{ display: "flex" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeData(props.dataIndex), () => { })} />
      <EditRoundButton onClick={() => editData(props)} />
    </div>
  )

  const removeData = (index) => {

    console.log(props);
    if (props.data.length == 1) {
      props.setDataList([]);
      setI(i - 1);
    }
    else {
      props.setDataList([
        ...props.data.slice(0, index),
        ...props.data.slice(index + 1, props.data.length)
      ]);
      setI(i - 1);
    }
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const editData = (data) => {
    let dataItem = data.dataItem;
    setDataItem({
      ...dataItem,
      FromTime: utility.convertMilisecondsToStringWithFrames(dataItem.FromTime),
      ToTime:utility.convertMilisecondsToStringWithFrames(dataItem.ToTime)
    });
    setSelectedIndexRef(data.dataIndex);
    setOpenFCT(true);
    setEditMode(true);
  }

  const editFCT = () => {
    let local = [...props.data]
    let temp = {
      ...dataItem,
      Channel: {
        _id:dataItem.Channel._id,
        SID:dataItem.Channel.SID,
        FullChannelName: dataItem.Channel.FullChannelName,
      },
      CampaignType: {
        _id: dataItem.CampaignType._id,
        SID: dataItem.CampaignType.SID,
        Description: dataItem.CampaignType.Description
        },
      FromTime: utility.convertShortTimeStringToMilliSeconds(dataItem.FromTime),
      ToTime: utility.convertShortTimeStringToMilliSeconds(dataItem.ToTime),
    }
    local[selectedIndexRef.current] = temp;
    if(!isValid()) return;
    props.setDataList(local);
    setEditMode(false);
    setOpenFCT(false);
    setDataItem(blankDataItem);
  }

  const cancelEdit = () => {
    setOpenFCT(false)
    setDataItem(blankDataItem)
  }

  return (
    <>
      <AddRoundButton onClick={() => setOpenFCT(true)} style={{ marginLeft: "-15px" }} title={lang.add_fct_button_tooltip} />

      <Grid data={props.data} style={{ height: "40vh", margin: "5px -15px 40px -15px" }}>
        <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
        <GridColumn field="Channel.FullChannelName" title={lang.channel_column} editable={false} />
        <GridColumn field="FCT" title={lang.fct_column} editable={false} />
        <GridColumn field="FromTime" title={lang.from_time_column} editable={false} cell={ShortTimeCell} />
        <GridColumn field="ToTime" title={lang.to_time_column} editable={false} cell={ShortTimeCell} />
        <GridColumn field="CampaignType.Description" title={lang.campaign_type_column} editable={false} />
        <GridColumn field="Rate10Sec" title={lang.rate10sec_column} editable={false} />
        <GridColumn field="Days" title={lang.days_column} editable={false} />
      </Grid>
      {openFCT && <Dialog
        title={lang.fct_dialog_header}
        onClose={cancelEdit}
        width={720}
        height={400}
      >
        <EditPageHeader onSubmit={() => { handleSubmit() }} onCancel={cancelEdit} showTitle={false} />
        <Form
          render={(formRenderProps) => (
            <FormElement>
              <div className="marlr">
              </div>
              <div className="row mt-2">
                <div className='col-12'>
                  <div className='row'>
                    <div className="col-6">
                      <label>{lang.channel_label} *</label>
                      <DropDownList
                        data={channel}
                        name="Channel"
                        textField="FullChannelName"
                        dataItemKey="_id"
                        value={dataItem.Channel}
                        onChange={onChangeForm}
                        validator={(value) => value ? "" : "Please select the value"}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.fct_label} *</label>
                      <input type="number" className="form-control form-control-sm"
                        name="FCT"
                        value={dataItem.FCT}
                        onChange={onChangeForm} />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label htmlFor="FromTime">{lang.from_time_label} *</label>
                        <TimeRangePicker
                          name="FromTime"
                          value={dataItem.FromTime}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className="form-group">
                        <label htmlFor="ToTime">{lang.to_time_label} *</label>
                        <TimeRangePicker
                          name="ToTime"
                          value={dataItem.ToTime}
                          onChange={(e) => onChangeForm(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className="col-6">
                      <label>{lang.campaign_type_label} *</label>
                      <DropDownList
                        data={campaignType}
                        name="CampaignType"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.CampaignType}
                        onChange={onChangeForm}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.rate10sec_label} *</label>
                      <input type="number" className="form-control form-control-sm"
                        name="Rate10Sec"
                        value={dataItem.Rate10Sec}
                        onChange={onChangeForm} />
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-12'>
                      <label className='mr-1'>{lang.day_label}</label>
                      <Checkbox name={"SelectAll"} label={lang.select_all_label} value={dataItem.Days.length == 7 ? true : false} onChange={onChangeDay} />
                      <div style={{ border: "1px dotted", display: "inline", padding: "5px", marginLeft: "5px" }}>
                        <div style={{ margin: '0px 4px', display: "inline" }} >
                          <Checkbox
                            name={"Sun"}
                            label={lang.sun_label}
                            onChange={onChangeDay}
                            value={dataItem.Days.includes("Sun")}
                          />
                        </div>
                        <div style={{ margin: '0px 4px', display: "inline" }} >
                          <Checkbox
                            name={"Mon"}
                            label={lang.mon_label}
                            onChange={onChangeDay}
                            value={dataItem.Days.includes("Mon")}
                          />
                        </div>
                        <div style={{ margin: '0px 4px', display: "inline" }} >
                          <Checkbox
                            name={"Tue"}
                            label={lang.tue_label}
                            onChange={onChangeDay}
                            value={dataItem.Days.includes("Tue")}
                          />
                        </div>
                        <div style={{ margin: '0px 4px', display: "inline" }} >
                          <Checkbox
                            name={"Wed"}
                            label={lang.wed_label}
                            onChange={onChangeDay}
                            value={dataItem.Days.includes("Wed")}
                          />
                        </div>
                        <div style={{ margin: '0px 4px', display: "inline" }} >
                          <Checkbox
                            name={"Thu"}
                            label={lang.thu_label}
                            onChange={onChangeDay}
                            value={dataItem.Days.includes("Thu")}
                          />
                        </div>
                        <div style={{ margin: '0px 4px', display: "inline" }} >
                          <Checkbox
                            name={"Fri"}
                            label={lang.fri_label}
                            onChange={onChangeDay}
                            value={dataItem.Days.includes("Fri")}
                          />
                        </div>
                        <div style={{ margin: '0px 4px', display: "inline" }} >
                          <Checkbox
                            name={"Sat"}
                            label={lang.sat_label}
                            onChange={onChangeDay}
                            value={dataItem.Days.includes("Sat")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </Dialog>}

    </>
  )
}