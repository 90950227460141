/* eslint-disable */
import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from "../../components/EditPageHeader";
import * as API from "../../framework/API/api";
import {ENTITYNAME, LOGEVENT, MODULE} from "../../framework/constant/constant";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { ImageBrowser } from "../../framework/forms/helpercomponents/selectFile/ImageBrowser";

const TutorialVideosEditForm = (props) => {
  const lang = useTranslation();
  const blankDataItem = {
    ...props.item,
    Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
    Summary: props.item.Summary,
    PlaybackUrl: props.item.PlaybackUrl,    
    ThumbnailUrl: props.item.ThumbnailUrl ?? '',
    Archive: props.item.Archive ?? false,
  };

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [thumbnailFiles, setThumbnailFiles] = useState(new Set());
  
  const onChangeForm = (e) => {
    if(e.target.name == 'Archive'){
      setDataItem({ ...dataItem, Archive : !dataItem.Archive });
    }
    else if (e.target.name == 'ThumbnailUrl' && e.target?.files) {
      if (e.target.files != undefined) {
        setThumbnailFiles(e.target.files[0]);
        setDataItem({ ...dataItem, ThumbnailUrl: e.target.value });
      } else {
        setThumbnailFiles(new Set());
        setDataItem({ ...dataItem, ThumbnailUrl: e.target.value });
      }
    }
    else{
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const handleChange = (e) => {
    if(e.target.name == 'ImageUrl' && e.target?.files){
      if(e.target.files != undefined){
        setImageFiles(e.target.files[0]);
      }else{
        setImageFiles(new Set());
      }
    }
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }
  const isValid = () => {   
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if(!dataItem.Description.trim()) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Summary == "" || dataItem.Summary == undefined) {
      toast.error(`${lang.please_enter_summary_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if(!dataItem.Summary.trim()) {
      toast.error(`${lang.please_enter_summary_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.PlaybackUrl == "" || dataItem.PlaybackUrl == undefined) {
      toast.error(`${lang.please_enter_playback_url_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if(!dataItem.PlaybackUrl.trim()) {
      toast.error(`${lang.please_enter_playback_url_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ThumbnailUrl == "" || dataItem.ThumbnailUrl == undefined) {
      toast.error(`${lang.please_enter_thumbnail_url_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    
    
    
    return true;
  };

  const handleSubmit = async () => {
    if (!isValid()) return;

    let ThumbnailUrl = dataItem.ThumbnailUrl;
    console.log(ThumbnailUrl);
    if (thumbnailFiles.size > 0) {
      console.log(thumbnailFiles);
      let resUrl = await API.upladImage(thumbnailFiles);
      if (!resUrl.success) {
        toast.error(`${lang.upload_fail_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      ThumbnailUrl = resUrl.data;
      dataItem.ThumbnailUrl = ThumbnailUrl;
    }

      const saveData = {
        _id: dataItem._id,
        SID: dataItem.SID,
        Description: dataItem.Description,
        Summary: dataItem.Summary,
        PlaybackUrl: dataItem.PlaybackUrl,
        ThumbnailUrl: ThumbnailUrl,//imagePotraitImageFiles.size > 0 ? thumbnailUrlUrl :  dataItem.ThumbnailUrl,//
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Description", "=", dataItem.Description]],
      };
      console.log(saveData)

      let res = await API.saveData(ENTITYNAME.TutorialVideos, saveData);

      if (res.success) {
        let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_TUTORIAL_VIDEO : LOGEVENT.UPDATE_TUTORIAL_VIDEO, module: MODULE.TUTORIAL_VIDEOS, data: res.data, message: res.message };
        API.SaveLogs(logData);
        props.cancelEdit();
        props.refresh();
        utility.deleteLocalStorageItem(ENTITYNAME.TutorialVideos)
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    
  };

  const handleThumbnailUrlSelect = (dataList) => {
    console.log(dataList)
    setDataItem({ ...dataItem, ThumbnailUrl: dataList[0].ImageUrl });
  }


  return (
    <>
      <Dialog
        title={props.item.Description ?? `${lang.create_tutorial_videos_dialog_header}`}
        onClose={props.cancelEdit}
        width={"800px"}>
        <EditPageHeader
          title={""}
          onSubmit={handleSubmit}
          onCancel={props.cancelEdit}
          showTitle={false}
        />
        <Form
          initialValues={dataItem}
          render={(formRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-12">
                  <div className="row mt-2">
                    <div className="col-6">
                      <label>{lang.description_label} *</label>
                      <input
                        name={"Description"}
                        type="text"
                        style={{
                          border: "solid lightgrey 1px",
                          height: "32px",
                        }}
                        onChange={onChangeForm}
                        value={dataItem.Description}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.summary_label} *</label>
                      <input
                        name={"Summary"}
                        type="text"
                        style={{
                          border: "solid lightgrey 1px",
                          height: "32px",
                        }}
                        onChange={onChangeForm}
                        value={dataItem.Summary}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <label>{lang.playback_url_label} *</label>
                      <input
                        name={"PlaybackUrl"}
                        type="text"
                        style={{
                          border: "solid lightgrey 1px",
                          height: "32px",
                        }}
                        onChange={onChangeForm}
                        value={dataItem.PlaybackUrl}
                      />
                    </div>
                    <div className="col-6">
                        <label>{lang.thumbnail_url_label} *</label>
                        <ImageBrowser name={'ThumbnailUrl'} value={dataItem.ThumbnailUrl} onChange={onChangeForm} hidePosterLibrary={false} setSelectedImage={handleThumbnailUrlSelect} />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 mt-2 ml-1">
                      <input type={'checkbox'}
                        name={"Archive"}
                        label={lang.archive}
                        value={dataItem.Archive}
                        checked={dataItem.Archive}
                        onClick={onChangeForm}
                        onChange={() => {}}
                      />
                    <label className='ml-2'>{lang.archive}</label>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </Dialog>
    </>
  );
};
export default TutorialVideosEditForm;
