/* eslint-disable */
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import {
  getSelectedState,
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  ENTITYNAME,
  FILEURL,
  MEDIACATEGORIES,
  MEDIACATEGORYTYPES,
  MODULE,
  SOCKET_ACTION,
  SOCKET_EVENTS,
} from "../../../framework/constant/constant";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { MenuCommandCell } from "../../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { useTranslation } from "../../../locale/useTranslation";
import LiveEventCard from "./LiveEventCard";
import { MatchesMenuModel } from "./menu/matchesMenuModel";
import * as API from "../../../framework/API/api";
import AddRoundButton from "../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { DateCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { LiveMatchEditForm } from "./LiveMatchEditForm";
import { toast } from "react-toastify";
import MyStatusCell from "../../../components/MyStatusCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TimePickerWithFormat } from "../../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import ActionButton from "../../../framework/forms/helpercomponents/buttons/ActionButton";
import { utility } from "../../../framework/utility/utilityProvider";
import { MyPublishedChannelArrayCell } from "./MyPublishedChannelArrayCell";
import UnPublishSelectionCollection from "./unPublishSelectionCollection";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import socket from "../../../framework/socket/socket";
import { getter } from "@progress/kendo-data-query";
import PlanningDistribution from "../../Planning/PlanningDistribution";
import RefreshButton from "../../../framework/forms/helpercomponents/buttons/RefreshButton";
import { Loader } from "../../../framework/forms/helpercomponents/Loader";
import { ContextMenu } from "primereact/contextmenu";
import useSocket from "../../../framework/socket/useSocket";
import LiveEventTypeEditForm from "../../masters/LiveEventTypeEditForm";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export default function TournamentMatchList(props) {
  const lang = useTranslation();

  const [showPublishMenu,setShowPublishMenu] = useState(true);
  const selectedItemsRef = useRef([]);
  const contextMenuRef = useRef();
  const setSelectedItems = (data) => {
    selectedItemsRef.current = data;
  };

  const [selectedState, setSelectedState] = useState({});

  const { selectedTournamentMatches, selectedTournament, isMatchLoading } = props;
  const [tileView, setTileView] = useState(false);
  const [matches, setMatches] = useState(selectedTournamentMatches);
  const [allMatches, setAllMatches] = useState(selectedTournamentMatches);

  //DIALOG BOOLEANS
  const [showMatchEditForm, setShowMatchEditForm] = useState(false);
  const [openPopupScheduleMatch, setOpenPopupScheduleMatch] = useState(false);
  const [openCollectionSelection, setOpenCollectionSelection] = useState(false);
  const [
    openCollectionSelectionUnPublish,
    setOpenCollectionSelectionUnPublish,
  ] = useState(false);
  const [openPopupExport, setOpenPopupExport] = useState(false);
  const [
    showPlanningDistributionTemplate,
    setShowPlanningDistributionTemplate,
  ] = useState(false);

  const selectedScheduleStatusItemRef = useRef([]);
  const setSelectedScheduleStatusItemRef = (data) => {
    selectedScheduleStatusItemRef.current = data;
  };
  const [showLiveEventTypeEditForm,setShowLiveEventTypeEditForm ] = useState(false);

  //DROPDOWN CONSTANTS
  const [tournament, setTournament] = useState([]);
  const [liveEventRound, setLiveEventRound] = useState([]);
  const [liveEventType, setLiveEventType] = useState([]);

  const matchItem = useRef({ SID: 0 });
  const setMatchItem = (data) => {
    matchItem.current = data;
  };

  const unPublishRef = useRef();
  const setUnPublishRef = (data) => {
    unPublishRef.current = data;
  };

  const [scheduleMatchDataItem, setScheduleMatchDataItem] = useState({
    Channels: [],
    StartDate: "",
    StartTime: "",
  });

  const defaultDates = utility.getFirstAndLastDateOfMonth();

  const [filterDataItem,setFilterDataItem] = useState({})

  const filterDataItemRef = useRef({
    FromDate: moment(new Date(selectedTournament.StartDate)).format('YYYY-MM-DD'),
    ToDate: moment(new Date(selectedTournament.EndDate)).format('YYYY-MM-DD'),
    Type: { SID: 0, Description: "All" },
    Round: { SID: 0, Description: "All" },
    Publish: "All",
    Tournament: { SID: 0, Name: "All" },
    ExportStartDate: defaultDates.FirstDate,
    ExportEndDate: defaultDates.LastDate,
  });
  const setFilterDataItemRef = (data) => {
    filterDataItemRef.current = data;
    setFilterDataItem(data);
  }

  useEffect(() => {
    loadcombo();
    loadMatches();
  }, [selectedTournamentMatches]);

  const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

    console.log(socketData)
   if (socketData.module != MODULE.LIVE_EVENTS) return;


   switch (socketData.action) {
       case SOCKET_ACTION.LIVE_EVENT_REFRESH:
            refreshAPICall();
           break;
       default:
           break;
   }

});

const loadMatches = () => {
  setFilterDataItemRef({
    FromDate: moment(new Date(selectedTournament.StartDate)).format('YYYY-MM-DD'),
    ToDate: moment(new Date(selectedTournament.EndDate)).format('YYYY-MM-DD'),
    Type: { SID: 0, Description: "All" },
    Round: { SID: 0, Description: "All" },
    Publish: "All",
    Tournament: { SID: 0, Name: "All" },
    ExportStartDate: defaultDates.FirstDate,
    ExportEndDate: defaultDates.LastDate,
  })
  setMatches(selectedTournamentMatches);
  setAllMatches(selectedTournamentMatches);

}

  //Edit Row
  const MyCommandCell = (props) => (
    <MenuCommandCell
      {...props}
      myMenuModel={MatchesMenuModel}
      onScheduleMatch={onScheduleMatch}
      onUnPublish={onUnPublish}
      onEditMatch={editMatch}
      onPlanningDistribution={onPlanningDistribution}
    />
  );

  const refreshAPICall = async () => {
    props.setStatusMesssage(`Loading please wait...`);

    const tournamentMatchListData = await API.getLiveEventsTournamentMatches(selectedTournament._id,{ 
      Publish : filterDataItemRef.current.Publish,
      Round : filterDataItemRef.current.Round,
      Type : filterDataItemRef.current.Type,
      FromDate : utility.getLocalDateTimeToUtcMiliseconds(new Date(filterDataItemRef.current.FromDate)),
      ToDate : utility.getLocalDateTimeToUtcMiliseconds(new Date(filterDataItemRef.current.ToDate))
    });
    if (tournamentMatchListData.success) {
      // setAllMatches(tournamentMatchListData.data);
      setMatches(tournamentMatchListData.data);
      props.setStatusMesssage(`Loaded successfully !!`);
    }
  };

  const loadcombo = async () => {
    var liveEventRound = await API.getDataLookup(ENTITYNAME.LiveEventRound, {
      sort: { SID: 1 },
    });
    setLiveEventRound(liveEventRound.data);

    var liveEventType = await API.getDataLookup(ENTITYNAME.LiveEventType, {
      sort: { SID: 1 },
    });
    setLiveEventType(liveEventType.data);

    var tournament = await API.getDataLookup(ENTITYNAME.Tournament, {
      sort: { SID: 1 },
    });
    setTournament(tournament.data);
  };

  const onChangeFilter = async (e) => {
    if (selectedTournamentMatches.length == 0) return;
    // setFilterDataItem({ ...filterDataItem, [e.target.name]: e.target.value });
    setFilterDataItemRef({ ...filterDataItemRef.current, [e.target.name]: e.target.value });
    await refreshAPICall();

  };

  const onChangeScheduleMatch = (e) => {
    setScheduleMatchDataItem({
      ...scheduleMatchDataItem,
      [e.target.name]: e.target.value,
    });
  };

  const onScheduleMatch = (index, dataItem) => {
    setOpenPopupScheduleMatch(true);

    var item = {
      ...dataItem,
      Channel: [],
      StartDate: moment(new Date(dataItem.StartDateTime))
        .utc()
        .format("YYYY-MM-DD"),
      StartTime: moment(new Date(dataItem.StartDateTime))
        .utc()
        .format("HH:mm:ss:SS"),
    };
    setScheduleMatchDataItem(item);
  };

  //onPublish
  const onPublish = async () => {
    const currentDateInMillisec = utility.getLocalDateTimeToUtcMiliseconds(new Date());

    props.setStatusMesssage(`Publishing please wait...`);

    //CHANNEL VALIDATION
    if (!scheduleMatchDataItem?.Channels || scheduleMatchDataItem?.Channels?.length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    //CANNOT SCHEDULE MATCH BEFORE CURRENT DATE //need to ask
    if (
      currentDateInMillisec >
      utility.getLocalDateTimeToUtcMiliseconds(
        new Date(scheduleMatchDataItem.StartDate)
      )
    ) {
      var msg =
        scheduleMatchDataItem.Type.Description == "Live"
          ? `Cannot schedule ${scheduleMatchDataItem.Type.Description}, schedule date already passed`
          : `Cannot schedule ${scheduleMatchDataItem.Type.Description} before current date `;
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    //CANNOT SCHEDULE MATCH BEFORE LIVE DATE
    if (
      !scheduleMatchDataItem.MediaEpisode.IsLive &&
      scheduleMatchDataItem.StartDateTime >
        utility.getLocalDateTimeToUtcMiliseconds(
          new Date(scheduleMatchDataItem.StartDate)
        )
    ) {
      toast.error(
        ` Cannot schedule ${scheduleMatchDataItem.Type.Description} before live start date `,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return false;
    }

    console.log("success");

    setOpenPopupScheduleMatch(false);

    var payload = {
      MediaEpisode_id: scheduleMatchDataItem.MediaEpisode._id,
      SlotDateTime: utility.getLocalDateTimeToUtcMiliseconds(
        new Date(
          `${scheduleMatchDataItem.StartDate} ${scheduleMatchDataItem.StartTime}`
        )
      ),
      ScheduleDate: new Date(scheduleMatchDataItem.StartDate).getTime(),
      Channel: scheduleMatchDataItem.Channels,
      MediaEpisodeLiveEventDetail_id: scheduleMatchDataItem._id,
    };

    console.log('---')
    console.log(payload)
    console.log('---')

    //call schedule match api here
    var scheduleResponse = await API.scheduleMatch(payload);
    console.log(scheduleResponse);

    if (scheduleResponse.success) {
      //set UI real time
      var item = {
        ...scheduleMatchDataItem,
        ScheduleStatus: scheduleResponse.scheduleStatusArray,
      };
      refreshMatch(scheduleMatchDataItem.MediaEpisode, item);

      props.setStatusMesssage(`${lang.match_scheduled_successfully_message}`);
      toast.success(`${lang.match_scheduled_successfully_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setScheduleMatchDataItem({
        Channels: [],
        StartDate: "",
        StartTime: "",
      });

      //REFRESH PLANNING
      for (let index = 0; index < payload.Channel.length; index++) {
        const ch = payload.Channel[index];

        socket.emit(SOCKET_EVENTS.onSocketData, {
          action: SOCKET_ACTION.PLANNING_REFRESH,
          module: MODULE.PLANNING,
          data: { scheduleScheduleStartDate: payload.SlotDateTime, scheduleScheduleEndDate: payload.SlotDateTime, channelSID: ch.SID },
        });
      }


      // give toast of invalid data
      // need to ask what to do if not scheduled
      // if(scheduleResponse.invalidData.length > 0){
      //   toast.error(`message`, {
      //     position: toast.POSITION.TOP_RIGHT
      //   });
      // }
    }
  };

  const onUnPublish = (index, dataItem) => {

    //CHECK IF IT IS PUBLISHED OR NOT
    if (dataItem.ScheduleStatus.length == 0) {
      toast.error(`Not published yet`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }


    setOpenCollectionSelectionUnPublish(true);
    setUnPublishRef(dataItem);
  };

  const unPublish = async (dataList) => {
    props.setStatusMesssage(`Un-Publishing please wait...`);

    setOpenCollectionSelectionUnPublish(false);
    var tempScheduleStatus = unPublishRef.current.ScheduleStatus;

    for (let i = 0; i < dataList.length; i++) {
      const obj = dataList[i];

      //call delete planning api here
      var payload = {
        _id: obj.ProgramSchedule_id,
        MediaDealRight_id: "",
      };

      // console.log(payload)
      var deleteResponse = await API.deletePlanning(payload);
      console.log(deleteResponse);

      var filter = tempScheduleStatus.filter(
        (x) =>
          obj.ProgramSchedule_id != x.ProgramSchedule_id &&
          obj.SlotDateTime != x.SlotDateTime
      );
      tempScheduleStatus = filter;

      if(deleteResponse.success){
        socket.emit(SOCKET_EVENTS.onSocketData, {
          action: SOCKET_ACTION.PLANNING_REFRESH,
          module: MODULE.PLANNING,
          data: {scheduleScheduleStartDate: obj.SlotDateTime, scheduleScheduleEndDate: obj.SlotDateTime, channelSID: obj.Channel.SID},
        });
      }
    }

    var isScheduled = tempScheduleStatus.length > 0 ? true : false

    var updateLiveEventDetail = await API.saveData(ENTITYNAME.MediaEpisodeDetailLiveEvent,
      { _id: unPublishRef.current._id, ScheduleStatus: tempScheduleStatus ,IsScheduled : isScheduled }
    );

    if (updateLiveEventDetail.success) {
      var item = {
        ...unPublishRef.current,
        ScheduleStatus: tempScheduleStatus,
        IsScheduled : isScheduled
      };
      refreshMatch(unPublishRef.current.MediaEpisode, item);
      socket.emit(SOCKET_EVENTS.onSocketData, {
        action: SOCKET_ACTION.PLANNING_REFRESH,
        module: MODULE.PLANNING,
        data: null,
      });

      props.setStatusMesssage(`Un-Published successfully!!`);
      toast.success(`Un-Published successfully!!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const editMatch = (index, dataItem) => {
    setMatchItem(dataItem);
    setShowMatchEditForm(true);
  };

  const onAddMatch = () => {

    if(selectedTournament && Object.keys(selectedTournament).length == 0){
      toast.success(`Please select tournament first !!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setMatchItem({ SID: 0 });
    setShowMatchEditForm(true);
  };

  const refreshMatch = (mediaEpisode, liveMatchDetail) => {
    var item = {
      ...liveMatchDetail,
      MediaEpisode: mediaEpisode,
    };

    var index = allMatches.findIndex((x) => x.SID == liveMatchDetail.SID);

    if (index < 0) {
      setMatches([...allMatches, item]);
      setAllMatches([...allMatches, item]);
      return;
    }

    var gridData = [...allMatches];
    gridData[index] = item;

    setMatches(gridData);
    setAllMatches(gridData);
  };

  const StatusCell = (props) => {
    var title = "NOT SCHEDULED";
    if (props.dataItem.ScheduleStatus?.length > 0) {
      title = "SCHEDULED";
    }
    return (
      <MyStatusCell
        {...props}
        title={title}
        titleColor="#ffffff"
        tileBackColor={title == "SCHEDULED" ? "#47A992" : "#E76161"}
        margin={"20px 10px"}
        titleFontSize={"12px"}
      />
    );
  };

  //EXPORT LIVE EVENT MATCHES
  const exportLiveEventCSV = async () => {
    props.setStatusMesssage(`Exporting live time table report !!`);

    //create payload
    const payload = {
      startDate: utility.getLocalDateTimeToUtcMiliseconds(
        new Date(filterDataItem.ExportStartDate)
      ),
      endDate: utility.getLocalDateTimeToUtcMiliseconds(
        new Date(filterDataItem.ExportEndDate)
      ),
      tournament: filterDataItem.Tournament,
    };

    const res = await API.exportLiveEventCSV(payload);
    if (res.success) {
      if(res.data.length > 0){
        let filePath = FILEURL.BASEURL + "downloadReport/" + res.fileName;
        window.open(filePath);
        setOpenPopupExport(false);
        props.setStatusMesssage(`Exported successfully..`);
      }else{
        props.setStatusMesssage(`No data found to export !!`);
        toast.info(`No data found to export`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      console.log(res.message);
    }
  };

  //SCHEDULE DISTRIBUTION
  const onPlanningDistribution = (index, dataItem) => {
    setOpenCollectionSelection(true);
    setUnPublishRef(dataItem);
  };

  const onSelectionScheduleStatusItem = (dataList) => {
    setSelectedScheduleStatusItemRef(dataList);
    setOpenCollectionSelection(false);

    console.log("apply distribute");
    setShowPlanningDistributionTemplate(true);
  };

  const setDistributionTemplateData = async (distributionTemplate) => {
    console.log(distributionTemplate);
    props.setStatusMesssage(
      `${lang.applying_planning_distribution_template_please_wait_planner_status_message}`
    );

    for (
      let index = 0;
      index < selectedScheduleStatusItemRef.current.length;
      index++
    ) {
      const plannedItem = {
        SlotDateTime: selectedScheduleStatusItemRef.current[index].SlotDateTime,
        Channel: selectedScheduleStatusItemRef.current[index].Channel,
        _id: selectedScheduleStatusItemRef.current[index].ProgramSchedule_id,
      };

      console.log(plannedItem);

      var response = await API.distributePlanningAcrossChannels(
        plannedItem,
        distributionTemplate,
        true
      );
      if (response.success) {
        toast.success(`${response.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.setStatusMesssage(
          `${lang.planning_distribution_template_applied_success_planner_status_message}`
        );
      } else {
        toast.error(`${response.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  //MULTISELECT
  const [selectedMatch,setSelectedMatch] = useState([]);
  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    setSelectedState(newSelectedState);

    //need to get selected
    var selectedItem = matches.filter((x) => newSelectedState[x._id]);
    setSelectedMatch(selectedItem);
  };

  const onScheduleMutipleEvents = async () => {

    if (selectedMatch.length == 0) {
      toast.error(`Please select live event first`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (selectedMatch.some(x => x.IsScheduled)) {
      toast.error(`Cannot schedule already published item`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (selectedMatch.some(x => x.Type.Description != 'Live')) {
      toast.info(`Can schedule live events only`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }


    console.log('success');


    for (let index = 0; index < selectedMatch.length; index++) {

      const liveEvent = selectedMatch[index];

      if(liveEvent.Type.Description != 'Live') continue;

      var payload = {
        MediaEpisode_id: liveEvent.MediaEpisode._id,
        SlotDateTime: utility.getLocalDateTimeToUtcMiliseconds(new Date(liveEvent.StartDateTime)),
        ScheduleDate: utility.getLocalDateTimeToUtcMiliseconds(new Date(liveEvent.StartDateTime)),
        Channel: liveEvent.Tournament.Channels,
        MediaEpisodeLiveEventDetail_id: liveEvent._id,
      };

      console.log(payload)

      //call schedule match api here
      var scheduleResponse = await API.scheduleMatch(payload);
      console.log(scheduleResponse);

      if (scheduleResponse.success) {
        //set UI real time
        var item = {
          ...liveEvent,
          ScheduleStatus: scheduleResponse.scheduleStatusArray,
          IsScheduled : true
        };
        refreshMatch(liveEvent.MediaEpisode, item);

        props.setStatusMesssage(`Match scheduled successfully !!`);
        toast.success(`Match scheduled successfully !!`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }


    //REFRESH PLANNING
    socket.emit(SOCKET_EVENTS.onSocketData, {
      action: SOCKET_ACTION.PLANNING_REFRESH,
      module: MODULE.PLANNING,
      data: null,
    });

    setSelectedState({});
    setSelectedMatch([]);
  }
  

  //CONTEXT MENU TILE VIEW

  const publishMenu = [
    { label: "Edit", icon: "fa-solid fa-pencil fa-lg", command: () => { editMatch(0, selectedItemsRef.current) }},
    { label: `Publish Match`, icon: "fa-solid fa-calendar-days fa-lg", command: () => { onScheduleMatch(0, selectedItemsRef.current) }},
  ];

  const unPublishMenu = [
    { label: "Edit", icon: "fa-solid fa-pencil fa-lg", command: () => { editMatch(0, selectedItemsRef.current) }},
    { label: `Un-Publish Match`, icon: "fa-solid fa-calendar-days fa-lg", command: () => { onUnPublish(0, selectedItemsRef.current) }},
    { label: 'Distribute Planning', icon: "fa-solid fa-sliders fa-lg", command: () => { onPlanningDistribution(0,selectedItemsRef.current) }}
  ];

  const onContextMenu = (e, item) => {
    contextMenuRef.current.show(e);
    setSelectedItems(item);
    setShowPublishMenu(item?.ScheduleStatus?.length == 0 ? true : false) 
  };

  
  return (
    <div>
      <div className="row m-1">
        <div className="col-8">
          <h1
            className="page-title txt-color-bludeDark"
            style={{ margin: "10px 0" }}
          >
            <i
              className="fa fa-tv"
              style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span
              style={{
                fontFamily: "sans-serif",
                paddingLeft: "5px",
                letterSpacing: "0.5px",
              }}
            >
              Total Assests - {matches?.length}
            </span>
          </h1>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-end">
          <div
            className="pointer p-1"
            onClick={() => setTileView(!tileView)}
            title={
              tileView ? `${lang.grid_view_label}` : `${lang.tile_view_label}`
            }
          >
            <i
              className={`fas ${
                tileView ? "fa-table-cells" : "fa-table-list"
              } fa-xl`}
            />
          </div>
        </div>
      </div>

      <div
        className="row m-2 pt-2"
        style={{ backgroundColor: "#fafafa", borderRadius: "10px" }}
      >
        {/* <div className="col-2 form-group">
          <label htmlFor="TabView">{lang.type_column}</label>
          <div style={{ display: 'flex' }}>
            <DropDownList
              style={{
                backgroundColor: "white",
              }}
              data={[{ SID: 0, Description: "All" }, ...liveEventType]}
              name="Type"
              textField="Description"
              dataItemKey="SID"
              value={filterDataItem.Type}
              onChange={onChangeFilter}
              validator={(value) => (value ? "" : "Please select the value")}
            />
            <button type="button" title='Create Live Event Type' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { setShowLiveEventTypeEditForm(true) }}><i className="fa-solid fa-plus fa-xs"></i>
            </button>
          </div>
        </div> */}
        <div className="col-2 form-group">
          <label htmlFor="TabView">{"Round"}</label>
          <DropDownList
            style={{ backgroundColor: "white" }}
            data={[{ SID: 0, Description: "All" }, ...liveEventRound]}
            name="Round"
            textField="Description"
            dataItemKey="SID"
            value={filterDataItem.Round}
            onChange={onChangeFilter}
            validator={(value) => (value ? "" : "Please select the value")}
          />
        </div>

        <div className="col-2 form-group">
          <label htmlFor="TabView">{"Publish"}</label>
          <DropDownList
            style={{  backgroundColor: "white" }}
            data={["All", "Published", "Un-Published"]}
            name="Publish"
            value={filterDataItem.Publish}
            onChange={onChangeFilter}
            validator={(value) => (value ? "" : "Please select the value")}
          />
        </div>

        <div className="col-2 form-group">
          <label htmlFor="TabView">{lang.from_date_label} </label>
          <input 
            type="date" className="form-control form-control-sm" name="FromDate" value={filterDataItem.FromDate} 
            onChange={onChangeFilter}
            min={moment(new Date(selectedTournament.StartDate)).format('YYYY-MM-DD')}
            max={moment(new Date(selectedTournament.EndDate)).format('YYYY-MM-DD')}  />
        </div>  
        <div className="col-2 form-group">
          <label htmlFor="TabView">{lang.to_date_label} </label>
          <input 
            type="date" className="form-control form-control-sm" name="ToDate" value={filterDataItem.ToDate} 
            onChange={onChangeFilter}
            min={moment(new Date(selectedTournament.StartDate)).format('YYYY-MM-DD')}
            max={moment(new Date(selectedTournament.EndDate)).format('YYYY-MM-DD')} />
        </div>
      </div>

      <div className={"row ml-3 mt-3"}>
        <AddRoundButton
          title={"Create Event"}
          name={"Create Event"}
          onClick={onAddMatch}
        />
        <RefreshButton onClick={refreshAPICall} />
        <RoundButton
          icon={"download"}
          title={"Export Report"}
          btnColor={"warning"}
          onClick={() => setOpenPopupExport(true)}
        />
        {/* <RoundButton
          icon={"calendar-days"}
          title={"Schedule Multiple Events"}
          btnColor={"info"}
          onClick={onScheduleMutipleEvents}
        /> */}
      </div>

      <div className="row ml-1 mr-1" style={{ height: "77vh" }}>
        {isMatchLoading ? (
          <Loader height={"65vh"} />
        ) : tileView ? (
          matches &&
          matches.length > 0 &&
          matches.map((media, index) => (
            <LiveEventCard
              item={media}
              index={index}
              onContextMenu={onContextMenu}
            />
          ))
        ) : (
          <Grid
            style={{ margin: "10px", height: "75vh", width: "100%" }}
            data={matches.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            resizable = {true}
            selectable={{
              enabled: true,
              cell: false,
              mode: "multiple",
            }}
            // onSelectionChange={onSelectionChange}
          >
            <Column cell={MyCommandCell} width={"60px"} locked={true} />
            <Column
              field="PosterUrl"
              title={"Poster"}
              editable={false}
              cell={ImageCell}
              width={"100px"}
            />
            <Column
              field="MediaEpisode.Title"
              title={lang.title_column}
              editable={false}
              width={"150px"}
            />
            <Column
              field="ScheduleStatus"
              title={"Status"}
              editable={false}
              width={"150px"}
              cell={StatusCell}
            />
            <Column
              field="StartDateTime"
              title={"Start Date Time"}
              editable={false}
              cell={DateCell}
              width={"160px"}
            />
            <Column
              field="Type.Description"
              title={"Type"}
              editable={false}
              width={"100px"}
            />
            <Column
              field="TeamA"
              title={"Team A"}
              editable={false}
              width={"120px"}
            />
            <Column
              field="TeamB"
              title={"Team B"}
              editable={false}
              width={"120px"}
            />
            <Column
              field="MediaEpisode.Description"
              title={lang.description_column}
              editable={false}
              width={"150px"}
            />
            <Column
              field="MediaEpisode.Duration"
              title={lang.duration_column}
              editable={false}
              cell={TimeCell}
              width={"100px"}
            />
            {/* <Column
                  field="Duration"
                  title={lang.duration_column}
                  editable={false}
                /> */}
            <Column
              field="Round.Description"
              title={"Round"}
              editable={false}
              width={"100px"}
            />
            <Column
              field="Venue"
              title={"Venue"}
              editable={false}
              width={"150px"}
            />
            <Column
              field="ScheduleStatus"
              cell={MyPublishedChannelArrayCell}
              title="Published Channel"
              width={200}
              format={"Channel.FullChannelName"} //display item in object
            />
          </Grid>
        )}
      </div>

      {/* scheduleMatch */}
      {openPopupScheduleMatch && (
        <Dialog
          title={`Publish Match`}
          onClose={() => {
            setOpenPopupScheduleMatch(false);
            setScheduleMatchDataItem({
              Channels: [],
              StartDate: "",
              StartTime: "",
            });
          }}
          width={"600px"}
        >
          <div className="row">
            <div className="col-6 form-group">
              <label htmlFor="TabView">{lang.channel_label} *</label>
              <MultiSelect
                data={props.selectedTournament.Channels}
                textField="FullChannelName"
                value={scheduleMatchDataItem.Channels}
                onChange={onChangeScheduleMatch}
                name={"Channels"}
              />
            </div>
            <div className="col-3 form-group">
              <label htmlFor="TabView">{lang.start_date_label} *</label>
              <input
                type="date"
                className="form-control form-control-sm"
                name="StartDate"
                value={scheduleMatchDataItem.StartDate}
                onChange={onChangeScheduleMatch}
                disabled={scheduleMatchDataItem.MediaEpisode.IsLive}
                min={moment(new Date(scheduleMatchDataItem.StartDateTime))
                  .utc()
                  .format("YYYY-MM-DD")}
              />
            </div>

            <div className="col-3">
              <label htmlFor="">{lang.start_time_label} *</label>
              <TimePickerWithFormat
                className="form-control form-control-sm"
                name="StartTime"
                value={scheduleMatchDataItem.StartTime}
                onChange={onChangeScheduleMatch}
                disabled={scheduleMatchDataItem.MediaEpisode.IsLive}
              />
            </div>
          </div>
          <div className="row mt-1 mb-1" style={{ marginLeft: "-25px" }}>
            <div className="col">
              <ActionButton
                title={lang.save_button_tooltip}
                name={"Confirm"}
                onClick={onPublish}
              />
              <ActionButton
                title={lang.cancel_button_tooltip}
                name={lang.cancel_button_text}
                btnColor={"danger"}
                onClick={() => {
                  setOpenPopupScheduleMatch(false);
                  setScheduleMatchDataItem({
                    Channels: [],
                    StartDate: "",
                    StartTime: "",
                  });
                }}
              />
            </div>
          </div>
        </Dialog>
      )}

      {/* export */}
      {openPopupExport && (
        <Dialog
          title={`Export Live Time Table Report`}
          onClose={() => setOpenPopupExport(false)}
          width={"600px"}
        >
          <div className="row">
            <div className="col-6 form-group">
              <label htmlFor="TabView">Tournament </label>
              <DropDownList
                data={[{ SID: 0, Name: "All" }, ...tournament]}
                textField="Name"
                value={filterDataItem.Tournament}
                dataItemKey = 'SID'
                onChange={onChangeFilter}
                name={"Tournament"}
              />
            </div>
            <div className="col-3 form-group">
              <label htmlFor="TabView">{lang.start_date_label} *</label>
              <input
                type="date"
                className="form-control form-control-sm"
                name="ExportStartDate"
                value={filterDataItem.ExportStartDate}
                onChange={onChangeFilter}
              />
            </div>

            <div className="col-3 form-group">
              <label htmlFor="TabView">{lang.end_Date_label} *</label>
              <input
                type="date"
                className="form-control form-control-sm"
                name="ExportEndDate"
                value={filterDataItem.ExportEndDate}
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div className="row mt-1 mb-1" style={{ marginLeft: "-25px" }}>
            <div className="col">
              <ActionButton
                title={lang.save_button_tooltip}
                name={"Confirm"}
                onClick={exportLiveEventCSV}
              />
              <ActionButton
                title={lang.cancel_button_tooltip}
                name={lang.cancel_button_text}
                btnColor={"danger"}
                onClick={() => setOpenPopupExport(false)}
              />
            </div>
          </div>
        </Dialog>
      )}

      {/* un-publish */}
      {openCollectionSelectionUnPublish && (
        <UnPublishSelectionCollection
          title = {`Un-Publish Match`}
          data={unPublishRef.current.ScheduleStatus}
          onConfirm={unPublish}
          closeForm={() => setOpenCollectionSelectionUnPublish(false)}
        />
      )}

      {/* planning distribute */}
      {openCollectionSelection && (
        <UnPublishSelectionCollection
          title = {`Select Channel`}
          data={unPublishRef.current.ScheduleStatus}
          onConfirm={onSelectionScheduleStatusItem}
          closeForm={() => setOpenCollectionSelection(false)}
          mode={"single"}
        />
      )}
      {showPlanningDistributionTemplate && (
        <PlanningDistribution
          selectedData={selectedScheduleStatusItemRef.current[0]}
          closeForm={() => setShowPlanningDistributionTemplate(false)}
          setDistributionTemplateData={setDistributionTemplateData}
        />
      )}

      {/* Edit Match */}
      {showMatchEditForm && (
        <LiveMatchEditForm
          title={"Match"}
          entityName={ENTITYNAME.MediaEpisode}
          item={matchItem.current}
          refresh={refreshMatch}
          cancelEdit={() => setShowMatchEditForm(false)}
          mediaCategory={MEDIACATEGORIES.Program}
          mediaCategoryType={MEDIACATEGORYTYPES.Program}
          selectedTournament={selectedTournament}
        />
      )}

      {showLiveEventTypeEditForm && <LiveEventTypeEditForm item={{ SID: 0 }} cancelEdit={() => setShowLiveEventTypeEditForm(false)} refresh={() => loadcombo()} />}

      <ContextMenu model={showPublishMenu ? publishMenu : unPublishMenu} ref={contextMenuRef} />
    </div>
  );
}
