/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, FILEURL, DELIVERYSTATUSENUM, SOCKET_EVENTS, SOCKET_ACTION, LOGEVENT, PUBLISHING_SOURCE } from "../../framework/constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { Dialog } from "@progress/kendo-react-dialogs";
import { PlatformCell, arrayEnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";
import MyStatusCell from "../../components/MyStatusCell";
import MediaEpisodeHistory from "../../modules/Planning/MediaEpisodeHistory";
import { IconCell } from "../../framework/forms/helpercomponents/CustomGridCells/IconCell";
import Preview from "../../modules/platformLayout/tv/homePage/components/Preview";
import PlanningFilter from "../../modules/Planning/PlanningFilter";
import { getter } from "@progress/kendo-data-query";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import MediaFastSegmentationPopup from "../../modules/Segment/MediaFastSegmentationPopup";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { utility } from "../../framework/utility/utilityProvider";
import socket from "../../framework/socket/socket";
import MediaEpisodePublishingEditForm from "./MediaEpisodePublishingEditForm";
import { EditPageHeader } from "../../components/EditPageHeader";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import UpdateMultipleEpisodeDialog from "../../components/UpdateMultipleEpisodeDialog";
import { MediaEpisodeMultiEdit } from "./MediaEpisodeMultiEdit";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);


export const MediaEpisodeCollection = (props) => {
  //possible props
  let page_take = props.page_take ?? 50;
  let showAdd = props.showAdd ?? true;
  let showBack = props.showBack ?? true;
  let showImport = props.showImport ?? false;
  let showExport = props.showExport ?? true;
  let showArchive = props.showArchive ?? true;
  let showCommandCell = props.showCommandCell ?? true;
  let showEdit = props.showEdit ?? true;
  let showDelete = props.showDelete ?? true;
  let showTitle = props.showTitle ?? true;
  let gridRowRender = props.gridRowRender;
  let CustomEditForm = props.customEditForm ?? EditForm;
  let filterable = props.filterable ?? false;
  let sortable = props.sortable ?? true;
  let isPageable = props.isPageable ?? true;
  let wherestatement = props.wherestatement;
  let andWhereStatement = props.andWhereStatement;
  let sort = props.sort ?? { SID: 1 };
  let customColumns = props.customColumns ?? [];
  let openNewEditForm = props.openNewEditForm ?? false;
  let myTitle = props.title ?? "";
  let myMenuModel = props.menuModel ?? collectionMenuModel;
  let entityName = props.entityname;
  let showPreferenceButton = props.showPreferenceButton ?? true;
  let hideArchiveProps = props.hideArchive ?? false;
  let showFilterTemplate = props.showFilterTemplate ?? false;
  let enableMutliSelect = props.enableMutliSelect ?? false;
  let module = props?.module;
  let enableMultipleUpdate = props?.enableMultipleUpdate ?? false;

  //Add this for media management preference column
  const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;
  // example custome columns[]
  const [gridData, setGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({ SID: 1 });
  const [warningDialog, setWarningDialog] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showPreferences, setShowPreferences] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [hideArchive, setHideArchive] = useState(hideArchiveProps);
  const [showHistory, setShowHistory] = useState(false);
  const [showPreviewCollection, setShowPreviewCollection] = useState(false);
  const [filterDataItem, setFilterDataItem] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [filterTemplate, setFilterTemplate] = useState([]);
  const [fastSegmentationPopupOpen, setFastSegmentationPopupOpen] = useState(false);
  const [showPublishingDealRight, setShowPublishingDealRight] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [removedMedia, setRemovedMedia] = useState([]);
  const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
  const [gridSort, setGridSort] = useState([initialSort]);
  const [showPlanningLogs, setShowPlanningLogs] = useState(false);
  const [planningLogData, setPlanningLogData] = useState([]);
  const [showUpdateMultiple, setShowUpdateMultiple] = useState(false);
  const [selectedItem, _setSelectedItem] = useState([]);
  const [showUpdateMultipleUpdateItem, setShowUpdateMultipleUpdateItem] = useState(false);

  const fastSegmentationItemRef = useRef({})
  const selectedItemsRef = useRef([]);
  const setSelectedItem = (items) => {
    _setSelectedItem(items);
    selectedItemsRef.current = items;
  }

  const historyRef = useRef();
  const setHistoryRef = (data) => {
    historyRef.current = data
  }

  const exportMECRef = useRef();
  const setExportMECRef = (data) => {
    exportMECRef.current = data
  }

  const exportMMCRef = useRef();
  const setExportMMCRef = (data) => {
    exportMMCRef.current = data
  }

  const exportSegmentationRef = useRef();
  const setExportSegmentationRef = (data) => {
    exportSegmentationRef.current = data
  }

  const previewRef = useRef();
  const setPreviewRef = (data) => {
    previewRef.current = data
  }

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  //only to get wherestatement from href cell
  const location = useLocation();
  const lang = useTranslation();

  let href = location.state ? location.state.href : false;
  if (href) {
    wherestatement = location.state.wherestatement;
  }

  useEffect(() => {
    setShowArchived(false);
    setIsLoading(true);
    setPage({ skip: 0, take: page_take });
    setSearchText('');
    setFilterTemplate([]);
    setGridSort([initialSort]);
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, '', false, []);
    setSelectedItem([]);
  }, [props.entityname, props.gridData, props.title]);

  const onSearch = (e) => {
    e.preventDefault();
    let text = e.target.value?.trim();
    setSearchText(text);
  };

  // Search 
  useEffect(() => {
    let sortEvent = gridSort[0] ?? initialSort;
    if (searchText != "") {
      const timeOut = setTimeout(() => {
        setIsLoading(true);
        loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
      }, 700);
      return () => clearTimeout(timeOut);
    } else {
      setIsLoading(true);
      setPage({ skip: 0, take: page_take });
      loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
    }
  }, [searchText]);

  const onKillUserSession = (userData) => {
    delete userData["Module"];
    console.log(userData);
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
    toast.success(`${userData.name} session has been killed successfully`, { position: toast.POSITION.TOP_RIGHT });
  }


  const enterEdit = (item) => {
    console.log("onEnterEdit");
    if (openNewEditForm === false) {
      setOpenForm(true);
      setEditItem(item);
    } // need to navigate to full page edit view
    else {
      navigate(`${item.AssetId}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false, prevPath: location.pathname } });
    }
  };

  const history = (item) => {
    console.log('history');
    setHistoryRef(item);
    setShowHistory(item.history);
  };

  const exportMECXML = (item) => {
    console.log(item);
    setExportMECRef(item);
    exportMECData(item)
  };

  const exportMECData = async (exportDataItem) => {
    let res = await API.getAmazonMECXML(exportDataItem);
    console.log('res.success');
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_exported_export_template_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const exportMMCXML = (item) => {
    console.log(item);
    setExportMMCRef(item);
    exportMMCData(item)
  };

  const onExportSegmentationData = (item) => {
    setExportSegmentationRef(item);
    onExportSegmentation(item)
  };

  const onExportSegmentation = async (item) => {
    let payload = [{ _id: item._id }];
    console.log(item)
    var res = await API.getSegmentationDataExport(payload);
    if (res.success) {
      if (res.dataLength == 0) {
        toast.info(`${lang.no_data_found_for_selected_filter_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      else {
        window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName)
        toast.success('Successfully Exported!', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const exportMMCData = async (exportDataItem) => {
    let res = await API.getAmazonMMCXML(exportDataItem);
    console.log('res.success');
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_exported_export_template_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const ottPackageDeliverySchedule = (item) => {
    onOttPackageDeliverySchedule(item)
  };

  const onOttPackageDeliverySchedule = async (item) => {
    const saveData = {
      MediaEpisode: { _id: item._id, Title: item.Title, AssetId: item.AssetId, MediaCategory: item.MediaCategory.Description, EpisodeNumber: item.EpisodeNumber },
      User: { _id: utility.getValue(LOCALSTORAGE_KEY.user_id) },
      DeliveryStatus: DELIVERYSTATUSENUM[0],
      TimeStamp: Date.now(),
      MetaDataChecklistResult: '',
      AssetChecklistResult: '',
      AmazonDeliveryGroup: { _id: "", Description: "" },
      Archive: false,
    };
    console.log(saveData)
    let res = await API.saveData(ENTITYNAME.OttPackageDeliverySchedule, saveData);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if (!res.success) return;
    navigate(-1);
  };

  //publish for OTT
  const onPublish = async (itemID) => {
    console.log('publish');
    let res = await API.onPublishSeason(itemID);
    if (res.success) {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  };

  const handleNavigate = (item) => {
    if (item?.Module) {
      window.open(item.path, "MyTargetWindowName");
    } else {
      navigate(`${item.path}`, { state: item });
    }
  }

  //onSubmit from EDIRFORM
  const handleSubmit = async (dataItem, dataToSave) => {
    console.log("handleSUbmit");
    //if datatosave is null then dataitem is datasave
    dataToSave = dataToSave == null ? dataItem : dataToSave;

    let isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

    if (!isDuplicate) {
      setOpenForm(true);
      toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let res = await API.saveData(props.entityname, dataToSave);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
    utility.deleteLocalStorageItem(props.entityname)
    //to load grid with new data
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
    setOpenForm(false);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleDelete = async (dataItem) => {
    console.log(dataItem);
    onConfirmDelete(dataItem)
  };

  const handleArchive = async (dataItem) => {
    if (entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season) {
      var resArchiveUpdated = await API.onChangeArchiveInSeriesSeason({
        _id: dataItem._id,
        archive: !dataItem.Archive,
        entityName: entityName
      })
    } else {
      var resArchiveUpdated = await API.saveData(entityName, {
        ...dataItem,
        Archive: !dataItem.Archive
      })
    }

    if (resArchiveUpdated.success) {
      console.log(`Archive is been updated to ${!dataItem.Archive}`);
      let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
      API.SaveLogs(logData);
      let sortEvent = gridSort[0] ?? initialSort;
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
      utility.deleteLocalStorageItem(entityName);
    } else {
      console.log(resArchiveUpdated.message);
    }
  }

  const onCloseUserPrefence = () => {
    let sortEvent = gridSort[0] ?? initialSort;
    loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
    setShowPreferences(!showPreferences);
  }

  const setAsDefault = async (data) => {

    let setAsDefaultRes = await API.setAsDefault(entityName, data._id);
    if (setAsDefaultRes.success) {
      let sortEvent = gridSort[0] ?? initialSort;
      loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
      utility.deleteLocalStorageItem(entityName);
    } else {
      toast.error(setAsDefaultRes.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  const preview = (props) => {
    setPreviewRef(props)
    setShowPreviewCollection(true);
  }

  const handleForceUnlock = (dataItem) => {
    onConfirmDelete(dataItem);
    let logData = { event: LOGEVENT.UNLOCK_PLANNING_SCHEDULE, module: MODULE.PLANNING_LOCK_SCHEDULE, data: dataItem, message: LOGEVENT.UNLOCK_PLANNING_SCHEDULE };
    API.SaveLogs(logData);
  }

  const publishingDealRights = async (data) => {
    let mediaDealRights = await API.getData(ENTITYNAME.MediaDealRights, {
      query: ["media_id", "=", data._id.toString()],
    });

    let tempData = [];
    if (mediaDealRights.data.length > 0) {
      tempData = mediaDealRights.data.map((obj, index) => {
        return {
          ...obj,
          index: index
        }
      })
    } else {
      tempData = mediaDealRights.data
    }
    let finalData = {
      ...data,
      MediaDealRights: tempData
    }
    setSelectedMedia(finalData);
    setShowPublishingDealRight(true);
  }

  const planningLogs = async (data) => {
    let logData = await API.getData(ENTITYNAME.UserActivityLogs, {
      query: [["MediaEpisode_id", "=", data._id.toString()], ["Module", "=", MODULE.PLANNING]],
    })
    if (!logData.success) return setPlanningLogData([]);
    let groupBy = logData.data.reduce((r, a) => {
      r[a.Header_id] = [...r[a.Header_id] || [], a];
      return r;
    }, {});
    let logDataArray = Object.keys(groupBy).map((key) => {
      return {
        Header_id: key,
        Channel: groupBy[key][0].Value.Channel,
        SlotDateTime: groupBy[key][0].Value.SlotDateTime,
        mediaEpisode: groupBy[key][0].Value.mediaEpisode,
        data: groupBy[key]
      }
    });
    setPlanningLogData(logDataArray);
    setShowPlanningLogs(true);
  }
  const showEpisodes = (data) => {
    console.log(data);
    let link = `/home/Season/Episode/${data.AssetId}`
    navigate(link);
  }

  const showSeasons = (data) => {
    console.log(data);
    // let link = `/home/Season/${data.AssetId}`
    // navigate(link);
  }


  //Edit Row
  const MyCommandCell = (props) => (

    <MenuCommandCell
      {...props}
      publish={onPublish}
      history={history}
      enterEdit={enterEdit}
      cancelEdit={handleCancelEdit}
      onSubmit={handleSubmit}
      item={editItem}
      dataColumns={dataColumns}
      onDelete={handleDelete}
      onArchive={handleArchive}
      showEdit={showEdit}
      showDelete={showDelete}
      toNavigate={handleNavigate}
      myMenuModel={myMenuModel}
      module={module}
      openNewEditForm={openNewEditForm}
      onKillUserSession={onKillUserSession}
      setAsDefault={setAsDefault}
      exportMECXML={exportMECXML}
      exportMMCXML={exportMMCXML}
      preview={preview}
      ottPackageDeliverySchedule={ottPackageDeliverySchedule}
      onForceUnlock={handleForceUnlock}
      onFastSegmentation={handleFastSegmentation}
      publishingDealRights={publishingDealRights}
      planningLogs={planningLogs}
      onExportSegmentationData={onExportSegmentationData}
      showEpisodes={showEpisodes}
      showSeasons={showSeasons}
    />
  );

  const handleFastSegmentation = (item) => {
    fastSegmentationItemRef.current = item
    setFastSegmentationPopupOpen(true)
  }

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={entityName}
      size={"small"}
    />
  );

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const Mystatuscell = (props) => (
    <div>
      <MyStatusCell title={props.dataItem.CurrentStatus} tileBackColor="#72eda9" />
    </div>
  )

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive, filters, channelFilter) => {

    let channel = channelFilter ? channelFilter : filterDataItem?.Channel && filterDataItem?.Channel?.length != 0 ? filterDataItem.Channel.map((item) => item.SID) : []

    if (props.gridColumns) {
      setDataColumns(props.gridColumns);
      setGridData(props.gridData);
      setIsLoading(false);
    } else {
      try {
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let columns = await API.getFormData(preferenceColumnENntityName, currentUserId);
        let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
        let json = await API.getEntitiesSearchWithMedia(
          props.entityname,
          { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort },
          columns,
          searchText,
          filters,
          props.mediaCategory,
          channel
        );
        setGridData(json.data);
        setTotal(json.pagination.total);
        //if custmoe colum is coming from compemnent level then no need  a column from Server API
        customColumns.length > 0
          ? setDataColumns(customColumns)
          : setDataColumns(columns);

        // console.log(columns)
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    }
  };

  const handleErrorDialogClose = () => {
    setDeleteError(!deleteError);
    setDeleteErrorMessage("");
  }
  const errorHandler = (message) => {
    setWarningDialog(!warningDialog);
    setDeleteError(true);
    setDeleteErrorMessage(message);
  }

  const onConfirmDelete = async (dataItem) => {
    const res = await API.deleteData(props.entityname, dataItem.SID);
    console.log("Loading");
    if (!res.success) {
      console.log(res.message);
      errorHandler(res.message);
      return;
    }
    let current_page = page.skip / page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, '', showArchived, filterTemplate);
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
    utility.deleteLocalStorageItem(props.entityname);
  }

  const pageChange = async (e) => {
    let current_page = e.page.skip / e.page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort;
    await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
    setPage(e.page);
    cancelSelectedItem()
  };

  const onArchiveClick = () => {
    setShowArchived(!showArchived);
    let sortEvent = gridSort[0] ?? initialSort;
    if (!showArchived) {
      loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, true, filterTemplate);
    } else {
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, false, filterTemplate);
    }
    cancelSelectedItem();
  }

  const refreshCollection = () => {
    setIsLoading(true);
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
    setGridSort([initialSort]);
    setSelectedItem([]);
    setSelectedState({});
  }

  const downloadExcel = async () => {

    if (gridData.length == 0) {
      toast.info(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let res = await API.exportGridExcel(gridData, dataColumns);
    console.log(res.data);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const toGetFilteredData = (data) => {

    setIsLoading(true);
    let finalData = {
      ...data,
      ReleaseYear: data.ReleaseYear == "" ? null : parseInt(data.ReleaseYear),
    }
    setFilterDataItem(finalData);

    //NEED TO CREATE WHERESTATEMENT HERE
    let finalFilterQuery = [];
    let channelFilter = [];

    if (Object.keys(finalData).length != 0) {

      let genresFilter = finalData.Genres.length != 0 ? [['Genres._id', 'in', finalData.Genres.map((item) => item._id)]] : [];
      let isLiveFilter = finalData.isLive ? [["IsLive", '=', true]] : [];
      let releaseYearFilter = finalData.ReleaseYear != null ? [["ReleaseDate", ">=", new Date(`${finalData.ReleaseYear}-01-01`).getTime()], ["ReleaseDate", "<=", new Date(`${finalData.ReleaseYear}-12-31`).getTime()]] : [];
      channelFilter = finalData.Channel.length != 0 ? finalData.Channel.map((item) => item.SID) : [];
      let castAndCrewFilter = finalData.CastName.length > 0 && Object.keys(finalData.CastType).length > 0 ?
        [['Metadata.CastAndCrew', 'elemMatch', { Name: finalData.CastName, CastType: finalData.CastType }]] : [];
      let createdFromFilter = finalData?.CreatedFrom ? [["addOn", ">=", new Date(`${finalData.CreatedFrom} 00:00:00`).getTime()]] : [];
      let createdToFilter = finalData?.CreatedTo ? [["addOn", "<=", new Date(`${finalData.CreatedTo} 23:59:59`).getTime()]] : [];

      finalFilterQuery = [...genresFilter, ...releaseYearFilter, ...isLiveFilter, ...castAndCrewFilter, ...createdFromFilter, ...createdToFilter];
    }
    console.log(finalFilterQuery);
    setFilterTemplate(finalFilterQuery);
    let sortEvent = gridSort[0] ?? initialSort;
    loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, finalFilterQuery, channelFilter)
  }

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    selectedIds.forEach((value) => {
      if (newSelectedState[value]) {
        let filter = gridData.find((obj) => obj._id == value);
        tempSelected.push(filter);
      }
    });

    setSelectedItem(tempSelected);
  };

  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    if (checked) {
      selectedIds.forEach((value) => {
        if (newSelectedState[value]) {
          let filtered = gridData.find((obj) => obj._id == value);
          tempSelected.push(filtered);
        }
      });
    }
    setSelectedItem(tempSelected);
  };

  const onArchiveButtonClick = async () => {
    if (selectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_atleast_one_item}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    for (const element of selectedItemsRef.current) {
      const item = element;
      await handleArchive(item);
    }
    cancelSelectedItem();
  }

  const onEnterEdit = () => {
    enterEdit({ SID: 0, AssetId:0 });
    cancelSelectedItem();
  }

  const cancelSelectedItem = () => {
    setSelectedItem([]);
    setSelectedState({});
  }

  const handleMediaPublishingData = (data) => {
    console.log(data);
    setSelectedMedia({
      ...selectedMedia,
      MediaDealRights: data
    })
  };

  const handleRemovePublishingData = (removedDataItem) => {
    setRemovedMedia(old => [...old, removedDataItem]);
  }

  const handleSubmitPublishings = async () => {

    let resMediaDealRights = await API.updateMediaDealRights(selectedMedia, removedMedia, selectedMedia?.MediaDealRights, props?.publishingSource);
    if (resMediaDealRights.success) {
      setShowPublishingDealRight(false);
      setRemovedMedia([]);
      setSelectedMedia({});
      refreshCollection();
    } else {
      toast.error(resMediaDealRights.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onSortChange = (e) => {
    setGridSort(e.sort)
    let sortEvent = e.sort[0] ?? sort
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate);
  }

  const handleShowResponse = async (item) => {

    let path = '/home/AuditLog' + "?module=" + MODULE.PLANNING + "&Header_id=" + item.Header_id + "&title=" + item.mediaEpisode.Title;
    window.open(path, "_blank");

  }

  const RoundedButton = (props) => <RoundButton icon={"external-link"} style={{ marginLeft: '20px', marginTop: '5px' }} title={lang.show_response_label} onClick={() => handleShowResponse(props.dataItem)} />

  const multipleUpdateFormClose = () => {
    setShowUpdateMultiple(false);
  }

  let timeOut;
  const handleColumnResize = (event) => {
    let lockedCol = event.columns.filter((item) => item?.locked).length ?? 1;
    try {
      if (event.index == 0) return;
      if (dataColumns == undefined || dataColumns.length == 0 || dataColumns.length < event.index) return;
      let column = dataColumns[event.index - lockedCol];
      let payload = {
        entityName: column?.entityName,
        name: column?.name,
        width: event.newWidth
      };
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        API.updateUserPreferenceColumnWidth(payload);
      }, 700);
    } catch (error) {
      console.error("Error handling column resize:", error);
    }
  };

  return (
    <div>
      {showTitle && (
        <h1 className="page-title txt-color-bludeDark">
          <i
            className="fa fa-table marlr"
            style={{ fontSize: "20px", color: "GrayText" }}
          ></i>
          <span>{myTitle === "" ? props.entityname : myTitle}</span>
        </h1>
      )}
      <div className="flex-container">
        <div className="martb mar">
          {showBack && (
            <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
          )}
          {showAdd && (
            <AddRoundButton onClick={onEnterEdit}></AddRoundButton>
          )}
          {(enableMultipleUpdate && enableMutliSelect && selectedItemsRef.current.length > 1) && (
            <EditRoundButton title={'Update Multiple'} onClick={() => { setShowUpdateMultiple(true) }} ></EditRoundButton>
          )}
          {showArchive && enableMutliSelect && selectedItemsRef.current.length != 0 && (
            <RoundButton icon={showArchived ? "circle-plus fa-xl" : "circle-minus fa-xl"} title={showArchived ? lang.un_archive_label : lang.archive} name={showArchived ? lang.un_archive_label : lang.archive}
              onClick={onArchiveButtonClick} />
          )}
          {enableMultipleUpdate && enableMutliSelect && selectedItemsRef.current.length > 1 && (
            <RoundButton icon="list" title={'Update Multiple'} onClick={() => { setShowUpdateMultipleUpdateItem(true) }} />
          )}
        </div>

        <div className="flex-container-reverse flex-item-auto">
          <div className="input-group input-group w-300 martb" >
            <input
              type="text"
              className="form-control shadow-inset-2 pt-0 pb-0"
              id="searchInput"
              placeholder={lang.search_button_text}
              onChange={onSearch}
              onFocus={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(true);
                }
              }}
              onBlur={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(false);
                }
              }}
            />
            {showFilterTemplate ? <PlanningFilter
              toGetFilterDataItem={toGetFilteredData}
              mediaCategory={props.mediaCategory}
              height={"38px"}
              disableMediaCategory={true}
              showTemplate={true}
              hideFields={true}
              onClear={() => { wherestatement = props.wherestatement; setFilterTemplate([]); }}
            /> :
              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                  </span>
                </div>
              </button>}
          </div>
          {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
            <PreferenceRoundButton
              icon={"gear"}
              title={lang.preference_button_tooltip}
              onClick={() => { setShowPreferences(!showPreferences) }}
            />
          </div>}
          <div className="mt-1" style={{ paddingTop: "12px" }}>
            <RefreshButton
              onClick={refreshCollection}
            />
          </div>
          {showImport && (
            <div className="mt-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"upload"}
                btnColor={"warning"}
                // style={{ margin: "0px 03px" }}
                // iconColor={"white"}
                title={lang.import_file_label}
                onClick={() => navigate('/home/DataMigration', { state: { mediaCategory: props.mediaCategory } })} //route navigate to data migration module
              /></div>
          )}
          {showExport && (
            <div className="m-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"download"}
                btnColor={"info"}
                style={{ color: 'white' }}
                // iconColor={"white"}
                title={lang.export_button_tooltip}
                onClick={downloadExcel}
              /></div>
          )}
          {!hideArchive && <div className="d-inline mt-3 mr-2">
            <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
          </div>}
        </div>
      </div>
      {isLoading && <Loader />}

      {!isLoading &&
        <Grid
          style={props.gridstyle ?? {
            height: "76vh",
          }}
          sort={gridSort}
          sortable={sortable}
          onSortChange={onSortChange}
          rowRender={gridRowRender}
          filterable={filterable}
          pageable={isPageable}
          skip={page.skip}
          take={page.take}
          onPageChange={pageChange}
          total={total}
          resizable={true}
          onColumnResize={handleColumnResize}
          data={
            gridData.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: enableMutliSelect,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          dataItemKey={DATA_ITEM_KEY}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
        >

          {/* Multi Select Checkbox row */}
          {enableMutliSelect && (<GridColumn
            field={SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              gridData.findIndex(
                (item) => !selectedState[idGetter(item)]
              ) === -1
            }
            locked={true}
            filterable={false}
            resizable={false}
          />
          )}

          {/* Edit Row */}
          {showCommandCell && (
            <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
          )}

          {/* Rows Loop */}
          {dataColumns.map((column, index) => {
            if (column.type === COLUMNSTYPE.date) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={DateOnlyCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (column.type === COLUMNSTYPE.datetime) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={DateCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={TimeCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.name === "SID") {
              return;
              //we are not returning any value for SID column
            } else if (column.type === COLUMNSTYPE.image) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={ImageCell}
                  title={column.label}
                  width={column.width ?? 100}
                />
              );
            } else if (column.type === COLUMNSTYPE.select) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={MyDatalookupCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.toggle) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={MyCustomToggleButton}
                  title={column.label}
                  width={85}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.checkbox) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={CheckboxCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.array) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={ArrayCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format ?? "Description"} //display item in object
                />
              );
            }
            else if (column.type === COLUMNSTYPE.href) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={HrefCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.enum) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={EnumCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format ?? "Description"} //display item in object
                />
              );
            } else if (column.type === COLUMNSTYPE.platform) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={PlatformCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.status) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={Mystatuscell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.localdatetime) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={LocalDateTimeColumn}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.icon) {
              return (
                <GridColumn
                  sortable={false}
                  key={index}
                  field={column.name}
                  cell={IconCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.arrayenum) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={arrayEnumCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            }
            else {
              return (
                column.type !== COLUMNSTYPE.hidden && (
                  <GridColumn
                    key={index}
                    field={column.name}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                )
              );
            }
          })}
        </Grid>}

      {openForm && (
        <CustomEditForm
          {...props} //to get other props from customEditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          refresh={refreshCollection} //to refresh the collection when data added
          item={editItem}
          dataColumns={dataColumns}
        />
      )}

      {deleteError &&
        <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
      {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}

      {showHistory && <MediaEpisodeHistory selectedData={historyRef.current} closeForm={() => setShowHistory(false)} />}

      {showPreviewCollection && <Dialog
        title={previewRef.current.isMobile ? lang.mobile_preview_label : lang.tv_preview_label}
        onClose={() => { setShowPreviewCollection(false) }}
        width={previewRef.current.isMobile ? "750px" : "1200px"}
        height={previewRef.current.isMobile ? "580px" : "700px"}
      >
        <div className="row">
          <div className={previewRef.current.isMobile ? "col mt-4" : "col"} style={previewRef.current.isMobile ? { marginLeft: '13%', height: '100%', scale: '0.7', color: 'white' } : { marginTop: '-5px', marginLeft: '4px' }}>
            <div className="row" style={previewRef.current.isMobile ? { display: "flex" } : { display: "block" }}>
              <Preview pageData={previewRef.current} isMobile={previewRef.current.isMobile} ></Preview>
            </div>
          </div>
        </div>
      </Dialog>}

      {fastSegmentationPopupOpen && <MediaFastSegmentationPopup mediaEpisodeEntity={fastSegmentationItemRef.current} programScheduleEntity={{}} refreshSegments={() => { }} onClose={() => setFastSegmentationPopupOpen(false)} source={'media'} />}
      {showPublishingDealRight && <Dialog
        title={selectedMedia?.Title}
        onClose={() => { setShowPublishingDealRight(false) }}
        width={"800px"}
      >
        <div className="mb-3">
          <EditPageHeader showTitle={false} onSubmit={handleSubmitPublishings} onCancel={() => { setShowPublishingDealRight(false) }} />
        </div>
        <div className="m-2">
          <MediaEpisodePublishingEditForm publishingSource={props?.publishingSource} handlePublishings={handleMediaPublishingData} handleRemovePublishingData={handleRemovePublishingData} mediaCategory={selectedMedia?.MediaCategory} data={selectedMedia?.MediaDealRights} />
        </div>
      </Dialog>
      }

      {showPlanningLogs && <Dialog
        title={lang.planning_logs_dialog_header}
        onClose={() => { setShowPlanningLogs(false) }}
        width={"800px"}
      >
        <div className="m-2">
          <Grid data={planningLogData} style={{ height: '350px' }}>
            <GridColumn
              width={"100px"}
              cell={RoundedButton}
              title={lang.show_details_column}
            />
            <GridColumn field="Channel.FullChannelName" title={lang.channel_column} />
            <GridColumn field="SlotDateTime" title={lang.slot_date_time_column} cell={LocalDateTimeColumn} />
            <GridColumn field="mediaEpisode.AssetId" title={lang.assetId_column} />
            <GridColumn field="mediaEpisode.Title" title={lang.title_column} />

          </Grid>
        </div>
      </Dialog>}
      {showUpdateMultiple && <UpdateMultipleEpisodeDialog selectedEpisode={selectedItem} setSelectedEpisode={_setSelectedItem} closeForm={multipleUpdateFormClose} refresh={refreshCollection}/>}
      {showUpdateMultipleUpdateItem && <MediaEpisodeMultiEdit selectedItems={selectedItem?.length > 0 ? selectedItem.map(x=>x?._id) : []} setSelectedEpisode={_setSelectedItem} refresh={refreshCollection} closeForm={()=> {setShowUpdateMultipleUpdateItem(false);}} />}
    </div>
  );
};