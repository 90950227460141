/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
    Grid,
    GridColumn,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../API/api";
import { LOCALSTORAGE_KEY } from "../constant/constant";
import { Dialog } from "@progress/kendo-react-dialogs";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../utility/utilityProvider";
import SaveButton from "./helpercomponents/buttons/SaveButton";
import CancelButton from "./helpercomponents/buttons/CancelButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const DATA_ITEM_KEY = "SID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const PageTypeCell = (props) => {

    const field = props.field || "";
    let value = props.dataItem[field] ? 'Menu' : 'Pages'
  
    return <td>
      {value}
    </td>;
  };

const PageCollectionSelection = memo((props) => {
    const lang = useTranslation();

    var wherestatement = props.wherestatement ?? [];
    var andWhereStatement = props.andWhereStatement;

    const [gridData, setGridData] = useState([]);
    const [originalGridData, setoriginalGridData] = useState([]);
    const [dataColumns, setDataColumns] = useState(props.customColumns ?? []);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedState, setSelectedState] = useState({});
    const [count, setCount] = useState(0);
    const [filterDataItem, setFilterDataItem] = useState("All");
    const gridRef = useRef();
    const per_page = props.perPage ?? 50;
    var currentPage = 1

    useEffect(() => {
        loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
    }, []);

    const loaddata = async (pagination, wherestatement, andWhereStatement, searchText) => {
        try {

            var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            var columns = await API.getFormData(props.entityname, currentUserId);

            setIsLoading(true);
            var json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: 1, query: wherestatement }, columns, searchText);

            setoriginalGridData(json.data);
            setIsLoading(false);

            setGridData(json.data);
            setCount(json.pagination.total);
            setDataColumns(columns);

        } catch (error) {
            console.log("error", error);
        }
    };

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);

        }, [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {

        if (props.mode == 'single') {
            toast.info(`${lang.select_any_one_collection_selection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

    }, []);

    //implementing search
    const onSearchEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            onSearchClick();
        }
    }

    const handleServerSideSearch = (e) => {
        setSearchText(() => e.target.value);

        let newWhereStatement = [...wherestatement];

        if(filterDataItem == "Pages"){
            newWhereStatement = [...newWhereStatement , ["IsParentMenuItem", "!=",true]]
        }else if(filterDataItem == "Menu"){
            newWhereStatement = [...newWhereStatement , ["IsParentMenuItem", "=",true]]
        }

        if (e.target.value == '') {
            loaddata({ per_page: 1000 }, newWhereStatement, andWhereStatement, '');
        }
    };

    const onSearchClick = () => {

        let newWhereStatement = [...wherestatement];

        if(filterDataItem == "Pages"){
            newWhereStatement = [...newWhereStatement , ["IsParentMenuItem", "!=",true]]
        }else if(filterDataItem == "Menu"){
            newWhereStatement = [...newWhereStatement , ["IsParentMenuItem", "=",true]]
        }

        if (searchText != '') {
            loaddata({ per_page: 1000 }, newWhereStatement, andWhereStatement, searchText);
        } else {
            loaddata({ per_page: 1000 }, newWhereStatement, andWhereStatement, '');
        }

    }

    const checkIfItemExist = (item) => {

        var isFound = false;
        var existingEntries = props.existingItems ?? [];
        // console.log("checking duplicate")
        // console.log(item.SID);
        // console.log(props);
        if (props.checkDuplicate ?? false) {
            isFound = existingEntries.some(element => {
                console.log("props");
                console.log(props);
                if (element.SID === item.SID) {
                    return true;
                }

                return false;
            });
        }
        // console.log("isFound");
        // console.log(isFound);
        return isFound;

    };

    //to sendDataList to RailsEditForm and to close the collection
    const onSelection = () => {
        var msg = `${lang.please_select_first_collection_error_message}`

        var selectedDataList = [];
        //loop for setting the selected obj in array
        gridData.map((obj) => {
            var key = obj.SID;
            //if true means checked
            if (selectedState[key]) {
                // checking duplicate as well
                if (!checkIfItemExist(obj)) {
                    selectedDataList.push(obj);
                } else {
                    msg = `${lang.already_attached_collection_error_message}`
                }
            }
        })

        if (selectedDataList.length != 0) {
            props.setDataList(selectedDataList);
            props.closeForm();
        } else {
            // alert(msg)
            toast.info(msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    //load for infinit scroll

    const loadInfiniteData = async () => {
        currentPage++;
        if (count > originalGridData.length) {
            var json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: currentPage, query: wherestatement }, dataColumns, searchText);
            setoriginalGridData((old) => [...old, ...json.data]);
            setGridData((old) => [...old, ...json.data]);
        }

    }

    //for infinite scroll
    const scrollHandler = async (event) => {
        const e = event.nativeEvent;
        if (e.target.className.indexOf('k-grid-content') === -1) {
            return;
        }

        if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
            await loadInfiniteData()
        }
    };

    const onPageTypeChange = (e) => {

        let newWhereStatement = [...wherestatement];

        if(e.target.value == "Pages"){
            newWhereStatement = [...newWhereStatement , ["IsParentMenuItem", "!=",true]]
        }else if(e.target.value == "Menu"){
            newWhereStatement = [...newWhereStatement , ["IsParentMenuItem", "=",true]]
        }

        loaddata({ per_page: per_page }, newWhereStatement, andWhereStatement, searchText);
        setFilterDataItem(e.target.value)

    }

    return (
        <Dialog title={props.title ?? `${lang.add_dialog_header}`}
            onClose={props.closeForm}
            height={"63vh"}
            width={"60vw"}>

            <div className="row ml-1 mb-3" >
                <div className="col-6">
                    <div className="row">
                        <SaveButton title={props.addButtonTitle ?? `${lang.add_button_text}`} style={{ backgroundColor: 'green', borderRadius: "4px" }} onClick={onSelection}></SaveButton>
                        <CancelButton onClick={props.closeForm}></CancelButton>
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        {/* <label htmlFor="">{lang.type_label} *</label> */}
                        <DropDownList
                            style={{ backgroundColor: "white" , height: "37px" }}
                            data={["All","Pages","Menu"]}
                            name="Type"
                            // textField="Description"
                            // dataItemKey="_id"
                            value={filterDataItem}
                            onChange={onPageTypeChange}
                        />
                    </div>
                </div>
                <div className="col-4" >
                    <div className="input-group input-group mb" >
                        <input type="text" className="form-control shadow-inset-2 pt-0 pb-0" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onKeyDown={onSearchEnter} onChange={handleServerSideSearch} />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} onClick={onSearchClick}>
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                {isLoading && <div className="ml-1">
                    <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
                </div>}

                {!isLoading &&
                    <Grid
                        data={gridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}

                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: props.mode ?? "multiple",
                        }}
                        ref={gridRef}
                        resizable={true}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        // onHeaderSelectionChange={props.mode == 'single' ? () => {} : onHeaderSelectionChange}
                        style={{ height: "43.5vh" }}
                        onScroll={scrollHandler}
                    >
                        <GridColumn
                            field={SELECTED_FIELD}
                            width="50px"
                            headerSelectionValue={  gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1 }
                            locked={true}
                        />
                        <GridColumn field={"Name"} title={"Name"} width={200} />
                        <GridColumn field={"DisplayName"} title={"DisplayName"} width={200} />
                        <GridColumn field={"IsParentMenuItem"} title={"Type"} width={200} cell={PageTypeCell} />
                        <GridColumn field={"verbiageKey"} title={"Verbiage Key"} width={200} />
                        <GridColumn field={"accessLevel"} title={"Access Level"} width={200} />
                    </Grid>}
            </div>
            <div className="text-right mt-1">{`${lang.total_label} ${count}`}</div>
        </Dialog>
    );
});

export default PageCollectionSelection;
