/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL, LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";


export const MediaMasterReport = () => {

    const blankDataItem = {
        MediaCategory: {},
        MediaCategoryType: {},
    }
    
    const lang = useTranslation();
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const [mediaCategory, setMediaCategory] = useState([]);
    const [mediaCategoryType, setMediaCategoryType] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(
        `${lang.no_record_found_error_message}`
    );
    const [isMinified, setIsMinified] = useState(
        utility.getValue(LOCALSTORAGE_KEY.isMenuMified)
    );

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {

        var mediaCategoryData = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 }, query: ['Archive', '=', false] });
        setMediaCategory(mediaCategoryData.data);
    
        var mediaCategoryType = await utility.getMediaCategroyTypeWithMediaCategory();
        var filterMediaCategoryType = mediaCategoryType.filter(x => {
            if (mediaCategoryData.data[0].SID == x.MediaCategorySID) {
                return x;
            } else if (!mediaCategoryData.data[0]) {
                return x;
            }
        });
        setMediaCategoryType([{ SID: 0, Description: 'All' }, ...filterMediaCategoryType]);
        setDataItem({ ...dataItem, MediaCategory: mediaCategoryData.data[0], MediaCategoryType: { SID: 0, Description: 'All' } });
    }

    const onChange = async (e) => {

        if(e.target.name == "MediaCategory"){
            setDataItem({ ...dataItem, [e.target.name]: e.target.value, MediaCategoryType: { SID: 0, Description: 'All' } });   
            var mediaCategoryType = await utility.getMediaCategroyTypeWithMediaCategory();
            var filterMediaCategoryType = mediaCategoryType.filter(x => {
                if (e.target.value.SID == x.MediaCategorySID) {
                    return x;
                } else if (!e.target.value) {
                    return x;
                }
            });
            setMediaCategoryType([{ SID: 0, Description: 'All' }, ...filterMediaCategoryType]);
        }else{
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    const isValid = () => {

        if(!dataItem.MediaCategory || Object.keys(dataItem.MediaCategory).length == 0){
            toast.error(`${lang.please_select_media_category_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        
        return true
    }

    const loadData = async () => {

        if (!isValid()) return;

        setLoadingStatus(lang.data_loading_error_message)

        var payload = {
            MediaCategory: dataItem.MediaCategory,
            MediaCategoryType: dataItem.MediaCategoryType,
        }

        const res = await API.getMediaMasterReport(payload);

        console.log(res.data);

        if (res.success) {
            if(res.data.length == 0){
                setGridData([]);
                setLoadingStatus(`${lang.no_record_found_error_message}`);
                return;
            }
            setGridData(res.data);
            setLoadingStatus(`${lang.data_load_successfully_message}`);
            return;
        }else{
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setGridData([]);
            setLoadingStatus(`${lang.no_record_found_error_message}`);
        }

    }

    const downloadExcel = async () => {

        if (gridData.length == 0) {
            toast.info(`${lang.no_data_found_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var storeData = gridData.map(x => {
            
            if(x.MediaCategory.isLong){
                return {
                    Title: x.Title ? x.Title : '',
                    AssetId: x.AssetId ? x.AssetId : '',
                    Description: x.Description ? x.Description : '',
                    MediaCategory: x.MediaCategory?.Description ?? '',
                    MediaCategoryType: x.MediaCategoryType?.Description ?? '',
                    EpisodeNumber: x.EpisodeNumber ? x.EpisodeNumber : 0,
                    Genres: x.Genres?.map(y => y.Description).join(',') ?? '',
                    Duration: utility.convertMilisecondsToStringWithFrames(x?.Duration ?? 0),
                    SeasonNumber: x.SeasonNumber ? x.SeasonNumber : 0,
                }

            }
            return {
                Title: x.Title ? x.Title : '',
                AssetId: x.AssetId ? x.AssetId : '',
                Description: x.Description ? x.Description : '',
                MediaCategory: x.MediaCategory?.Description ?? '',
                MediaCategoryType: x.MediaCategoryType?.Description ?? '',
                Duration: utility.convertMilisecondsToStringWithFrames(x?.Duration ?? 0),
                TcIn: utility.convertMilisecondsToStringWithFrames(x?.TcIn ?? 0),
                TcOut: utility.convertMilisecondsToStringWithFrames(x?.TcOut ?? 0),
                Brand: x.Brand?.Name ?? '',
                Product: x.Product?.Name ?? '',
                Genres: x.Genres?.map(y => y.Description).join(',') ?? '',
            }
        })
        
        var res = await API.exportCSV(storeData);
        
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (<>
        <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.media_master_report_page_breadcrumb}</span>
        </h1>
        <div className="row m-1">
            <div className="col-12">
                <div className="row">
                    <div className="col-3">
                        <label className="form-label">{lang.media_category_label} *</label>
                        <DropDownList
                            style={{backgroundColor: 'white'}}
                            data={mediaCategory}
                            textField="Description"
                            dataItemKey="_id"
                            name="MediaCategory"
                            onChange={onChange}
                            value={dataItem.MediaCategory}
                        />
                    </div>
                    <div className="col-3">
                        <label className="form-label">{lang.media_category_type_label} *</label>
                        <DropDownList
                            style={{backgroundColor: 'white'}}
                            data={mediaCategoryType}
                            textField="Description"
                            dataItemKey="_id"
                            name="MediaCategoryType"
                            onChange={onChange}
                            value={dataItem.MediaCategoryType}
                        />
                    </div>
                    <div className="col mt-4">
                        <ActionButton name={lang.show_button_text} onClick={loadData} />
                        <RoundButton icon='download' onClick={() => downloadExcel()} style={{ marginLeft: '10px' }} title={lang.download_button_tooltip} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <Grid data={gridData} style={{height: '70vh'}}>
                            <GridNoRecords>{loadingStatus}</GridNoRecords>
                            <Column field="Title" title={lang.title_column} width={150}/>
                            <Column field="AssetId" title={lang.assetId_column} width={100}/>
                            <Column field="Description" title={lang.description_column} width={170}/>
                            <Column field="Duration" title={lang.duration_column} cell={TimeCell} width={100}/>
                            <Column field="Genres" format={"Description"} title={lang.genres_column} cell={ArrayCell} width={150}/>
                            <Column field="Brand.Name" title={lang.brand_column} width={150}/>
                            <Column field="Product.Name" title={lang.product_column} width={150}/>
                            <Column field="MediaCategory.Description" title={lang.media_category_column} width={150} />
                            <Column field="MediaCategoryType.Description" title={lang.media_category_type_column} width={150} />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
        <div
        className={
          isMinified
            ? "scheduleFooter scheduleFooterMinified"
            : "scheduleFooter"
        }
      >
        <div className="flex-container-reverse flex-item-auto p-2">
          <div className="footerText">
            {loadingStatus}
          </div>
        </div>
      </div>

    </>)
}
export default MediaMasterReport;