import { toast } from "react-toastify";
import { ConfirmDeleteAlert } from "../../../ConfirmAlert";
import { CONTENT_TYPE, ENTITYNAME, PLAYLIST_SCHEDULE_TYPE, PLAYLIST_STATUS } from "../../../framework/constant/constant";
import * as API from "../../../framework/API/api";
import * as API_DIGI_SIGN from "../../../framework/API/api_digitalSign";
import { utility } from "../../../framework/utility/utilityProvider";
const PlaylistHelper = {};

PlaylistHelper.getPlaylistIndex = (item, playlistData) => {
    let contentIndex = null;

    for (let j = 0; j < playlistData.children.length; j++) {
        const content = playlistData.children[j];
        if (content._id == item._id) {
            contentIndex = j;
            break;
        }
    }

    return contentIndex;
}

PlaylistHelper.getPlaylistMenu = (props) => {

    const playlist = props.dataItem;

    let publish = playlist != undefined && playlist.Status == PLAYLIST_STATUS.Published ? true : false

    let showPlayingOnce = false;
    let showPlayingLoop = false;
    let showPublishUnPublish = false;
    let showStop = false;

  
    if(playlist != undefined && playlist.Schedule && playlist.Schedule.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover && playlist.Status == PLAYLIST_STATUS.Standby){
        showPlayingOnce = true;
        showPlayingLoop = true;
    } else if(playlist != undefined && playlist.Schedule && playlist.Schedule.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover && playlist.Status != PLAYLIST_STATUS.Standby){
        showStop = true;
    } else if (playlist != undefined && playlist.Schedule && playlist.Schedule.ScheduleType == PLAYLIST_SCHEDULE_TYPE.SetDateRange) {
        showPublishUnPublish = true;
    }
  
    const menu = [
          { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...playlist, copy: false }) },
          { label: 'Duplicate', icon: 'pi pi-fw pi-copy', command: () => { props.enterEdit({ ...playlist, copy: true })} },
          { label: 'Manage Screen Group', icon: 'pi pi-fw pi-stop', command: () => props.onManageScreenGroup(props.dataItem) },

          //ON SCHEDULE SHOW POPUP TO SET PLAYLIST SCHEDULE TYPE AND STATUS
          { label: 'Schedule', icon: 'pi pi-fw pi-calendar', command: () => props.onSchedulePlaylist(props.dataItem) },

          { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },

          { label: 'Refresh', icon: 'pi pi-fw pi-sync', command: () => props.onRefreshPlaylist(props.dataItem) },

          showPlayingOnce && { label: 'Play Once', icon: 'pi pi-fw pi-play', command: () => props.onPlaylistAction(props.dataItem,PLAYLIST_STATUS.PlayingOnce) },

          showPlayingLoop && { label: 'Loop', icon: 'pi pi-fw pi-replay', command: () => props.onPlaylistAction(props.dataItem,PLAYLIST_STATUS.PlayingLoop) },

          showStop && { label: 'Stop', icon: 'pi pi-fw pi-stop-circle', command: () => props.onPlaylistAction(props.dataItem,PLAYLIST_STATUS.Standby) },

          showPublishUnPublish && { label: publish ? 'Un-Publish' : 'Pulish', icon: `pi pi-fw pi-${publish ? 'calendar-minus' : 'calendar-plus'}`, command: () => props.onPlaylistAction(props.dataItem, publish ? PLAYLIST_STATUS.UnPublished : PLAYLIST_STATUS.Published) },
        ]


    return menu;
}

PlaylistHelper.handlePlaylistAction = async (dataItem,status) => {

    let updatePlaylistData = {
        _id: dataItem._id,
        Status: status,
    }

    let res = await API.saveData(ENTITYNAME.DigitalSignPlaylist, updatePlaylistData);
    if (res.success) {

        //UPDATE PLAYLIST_ID in SCREEN GROUP
        let updateScreenGroupData = []
        if(dataItem.screenGroup && dataItem.screenGroup.length > 0){

            //SEND EVENT
            for (let i = 0; i < dataItem.screenGroup.length; i++) {
                const s = dataItem.screenGroup[i];
                
                if(status == PLAYLIST_STATUS.Standby || status == PLAYLIST_STATUS.UnPublished){
    
                    //SEND BLANK EVENT
                    await API_DIGI_SIGN.sendEvent(s?._id, {});
                    updateScreenGroupData.push({ _id: s._id, playlist_id: null })
    
                } else {
    
                    //SEND EVENT
                    if(status != PLAYLIST_STATUS.Published){

                        let publishPlaylist = await API_DIGI_SIGN.getPublishPlaylist(dataItem?._id) 

                        if(publishPlaylist.success){
                            await API_DIGI_SIGN.sendEvent(s?._id, publishPlaylist.data);
                        }
                    } else {
                        //SET CHROME JOB FOR PUBLISHED CASE
                    }
    
                    updateScreenGroupData.push({ _id: s._id, playlist_id: dataItem._id })
                }
            }
        }

        if(updateScreenGroupData.length > 0) await API.saveData(ENTITYNAME.DigitalSignScreenGroup, updateScreenGroupData);

        toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
        });

    } else {
        toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    return res;

}

PlaylistHelper.handleSchedulePlaylist = async (playlistItem, scheduleDataItem) => {
    console.log(scheduleDataItem);

    let updatePlaylistData = {
        _id: playlistItem?._id,
        Status: scheduleDataItem.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover ? PLAYLIST_STATUS.Standby : PLAYLIST_STATUS.UnPublished,
        Schedule: {
            ScheduleType: scheduleDataItem.ScheduleType,
            StartDate: scheduleDataItem.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover ? null : utility.convertStringDatetoMilliseconds(scheduleDataItem.StartDate),
            EndDate: scheduleDataItem.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover && scheduleDataItem.isNoEndDate ? null : utility.convertStringDatetoMilliseconds(scheduleDataItem.EndDate),
            isNoEndDate: scheduleDataItem.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover ? true : scheduleDataItem.isNoEndDate
        },
    }

    let res = await API.saveData(ENTITYNAME.DigitalSignPlaylist, updatePlaylistData);
    if (res.success) {
        toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    } else {
        toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }

    return res;

}

PlaylistHelper.onRefreshPlaylist = async (dataItem) => {

    if(dataItem.screenGroup && dataItem.screenGroup.length > 0){

        //REFRESH PLAYLIST SOCKET
        for (let i = 0; i < dataItem.screenGroup.length; i++) {
            const s = dataItem.screenGroup[i];
            await API_DIGI_SIGN.refreshPlaylist(s?._id);
        }

    }

}


export {
    PlaylistHelper
}