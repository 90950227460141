/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { DISCOUNT_TYPE, ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import moment from "moment";

export const CouponEditForm = (props) => {

    const [dataItem, setDataItem] = useState({
        SID: props.item.SID ?? 0,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Code: props.item.Code ?? "",
        DiscountType: props.item.DiscountType ?? {},
        DiscountValue: props.item.DiscountValue ?? 0,
        Region: props.item.Region ?? [],
        ValidFrom: props.item.ValidFrom == 0 ? 'YYYY-MM-DD' : moment(new Date(props.item.ValidFrom)).format('YYYY-MM-DD') ?? "",
        ValidTo: props.item.ValidTo == 0 ? 'YYYY-MM-DD' : moment(new Date(props.item.ValidTo)).format('YYYY-MM-DD') ?? "",
        Archive: props.item.Archive ?? false,
        LimitedPeriod: props.item.LimitedPeriod ?? false
    });
    const [region, setRegion] = useState([]);
    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let regionRes = await API.getData(ENTITYNAME.Region, { query: ["Archive", "=", false] });
        setRegion(regionRes.data);
    }

    const onchange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else if (e.target.name == "LimitedPeriod") {
            setDataItem({ ...dataItem, LimitedPeriod: !dataItem.LimitedPeriod });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.Name == "" || dataItem.Name == undefined) {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Code == "" || dataItem.Code == undefined) {
            toast.error(`${lang.please_enter_code_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.DiscountType).length == 0) {
            toast.error(`${lang.please_select_discount_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.DiscountType.Type == "Percentage" && dataItem.DiscountValue > 100) {
            toast.error(`${lang.percentage_can_not_be_greater_than_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.DiscountValue < 1) {
            toast.error(`${lang.discount_should_be_greater_then_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Region).length == 0) {
            toast.error(`${lang.please_select_region_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.LimitedPeriod) {

            if (!utility.isValidDate(dataItem.ValidFrom)) {
                toast.error(`${lang.please_select_valid_from_date_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;

            }
            if (!utility.isValidDate(dataItem.ValidTo)) {
                toast.error(`${lang.please_select_valid_to_date_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
        }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {

            let localData = {
                SID: dataItem.SID,
                Name: dataItem.Name,
                Code: dataItem.Code,
                DiscountType: {
                    _id : dataItem.DiscountType,
                    Type: dataItem.DiscountType.Type
                },
                DiscountValue: parseInt(dataItem.DiscountValue),
                Region: dataItem.Region.map((x) => {
                    return {
                      _id: x._id,
                      SID: x.SID,
                      Description: x.Description
                    }
                  }),
                ValidFrom: dataItem.LimitedPeriod ? new Date(dataItem.ValidFrom).getTime() : 0,
                ValidTo: dataItem.LimitedPeriod ? new Date(dataItem.ValidTo).getTime() : 0,
                Archive: dataItem.Archive ?? false,
                LimitedPeriod: dataItem.LimitedPeriod ?? false,
                checkDuplicate: true,
                query: [["Name", "=", dataItem.Name]]
            }

            let res = await API.saveData(ENTITYNAME.Coupon, localData);
            if (res.success) {
                props.cancelEdit();
                props.refresh();
                utility.deleteLocalStorageItem(ENTITYNAME.Coupon);
                return;
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>
            {<Dialog
                title={props.item.Name ? `${dataItem.Name}` : `${lang.create_coupon_dialog_header}`}
                onClose={props.cancelEdit}
                width={"830px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <Form
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6 form-group">
                                            <label htmlFor="TabView">{lang.name_label} *</label>
                                            <input className="pl-2" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Name"} onChange={onchange} value={dataItem.Name} />
                                        </div>
                                        <div className="col-6 form-group">
                                            <label htmlFor="TabView">{lang.code_label} *</label>
                                            <input className="pl-2" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Code"} onChange={onchange} value={dataItem.Code} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 ">
                                            <label htmlFor="TabView">{lang.discount_type_label} *</label>
                                            <DropDownList
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                                data={DISCOUNT_TYPE}
                                                name="DiscountType"
                                                textField="Type"
                                                dataItemKey="ID"
                                                value={dataItem.DiscountType}
                                                onChange={onchange}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="TabView">{lang.discount_value_label} *</label>
                                            <div className="form-group">
                                                <input className="pl-2" type="number" style={{ border: 'solid lightgrey 1px', width: '100%', height: "35px", borderRadius: '5px' }} name={"DiscountValue"} onChange={onchange} value={dataItem.DiscountValue} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 form-group">
                                            <label htmlFor="TabView">{lang.region_label} *</label>
                                            <MultiSelect
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                                data={region}
                                                name="Region"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.Region}
                                                onChange={onchange}
                                            />
                                        </div>
                                        <div className="col-6 mt-4">
                                            <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onchange} />
                                            <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-4 p-4'>
                                            <input name="LimitedPeriod" type={"checkbox"} value={dataItem.LimitedPeriod} checked={dataItem.LimitedPeriod} onClick={onchange} onChange={() => { }} />
                                            <label className='ml-2'>{lang.limited_period_label}</label>
                                        </div>


                                        {dataItem.LimitedPeriod && <div className="form-group col-4">
                                            <label htmlFor="">{lang.valid_from_label} *</label><br />
                                            <input type={'date'} name={"ValidFrom"} value={dataItem.ValidFrom} onChange={onchange} max={dataItem.ValidTo} className="form-control-sm"
                                                style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px' }} label={lang.start_date_label}
                                            />
                                        </div>}

                                        {dataItem.LimitedPeriod && <div className="form-group col-4">
                                            <label htmlFor="">{lang.valid_to_label} *</label><br />
                                            <input type={'date'} name={"ValidTo"} onChange={onchange} value={dataItem.ValidTo} min={dataItem.ValidFrom} className="form-control-sm"
                                                style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px' }} label={lang.end_Date_label}
                                            />
                                        </div>}
                                    </div>

                                </div>
                            </div>
                        </FormElement>
                    )} />
            </Dialog>}
        </>
    )
}