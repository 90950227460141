import { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import SaveButton from "../../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../../framework/forms/helpercomponents/buttons/CancelButton";
import { ASSETTYPE, ENTITYNAME, OTTROUTETYPE } from "../../../../framework/constant/constant";
import * as API from '../../../../framework/API/api'
import { toast } from "react-toastify";
import MediaEpisodeAssetEditForm from "../../MediaEpisodeAssetEditForm";
import moment from "moment";
import { useTranslation } from "../../../../locale/useTranslation";
import { utility } from "../../../../framework/utility/utilityProvider";
import { Checkbox } from "@progress/kendo-react-inputs";
import MediaEpisodeSegmentEditForm from "../../../Segment/MediaEpisodeSegmentEditForm";
import IsShortMediaCollectionSelection from "../../../../framework/forms/IsShortMediaCollectionSelection";
import RoundButton from "../../../../framework/forms/helpercomponents/buttons/RoundButton";
import DeleteRoundButton from "../../../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../../../ConfirmAlert";



const AttachMediaTab = (props) => {

    const { mediaepisode_id } = props;
    const { mediaEpisode } = props;

    const none = "N/A";

    const [showEdit, setShowEdit] = useState(false);
    const boxStyle = { margin: "10px 5px", padding: "10px", borderRadius: "10px", backgroundColor: "white", wordWrap: "break-word" }
    const headingStyle = { fontFamily: "Roboto", fontSize: "16px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", padding: "10px 0" }
    const subHeadingStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", margin: "7px 0", padding: 0 }
    const subHeadingDetailStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: 0, marginBottom: "25px" }
    const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px" }

    const lang = useTranslation();


   
    const [selectedAttachedMedia, setSelectedAttachedMedia] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        setLoading(true);

      
        let attachedMediaRes = await API.getData(ENTITYNAME.MediaEpisode, { query: ['Media._id', '=', mediaEpisode._id] });

       
        if (attachedMediaRes.success) {
            setSelectedAttachedMedia(attachedMediaRes.data);
        }

        setLoading(false);
    }

    const onClose = () => {
        setShowEdit(!showEdit);
    }


    const handleMediaSelect = async (newAttachedMedia) => {

        if (newAttachedMedia.length == 0) return;

        var attachedMedia = {
            _id: mediaEpisode._id,
            SID: mediaEpisode.SID,
            Title: mediaEpisode.Title,
            MediaCategory: mediaEpisode.MediaCategory,
            entityName: 'mediaepisode'
        }

        var res = await API.saveData(ENTITYNAME.MediaEpisode, { _id: newAttachedMedia[0]._id, Media: attachedMedia });

        if (res.success) {
            setSelectedAttachedMedia([...selectedAttachedMedia, ...newAttachedMedia]);
        } else {
            toast.error(res.message);
        }

    }


    const onDeleteAttachMedia = async (attachedMedia) => {
        var res = await API.saveData(ENTITYNAME.MediaEpisode, { _id: attachedMedia._id, Media: {} });
        if (res.success) {
            setSelectedAttachedMedia(selectedAttachedMedia.filter(x => x._id != attachedMedia._id));
        } else {
            toast.error(res.message);
        }
    }

    return (
        <>
            <div style={boxStyle}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                    <h1 style={headingStyle}>Attach Media</h1>
                    <RoundButton icon={'link'} title={lang.attach_media_button_tooltip} onClick={onClose} />
                </div>
                {loading ? <div style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
                    <i className="fa fa-spinner fa-spin fa-xl " />
                </div> :
                    <div style={{ overflow: "auto" }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ width: "50px" }} scope="col"></th>
                                    <th scope="col">Asset ID</th>
                                    <th scope="col">Title</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedAttachedMedia?.map((attachedMedia, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-nowrap" style={tableDataStyle} ><DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onDeleteAttachMedia(attachedMedia), () => { })} /></td>
                                            <td className="text-nowrap" style={tableDataStyle}>{attachedMedia?.AssetId}</td>
                                            <td className="text-nowrap" style={tableDataStyle}>{attachedMedia?.Title}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                }

            </div>

            {showEdit && <IsShortMediaCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={handleMediaSelect} entityname={ENTITYNAME.MediaEpisode} closeForm={onClose} mode={'single'} width={"84vw"} height={"68vh"} />}
        </>
    )
}

export default AttachMediaTab;