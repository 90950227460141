import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useEffect, useRef, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import { useTranslation } from '../../locale/useTranslation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME, MEDIACATEGORIES } from '../../framework/constant/constant';
import * as API from '../../framework/API/api'
import { toast } from 'react-toastify';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import { utility } from '../../framework/utility/utilityProvider';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import CollectionSelection from '../../framework/forms/CollectionSelection';
import moment from 'moment';

const PlanningInsertProgramGroup = (props) => {

    const lang = useTranslation();
    const planningGroupItemsRef = useRef({ Name : ''});
    const [dataItem, setDataItem] = useState({
        // SlotDate : '',
        Time : '00:00:00:00',
        isLoop : false
    })
    const [showCollection,setShowCollection] = useState(false);

    useEffect(() => {
        loadcombo()
    }, [])

    const loadcombo = async () => {
    }

    const onChange = (e) => {
        if(e.target.name == 'isLoop'){
            setDataItem({ ...dataItem, isLoop : !dataItem.isLoop });
        }else{
            setDataItem({ ...dataItem, [e.target.name] : e.target.value });
        }
    }

    const isValid = () => {

        if (Object.keys(planningGroupItemsRef.current).length == 0) {
            toast.error(`Please select program group first`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.isLoop && !utility.isValidDate(dataItem.SlotDate)) {
            toast.error(`Please enter valid slot date`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.isLoop && (dataItem.Time == undefined || dataItem.Time.includes('_'))) {
            toast.error(`Please enter valid time`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        let slotDateTimeFrom = props.selectedSlotDateTime + props.offSetTime
        let slotDateTimeTo = utility.convertStringDatetoMilliseconds(dataItem.SlotDate) + 
        utility.convertStringWithFramesToMilliseconds(dataItem.Time);
        console.log(slotDateTimeTo < slotDateTimeFrom);
        if (slotDateTimeTo < slotDateTimeFrom) {
            toast.error(`SlotDateTime should be greater than the selected slot cell SlotDate Time`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;

    }

    const onInsertProgramGroupPlanning = async () => {

        if(!isValid()) return;

        let payload = {
            programGroup : planningGroupItemsRef.current,
            selectedSlotDateTime : props.selectedSlotDateTime + props.offSetTime,
            channel : props.channel,
            isLoop : dataItem.isLoop,
            slotDateTime : dataItem.isLoop ? 
                utility.convertStringDatetoMilliseconds(dataItem.SlotDate) + 
                utility.convertStringWithFramesToMilliseconds(dataItem.Time) : 0
        }

        props.status(lang.inserting_program_group_status_label);

        let resInsertProgramGroup = await API.insertProgramGroup(payload);

        if(resInsertProgramGroup.success){
            props.status(lang.successfully_created_group_label);
            props.refreshPlanning();
            onClose();
        }else{
            toast.error(resInsertProgramGroup.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const onClose = () => {
        props.onClose();
        setDataItem({ isLoop : false })
    }

    const selectProgramGroup = (programGroups) => {
        if (programGroups.length > 0) {
            planningGroupItemsRef.current = programGroups[0];
        }
    }


    return (
        <>
            <Dialog title={lang.insert_program_group_label_planning} onClose={onClose} width={'500px'}>
                <EditPageHeader saveButtonTitle={'Insert'} title={""} onSubmit={onInsertProgramGroupPlanning} onCancel={onClose} showTitle={false} />
                <div className='row mt-1'>  
                    <div className="col-10" style={{ padding : "0px 0px 0px 16px" }}>
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.program_group_sub_menu}</label>
                            <input name="Title" type="text" className="form-control form-control-sm" value={planningGroupItemsRef.current.Name} onChange={()=>""} disabled={true} />
                        </div>
                    </div>
                    <div className="col-2" style={{ padding : "0px 16px 0px 0px" }}> 
                        <ActionButton 
                            style={{marginTop: "18px", height:"30px"}} 
                            btnColor={'success'} 
                            title={lang.select_button_tooltip} 
                            name={"..."} 
                            onClick={() => { setShowCollection(true)}}
                        />  
                    </div>           
                </div>
                <div className="row">
                    <div className="col-3">
                        <input type = "checkbox" name="isLoop" value={dataItem.isLoop} checked= {dataItem.isLoop} onChange={onChange}/>  
                        <label htmlFor="TabView" className="pl-2">{'Loop'}</label> 
                    </div> 
                </div>
                {dataItem.isLoop && <hr></hr>}
                {dataItem.isLoop && <div className="row mt-2">
                    <div className="col-6">
                        <label htmlFor="TabView">{lang.slot_date_column} *</label>
                        <div className="form-group">
                            <input className="form-control form-control-sm" type="date" name="SlotDate" onChange={onChange} value={dataItem.SlotDate} />
                        </div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="">{lang.time_column} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="Time"
                            value={dataItem.Time}
                            onChange={(e) => onChange(e)}
                            required
                        />
                    </div>
                </div>}

                {showCollection && (<CollectionSelection
                    title={lang.select_program_group_label}
                    entityname={ENTITYNAME.MediaEpisodeGroup}
                    wherestatement={['MediaCategory.isLong', '=', true]}
                    setDataList={selectProgramGroup}
                    mode={"single"}
                    closeForm={() => { setShowCollection(false) }}
                />)}
            </Dialog>
        </>

    )
}

export default PlanningInsertProgramGroup