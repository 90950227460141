/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../API/api";
import { DateCell, DateOnlyCell } from "../forms/helpercomponents/CustomGridCells/DateCell";
import { LookupCell } from "../forms/helpercomponents/CustomGridCells/LookupCell";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, PRODUCTS, ROLETYPE } from "../constant/constant";
import { ImageCell } from "../forms/helpercomponents/CustomGridCells/ImageCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ArrayCell } from "../forms/helpercomponents/CustomGridCells/ArrayCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../utility/utilityProvider";
import { TimeCell } from "../forms/helpercomponents/CustomGridCells/TimeCell";
import { CheckboxCell } from "../forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../forms/helpercomponents/CustomGridCells/HrefCell";
import { MyToggleButton } from "../forms/helpercomponents/CustomGridCells/MyToggleButton";
import { arrayEnumCell } from "../forms/helpercomponents/CustomGridCells/PlatformCell";
import SaveButton from "../forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../forms/helpercomponents/buttons/CancelButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "../forms/helpercomponents/Loader";


const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const RoleModuleCollectionSelection = memo((props) => {
  const lang = useTranslation();

  let wherestatement = props.wherestatement;
  let andWhereStatement = props.andWhereStatement;
  let collectionData = props?.data ?? [];
  let showProductFilter = props.showProductFilter ?? false;

  const [gridData, setGridData] = useState([]);
  const [originalGridData, setOriginalGridData] = useState([]);
  const [dataColumns, setDataColumns] = useState(props.customColumns ?? []);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedState, setSelectedState] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({
    _id: PRODUCTS.All,
    Name: utility.getKeyByValue(PRODUCTS, PRODUCTS.All),
  });
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const gridRef = useRef();
  const per_page = props.perPage ?? 50;
  var currentPage = 1

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );
  const MyDateCell = (props) => <DateOnlyCell {...props} />;
  const MyArrayCell = (props) => <ArrayCell {...props} />;
  const MyHrefCell = (props) => <HrefCell {...props} />;

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={props.entityname}
      size={"small"}
    />
  );


  useEffect(() => {
    loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
  }, []);

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText) => {
    try {
      setIsLoading(true);
      const currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
      const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
      const user = utility.getValue(LOCALSTORAGE_KEY.userData);
      let products = user.RoleType.ID == ROLETYPE.SuperAdmin ?
        Object.keys(PRODUCTS).map((x) => {
          return { _id: PRODUCTS[x], Name: x};
        }) : 
        company.Products.map((x) => {
        return { _id: x, Name: utility.getKeyByValue(PRODUCTS, x)};
      });
      let finalProducts = products.some(x=>x._id == PRODUCTS.All) ? products : products.unshift({ _id: PRODUCTS.All, Name: utility.getKeyByValue(PRODUCTS, PRODUCTS.All)})
      setProducts(finalProducts);
      const columns = await API.getFormData(props.entityname, currentUserId);
      setDataColumns(columns);

      let data = [];

      if (collectionData.length > 0) {

        data = company?.Products.includes(PRODUCTS.All) ? collectionData : collectionData?.filter((x) => {
          return x?.Products?.some((y) => company?.Products?.includes(y))
        })

      } else {

        const json = await API.getEntitiesWithSearch(props.entityname, { perPage: null, page: null, query: wherestatement }, columns, searchText);
        data = json.data.length > 0 && company?.Products?.includes(PRODUCTS.All) ? json.data : json.data?.filter((x) => {
          return x?.Products?.some((y) => company?.Products?.includes(y))
        })

      }

      setOriginalGridData(data);
      setGridData(data);
      setCount(data?.length);
      setIsLoading(false);

    } catch (error) {
      console.log("error", error);
    }
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);

    }, [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {

    if (props.mode == 'single') {
      toast.info(`${lang.select_any_one_collection_selection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    setSearchText(() => e.target.value);
    let searchValue = e.target.value;

    if (searchValue != '') {
      let filteredData = originalGridData.filter((x) => x?.Name.toLowerCase().replace(" ", "").trim().includes(searchValue))
      setGridData(filteredData)
      setCount(filteredData.length)
    } else {
      setGridData(originalGridData)
      setCount(originalGridData.length)
    }

  }

  const checkIfItemExist = (item) => {

    let isFound = false;
    let existingEntries = props?.existingItems ?? [];
    if (props.checkDuplicate ?? false) {
      isFound = existingEntries.some(element => {
        return element.SID === item.SID;
      });
    }

    return isFound;

  };

  //to sendDataList to RailsEditForm and to close the collection
  const onSelection = () => {
    var msg = `${lang.please_select_first_collection_error_message}`

    var selectedDataList = [];
    //loop for setting the selected obj in array
    gridData.map((obj) => {
      var key = obj._id;
      //if true means checked
      if (selectedState[key]) {
        // checking duplicate as well
        if (!checkIfItemExist(obj)) {
          selectedDataList.push(obj);
        } else {
          msg = `${lang.already_attached_collection_error_message}`
        }
      }
    })

    if (selectedDataList.length != 0) {
      props.setDataList(selectedDataList);
      props.closeForm();
    } else {
      // alert(msg)
      toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  //load for infinit scroll

  const loadInfiniteData = async () => {
    currentPage++;
    if (count > originalGridData.length) {
      var json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: currentPage, query: wherestatement }, dataColumns, searchText);
      setOriginalGridData((old) => [...old, ...json.data]);
      setGridData((old) => [...old, ...json.data]);
    }

  }

  //for infinite scroll
  const scrollHandler = async (event) => {
    const e = event.nativeEvent;
    if (e.target.className.indexOf('k-grid-content') === -1) {
      return;
    }

    if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
      await loadInfiniteData()
    }
  };

  const onChange = (e) => {
    setSelectedProduct(e.target.value);
    if (e.target.value._id === PRODUCTS.All) {
      loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
    } else {
      let query = ["Products", "=", e.target.value._id];
      loaddata({ per_page: per_page }, query, andWhereStatement, searchText);
    }
  }

  return (
    <Dialog
      title={props.title ?? `${lang.add_dialog_header}`}
      onClose={props.closeForm}
      width={props.width ?? "75vw"}
      height={props.height ?? "64vh"}>
      <div>
        <div className="row mb-3" >
          <div className={showProductFilter ? "col-5" : "col-8"}>
            <SaveButton title={props.addButtonTitle ?? `${lang.add_button_text}`} style={{ backgroundColor: 'green', borderRadius: "4px" }} onClick={onSelection}></SaveButton>
            <CancelButton onClick={props.closeForm}></CancelButton>
          </div>
          <div className="col" style={{ display: 'flex' }}>
            {showProductFilter && <DropDownList
              data={products}
              textField="Name"
              dataItemKey="_id"
              value={selectedProduct}
              onChange={onChange}
              style={{ width: "200px", backgroundColor: 'white' }}
            />}
            <div className="input-group input-group mb ml-1">
              <input type="text" className="form-control shadow-inset-2 pt-0 pb-0" id="searchInput"
                placeholder={lang.search_button_text}
                onChange={onSearch}
                onFocus={(e) => {
                  if (props.setOutSideFocus) {
                    props.setOutSideFocus(true);
                  }
                }}
                onBlur={(e) => {
                  if (props.setOutSideFocus) {
                    props.setOutSideFocus(false);
                  }
                }}
              />
              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>

        {isLoading && <Loader height="40vh" />}

        {!isLoading &&
          <Grid
            data={gridData.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}

            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: props.mode ?? "multiple",
            }}
            ref={gridRef}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            // onHeaderSelectionChange={props.mode == 'single' ? () => {} : onHeaderSelectionChange}
            style={{ height: "42vh", wdith: "60vw" }}
            onScroll={scrollHandler}
          >
            <GridColumn
              field={SELECTED_FIELD}
              width="50px"
              headerSelectionValue={
                gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1
              }
              locked={true}
            />
            {dataColumns.map((column, index) => {
              if (column.type === COLUMNSTYPE.date) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyDateCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
                // }else if (column.name === COLUMNSTYPE.time) {
              } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={TimeCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              } else if (column.name === "SID") {
                return;
                //we are not returning any value for SID column
              } else if (column.type === COLUMNSTYPE.image) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ImageCell}
                    title={column.label}
                    width={column.width ?? 100}
                  />
                );
              } else if (column.type === COLUMNSTYPE.select) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyDatalookupCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.toggle) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyCustomToggleButton}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.checkbox) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={CheckboxCell}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.array) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyArrayCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format ?? "Description"} //display item in object
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.href) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyHrefCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format}
                  />
                );
              } else if (column.type === COLUMNSTYPE.arrayenum) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={arrayEnumCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format}
                  />
                );
              }
              else {
                return (
                  column.type !== COLUMNSTYPE.hidden && (
                    <GridColumn
                      key={index}
                      field={column.name}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  )
                );
              }
            })}
          </Grid>}
      </div>
      <div className="text-right mt-1">{`Total : ${count}`}</div>
    </Dialog>
  );
});

export default RoleModuleCollectionSelection;
