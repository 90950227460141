/* eslint-disable */
import React, { useState, useRef } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { EditPageHeader } from '../../components/EditPageHeader';
import { APPROVESTATUS, ENTITYNAME, RECURRING, TERM } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { utility } from '../../framework/utility/utilityProvider';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import moment from 'moment';
import { ConfirmAlert } from '../../ConfirmAlert';
import EditRoundButton from '../../framework/forms/helpercomponents/buttons/EditRoundButton';
import { DateOnlyCell } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';

const ContractPaymentSchedule = (props) => {

    const lang = useTranslation();
    const [showGeneratePayment, setShowGeneratePayment] = useState(false);
    const [showEditPayment, setShowEditPayment] = useState(false);
    const [selectedEditItem, setselectedEditItem] = useState({
        DueDate: '',
        DueAmount: 0,
        ApprovalStatus: {},
        AmountPaid: 0,
        Remark: '',
    });
    const [gridData, setGridData] = useState(props?.contractPaymentSchedule?.PaymentDetails ?? []);

    const blankDataItem = {
        Term: props?.contractPaymentSchedule?.Term ?? {},
        Reason: props?.contractPaymentSchedule?.Reason ?? '',
        HoldAllPayment: props?.contractPaymentSchedule?.HoldAllPayment ?? false,
        StartDate: moment(new Date()).format("YYYY-MM-DD"),
        Recurring: {},
        InterestRate: 0,
        NoOfInstallment: 0,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);

    const onChange = (e) => {

        if (e.target.name == 'HoldAllPayment') {
            setDataItem({ ...dataItem, HoldAllPayment: !dataItem.HoldAllPayment });
            props.setContractPaymentSchedule({
                ...dataItem,
                [e.target.name]: e.target.value ,
                PaymentDetails: gridData,
            });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
            props.setContractPaymentSchedule({
                ...dataItem,
                [e.target.name]: e.target.value,
                PaymentDetails: gridData,
            });
        }
    }

    const isValid = () => {

        if (!utility.isValidDate(dataItem.StartDate)) {
            toast.error(`${lang.please_select_start_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Recurring).length == 0) {
            toast.error(`${lang.please_select_recurring_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.InterestRate < 0) {
            toast.error(`${lang.interest_rate_should_be_negative_error_message}`, {
                position: toast.POSITION.TOP_RIGHTs
            });
            return false;
        }
        if (dataItem.NoOfInstallment < 1) {
            toast.error(`${lang.no_of_installment_should_be_greater_than_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const onSaveGeneratePayment = () => {

        var totalAmount = props.data.reduce((value, obj) => {
            return value + obj.Amount;
        }, 0);

        var storeDate = []

        for (let i = 0; i < dataItem.NoOfInstallment; i++) {
            var data = {
                DueDate: dataItem.Recurring.Description == "Yearly" ? moment(new Date(dataItem.StartDate)).add(i, "y").format("YYYY-MM-DD") : dataItem.Recurring.Description == "Monthly" ? moment(new Date(dataItem.StartDate)).add(i, "M").format("YYYY-MM-DD") : moment(new Date(dataItem.StartDate)).add(i, "w").format("YYYY-MM-DD"),
                DueAmount: totalAmount / parseInt(dataItem.NoOfInstallment),
                // InterestRate: parseInt(dataItem.InterestRate),
                // NoOfInstallment: parseInt(dataItem.NoOfInstallment),
                ApprovalStatus: APPROVESTATUS[0],
                AmountPaid: parseInt(0),
                BalanceAmount: totalAmount / parseInt(dataItem.NoOfInstallment),
                Remark: '',
            }
            data.DueDate = (new Date(data.DueDate)).getTime();
            storeDate.push(data);
        }
        props.setContractPaymentSchedule({
            Term: dataItem.Term,
            Reason: dataItem.Reason,
            HoldAllPayment: dataItem.HoldAllPayment,
            PaymentDetails: storeDate,
        });
        setGridData([...storeDate]);
        setShowGeneratePayment(false);
        setDataItem(blankDataItem);
    }

    const generatePaymentSave = () => {

        if (isValid()) {

            if (gridData.length != 0) {
                ConfirmAlert(
                    () => { onSaveGeneratePayment() }, // on Click yes
                    () => { }, // on click no
                    `${lang.generate_payment_label}`, //title
                    `${lang.are_you_sure_you_want_to_generate_payment}`// message
                )
            } else {
                onSaveGeneratePayment();
            }
        }
    }

    const onEditGeneratePayment = (dataItem) => {
        console.log(dataItem);
        setShowEditPayment(true);
        dataItem.DueDate = moment(new Date(dataItem.DueDate)).format("YYYY-MM-DD");
        setselectedEditItem(dataItem);
    }

    const MyCommandCell = (props) => {
        return (
            <EditRoundButton onClick={() => onEditGeneratePayment(props?.dataItem)} />
        )
    }

    const onPaymnetScheduleChange = (e) => {
        setselectedEditItem({ ...selectedEditItem, [e.target.name]: e.target.value })
    }

    const onEditGeneratePaymentSave = () => {
        if(selectedEditItem.AmountPaid < 0){
            toast.error(`${lang.amount_paid_can_not_be_negative_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if(selectedEditItem.DueAmount < parseFloat(selectedEditItem.AmountPaid)){
            toast.error(`${lang.total_amount_is_smaller_than_amount_paid_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        var data = {
            ...selectedEditItem,
            DueDate : (new Date(selectedEditItem.DueDate)).getTime(),
            ApprovalStatus: selectedEditItem.ApprovalStatus,
            AmountPaid: parseFloat(selectedEditItem.AmountPaid),
            Remark: selectedEditItem.Remark,
            BalanceAmount: selectedEditItem.DueAmount - selectedEditItem.AmountPaid,
        }
        var index = gridData.findIndex(x => x.DueDate == selectedEditItem.DueDate);
        gridData[index] = data;
        props.setContractPaymentSchedule({
            Term: dataItem.Term,
            Reason: dataItem.Reason,
            HoldAllPayment: dataItem.HoldAllPayment,
            PaymentDetails: gridData,
        });
        setGridData([...gridData]);
        setShowEditPayment(false);
    }

    const calculatedDueAmountCell = (props) => {
        let sum = 0;    
        gridData.forEach((item) => {
          sum = sum + (item.DueAmount);
        })
        let totalDueAmount = sum;
        return (
          <td colSpan={props.colSpan} style={props.style}>
            {totalDueAmount}
          </td>
        );
    };

    const calculatedPaidAmountCell = (props) => {
        let sum = 0;    
        gridData.forEach((item) => {
          sum = sum + (item.AmountPaid);
        })
        let totalAmountPaid = sum;  
        return (
          <td colSpan={props.colSpan} style={props.style}>
            {totalAmountPaid}
          </td>
        );
    };

    const calculatedBalanceAmountCell = (props) => {
        let sum = 0;    
        gridData.forEach((item) => {
          sum = sum + (item.BalanceAmount);
        })
        let totalBalanceAmount = sum;  
        return (
          <td colSpan={props.colSpan} style={props.style}>
            {totalBalanceAmount}
          </td>
        );
    };

    const totalLabelCell = (props) => {  
        return (
          <td colSpan={props.colSpan} style={props.style}>
            {lang.total_label}
          </td>
        );
    };

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-6'>
                            <label>{lang.term_label} *</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white",
                                }}
                                data={TERM}
                                name="Term"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.Term}
                                onChange={onChange}
                            />
                            <div className='mt-4'>
                                <input type="checkbox" name={"HoldAllPayment"} value={dataItem.HoldAllPayment} checked={dataItem.HoldAllPayment} onChange={onChange} />
                                <label className="pl-2" htmlFor="TabView">{lang.hold_all_payment_label}</label>
                            </div>
                        </div>
                        <div className='col-6'>
                            <label>{lang.reason_label}</label>
                            <textarea
                                name={"Reason"}
                                type="text"
                                className='form-control form-control-sm'
                                style={{ height: "10vh", resize: "none" }}
                                onChange={onChange}
                                value={dataItem.Reason}
                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <ActionButton style={{ marginLeft: '15px' }} title={lang.generate_payment_button_text} name={lang.generate_payment_button_text} onClick={() => setShowGeneratePayment(true)} />
                    </div>
                    <div className='row mt-3 mb-3'>
                        <div className='col'>
                            <Grid data={gridData} style={{ height: "35vh" }}>
                                <GridColumn cell={MyCommandCell} width={"40px"} />
                                <GridColumn field="DueDate" cell={DateOnlyCell} title={lang.due_date_column} editable={false} />
                                <GridColumn field="ApprovalStatus.Description" title={lang.approve_status_column} editable={false} footerCell={totalLabelCell} />
                                <GridColumn field="DueAmount" title={lang.due_amount_column} editable={false} footerCell={calculatedDueAmountCell} width={'120px'} />
                                <GridColumn field="AmountPaid" title={lang.amount_paid_column} editable={false} footerCell={calculatedPaidAmountCell} width={'120px'} />
                                <GridColumn field="BalanceAmount" title={lang.balance_amount_column} editable={false} footerCell={calculatedBalanceAmountCell} width={'120px'} />
                                <GridColumn field="Remark" title={lang.remark_column} editable={false} />
                            </Grid>
                        </div>
                    </div>
                    {showGeneratePayment &&
                        <Dialog title={lang.generate_payment_schedule_dialog_header} onClose={() => { setShowGeneratePayment(false); setDataItem(blankDataItem) }} width={"500px"}>
                            <div className="row">
                                <div className="col-12">
                                    <EditPageHeader showTitle={false} onSubmit={generatePaymentSave} onCancel={() => { setShowGeneratePayment(false); setDataItem(blankDataItem) }} />
                                    <div className="row mt-2">
                                        <div className='col-6'>
                                            <label htmlFor="">{lang.start_date_label} *</label>
                                            <input type="date" className="form-control form-control-sm" name="StartDate" value={dataItem.StartDate} onChange={(e) => onChange(e)} />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.recurring_label} *</label>
                                            <DropDownList
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                                data={RECURRING}
                                                name="Recurring"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.Recurring}
                                                onChange={onChange}
                                            />
                                        </div>

                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-6">
                                            <label htmlFor="">{lang.interest_rate_label} *</label>
                                            <input type="number" className="form-control form-control-sm" name="InterestRate" value={dataItem.InterestRate} onChange={(e) => onChange(e)} />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="">{lang.no_of_installment_label} *</label>
                                            <input type="number" className="form-control form-control-sm" name="NoOfInstallment" value={dataItem.NoOfInstallment} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>}
                    {showEditPayment &&
                        <Dialog title={lang.contract_payment_schedule_dialog_header} onClose={() => setShowEditPayment(false)} width={"500px"}>
                            <div className="row">
                                <div className="col-12">
                                    <EditPageHeader showTitle={false} onSubmit={onEditGeneratePaymentSave} onCancel={() => setShowEditPayment(false)} />
                                    <div className="row mt-2">
                                        <div className='col-6'>
                                            <label htmlFor="">{lang.due_date_label} *</label>
                                            <input type="date" className="form-control form-control-sm" name="DueDate" value={selectedEditItem.DueDate} onChange={(e) => onPaymnetScheduleChange(e)} disabled />
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="">{lang.due_amount_label} *</label>
                                            <input type="number" className="form-control form-control-sm" name="DueAmount" value={selectedEditItem.DueAmount} onChange={(e) => onPaymnetScheduleChange(e)} disabled />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.approve_status_label} *</label>
                                            <DropDownList
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                                data={APPROVESTATUS}
                                                name="ApprovalStatus"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={selectedEditItem.ApprovalStatus}
                                                onChange={onPaymnetScheduleChange}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="">{lang.amount_paid_label}</label>
                                            <input type="number" className="form-control form-control-sm" name="AmountPaid" value={selectedEditItem.AmountPaid} onChange={(e) => onPaymnetScheduleChange(e)} />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-12">
                                            <label htmlFor="">{lang.remark_label}</label>
                                            <textarea style={{ height: "6vh", resize: "none" }} type="text" className="form-control form-control-sm" name="Remark" value={selectedEditItem.Remark} onChange={(e) => onPaymnetScheduleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>}
                </div>
            </div>
        </>
    )
}

export default ContractPaymentSchedule;