/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME } from "../../framework/constant/constant";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridColumn } from "@progress/kendo-react-grid";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { utility } from "../../framework/utility/utilityProvider";

const DefaultSecondaryEventPrototype = () => {
  const [dataItem, setDataItem] = useState({ Channel: {}, Name: '', SID: 0,  Archive: false , TxMode: { _id: '0', Name: 'All' }, });
  const [channel, setChannel] = useState([]);
  const [prototypeOpen, setPrototypeOpen] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [txModes, setTxMode] = useState([])

  const { SID } = useParams();
  const navigate = useNavigate();
  const lang = useTranslation();
  const loc = useLocation();


  useEffect(() => {
    loadcombo();
  }, []);

  const loadcombo = async () => {
    let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannel(channelRes.data);
    let txModeRes = await API.getDataLookup(ENTITYNAME.TXMode, { sort: { Name: 1 } });
    txModeRes.data.unshift({ _id: '0', Name: 'All' });
    setTxMode(txModeRes.data);
    if (SID == 0) {
      setDataItem({...dataItem , Channel : utility.getDefaultItem(channelRes.data)});
    }else {
      loadEditData(txModeRes.data);
    }
  }

  const loadEditData = async (txModeData) => {

    let linkData = {
      link_collection: 'prototype',
      link_field: 'Prototypes'
    }

    let res = await API.getEntity(ENTITYNAME.SecondaryEventDefaultPrototype, parseInt(SID), linkData);
 
    if(res.success){
    setDataItem({
      ...res.data ,
      SID: loc.state.copy ? 0 : parseInt(SID),
      Name: loc?.state?.copy ? "Copy of " + res.data.Name : res.data.Name,
      TxMode: res?.data?.TxMode ? txModeData.find(x => x?._id == res?.data?.TxMode?._id) : { _id: '0', Name: 'All' }
    });
    setGridData(res.data.Prototypes);
    }
  }

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }
  const onPrototypeSelection = () => {
    prototypeOpen ? setPrototypeOpen(false) : setPrototypeOpen(true);
  }

  const onAddPrototype = () => {
    if (Object.keys(dataItem.Channel).length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    setPrototypeOpen(true);
  }

  const MyDeleteCommandCell = (props) => (
    <div style={{ display: "flex" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onDelete(props.dataItem), () => { })} />
    </div>
  );


  const onDelete = (data) => {
    let filtered = gridData.filter((obj) => obj._id != data._id);
    setGridData(filtered);
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }


  const handleGridData = (dataList) => {
    setGridData([...gridData, ...dataList]);
  }

  function validationCheckForSave() {
    if (dataItem.Name == '' || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.Channel).length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (gridData.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_prototype_stylesheet_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onSave = async () => {

    if (validationCheckForSave()) {
      const saveData = {
        SID: loc.state.copy ? 0 : parseInt(SID),
        Name: dataItem.Name,
        Channel: {_id: dataItem.Channel._id, SID: dataItem.Channel.SID, FullChannelName: dataItem.Channel.FullChannelName },
        Prototypes: gridData.length > 0 ? gridData.map(x => x._id) : [],
        TxMode: {
          _id: dataItem?.TxMode?._id,
          Name: dataItem?.TxMode?.Name
        },
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Name", "=", dataItem.Name]]
      };
       
      console.log(saveData);
      loc.state.copy && delete saveData._id

      let res = await API.saveData(ENTITYNAME.SecondaryEventDefaultPrototype, saveData);
      console.log(res);
      if (!res.success) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      setDataItem({});
      setGridData([]);

      if (!res.success) return;
      navigate(-1);
    }
  }

  return <>
    <div className="row m-1">
      <div className="col-12">
        <EditPageHeader title={`${lang.default_prototype_editpageheader} -> ` + (SID > 0 ? dataItem.Name : `${lang.new_title_editpageheader}`)} disableSave={false} onSubmit={onSave} onCancel={() => navigate(-1)} />
        <div className="row">
          <div className="col-3" style={{ marginTop: "0px" }}>
            <div className="form-group">
              <label htmlFor="">{lang.name_label} *</label>
              <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={onChange} required />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="TabView">{lang.channel_label} *</label>
              <DropDownList
                style={{
                  backgroundColor: "white",
                }}
                data={channel}
                name="Channel"
                textField="FullChannelName"
                dataItemKey="_id"
                value={dataItem.Channel}
                onChange={onChange}
                validator={(value) => value ? "" : "Please select the value"}
              />
            </div>
          </div>
          <div className="col-3 form-group">
              <label htmlFor="TabView">{lang.tx_mode_label} *</label>
              <DropDownList
                style={{backgroundColor: "white",}}
                data={txModes}
                name="TxMode"
                textField="Name"
                dataItemKey="_id"
                value={dataItem.TxMode}
                onChange={onChange}
              />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <input name="Archive" type="checkbox" value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange}/>
            <label className="ml-1">{lang.archive}</label>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <AddRoundButton title={lang.add_prototype_button_tooltip} onClick={onAddPrototype} />
            <Grid data={gridData} style={{ height: "67vh", marginTop: "04px" }}>
              <GridColumn field="" title="" editable={false} cell={MyDeleteCommandCell} width={'40px'} />
              <GridColumn field="Name" title={lang.name_column} editable={false} />
              <GridColumn field="ID" title={lang.id_column} editable={false} />
              <GridColumn field="ProtoTypeSource.Name" title={lang.protoType_source_column} editable={false} />
              <GridColumn field="Group.Description" title={lang.group_column} />
            </Grid>
          </div>
        </div>
      </div>
    </div>
    {prototypeOpen && <CollectionSelection title={lang.select_prototype_collection_header} entityname={ENTITYNAME.Prototype} wherestatement={['Channel._id', '=', dataItem.Channel._id]} setDataList={handleGridData} closeForm={onPrototypeSelection} existingItems={gridData} checkDuplicate={true} width={"45vw"} height={"64vh"} />}
  </>
}


export default DefaultSecondaryEventPrototype;