import React, { useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader'
import { useTranslation } from '../../locale/useTranslation'
import { toast } from 'react-toastify'
import { ENTITYNAME } from '../../framework/constant/constant'
import { utility } from '../../framework/utility/utilityProvider'
import * as API from "../../framework/API/api";
import { Form, FormElement } from '@progress/kendo-react-form'
import { Dialog } from '@progress/kendo-react-dialogs'
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat'

const TimeZoneEditForm = (props) => {
  const lang = useTranslation();

  const blankDataItem = {
    ...props.item,
    SID: props.item.SID ?? 0,
    Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
    Offset: props.item.Offset ? utility.convertMilisecondsToStringWithFrames(props.item.Offset) : "00:00:00:00",
    IsNegativeOffset: props.item.IsNegativeOffset ?? false,
    Archive: props.item.Archive ?? false
  }
  console.log(props.item)

  const [dataItem, setDataItem] = useState(blankDataItem)

  const onChange = (e) => {
    if (e.target.name == 'Archive') {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else if (e.target.name == 'IsNegativeOffset') {
      setDataItem({ ...dataItem, IsNegativeOffset: !dataItem.IsNegativeOffset });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const isValid = () => {
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.Offset == undefined || dataItem.Offset == '') {
      toast.error(`${lang.please_enter_valid_start_time_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    if (isValid()) {
      let data = {
        SID: dataItem.SID ?? 0,
        Description: dataItem.Description,
        Offset: utility.convertStringWithFramesToMilliseconds(dataItem.Offset),
        Archive: dataItem.Archive ?? false,
        IsNegativeOffset: dataItem.IsNegativeOffset ?? false
      }
      console.log(data)
      let res = await API.saveData(ENTITYNAME.TimeZone, data);
      if (res.success) {
        props.cancelEdit();
        props.refresh();
        utility.deleteLocalStorageItem(ENTITYNAME.TimeZone);
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }

  return (
    <Dialog
      title={props.item.SID > 0 ? props.item.Description : "Create Time Zone"}
      onClose={props.cancelEdit}
      width={"770px"}
    >
      <EditPageHeader
        title={""}
        onSubmit={handleSubmit}
        onCancel={props.cancelEdit}
        showTitle={false}
      />
      <Form
        initialValues={dataItem}
        render={(formRenderProps) => (
          <FormElement>
            <div className='row'>
              <div className='col-12'>
                <div className='row mt-2'>
                  <div className='col-12'>
                    <label>{lang.description_label} *</label>
                    <input
                      name={"Description"}
                      type="text"
                      style={{ border: "solid lightgrey 1px", height: "32px" }}
                      onChange={onChange}
                      value={dataItem.Description}
                    />
                  </div>

                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="">{lang.offset_label} *</label>
                      <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="Offset"
                        value={dataItem.Offset}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className='col-6 mt-3'>
                    <input type={"checkbox"} name={"IsNegativeOffset"} style={{ marginTop: "10px" }}
                      onChange={(e) => onChange(e)}
                      value={dataItem.IsNegativeOffset}
                      checked={dataItem.IsNegativeOffset} />
                    <label className="ml-1">{lang.is_negative_offset_default_label}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <input type={'checkbox'}
                      name={"Archive"}
                      label={lang.archive}
                      value={dataItem.Archive}
                      checked={dataItem.Archive}
                      onClick={onChange}
                      onChange={() => { }}
                    />
                    <label className='ml-2'>{lang.archive}</label>
                  </div>
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    </Dialog>

  )
}

export default TimeZoneEditForm
