import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useEffect, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import { useTranslation } from '../../locale/useTranslation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME } from '../../framework/constant/constant';
import * as API from '../../framework/API/api'
import { toast } from 'react-toastify';
import { utility } from '../../framework/utility/utilityProvider';

const MediaFastSegmentationPopup = (props) => {

    // console.log(props.mediaEpisodeEntity)
    // console.log(props.programScheduleEntity)

    const lang = useTranslation();
    const [txMode, setTxMode] = useState([]);
    const [dataItem, setDataItem] = useState({
        TXMode: {},
        Duration: '',
        MediaEpisode: props.mediaEpisodeEntity
    })

    useEffect(() => {
        loadcombo()
    },[])

    const loadcombo = async () => {
        let txmodeRes = await API.getDataLookup(ENTITYNAME.TXMode, {sort: { Name: 1 }});
        setTxMode(txmodeRes.data);  
        setDataItem({ ...dataItem,TXMode : txmodeRes.data.find((x) => x.SID == 1)})
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const getFastSegmentDurations = () => {

        let tempDurations = dataItem.Duration.split(',');
        let durations = [];
        let isValid = true;
        for (let i = 0; i < tempDurations.length; i++) {
            const duration = tempDurations[i];
            if(duration.includes('*')){

                let temp = duration.split('*');

                let { value, isValidTime } = utility.maskStringToTimeFormat(temp[0]);
                if(!isValidTime){
                    return {durations : [], isValid : false};
                }
                let time = value;
                let number = parseInt(temp[1]);

                if(!utility.checkValidNumber(temp[1])){
                    return {durations : [], isValid : false};
                }

                //LOOP TO FILL DURATIONS
                for (let j = 0; j < number; j++) {
                    durations.push(time);
                } 
            } else {

                let { value, isValidTime } = utility.maskStringToTimeFormat(duration);

                if(!isValidTime){
                    return {durations : [], isValid : false};
                }

                durations.push(value);

            }            
        }

        console.log(durations);

        return { durations : durations, isValidDuration : isValid };

    }

    const isValid = () => {
    
        if(dataItem.Duration==undefined || dataItem.Duration.trim() == ""){
          toast.error(`${lang.please_enter_duration}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }

        let { durations, isValidDuration } = getFastSegmentDurations();
        if(durations.length == 0 && !isValidDuration){
            toast.error(lang.please_enter_valid_numbers_in_duration, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
    
        let durationSumInMilliSec = durations.reduce((acc, o) => acc + utility.convertStringWithFramesToMilliseconds(o), 0);

        console.log(durationSumInMilliSec)

        if(!durationSumInMilliSec){
            toast.error(lang.please_enter_valid_numbers_in_duration, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if(durationSumInMilliSec > props.mediaEpisodeEntity.Duration){
            toast.error(`${lang.sum_of_entered_duration_should_be_less_then_or_equal_to_media_duration}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
        if(!dataItem.TXMode || Object.keys(dataItem.TXMode).length==0){    
          toast.error(`${lang.please_select_tx_mode_segment_type_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });                        
          return false;
        }
        
        return true;
    
      }

    const onCreateSegment = async() => {

        if(!isValid()) return;

        let { durations, isValidDuration } = getFastSegmentDurations();

        let payload = {
            txmode: dataItem.TXMode,
            duration: durations,
            mediaEpisodeId: dataItem.MediaEpisode._id,
            source : props.source,
            parentProgramSchedule: props.programScheduleEntity
        }

        let response = await API.createFastSegments(payload);
        if(response.success){
            props.refreshSegments()
            props.onClose()
            setDataItem({ 
                TXMode: {},
                Duration: '',
                MediaEpisode: props.mediaEpisodeEntity 
            })
            console.log(response.data)
        }else{
            // props.onClose()
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            console.log(response)
        }
    }

    const onClose = () => {
        props.onClose();
        setDataItem({ TXMode: {}, Duration: '',  MediaEpisode: props.mediaEpisodeEntity })
    }


    return (
        <>
            <Dialog
                title={`${props.mediaEpisodeEntity.Title} Fast Segmentation`}
                onClose={onClose}
                width={'600px'}
            >
                <EditPageHeader title={""} onSubmit={onCreateSegment} onCancel={onClose} showTitle={false} />
                <div className="row mt-1">
                    <div className="col-6">
                        <label htmlFor="TabView">{lang.duration_label} *</label>
                        <div className="form-group">
                            <input className="form-control form-control-sm" type="text" name="Duration" onChange={onChange} value={dataItem.Duration} />
                        </div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="TabView">{lang.tx_mode_label} *</label>
                        <DropDownList
                            data={txMode}
                            name="TXMode"
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.TXMode}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <p className="text-muted" style={{ fontSize: "10px",margin : '0px' }}>{`Example :`}</p>
                <p className="text-muted" style={{ fontSize: "10px",margin : '0px' }}>{`1. Put input as 10, 12, 8. It will create segment of 3 of these durations in minutes respectively.`}</p>
                <p className="text-muted" style={{ fontSize: "10px",margin : '0px' }}>{`2. Put input as 10*5. It will create a segment of 5 of 10 minutes each.`}</p>
                <p className="text-muted" style={{ fontSize: "10px",margin : '0px' }}>{`3. Put input as 10:20*5, 8:30*5. It will create a segment of 10, in which 5 of 10 min 20 sec and 5 of 8 min 30 sec `}</p>
            </Dialog>
        </>

    )
}

export default MediaFastSegmentationPopup