import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../locale/useTranslation";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";
import { utility } from "../../framework/utility/utilityProvider";

export const AuditLogResponse = (props) => {

    const [finalDisplayValue, setFinalDisplayValue] = useState([]);
    const [showAll, setShowAll] = useState(true);
    const [showObjectDetails, setShowObjectDetails] = useState(false);
    const [selectedObjectData, setSelectedObjectData] = useState({});

    const lang = useTranslation();
    const filterSearchRef = useRef();
    const title = lang.details_label + (props.details ? " - " + props.details?.Event + " (" + props.details?.User + " - " + props.details?.EntryDateTime + ")" : "");

    useEffect(() => {
        getDipslayValue();
    }, []);

    const getValueFromObject = (value, key,) => {

        switch (key.toLowerCase()) {
            case 'channel':
            case 'channels':
                return value?.FullChannelName;
            case 'addby':
            case 'modby':
            case 'reportingto':
                return value?.name;
            case 'mediadealrights':
            case 'publishings':
                return value?.Channel?.map(x => x?.FullChannelName).join(",") + value?.Platform?.map(x => x?.Description).join(",") + " - " + value?.PlatformType?.Description + " ( " + moment(new Date(value?.PublishStartDate)).format("YYYY-MM-DD") + " - " + moment(new Date(value?.PublishEndDate)).format("YYYY-MM-DD") + " )";
            case 'synopsis':
                return value?.Synopsis
            case 'audiotrack':
            case 'localization':
                return value?.Language?.Description;
            case 'segmentdetails':
                return value?.MediaId
            case 'formatdetail':
                return value?._id
            case 'style':
                return value?.color
            case 'roletype':
                return value?.NAME
            case 'segments':
            case 'segment':
                return value?.SegmentType?.Description
        }
        return value?.Description ? value?.Description : value?.Title ? value?.Title : value?.Name ? value?.Name : value?.SegmentType?.Title ? value?.SegmentType?.Title : Object.keys(value).length > 0 ? JSON.stringify(value) : "";
    }
    const getValueFromArray = (value, key) => {
        let tempData = [];

        switch (key?.toLowerCase()) {

            default:
                value?.forEach((item) => {
                    let temp = getValueFromObject(item, key);
                    tempData.push(temp);
                });
                return tempData?.join(", ") || "";
        }
    };

    const getValue = (value, key) => {

        if (value == undefined || value == null) {
            return "";
        }
        let type = typeof value;
        switch (type) {
            case "object":
                if (Array.isArray(value)) {
                    return getValueFromArray(value, key);
                } else {
                    return getValueFromObject(value, key);
                }
            case "string":
                return value;
            case "number":
                if (key.toLowerCase().includes("scheduledate") || key.toLowerCase().includes("slotdatetime")) {
                    return utility.convertMilisecondsToDateTimeString(value);
                } else if (key.toLowerCase().includes("periodfrom") || key.toLowerCase().includes("periodto") || key.toLowerCase().includes("date") || key.toLowerCase().includes("modon") || key.toLowerCase().includes("addon") || key.toLowerCase().includes("orderdate") || key.toLowerCase().includes("nominalonair") || key.toLowerCase().includes("exclusivestartdate") || key.toLowerCase().includes("exclusiveenddate") || key.toLowerCase().includes("publishstartdate") || key.toLowerCase().includes("publishenddate")) {
                    return moment(value).format("DD-MM-YYYY HH:mm:ss");
                } else if (key.toLowerCase().includes("time") || key.toLowerCase().includes("duration") || key.toLowerCase().includes("tcin") || key.toLowerCase().includes("tcout")) {
                    return moment.utc(value).format("HH:mm:ss");
                } else {
                    return value;
                }
            case "boolean":
                return value ? "Yes" : "No";
            default:
                return value;
        }
    }

    const getDipslayValue = () => {
        let finalPreareData = [];
        Object.keys(props?.newValue)?.map(key => {
            if (key == "_id" || key == "SID" || key == "modOn" || key == "modBy") {
                return;
            }
            let newValue = props.newValue ? props.newValue[key] : "";
            let oldValue = (props.oldValue && Object.keys(props.oldValue)?.length > 0) ? props.oldValue[key] : "";

            let tempData = {};
            tempData.Properties = key;
            tempData.NewValue = newValue;
            tempData.OldValue = "";

            if (oldValue != undefined && oldValue != "") {
                tempData.OldValue = oldValue;
            }

            tempData.isChanged = oldValue?.length == 0 ? false : JSON.stringify(newValue) != JSON.stringify(oldValue);

            finalPreareData.push(tempData);
        })

        console.log(finalPreareData);

        setFinalDisplayValue(finalPreareData);
        filterSearchRef.current = finalPreareData;


    }

    const onSearch = (searchText) => {

        const keyword = searchText ? searchText.toLowerCase() : "";

        let showChangesData = filterSearchRef.current.filter((item) => item.isChanged == !showAll);

        if (keyword == undefined || keyword == "") {
            setFinalDisplayValue(showChangesData);
            return;
        }
        let filterSearchData = showChangesData.filter((item) => {
            return item.Properties.toLowerCase().includes(keyword) || JSON.stringify(item.NewValue).toString().toLowerCase().includes(keyword) || JSON.stringify(item.OldValue).toString().toLowerCase().includes(keyword);
        });

        setFinalDisplayValue(filterSearchData);

    };



    const onClickShowChanges = (e) => {
        setShowAll(e.target.checked);
        if (e.target.checked) {
            setFinalDisplayValue(filterSearchRef.current);
        } else {
            let showChangesData = filterSearchRef.current.filter((item) => item.isChanged == !e.target.checked);
            setFinalDisplayValue(showChangesData);
        }
    }

    return (
        <>

            <Dialog
                title={title}
                onClose={props.onClose}
                width={props.width ?? "870px"}
                height={props.height ?? "480px"}
            >
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-3"></div>

                    <div className="col-2" style={{ width: '45%', marginTop: '21px', textAlign: 'right', padding: "0px 0px 0px 15px" }}>
                        <input type="checkbox" checked={showAll} onChange={(e) => onClickShowChanges(e)} />
                        <label className="ml-1">{lang.show_all_label}</label>
                    </div>
                    <div className="input-group input-group col-4 mb-3" >
                        <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onChange={(e) => onSearch(e.target.value)} />
                        <span className="input-group-text">
                            <i className="fa fa-search" />
                        </span>
                    </div>


                    <div className="col-12" style={{ overflowY: 'auto', height: '85%' }}>
                        {finalDisplayValue?.length > 0 ? (
                            <table className="table table-bordered table-hover border">
                                <thead style={{ position: 'sticky', top: '0', background: "#ffffff", zIndex: '1' }}>
                                    <tr className="border">
                                        {Object.keys(finalDisplayValue[0])?.map((item, index) => {
                                            if (item == "isChanged") {
                                                return;
                                            }
                                            return (
                                                <th key={index} className="border" style={{ width: '200px' }}>{item}</th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {finalDisplayValue?.map((rowItem, index) => (
                                        <tr key={index} style={{
                                            background: rowItem.isChanged ? "#d9d9db" : "none"
                                        }}>
                                            {Object.keys(rowItem).map((key, index) => {
                                                if (key == "isChanged") {
                                                    return;
                                                }
                                                return (
                                                    <td key={index} className="border" style={{ width: '200px' }}>
                                                        <div>
                                                            {rowItem && getValue(rowItem[key], rowItem?.Properties)}
                                                            {typeof rowItem[key] === "object" &&
                                                                (rowItem && rowItem[key] && Object.keys(rowItem[key])?.length > 0)
                                                                && <div className="fa fa-info-circle ml-1 cursor-pointer" onClick={() => { setShowObjectDetails(true); setSelectedObjectData(rowItem && rowItem[key]) }}></div>}
                                                        </div>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center mt-2">{lang.no_record_found_error_message}</div>
                        )}
                    </div>
                </div>
            </Dialog>

            {showObjectDetails && <AuditLogResponse onClose={() => setShowObjectDetails(false)} newValue={selectedObjectData} width="750px" height="420px" />}
        </>
    )
}