/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";

export const FaqEditForm = (props) =>  {

    const [dataItem, setDataItem] = useState({ 
      Question: props.item?.Question ?? "",
      FaqCategory: props.item?.FaqCategory  ?? {},
      Answer:  props.item?.Answer ,
      Archive: props.item?.Archive  ?? false
    });
    const lang=useTranslation();
    const [faqcategory,setFaqCategory]= useState([]);

    useEffect(() => {
        loadcombo();
    },[]);

    const loadcombo = async () => {
        var faqCategoryRes = await API.getDataLookup(ENTITYNAME.FaqCategory, {sort: { Description: 1 }});
        setFaqCategory(faqCategoryRes.data);
        setDataItem({ ...dataItem, FaqCategory: utility.getDefaultItem(faqCategoryRes.data)})
    }

    const isValid = () => {
      if(dataItem.Question=="" || dataItem.Question==undefined){
        toast.error(`${lang.please_enter_question_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if(Object.keys(dataItem.FaqCategory).length == 0){
        toast.error(`${lang.please_select_faq_Category_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if(dataItem.Answer=="" || dataItem.Answer==undefined){
        toast.error(`${lang.please_enter_answer_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      return true;
    }  

    const onChange = (e) => {
      if(e.target.name == "Archive"){
        setDataItem({...dataItem, [e.target.name]: !dataItem[e.target.name]});
      }else
      {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
      }
    }

    const handleSubmit = async () => {
      if(isValid()){
        var localData = {
            SID: dataItem.SID ?? 0,
            Question: dataItem.Question,
            FaqCategory: {
                _id: dataItem.FaqCategory._id,
                Description: dataItem.FaqCategory.Description
            },
            Answer: dataItem.Answer,
            Archive : dataItem.Archive ?? false,
            checkDuplicate:true,
            query:[["Question", "=", dataItem.Question]]
        }
        var res = await API.saveData(ENTITYNAME.Faq, localData);
        if(res.success){
            props.closeForm();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.Faq)
            return;
        }else{
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
         }
    }

  return (
    <>
        {<Dialog
            title={lang.faqs_dailog_header}
            onClose={props.closeForm}
            width={"600px"}
        >
          <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.closeForm} showTitle={false}/>
            <Form
            render={(formRenderProps) => (
                <FormElement>
                    <div className="row mt-2">
                    <div className="col-12">
                        <div className="row">
                          <div className="col-6 form-group">
                            <label htmlFor="TabView">{lang.question_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Question" value={dataItem.Question} onChange={onChange}/>
                          </div>  
                          <div className="col-6 form-group">
                          <label htmlFor="TabView">{lang.faq_category_label} *</label>
                          <DropDownList
                              style={{
                                backgroundColor:"white",
                              }}
                              data={faqcategory}
                              name="FaqCategory"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.FaqCategory}
                              onChange={onChange}
                            />  
                          </div> 
                        </div> 
                        <div className="row">
                          <div className="col-12 form-group">
                            <label htmlFor="TabView">{lang.answer_label} *</label>
                            <textarea type="text" className="form-control form-control-sm" name="Answer" value={dataItem.Answer} onChange={onChange}/>
                          </div>                           
                        </div> 
                        <div className="row">
                          <div className="col-3 form-group">
                            <input type={"checkbox"} name={"Archive"}
                              value={dataItem.Archive}
                              checked={dataItem.Archive}
                              onChange={ onChange}/>  
                              <label className='ml-1'>{lang.archive}</label>                              
                          </div> 
                        </div>

                    </div>
                    </div>
                </FormElement>
            )} />
        </Dialog>}
    </>
  )
}