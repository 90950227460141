import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react'
import { useState } from 'react'
import { useTranslation } from '../../locale/useTranslation';
import { useEffect } from 'react';
import { CheckboxCell } from '../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell';
import { CellRender, RowRender } from '../Segment/renderers';
import { EditPageHeader } from '../../components/EditPageHeader';
import { Dialog } from '@progress/kendo-react-dialogs';
import { myTimeCell } from '../../framework/forms/helpercomponents/inline grid cell/MyTimeCell';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { toast } from 'react-toastify';

const MyNumericCell = (props) => {
    const isPlayViaPlayCount = props.dataItem.PlayViaPlayCount

    const onChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                // value: field = 'Weight' ? e.target.value : isPlayViaPlayCount && field == 'PlayCount' ? e.target.value : 0,
                value : e.target.value
            });
        }
    };

    const field = props.field || "";
    const dataValue = props.dataItem[field] === null ? '' : props.dataItem[field]; 
    

    return <td> 
        {field == 'Weightage' && !isPlayViaPlayCount ? <NumericTextBox name={field} value={dataValue} onChange={onChange} min={0} max = {100} /> : 
        isPlayViaPlayCount ? <NumericTextBox name={field} value={dataValue} onChange={onChange} min={0} /> : 0}
    </td>
};

const AutoPlotPromo = (props) => {
    const EDIT_FIELD = 'inEdit';
    const [data, setData] = useState([]);

    const lang = useTranslation();

    useEffect(() => {
        setData(props.data);
    }, [])

    const enterEdit = (dataItem, field) => {
        const newData = data.map(item => ({
            ...item,
            [EDIT_FIELD]: item._id === dataItem._id ? field : undefined
        }));
        console.log(dataItem);
        setData(newData);
    };

    const exitEdit = () => {
        const newData = data.map(item => ({
            ...item,
            [EDIT_FIELD]: undefined
        }));
        setData(newData);
    };

    const itemChange = event => {

        let field = event.field || '';
        event.dataItem[field] = event.value;

        let newData = data.map(item => {
            if (item._id === event.dataItem._id) {
                item[field] = event.value;
            }

            return item;
        });

        console.log(newData)

        setData(newData);
        props.setData(newData);
    };

    const customCellRender = (td, props) => <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;
    const customRowRender = (tr, props) => <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />;
    const MyCheckBoxCell = (props) => <CheckboxCell {...props} disable={false}/>;

    const handleSubmit = () => {
        //VALIDATION 
        if(data.some((x) => x.Weightage > 100)){
            toast.error("Weightage should be less than or equal to 100", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        props.onSave();
    }

    return (
        <>
        <Dialog
            title={`Auto Plot Promo`}
            onClose={props.cancelEdit}
            width={"60%"}
        >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false}/>
        <Grid data={data} resizable={true} style={{ height: "30vh" }} dataItemKey={'_id'} onItemChange={itemChange} cellRender={customCellRender} rowRender={customRowRender} editField={EDIT_FIELD}>
            <GridColumn field="Name" title={lang.name_column} editable={false}/>
            {/* cell={myTimeCell} */}
            <GridColumn field="From" title={lang.from_column} editable={false}/>
            <GridColumn field="To" title={lang.to_column} editable={false}/>
            <GridColumn field="Weightage" title={'Weightage'} editor='numercic' cell = {MyNumericCell}/>
            <GridColumn field="PlayCount" title={'Play Count'} editor='numercic' cell = {MyNumericCell} />
            <GridColumn field="PlayViaPlayCount" title={'Play Vai Play count'} width={'90px'} editor="boolean" cell={MyCheckBoxCell} />
        </Grid>
        </Dialog>
    </>
    )
}

export default AutoPlotPromo