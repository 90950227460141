/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Splitter } from "@progress/kendo-react-layout";
import { getSelectedState, Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { BOOKINGSTATUS, ENTITYNAME, FILEURL, LOCALSTORAGE_KEY, LOGEVENT, MEDIACATEGORIES, MODULE, RECONCILESTATUS, SOCKET_ACTION, SOCKET_EVENTS } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import moment from "moment";
import { getter } from "@progress/kendo-data-query";
import { ContextMenu } from 'primereact/contextmenu';
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import CustomLegend from "../../components/CustomLegend";
import { useTranslation } from "../../locale/useTranslation";
import { useNavigate } from "react-router-dom";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { Dialog } from '@progress/kendo-react-dialogs';
import { EditPageHeader } from "../../components/EditPageHeader";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import socket from "../../framework/socket/socket";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

const TitleCell = (props) => {
    const field = props.field || "";

    const displayValue = `${props.dataItem[field]?.substr(0, 27)}...`;
    var displayValueLength = props.dataItem[field]?.length;

    return <td>
        {displayValueLength >= 28 ? displayValue : props.dataItem[field]}
    </td>;
};

const ScheduleReconciliation = () => {

    const lang = useTranslation();
    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    const reconsiledData = [
        { SID: 0, Description: "All", IsReconciled: '' },
        { SID: 1, Description: "Reconsiled", IsReconciled: true },
        { SID: 2, Description: "Non Reconsiled", IsReconciled: false }
    ]

    const blankDataItem = {
        FromDate: moment(new Date()).format("YYYY-MM-DD"),
        Channel: {},
        Reconsile: reconsiledData[0],
        MediaCategory: { SID: 0, Description: "All" }

    }

    const blankoffsetDataItem = {
        OffsetTimePlus:'00:00:00:00',
        OffsetTimeMinus: '00:00:00:00',
        IgnoreDuration: false
    }
    const [showOffSetSetting, setShowOffSetSetting] = useState(false);

    const [dataItem, setDataItemObject] = useState(blankDataItem);
    const dataItemRef = useRef(blankDataItem);

    const setDataItem = (di) => {
        dataItemRef.current = di;
        setDataItemObject(di);
    }

    const [offsetdataItem, setOffsetDataItem] = useState(blankoffsetDataItem);

    const [scheduleGridData, _setScheduleGridData] = useState([]);
    const schedulerDataRef = useRef([]);
    const setScheduleGridData = (data) => {
        schedulerDataRef.current = data;
        _setScheduleGridData(data);
    }

    const [asRunGridData, _setAsRunGridData] = useState([]);
    const asrunDataRef = useRef([]);
    const setAsRunGridData = (data) => {
        asrunDataRef.current = data;
        _setAsRunGridData(data);
    }

    const [channel, setChannel] = useState([]);
    const contents = useRef([]);
    const filterMediaCategoryContents = useRef([]);
    const [mediaCategory, setMediaCategory] = useState([]);

    //selected item
    const [selectedScheduleState, setSelectedScheduleState] = useState({});
    const [selectedAsRunState, setSelectedAsRunState] = useState({});
    const [selectedScheduleItem, setSelectedScheduleItem] = useState([]);
    const [selectedAsRunItem, setSelectedAsRunItem] = useState([]);

    //Parallel Scrolling
    const scheduleScroll = useRef(null);
    const asRunScroll = useRef(null);
    const parallelScrollRef = useRef(false);
    const [isScrollTogether, setIsScrollTogether] = useState(true);

    //UI variables
    const [scheduleFilterGridData, setScheduleFilterGridData] = useState([]); //temp var to store filtered object 
    const [asRunFilterGridData, setAsRunFilterGridData] = useState([]); //temp var to store filtered object 
    const [scheduleInfo, setScheduleInfo] = useState({ total: 0, reconciled: 0, commercials: 0 });
    const [asrunInfo, setAsrunInfo] = useState({ total: 0, reconciled: 0, commercials: 0 });

    //footer status 
    const [loadingStatus, setLoadingStatus] = useState(
        `${lang.no_record_found_error_message}`
    );
    const navigate = useNavigate();

    useEffect(() => {
        loadcombo();
    }, []);


    const loadcombo = async () => {

        let channelRes = await API.getValidChannels(ENTITYNAME.Channel);
        setChannel(channelRes.data);
        setDataItem({...blankDataItem,Channel : utility.getDefaultItem(channelRes.data)})

        let contentRes = await API.getDataLookup(ENTITYNAME.Content);
        contents.current = contentRes.data.filter((x) => x.MediaCategoryType.MediaCategorySID == MEDIACATEGORIES.Commercial);

        let mediacategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory)
        setMediaCategory([{ SID: 0, Description: "All" }, ...mediacategoryRes.data])

        let offsetsettingRes = await API.getDataLookup(ENTITYNAME.ReconsileSetting);
        setOffsetDataItem(offsetsettingRes.data[0] ? { ...offsetsettingRes.data[0]} : blankoffsetDataItem)
       
    }

    const cm = useRef();//FOR SCHEDULE AND ASRUN RIGHT CLICK : CONTEXT MENU
    const ref = useRef();

    const gridRowRender = (tr, props) => {

        const green = { backgroundColor: "#609EA2", };
        const defaultColor = { backgroundColor: "rgb(255, 255, 224)", };
        const orange = { backgroundColor: "rgb(255, 153, 51)", };


        const trProps = {
            style: props.dataItem.IsReconciled ? (props.dataItem.ReconciledStatus == RECONCILESTATUS.AutoReconciled ? green : orange) : defaultColor,
            onContextMenu: (e) => { ref.current = props.dataItem; cm.current.show(e) },
        };
        // console.log(props.dataItem.ReconciledStatus);
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };


    const onChange = (e) => {

        setDataItem({ ...dataItem, [e.target.name]: e.target.value });

        filterGrids();

    }

    const onChangeOffset = (e) => {
        if (e.target.name == "IgnoreDuration") {
            setOffsetDataItem({ ...offsetdataItem, IgnoreDuration: !offsetdataItem.IgnoreDuration });
            console.log(!offsetdataItem.IgnoreDuration)
        }
        else {
            setOffsetDataItem({ ...offsetdataItem, [e.target.name]: e.target.value });
        }


    }

    const resetBothGrids = () => {
        setScheduleGridData([]);
        setAsRunGridData([]);
        setAsRunFilterGridData([]);
        setScheduleFilterGridData([]);
    }

    const isValid = () => {

        if (!utility.isValidDate(dataItem.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.Channel == undefined || Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const loadScheduledata = async () => {

        // empty
        resetBothGrids();

        if (!isValid()) return;

        setDataItem({ ...dataItem, Reconsile: { SID: 0, Description: "All", IsReconciled: '' }, MediaCategory: { SID: 0, Description: "All" } });

        setLoadingStatus(`${lang.data_loading_error_message}`);

        // filtering contents
        filterMediaCategoryContents.current = (contents.current);

        try {

            const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
            const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
            const offsetTime = offSetHours * 3600000;
            var scheduleStart = new Date(dataItem.FromDate).getTime() + offsetTime;

            var payload = {
                ScheduleDate: scheduleStart ,
                ScheduleEndDate: new Date(dataItem.FromDate).getTime() + offsetTime + (24 * 3600000),
                channelSID: dataItem.Channel.SID,
                isWithOutHeaders: true
            };

            const json = await API.getScheduling(payload);
            console.log(json);

            if (json.data == undefined || json.data.length == 0) {
                setLoadingStatus(`${lang.no_record_found_error_message}`);
                toast.info(`${lang.no_data_found_scheduling_reconciliation_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            //remove header and breaks
            const scheduleWithoutHeader = json.data.filter((item) => item.ParentProgramSchedule_id != null && (item.IsBreak == undefined || item.IsBreak == false));
            const scheduleData = scheduleWithoutHeader.sort((a, b) => a.SlotDateTime - b.SlotDateTime);

            setScheduleGridData(() => scheduleData);

            // setScheduleFilterGridData(() => scheduleData);
            //map function to add seriel number
            var data = []
            scheduleData.map((item, index) => {
                data.push({ SerielNo: index + 1, ...item })
            })

            schedulerDataRef.current = scheduleData;
            setScheduleFilterGridData(data);
            loadAsRun(scheduleData, payload);

        } catch (error) {
            console.log("error", error);
        }

    }

    const loadAsRun = async (scheduleData, schedulePayload) => {


        if (scheduleData.length == 0) {
            setAsRunGridData([]);
            setAsRunFilterGridData([]);
            setAsrunInfo({ total: 0, reconciled: 0, commercials: 0 });
            setLoadingStatus(`${lang.no_record_found_error_message}`);
            return;
        }

        var res = await API.getAsrunData(schedulePayload);

        console.log(res);

        if (res.success) {
            // if IsReconcile is Null It SHould be treated as False.
            setLoadingStatus(`${lang.data_loading_error_message}`);
            var data = [];
            res.data.sort((a, b) => a.SlotDateTime - b.SlotDateTime).map((item, index) => {
                if (item.IsReconciled == undefined) {
                    data.push({ TempSNo: index + 1, ...item, IsReconciled: false });
                } else {
                    data.push({ TempSNo: index + 1, ...item });
                }
            });

            setAsRunGridData(data);
            setAsRunFilterGridData(data);
            updateInfo();
            setLoadingStatus(`${lang.no_record_found_error_message}`);

            // if (data.length > 0) {
            //     toast.success(`${lang.schedule_and_as_run_data_load_successfully_scheduling_reconciliation_success_message}` + res.message, {
            //         position: toast.POSITION.TOP_RIGHT
            //     })
            //     setLoadingStatus(`${lang.no_record_found_error_message}`);
            // }

        }
        else {
            toast.error(`${lang.error_while_loading_as_run_data_scheduling_reconciliation_error_message}` + res.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }

    }

    const MyTimeCell = (props) => {
        return <TimeCell {...props} />;
    }

    const onScheduleSelectionChange = (event) => {

        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedScheduleState,
            dataItemKey: DATA_ITEM_KEY
        });
        setSelectedScheduleState(newSelectedState);

        var selectedItem = scheduleGridData.filter((item) => item._id == Object.keys(newSelectedState)[0])
        setSelectedScheduleItem(selectedItem);

        //for automatically select asRun on selecting program schedule on the basis of ProgramScheduleReconcile_id
        if (selectedItem[0].IsReconciled) {

            console.log(selectedItem[0]);
            setSelectedAsRunState({
                [selectedItem[0].ProgramScheduleReconcile_id]: true
            });
            setSelectedAsRunItem(asRunGridData.filter((item) => item._id == selectedItem[0].ProgramScheduleReconcile_id));
        }
        else {
            var nearestAsRun = getNearestAsRun(selectedItem);
            setSelectedAsRunState({
                [nearestAsRun[0]?._id]: true
            });
            setSelectedAsRunItem(asRunGridData.filter((item) => item?._id == nearestAsRun[0]?._id));
        }

    };

    const getNearestAsRun = (selectedItem) => {
        
        const pOffset = utility.convertStringWithFramesToMilliseconds(offsetdataItem.OffsetTimePlus);
        const nOffset = utility.convertStringWithFramesToMilliseconds(offsetdataItem.OffsetTimeMinus);

        return asRunGridData.filter((item) => {
            if (item.AssetId == selectedItem[0].mediaEpisode.AssetId && !item.IsReconciled && item.SlotDateTime >= selectedItem[0].SlotDateTime - nOffset && item.SlotDateTime <= selectedItem[0].SlotDateTime + pOffset) {
                return item;
            }
        });
    }

    //old
    const autoReconcile = () => {
        if (scheduleGridData.length > 0) {
            scheduleGridData.map(data => {
                if (!data.IsReconciled) {
                    var nearestAsRun = getNearestAsRun([data]);
                    if (nearestAsRun.length > 0) {
                        ReconcileSelectedItems([data], nearestAsRun, RECONCILESTATUS.AutoReconciled,true);
                    }
                }
            })
            toast.success(`${lang.auto_recocile_successfully_scheduling_reconciliation_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            toast.error(`${lang.please_load_first_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        updateInfo();
    }


    //new
    // const autoReconcile = async() => {

    //     //payload for fetch data on backend
    //     const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
    //     const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
    //     const offsetTime = offSetHours * 3600000;

    //     console.log(dataItem.Channel.SID)

    //     var scheduleStart = new Date(dataItem.FromDate).getTime() + offsetTime;

    //     var payload = {
    //         ScheduleDate: scheduleStart,
    //         ScheduleEndDate: new Date(dataItem.FromDate).getTime() + offsetTime + (24 * 3600000),
    //         channelSID: dataItem.Channel.SID,
    //         isWithOutHeaders: true,
    //         offSetDataItem : offsetdataItem
    //     };
        
    //     var matchedResponse = await API.autoReconcilation(payload);
    //     console.log(matchedResponse);
    //     if(matchedResponse.success){
    //         setScheduleGridData(matchedResponse.scheduleData.data);
    //         setAsRunGridData(matchedResponse.asRunData.data);
    //         setScheduleFilterGridData(matchedResponse.scheduleData.data);
    //         setAsRunFilterGridData(matchedResponse.asRunData.data);
    //         toast.success(`${lang.auto_recocile_successfully_scheduling_reconciliation_success_message}`, {
    //             position: toast.POSITION.TOP_RIGHT
    //         })
    //     }else{
    //         toast.error(`${matchedResponse.message}`, {
    //             position: toast.POSITION.TOP_RIGHT
    //         })
    //     }
    //     updateInfo();
    // }

    const openImportAsRun = () => {
        navigate("/home/ImportAsRun");
    }

    const onAsRunSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedAsRunState,
            dataItemKey: DATA_ITEM_KEY
        });
        setSelectedAsRunState(newSelectedState);

        var selectedItem = asRunGridData.filter((item) => item._id == Object.keys(newSelectedState)[0])
        setSelectedAsRunItem(selectedItem);

    };

    const ReconcileSelectedItems = (scheduleItemToMatch = null, asrunItemToMatch = null, reconType = RECONCILESTATUS.ForceMatch, isFromAutoReconcile = false) => {

        console.log(scheduleItemToMatch);

        if (scheduleItemToMatch == null || asrunItemToMatch == null) {
            scheduleItemToMatch = selectedScheduleItem;
            asrunItemToMatch = selectedAsRunItem;
        }

        if (!asRunGridData || asRunGridData.length == 0) {
            contextMenuCancel();
            if(!isFromAutoReconcile)toast.error(`${lang.no_as_run_found_error_message}`, {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        //VALIDATIONS 
        if (scheduleItemToMatch == undefined || scheduleItemToMatch.length == 0 || asrunItemToMatch == undefined || asrunItemToMatch.length == 0) {
            if(!isFromAutoReconcile)toast.error(`${lang.select_first_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if ((scheduleItemToMatch != undefined && scheduleItemToMatch[0]?.IsReconciled) || (asrunItemToMatch != undefined && asrunItemToMatch[0]?.IsReconciled)) {
            contextMenuCancel();
            if(!isFromAutoReconcile)toast.error(`${lang.already_reconsiled_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (scheduleItemToMatch[0].mediaEpisode?.AssetId != asrunItemToMatch[0]?.AssetId) {
            contextMenuCancel();
            if(!isFromAutoReconcile)toast.error(`${lang.select_item_have_different_assetid_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //IF IGNORE DURATION IS NOT CHECKED THEN WE HAVE TO MATCH THE EXACT DURATION
        console.log(offsetdataItem);
        if (offsetdataItem.IgnoreDuration == undefined || offsetdataItem.IgnoreDuration == false) {
            var scheduelData = scheduleGridData.find(x => x._id == scheduleItemToMatch[0]._id);

            var asRunData = {};
            for (var i = 0; i < asrunItemToMatch.length; i++) {
                asRunData = asRunGridData.find(x => x._id == asrunItemToMatch[i]._id);
                if (asRunData != undefined && asRunData._id != undefined) {
                    break;
                }

            }

            if (scheduelData.Duration != asRunData.ActualDuration) {
                contextMenuCancel();
                if(!isFromAutoReconcile)toast.info(`${lang.duration_mismatch_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }


        }

        scheduleGridData.map((item, index) => {
            if (item._id == scheduleItemToMatch[0]._id) {
                var updatedScheduleItem = {
                    ...item,
                    ReconciledStatus: reconType,
                    IsReconciled: true,
                    ActualOnAir: asrunItemToMatch[0].SlotDateTime,
                    ProgramScheduleReconcile_id: asrunItemToMatch[0]._id.toString()
                }
                scheduleGridData.splice(index, 1, updatedScheduleItem);
                setScheduleGridData(scheduleGridData);
            }
        })
        setSelectedScheduleItem({});
        setSelectedScheduleState({});

        asRunGridData.map((item, index) => {
            if (item._id == asrunItemToMatch[0]._id) {
                var updatedAsRunItem = {
                    ...item,
                    ReconciledStatus: reconType,
                    IsReconciled: true,
                    ProgramSchedule_id: scheduleItemToMatch[0]._id.toString()
                }
                asRunGridData.splice(index, 1, updatedAsRunItem);
                setAsRunGridData(asRunGridData);
            }
        })
        setSelectedAsRunItem({});
        setSelectedAsRunState({});
        updateInfo();

        filterGrids();

    }

    const updateInfo = () => {

        // Schedule Side
        const com = schedulerDataRef.current.filter((item) => item.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Commercial && (item.IsBreak == undefined || item.IsBreak == false))
        const scheduleWithoutHeader = schedulerDataRef.current.filter((item) => item.ParentProgramSchedule_id != null && (item.IsBreak == undefined || item.IsBreak == false));
        var reconciledItems = schedulerDataRef.current.filter((item) => item.IsReconciled == true);

        setScheduleInfo(old => { return { ...old, total: scheduleWithoutHeader.length, reconciled: reconciledItems.length, commercials: com.length } });

        // as run Side
        var reconciledAsruns = asrunDataRef.current.filter((item) => item.IsReconciled == true);

        var asRunCom = [];
        if (asrunDataRef.current.length != 0 && filterMediaCategoryContents.current.length != 0) {
            filterMediaCategoryContents.current.map((x) => {
                var temp = asrunDataRef.current.filter((item) => item.AssetId.includes(x.Prefix + x.Suffix));
                // var temp = asrunDataRef.current.filter((item) => item.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Commercial);
                if (temp.length != 0) {
                    asRunCom.push(...temp);
                }
            })
        }

        setAsrunInfo({ total: asrunDataRef.current.length, reconciled: reconciledAsruns.length, commercials: asRunCom.length });

    }

    const offsetSettingdata = () => {

        setShowOffSetSetting(true);
    }

    const forceUnMatch = () => {

        if (selectedScheduleItem[0] == undefined || selectedAsRunItem[0] == undefined) {

            contextMenuCancel();
            toast.error(`${lang.please_select_schedule_and_as_run_item_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //VALIDATIONS 
        if (!selectedScheduleItem[0].IsReconciled || !selectedAsRunItem[0].IsReconciled) {
            contextMenuCancel();
            toast.error(`${lang.select_item_is_not_reconsiled_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (selectedScheduleItem[0]._id.toString() != selectedAsRunItem[0].ProgramSchedule_id) {
            contextMenuCancel();
            toast.error(`${lang.select_schedule_and_asrun_item_not_reconciled_together_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (selectedScheduleItem[0].booking && selectedScheduleItem[0].booking.BookingStatusSID > BOOKINGSTATUS.Reconciled) {
            contextMenuCancel();
            toast.error(`${lang.select_schedule_item_already_billed_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        scheduleGridData.map((item, index) => {
            if (item._id == Object.keys(selectedScheduleState)[0]) {
                var updatedScheduleItem = {
                    ...item,
                    ReconciledStatus: RECONCILESTATUS.None,
                    IsReconciled: false,
                    ActualOnAir: null,
                    ProgramScheduleReconcile_id: null
                }
                scheduleGridData.splice(index, 1, updatedScheduleItem);
                setScheduleGridData(scheduleGridData);
            }
        })
        setSelectedScheduleItem({});
        setSelectedScheduleState({});

        asRunGridData.map((item, index) => {
            if (item._id == Object.keys(selectedAsRunState)[0]) {
                var updatedAsRunItem = {
                    ...item,
                    ReconciledStatus: RECONCILESTATUS.None,
                    IsReconciled: false,
                    ProgramSchedule_id: null
                }
                asRunGridData.splice(index, 1, updatedAsRunItem);
                setAsRunGridData(asRunGridData);

            }
        })
        setSelectedAsRunItem({});
        setSelectedAsRunState({});
        toast.success(`${lang.force_unmatch_successfully_scheduling_reconciliation_success_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        updateInfo();

        filterGrids();
    }

    const filterGrids = () => {

        // filtering contents
        var filteredContent = contents.current.filter((item) => item.MediaCategoryType.MediaCategorySID == dataItemRef.current.MediaCategory.SID);
        
        filterMediaCategoryContents.current = filteredContent;

        // filtyering schedule by media category & reconciled status selected
        var filteredSchedules = schedulerDataRef.current.filter((item) =>
            (dataItemRef.current.MediaCategory.SID == 0 || item.mediaEpisode.MediaCategory.SID == dataItemRef.current.MediaCategory.SID)
            &&
            (dataItemRef.current.Reconsile.SID == 0 || item.IsReconciled == dataItemRef.current.Reconsile.IsReconciled));
        setScheduleFilterGridData(() => filteredSchedules);


        // filtering as run side data with media category and reconcile status
        var filteredAsruns = asrunDataRef.current.filter((item) =>
            (dataItemRef.current.MediaCategory.SID == 0 || filterMediaCategoryContents.current.some(x => item.AssetId.includes(x.Prefix + x.Suffix)))
            &&
            (dataItemRef.current.Reconsile.SID == 0 || item.IsReconciled == dataItemRef.current.Reconsile.IsReconciled));
        setAsRunFilterGridData(() => filteredAsruns);

    }

    //clear selected Item 
    const contextMenuCancel = () => {

        setSelectedScheduleItem({});
        setSelectedScheduleState({});
        setSelectedAsRunItem({});
        setSelectedAsRunState({});

    }

    const refreshLocalStatusforOtheruser = () => {
        const date = new Date(dataItem.FromDate)
        socket.emit(SOCKET_EVENTS.onSocketData, {
          action: SOCKET_ACTION.SCHEDULE_LOCK_UPDATE,
          module: MODULE.SCHEDULING,
          data: {
            selectedChannelSID: dataItem.Channel.SID, selectedScheduleDate: date.getTime()
          },
          user:  {
            _id: userData._id,
            name: userData.name
        }
        });
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_REFRESH, module: MODULE.PLANNING, data: {scheduleScheduleStartDate: date.getTime(), scheduleScheduleEndDate: date.getTime(), channelSID: dataItem.Channel.SID} });
    }

    const saveReconciliationStatus = async () => {

        if (scheduleGridData.length == 0) {
            toast.info(`${lang.no_data_schedule_to_save_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }
        if (asRunGridData.length == 0) {
            toast.info(`${lang.no_data_asrun_to_save_scheduling_reconciliation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }

        var saveData = {
            programSchedule: scheduleGridData,
            asRun: asRunGridData,
        }
        console.log(saveData);
        var res = await API.saveReconciliationStatus(saveData);

        const channelObj = {
            _id: dataItem.Channel._id,
            name: dataItem.Channel.FullChannelName,
        }
        const date = new Date(dataItem.FromDate)

        if (res.success) {
            //LOCK PLANNING AND SCHEDULE WHEN RECONCILED SUCCESSFULLY
            let lockSchedule = await utility.lockUnlockPlaylist(channelObj, date.getTime(), MODULE.SCHEDULING);
            let logScheduleData = { 
                event: LOGEVENT.LOCK_SCHEDULE, 
                module: MODULE.SCHEDULING, 
                data:  {
                    _id: userData._id,
                    name: userData.name
                }, 
                message: LOGEVENT.LOCK_SCHEDULE 
            };
            API.SaveLogs(logScheduleData);
            let lockPlanning = await utility.lockUnlockPlaylist(channelObj, date.getTime(), MODULE.PLANNING);
            let logPlanningData = {
                event: LOGEVENT.LOCK_PLANNING, 
                module: MODULE.PLANNING, 
                data:  {
                    _id: userData._id,
                    name: userData.name
                }, 
                message: LOGEVENT.LOCK_PLANNING 
            }
            API.SaveLogs(logPlanningData);
            toast.success(`${lang.saved_successfully_scheduling_reconciliation_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            })
            refreshLocalStatusforOtheruser();
        } else {
            console.log(res.message)
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        // console.log(res);
        // if(!res.success) return;
    }

    const onScheduleScroll = (event) => {
        if (!isScrollTogether) return;

        const { scrollLeft, scrollTop } = event.nativeEvent.target;

        if (parallelScrollRef.current) {
            parallelScrollRef.current = false;
            return;
        }

        parallelScrollRef.current = true;
        asRunScroll.current.containerRef.current.scrollTo(scrollLeft, scrollTop);
    };

    const onAsRunScroll = (event) => {
        if (!isScrollTogether) return;

        const { scrollLeft, scrollTop } = event.nativeEvent.target;

        if (parallelScrollRef.current) {
            parallelScrollRef.current = false;
            return;
        }

        parallelScrollRef.current = true;
        scheduleScroll.current.containerRef.current.scrollTo(scrollLeft, scrollTop);

    };
    const isValidforOffSetSetting = () => {
        if (offsetdataItem.OffsetTimeMinus === undefined || offsetdataItem.OffsetTimeMinus === "") {
            toast.error(`${lang.please_enter_OffSet_Time_Minus_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (offsetdataItem.OffsetTimePlus === undefined || offsetdataItem.OffsetTimePlus === "") {
            toast.error(`${lang.please_enter_offSet_time_plus_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const onSaveOffSetSetting = async () => {
        if (isValidforOffSetSetting()) {

            var localData = {
                SID: offsetdataItem.SID ?? 0,
                OffsetTimeMinus: offsetdataItem.OffsetTimeMinus,
                OffsetTimePlus: offsetdataItem.OffsetTimePlus,
                IgnoreDuration: offsetdataItem.IgnoreDuration ? true : false,
            }

            var res = await API.saveData(ENTITYNAME.ReconsileSetting, localData);
            console.log(localData);
            if (res.success) {
                // navigate(-1);
                setShowOffSetSetting(false);
                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setShowOffSetSetting(false);
            utility.deleteLocalStorageItem(ENTITYNAME.ReconsileSetting)
            setOffsetDataItem(blankoffsetDataItem);
        }
    }

    const downloadExcel = async () => {

        if (scheduleGridData.length == 0) {
            toast.info(`${lang.no_data_schedule_to_download_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }

        console.log(scheduleGridData);
        console.log(blankoffsetDataItem);
        var saveData = scheduleGridData.map((x, index) => {
            return {
                SerielNo: index,
                Date: moment(new Date(x.SlotDateTime)).utc().format("YYYY-MM-DD"),
                Time: utility.convertMilisecondsToStringWithFrames(x.SlotDateTime),
                AssetId: x.mediaEpisode.AssetId,
                Title: x.mediaEpisode.Title,
                Duration: utility.convertMilisecondsToStringWithFrames(x.Duration),
                Category: x.mediaEpisode.MediaCategory.Description,
                Source: x.Source,
                ReconciledStatus: x.IsReconciled ? "Reconciled" : "Not Reconciled",
                ReconciledOffSet: blankoffsetDataItem.OffsetTimeMinus !="00:00:00:00" && blankoffsetDataItem.OffsetTimePlus !="00:00:00:00" ? "Yes" : blankoffsetDataItem.IgnoreDuration ? "NA" : "No"
            }
        })

        console.log(saveData);
        var res = await API.exportCSV(saveData);
        console.log(res.data);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    return <div style={{ height: "70%" }}>

        <div className="row" style={{ fontSize: "10px", margin: "2px", border: '1px solid black', borderRadius: '5px!important' }}>
            <div className="col-12">
                <div className="row mt-1">
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="">{lang.from_date_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="FromDate"
                                value={dataItem.FromDate}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                            }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                    <div className="row ml-1" style={{ marginTop: "18px", padding: "0px 0px 15px 0px" }}>
                        <ActionButton btnColor={'info'} style={{ borderRadius: '5px' }} title={lang.load_button_tooltip} name={lang.load_button_text} onClick={loadScheduledata} />
                        <ActionButton title={lang.save_button_tooltip} style={{ borderRadius: '5px' }} name={lang.save_button_text} onClick={saveReconciliationStatus} />
                        <RoundButton style={{ marginLeft: '5px' }} icon={'check-square'} title={lang.auto_reconcile_button_tooltip} onClick={autoReconcile} />
                        <RoundButton style={{ marginLeft: '5px' }} icon={'upload'} title={lang.import_as_run_button_tooltip} onClick={openImportAsRun} />
                        <RoundButton style={{ marginLeft: '5px' }} icon={'download'} title={lang.download_button_tooltip} onClick={downloadExcel} />
                        <RoundButton style={{ marginLeft: '5px' }} icon={'slack'} title={lang.ref_off_setting_label} onClick={offsetSettingdata} />
                    </div>
                    <div className="col" style={{ textAlign: "right" }}>
                        {/* checkbox */}
                        <Checkbox
                            name="ScrollTogether"
                            label={"Scroll together"}
                            labelPlacement="before"
                            checked={isScrollTogether}
                            onChange={() => { setIsScrollTogether(!isScrollTogether) }}
                        />
                    </div>

                </div>
                <div className="row">

                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.reconciled_label} *</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white",
                                }}
                                data={reconsiledData}
                                defaultValue={reconsiledData[0]}
                                name="Reconsile"
                                textField="Description"
                                dataItemKey="SID"
                                value={dataItem.Reconsile}
                                onChange={onChange}
                                validator={(value) => value ? "" : "Please select the value"}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.media_category_label} *</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white",
                                }}
                                data={mediaCategory}
                                defaultValue={mediaCategory[0]}
                                name="MediaCategory"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.MediaCategory}
                                onChange={onChange}
                                validator={(value) => value ? "" : "Please select the value"}
                            />
                        </div>
                    </div>

                    <div className="col mt-4" >
                        <div className="row" style={{ textAlign: 'right' }}>
                            <div className="col">&nbsp;</div>

                            <div className="mr-1">
                                {/* <p style={{margin : '0px',display:'flex'}}><div style={{width : '15px' ,height : '15px',margin:"0px" ,padding:"0px",backgroundColor : 'rgb(0, 255, 0)',margin:'02px 05px'}}></div> */}
                                <CustomLegend title={lang.reconciled_r_label} onHoverTitle={lang.reconciled_label} titleColor="black" tileBackColor="#609EA2"></CustomLegend>
                                {/* </p> */}

                            </div>
                            <div className="mr-1">
                                {/* <p style={{margin : '0px',display:'flex'}}><div style={{width : '15px' ,height : '15px',margin:"0px" ,padding:"0px",backgroundColor : 'rgb(255, 255, 224)',margin:'02px 05px'}}></div> */}
                                <CustomLegend title={lang.non_reconciled_nr_label} onHoverTitle={lang.non_reconciled_label} titleColor="black" tileBackColor="rgb(255, 255, 224)" ></CustomLegend>
                                {/* </p> */}
                            </div>
                            <div className="mr-1">
                                {/* <p style={{margin : '0px',display:'flex'}}><div style={{width : '15px' ,height : '15px',margin:"0px" ,padding:"0px",backgroundColor : 'rgb(255, 153, 51)',margin:'02px 05px'}}></div> */}
                                <CustomLegend title={lang.force_match_fm_label} onHoverTitle={lang.force_match_label} tileBackColor="rgb(255, 153, 51)" ></CustomLegend>
                                {/* </p> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className="row" style={{ height: "25px", fontSize: "12px", marginTop: "05px" }}>
            <div className="col-2">
                {lang.total_label} {scheduleInfo.total}
            </div>
            <div className="col-2" >
                {lang.reconciled_label}: {scheduleInfo.reconciled}
            </div>
            <div className="col-2">
                {lang.total_commercial} {scheduleInfo.commercials}
            </div>

            <div className="col-2">
                {lang.total_label} {asrunInfo.total}
            </div>
            <div className="col-2">
                {lang.reconciled_label}: {asrunInfo.reconciled}
            </div>
            <div className="col-2">
                {lang.total_commercial} {asrunInfo.commercials}
            </div>


        </div>

        <Splitter
            style={{ height: "100%" }}

        >

            {/* parent grid */}
            <>
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{lang.schedule_label}</div>
                <Grid data={scheduleFilterGridData.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedScheduleState[idGetter(item)]
                    // "selected" : true
                }))}
                    selectedField={SELECTED_FIELD}
                    selectable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    onSelectionChange={onScheduleSelectionChange}
                    rowRender={gridRowRender}
                    style={{ height: "95%" }}
                    ref={scheduleScroll}
                    onScroll={onScheduleScroll}
                >
                    <GridNoRecords>{loadingStatus}</GridNoRecords>
                    <Column field="SerielNo" title={lang.sno_column} width={70} />
                    <Column field="SlotDateTime" title={lang.date_column} cell={DateOnlyCell} width={100} />
                    <Column field="SlotDateTime" title={lang.time_column} cell={MyTimeCell} width={100} />
                    <Column field="mediaEpisode.AssetId" title={lang.assetId_column} width={150} />
                    <Column field="mediaEpisode.Title" title={lang.title_column} width={250} />
                    <Column field="Duration" title={lang.duration_column} cell={MyTimeCell} width={100} />
                    <Column field="IsReconciled" title={lang.is_reconciled_column} width={100} />
                    <Column field="mediaEpisode.MediaCategory.Description" title={lang.category_column} width={100} />
                    <Column field="Source" title={lang.source_column} width={100} />


                </Grid>
            </>

            <>
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{lang.as_run_label}</div>
                <Grid data={asRunFilterGridData.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedAsRunState[idGetter(item)]
                }))}
                    selectedField={SELECTED_FIELD}
                    selectable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    onSelectionChange={onAsRunSelectionChange}
                    rowRender={gridRowRender}
                    style={{ height: "95%" }}
                    ref={asRunScroll}
                    onScroll={onAsRunScroll}
                >
                    {/* <Column field="SNo" title="SNo."  width={40} /> */}
                    <GridNoRecords>{loadingStatus}</GridNoRecords>
                    <Column field="TempSNo" title={lang.sno_column} width={70} />
                    <Column field="SlotDateTime" title={lang.date_column} cell={DateOnlyCell} width={100} />
                    <Column field="SlotDateTime" title={lang.time_column} cell={MyTimeCell} width={100} />
                    <Column field="AssetId" title={lang.assetId_column} width={150} />
                    <Column field="Title" title={lang.title_column} width={250} cell={TitleCell} />
                    <Column field="ActualDuration" title={lang.duration_column} cell={MyTimeCell} width={100} />
                    <Column field="IsReconciled" title={lang.is_reconciled_column} width={100} />
                    <Column field="Channel.SID" title={"Channel"} width={100} />
                    <Column field="" title={""} width={100} />

                </Grid>
                <ContextMenu ref={cm} model={
                    [
                        { label: `${lang.ref_force_match_label}`, icon: 'pi pi-fw pi-window-minimize', command: () => { ReconcileSelectedItems() } },
                        { label: `${lang.ref_force_unmatch_label}`, icon: 'pi pi-fw pi-window-maximize', command: () => { forceUnMatch() } },
                        { label: `${lang.ref_cancel_label}`, icon: 'pi pi-fw pi-times', command: () => { contextMenuCancel() } }
                    ]
                } />

            </>


        </Splitter>

        {showOffSetSetting && <Dialog title={lang.offsetsetting_dialog_header} onClose={() => { setShowOffSetSetting(false);}} width={"400px"} height={"300px"}>
            <div className="row">
                <div className="col-12">
                    <EditPageHeader showTitle={false} onSubmit={onSaveOffSetSetting} onCancel={() => { setShowOffSetSetting(false);}} />
                    <div className="row mt-2">
                        <div className="col-5 mt-4">
                            <div className="form-group">
                                <label htmlFor="">{lang.offset_time_minus_label} * :</label>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="form-group">
                                <label htmlFor="">{lang.time_hh_mm_ss_ff_label}</label>
                                <TimePickerWithFormat
                                    className="form-control form-control-sm"
                                    name="OffsetTimeMinus"
                                    value={offsetdataItem.OffsetTimeMinus}
                                    onChange={onChangeOffset}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-5 mt-4">
                            <div className="form-group">
                                <label htmlFor="">{lang.offset_time_plus_label} * :</label>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="form-group">
                                <label htmlFor="">{lang.time_hh_mm_ss_ff_label}</label>
                                <TimePickerWithFormat
                                    className="form-control form-control-sm"
                                    name="OffsetTimePlus"
                                    value={offsetdataItem.OffsetTimePlus}
                                    onChange={onChangeOffset}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5"> <label style={{ textAlign: "left", marginRight: "10px" }} htmlFor="TabView">{lang.ignore_duration_lable} :</label> </div>
                        <div className="col-2"> <input type="checkbox" name={"IgnoreDuration"} value={offsetdataItem.IgnoreDuration} checked={offsetdataItem.IgnoreDuration} onChange={onChangeOffset} /> </div>
                    </div>
                </div>
            </div>
        </Dialog>
        }
    </div>
}

export default ScheduleReconciliation;