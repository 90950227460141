/* eslint-disable */
import React, { useEffect } from "react";
import { utility } from "../../framework/utility/utilityProvider";
import { LOCALSTORAGE_KEY, LOGEVENTARRAY, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../../framework/constant/constant";
import { useState } from "react";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Avatar from "../../components/Avatar";
import useSocket from "../../framework/socket/useSocket";
import { useNavigate } from "react-router";



export const PlaningFooter = (props) => {

    const { statusMsg, selectedPlanningItemCount, plannerCopiedItemsCount } = props;
    const [activteUsers, setActivteUsers] = useState([]);
    const durationTypes = ["Nominal", "ActualDuration"];
    const startTypes = ["Nominal", "Actual"];
    const lang = useTranslation();
    const navigate = useNavigate();
    const [isMinified, setIsMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified))
    let user = utility.getValue(LOCALSTORAGE_KEY.userData);

    window.addEventListener('isMinified', () => {
        setIsMinified(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
    });

    //socket
    const socket = useSocket(SOCKET_EVENTS.onSocketUsers, (socketData) => {
        if (socketData.module != MODULE.PLANNING) return; //no need to filter active user from module 

        switch (socketData.action) {
            case SOCKET_ACTION.ADD_ACTIVE_USER:
            case SOCKET_ACTION.REMOVE_ACTIVE_USER:
                let scheduleActiveUsers = socketData.data.filter((item) => item.module == MODULE.PLANNING);
                console.log(scheduleActiveUsers);
                if (scheduleActiveUsers.length > 0) {
                    const unique = scheduleActiveUsers.filter((obj, index) => {
                        return index === scheduleActiveUsers.findIndex(o => obj?.user?.name === o?.user?.name);
                    });
                    setActivteUsers(unique);
                }
                break;
            default:
                break;
        }
    });
    //for activate user;
    useEffect(() => {
        socket.emit(SOCKET_EVENTS.onSocketUsers, { action: SOCKET_ACTION.ADD_ACTIVE_USER, module: MODULE.PLANNING, data: { name: user.name, _id: user._id, color: user.color } });

        return () => {

            socket.emit(SOCKET_EVENTS.onSocketUsers, { action: SOCKET_ACTION.REMOVE_ACTIVE_USER, module: MODULE.PLANNING, data: { name: user.name, _id: user._id, color: user.color } });
        }

    }, []);


    const IconsStyle = (color) => {
        return { color: color, fontSize: "1.0em", size: "1.6em", marginRight: "0.12em" };
    }

    return <div className={isMinified ? "scheduleFooter scheduleFooterMinified" : 'scheduleFooter'}>
        <div className="row mr-2">

            <div className="col-9" >
                <div className="flex-container" style={{ alignItems: "left", justifyContent: "left" }}>

                    <button title={lang.filter_button_tooltip} type="button" className="btn btn-outline btn-sm" onClick={props.handleChannelFilterDialog}>
                        <i style={IconsStyle("#ffffff")} className="fa fa-filter"></i>
                    </button>

                    <button title={lang.refresh_button_tooltip} type="button" className="btn btn-sm" onClick={props.planningLoading}>
                        <i className="k-icon k-i-reload pb-1" style={IconsStyle("#ffffff")}></i>
                    </button>

                    <button title={lang.planing_preference_button_tooltip} type="button" className="btn btn-outline btn-sm" onClick={props.onOpenPlanningPreference}>
                        <i className="fa fa-cogs" style={IconsStyle("#ffffff")}></i>
                    </button>
                    <button title={lang.check_list_button_tooltip} type="button" className="btn btn-outline btn-sm" onClick={props.onCheckList}>
                        <i className="fa fa-calendar-check-o" style={IconsStyle("#ffffff")}></i>
                    </button>
                    <button title={lang.export_planning_button_tooltip} type="button" className="btn btn-sm" onClick={props.showExcelPopup} >
                        <i className="fa fa-file-export " style={IconsStyle("#ffffff")}></i>
                    </button>
                    <button title={lang.copy_Schedule_button_tooltip} type="button" className="btn btn-sm" onClick={props.onShowCopySchedulePopup} >
                        <i className="fa fa-copy " style={IconsStyle("#ffffff")}></i>
                    </button>

                    <div title={lang.selected_content_text_planning_footer} className="iconBase2" >
                        <span className="footerText">{selectedPlanningItemCount}&nbsp;{lang.selected_label}</span>
                    </div>

                    <div title={lang.copied_content_text_planning_footer} className="iconBase2" >
                        <span className="footerText">{plannerCopiedItemsCount}&nbsp;{lang.copied_label}</span>
                    </div>

                    {activteUsers?.map((item) => {
                        return <Avatar data={item} />
                    })}

                    <label className="footerText" style={{marginLeft:"20px", marginTop:"8px"}}>{lang.start_type_label}:</label>
                    <DropDownList
                        value={props.startType}
                        className="mt-1 mr-2"
                        style={{
                            width: "100px",
                            marginLeft: "5px",
                            height:"25px"
                        }}
                        data={startTypes}
                        onChange={(e) => {
                            props.onStartTypeChange(e.value);
                        }}
                    />
                    
                    <label className="footerText" style={{marginTop:"8px"}}>{lang.duration_type_label}:</label>
                    <DropDownList
                        value={props.durationType}
                        className="mt-1 mr-2"
                        style={{
                            width: "100px",
                            marginLeft: "5px",
                            height:"25px"
                        }}
                        data={durationTypes}
                        onChange={(e) => {
                            props.onDurationTypeChange(e.value);
                        }}
                    />

                    



                </div>
            </div>


            <div className="col-3 mt-2" style={{ textAlign: "right" }}>
                <div className="row">
                    <div className="col">
                        <span className="footerText">{statusMsg}</span>

                    </div>

                </div>

            </div>

        </div>


    </div>
}




export default PlaningFooter;